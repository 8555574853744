import { ModuleWithProviders, NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { PmpRestApiService } from './pmp-rest-api.service';
import { SharedDialogsNotifyErrorModule } from '@fixiti/shared/dialogs/notify-error/src';

const MATERIAL = [MatDialogModule, MatCardModule, MatButtonModule];

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        SharedDialogsNotifyErrorModule,
        ...MATERIAL,
    ],
})
export class ApiRestPmpModule {
    /* istanbul ignore next */
    static forRoot(): ModuleWithProviders {
        return {
            /* ts-lint ignore next */
            ngModule: RootRestApiPmpModule,
        };
    }
}

@NgModule({
    imports: [ApiRestPmpModule],
    exports: [ApiRestPmpModule],
    providers: [PmpRestApiService],
})
export class RootRestApiPmpModule {}
