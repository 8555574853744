"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var effects_1 = require("@ngrx/effects");
var actions_1 = require("@fixiti/actions");
var nx_1 = require("@nrwl/nx");
var src_1 = require("@fixiti/api/rest/pmp/src");
var operators_1 = require("rxjs/operators");
var RolesEffects = /** @class */ (function () {
    function RolesEffects(actions$, dataPersistence, dataService) {
        var _this = this;
        this.actions$ = actions$;
        this.dataPersistence = dataPersistence;
        this.dataService = dataService;
        this.loadRoles$ = this.dataPersistence.fetch(actions_1.RolesActionTypes.LoadRoles, {
            run: function () {
                return _this.dataService
                    .getRoles()
                    .pipe(operators_1.map(function (roles) { return new actions_1.RolesLoaded(roles); }));
            },
            onError: function (_a, error) {
                return new actions_1.RolesLoadError(error);
            },
        });
        this.updateRole$ = this.dataPersistence.fetch(actions_1.RolesActionTypes.UpdateRole, {
            run: function (action) {
                return _this.dataService
                    .updateRole(action.payload.id, action.payload.name)
                    .pipe(operators_1.map(function (role) { return new actions_1.RoleUpdated(role); }));
            },
            onError: function (_a, error) { return ({
                type: 'ERROR',
                error: error,
            }); },
        });
        this.addUserToRole$ = this.dataPersistence.fetch(actions_1.RolesActionTypes.AddUserToRole, {
            run: function (action) {
                return _this.dataService
                    .addRoleToUser(action.payload.roleId, action.payload.managerId)
                    .pipe(operators_1.map(function (result) {
                    return new actions_1.UserAddedToRole({
                        roleId: action.payload.roleId,
                        user: result,
                    });
                }));
            },
            onError: function (_a, error) { return ({
                type: 'ERROR',
                error: error,
            }); },
        });
        this.removeUserFromRole$ = this.dataPersistence.fetch(actions_1.RolesActionTypes.RemoveUserFromRole, {
            run: function (action) {
                return _this.dataService
                    .removeRoleFroUser(action.payload.roleId, action.payload.managerId)
                    .pipe(operators_1.map(function () { return new actions_1.UserRemovedFromRole(action.payload); }));
            },
            onError: function (_a, error) { return ({
                type: 'ERROR',
                error: error,
            }); },
        });
        this.loadPermissionsForRole$ = this.dataPersistence.fetch(actions_1.RolesActionTypes.LoadPermissionForRole, {
            run: function (action) {
                return _this.dataService
                    .getPermissionForRole(action.payload.roleId, action.payload.permissionId)
                    .pipe(operators_1.map(function (permission) {
                    return new actions_1.PermissionForRoleLoaded(permission);
                }));
            },
            onError: function (_a, error) { return ({
                type: 'ERROR',
                error: error,
            }); },
        });
        this.loadUsersForRole$ = this.dataPersistence.fetch(actions_1.RolesActionTypes.LoadUsersForRole, {
            run: function (action) {
                return _this.dataService
                    .getUsersWithRole(action.payload)
                    .pipe(operators_1.map(function (users) { return new actions_1.UsersForRoleLoaded(users); }));
            },
            onError: function (_a, error) { return ({
                type: 'ERROR',
                error: error,
            }); },
        });
    }
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], RolesEffects.prototype, "loadRoles$", void 0);
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], RolesEffects.prototype, "updateRole$", void 0);
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], RolesEffects.prototype, "addUserToRole$", void 0);
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], RolesEffects.prototype, "removeUserFromRole$", void 0);
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], RolesEffects.prototype, "loadPermissionsForRole$", void 0);
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], RolesEffects.prototype, "loadUsersForRole$", void 0);
    return RolesEffects;
}());
exports.RolesEffects = RolesEffects;
