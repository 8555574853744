"use strict";
/// <reference path="../../../../../models/src/lib/api/permission.ts" />
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var actions_1 = require("@fixiti/actions");
exports.permissionsInitialState = {
    list: [],
    loaded: false,
    pendingChanges: [],
};
function permissionsReducer(state, action) {
    if (state === void 0) { state = exports.permissionsInitialState; }
    switch (action.type) {
        case actions_1.PermissionsActionTypes.PermissionsLoaded: {
            state = __assign({}, state, { list: action.payload, loaded: true });
            break;
        }
        case actions_1.PermissionsActionTypes.PermissionsSaved: {
            state = __assign({}, state, { pendingChanges: [] });
            break;
        }
        case actions_1.PermissionsActionTypes.ChangePermission: {
            var index = state.pendingChanges.findIndex(function (item) {
                return item.roleId === action.payload.roleId &&
                    item.permissionId === action.payload.roleId;
            });
            if (index >= 0) {
                state = __assign({}, state, { pendingChanges: state.pendingChanges.slice(0, index).concat([
                        action.payload
                    ], state.pendingChanges.slice(index + 1)) });
            }
            else {
                state = __assign({}, state, { pendingChanges: state.pendingChanges.concat([action.payload]) });
            }
            break;
        }
        case actions_1.PermissionsActionTypes.DiscardPermissionChange: {
            var index = state.pendingChanges.findIndex(function (item) {
                return item.roleId === action.payload.roleId &&
                    item.permissionId === action.payload.roleId;
            });
            if (index >= 0) {
                state = __assign({}, state, { pendingChanges: state.pendingChanges.slice(0, index).concat(state.pendingChanges.slice(index + 1)) });
            }
            break;
        }
        case actions_1.PermissionsActionTypes.PermissionsUpdated: {
            state = __assign({}, state, { pendingChanges: [] });
            break;
        }
        case actions_1.RolesActionTypes.SelectRole: {
            state = __assign({}, state, { list: [], loaded: false });
            break;
        }
    }
    return state;
}
exports.permissionsReducer = permissionsReducer;
