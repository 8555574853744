"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var ModalComponent = /** @class */ (function () {
    function ModalComponent(componentFactoryResolver, changeDetectorRef) {
        this.componentFactoryResolver = componentFactoryResolver;
        this.changeDetectorRef = changeDetectorRef;
        this.ngUnsubscribe = new rxjs_1.Subject();
        this.events = [];
        this.close = new core_1.EventEmitter();
        this.childEvents = new core_1.EventEmitter();
    }
    ModalComponent.prototype.ngOnChanges = function () {
        this.refreshComponent();
    };
    ModalComponent.prototype.ngAfterViewInit = function () {
        this.refreshComponent();
    };
    ModalComponent.prototype.squelch = function (event) {
        event.stopPropagation();
    };
    ModalComponent.prototype.doClose = function (event) {
        event.preventDefault();
        event.stopPropagation();
        this.close.emit();
    };
    ModalComponent.prototype.refreshComponent = function () {
        var _this = this;
        if (!this.componentClass) {
            return;
        }
        if (!this.componentReference) {
            var componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.componentClass);
            this.componentContainer.clear();
            this.componentReference = this.componentContainer.createComponent(componentFactory);
            this.componentInstance = this.componentReference.instance;
            var _loop_1 = function (event_1) {
                if (!this_1.componentInstance[event_1]) {
                    throw new Error('Asked to subscribe to Output ' +
                        event_1 +
                        ' on component ' +
                        this_1.componentClass +
                        ' but component instance has no such Output');
                }
                this_1.componentInstance[event_1]
                    .pipe(operators_1.takeUntil(this_1.ngUnsubscribe))
                    .subscribe(function (eventData) {
                    _this.childEvents.emit({ name: event_1, data: eventData });
                });
            };
            var this_1 = this;
            // take care of outputs
            for (var _i = 0, _a = this.events; _i < _a.length; _i++) {
                var event_1 = _a[_i];
                _loop_1(event_1);
            }
        }
        // take care of inputs
        if (this.componentData) {
            for (var _b = 0, _c = Object.keys(this.componentData); _b < _c.length; _b++) {
                var key = _c[_b];
                this.componentInstance[key] = this.componentData[key];
            }
        }
    };
    ModalComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        if (this.componentReference) {
            this.componentReference.destroy();
        }
    };
    return ModalComponent;
}());
exports.ModalComponent = ModalComponent;
