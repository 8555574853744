"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var TaskPageActionTypes;
(function (TaskPageActionTypes) {
    TaskPageActionTypes["LoadTask"] = "[TaskPage] Load Data";
    TaskPageActionTypes["SaveTask"] = "[TaskPage] Save Task";
})(TaskPageActionTypes = exports.TaskPageActionTypes || (exports.TaskPageActionTypes = {}));
var LoadTask = /** @class */ (function () {
    function LoadTask(payload) {
        this.payload = payload;
        this.type = TaskPageActionTypes.LoadTask;
    }
    return LoadTask;
}());
exports.LoadTask = LoadTask;
var SaveTask = /** @class */ (function () {
    function SaveTask(payload) {
        this.payload = payload;
        this.type = TaskPageActionTypes.SaveTask;
    }
    return SaveTask;
}());
exports.SaveTask = SaveTask;
