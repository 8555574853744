"use strict";
/// <reference path="./../../../../models/src/lib/api/job.ts" />
Object.defineProperty(exports, "__esModule", { value: true });
var JobsActionTypes;
(function (JobsActionTypes) {
    JobsActionTypes["LoadJobs"] = "[Jobs] Load Data";
    JobsActionTypes["JobsLoaded"] = "[Jobs] Data Loaded";
    JobsActionTypes["SaveJob"] = "[Jobs] Save Job";
    JobsActionTypes["JobSaved"] = "[Jobs] Job Saved";
})(JobsActionTypes = exports.JobsActionTypes || (exports.JobsActionTypes = {}));
var LoadJobs = /** @class */ (function () {
    function LoadJobs(payload) {
        this.payload = payload;
        this.type = JobsActionTypes.LoadJobs;
    }
    return LoadJobs;
}());
exports.LoadJobs = LoadJobs;
var JobsLoaded = /** @class */ (function () {
    function JobsLoaded(payload) {
        this.payload = payload;
        this.type = JobsActionTypes.JobsLoaded;
    }
    return JobsLoaded;
}());
exports.JobsLoaded = JobsLoaded;
var SaveJob = /** @class */ (function () {
    function SaveJob(payload) {
        this.payload = payload;
        this.type = JobsActionTypes.SaveJob;
    }
    return SaveJob;
}());
exports.SaveJob = SaveJob;
var JobSaved = /** @class */ (function () {
    function JobSaved(payload) {
        this.payload = payload;
        this.type = JobsActionTypes.JobSaved;
    }
    return JobSaved;
}());
exports.JobSaved = JobSaved;
