"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var store_1 = require("@ngrx/store");
// Lookup the 'Permissions' feature state managed by NgRx
var getPermissionsState = store_1.createFeatureSelector('permissions');
var ɵ0 = function (state) { return state.loaded; };
exports.ɵ0 = ɵ0;
var getLoaded = store_1.createSelector(getPermissionsState, ɵ0);
var ɵ1 = function (state) { return state.error; };
exports.ɵ1 = ɵ1;
var getError = store_1.createSelector(getPermissionsState, ɵ1);
var ɵ2 = function (state, isLoaded) {
    return isLoaded ? state.list : [];
};
exports.ɵ2 = ɵ2;
var getAllPermissions = store_1.createSelector(getPermissionsState, getLoaded, ɵ2);
var ɵ3 = function (state) { return state.selectedId; };
exports.ɵ3 = ɵ3;
var getSelectedId = store_1.createSelector(getPermissionsState, ɵ3);
var ɵ4 = function (permissions, id) {
    var result = permissions.find(function (it) { return it['id'] === id; });
    return result ? Object.assign({}, result) : undefined;
};
exports.ɵ4 = ɵ4;
var getSelectedPermissions = store_1.createSelector(getAllPermissions, getSelectedId, ɵ4);
var ɵ5 = function (state) { return state.pendingChanges; };
exports.ɵ5 = ɵ5;
var getPendingChanges = store_1.createSelector(getPermissionsState, ɵ5);
exports.permissionsQuery = {
    getLoaded: getLoaded,
    getError: getError,
    getAllPermissions: getAllPermissions,
    getSelectedPermissions: getSelectedPermissions,
    getPendingChanges: getPendingChanges,
};
