"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var SidenavActionTypes;
(function (SidenavActionTypes) {
    SidenavActionTypes["OpenSidenav"] = "[Sidenav] Open Sidenav";
    SidenavActionTypes["CloseSidenav"] = "[Sidenav] Close Sidenav";
    SidenavActionTypes["ToggleSidenav"] = "[Sidenav] Toggle Sidenav";
})(SidenavActionTypes = exports.SidenavActionTypes || (exports.SidenavActionTypes = {}));
var ToggleSidenav = /** @class */ (function () {
    function ToggleSidenav() {
        this.type = SidenavActionTypes.ToggleSidenav;
    }
    return ToggleSidenav;
}());
exports.ToggleSidenav = ToggleSidenav;
var OpenSidenav = /** @class */ (function () {
    function OpenSidenav() {
        this.type = SidenavActionTypes.OpenSidenav;
    }
    return OpenSidenav;
}());
exports.OpenSidenav = OpenSidenav;
var CloseSidenav = /** @class */ (function () {
    function CloseSidenav() {
        this.type = SidenavActionTypes.CloseSidenav;
    }
    return CloseSidenav;
}());
exports.CloseSidenav = CloseSidenav;
exports.fromSidenavActions = {
    OpenSidenav: OpenSidenav,
    CloseSidenav: CloseSidenav,
    ToggleSidenav: ToggleSidenav,
};
