"use strict";
/// <reference path="./../../../../models/src/lib/api/all-query.ts" />
Object.defineProperty(exports, "__esModule", { value: true });
var EstatesActionTypes;
(function (EstatesActionTypes) {
    EstatesActionTypes["LoadEstates"] = "[Estates] Load Estates";
    EstatesActionTypes["EstatesLoaded"] = "[Estates] Estates Loaded";
    EstatesActionTypes["EstatesLoadError"] = "[Estates] Estates Load Error";
    EstatesActionTypes["SelectEstate"] = "[Estates] Select Estate";
    EstatesActionTypes["LoadExcel"] = "[Estates] Load Excel";
    EstatesActionTypes["LoadDuplicates"] = "[Estates] Load Duplicates";
    EstatesActionTypes["ResolveDuplicates"] = "[Estates] Resolve Duplicates";
    EstatesActionTypes["LoadTargetColumns"] = "[Estates] Load Target Columns";
    EstatesActionTypes["LoadExcelColumns"] = "[Estates] Load Excel Columns";
    EstatesActionTypes["ResolveColumns"] = "[Estates] Resolve Columns";
    EstatesActionTypes["EditEstate"] = "[Estates] Edit Estate";
    EstatesActionTypes["SaveEstate"] = "[Estates] Save Estate";
    EstatesActionTypes["EstateSaved"] = "[Estates] Estate Saved";
    EstatesActionTypes["RemoveEstate"] = "[Estates] Remove Estate";
    EstatesActionTypes["EstateRemoved"] = "[Estates] Estate Removed";
})(EstatesActionTypes = exports.EstatesActionTypes || (exports.EstatesActionTypes = {}));
var LoadEstates = /** @class */ (function () {
    function LoadEstates(payload) {
        this.payload = payload;
        this.type = EstatesActionTypes.LoadEstates;
    }
    return LoadEstates;
}());
exports.LoadEstates = LoadEstates;
var EstatesLoadError = /** @class */ (function () {
    function EstatesLoadError(payload) {
        this.payload = payload;
        this.type = EstatesActionTypes.EstatesLoadError;
    }
    return EstatesLoadError;
}());
exports.EstatesLoadError = EstatesLoadError;
var EstatesLoaded = /** @class */ (function () {
    function EstatesLoaded(payload) {
        this.payload = payload;
        this.type = EstatesActionTypes.EstatesLoaded;
    }
    return EstatesLoaded;
}());
exports.EstatesLoaded = EstatesLoaded;
var SelectEstate = /** @class */ (function () {
    function SelectEstate(payload) {
        this.payload = payload;
        this.type = EstatesActionTypes.SelectEstate;
    }
    return SelectEstate;
}());
exports.SelectEstate = SelectEstate;
var LoadExcel = /** @class */ (function () {
    function LoadExcel(payload) {
        this.payload = payload;
        this.type = EstatesActionTypes.LoadExcel;
    }
    return LoadExcel;
}());
exports.LoadExcel = LoadExcel;
var LoadDuplicates = /** @class */ (function () {
    function LoadDuplicates(payload) {
        this.payload = payload;
        this.type = EstatesActionTypes.LoadDuplicates;
    }
    return LoadDuplicates;
}());
exports.LoadDuplicates = LoadDuplicates;
var ResolveDuplicates = /** @class */ (function () {
    function ResolveDuplicates(payload) {
        this.payload = payload;
        this.type = EstatesActionTypes.ResolveDuplicates;
    }
    return ResolveDuplicates;
}());
exports.ResolveDuplicates = ResolveDuplicates;
var LoadExcelColumns = /** @class */ (function () {
    function LoadExcelColumns(payload) {
        this.payload = payload;
        this.type = EstatesActionTypes.LoadExcelColumns;
    }
    return LoadExcelColumns;
}());
exports.LoadExcelColumns = LoadExcelColumns;
var LoadTargetColumns = /** @class */ (function () {
    function LoadTargetColumns(payload) {
        this.payload = payload;
        this.type = EstatesActionTypes.LoadTargetColumns;
    }
    return LoadTargetColumns;
}());
exports.LoadTargetColumns = LoadTargetColumns;
var ResolveColumns = /** @class */ (function () {
    function ResolveColumns(payload) {
        this.payload = payload;
        this.type = EstatesActionTypes.ResolveColumns;
    }
    return ResolveColumns;
}());
exports.ResolveColumns = ResolveColumns;
var EditEstate = /** @class */ (function () {
    function EditEstate(payload) {
        this.payload = payload;
        this.type = EstatesActionTypes.EditEstate;
    }
    return EditEstate;
}());
exports.EditEstate = EditEstate;
var SaveEstate = /** @class */ (function () {
    function SaveEstate(payload) {
        this.payload = payload;
        this.type = EstatesActionTypes.SaveEstate;
    }
    return SaveEstate;
}());
exports.SaveEstate = SaveEstate;
var EstateSaved = /** @class */ (function () {
    function EstateSaved(payload) {
        this.payload = payload;
        this.type = EstatesActionTypes.EstateSaved;
    }
    return EstateSaved;
}());
exports.EstateSaved = EstateSaved;
var RemoveEstate = /** @class */ (function () {
    function RemoveEstate(payload) {
        this.payload = payload;
        this.type = EstatesActionTypes.RemoveEstate;
    }
    return RemoveEstate;
}());
exports.RemoveEstate = RemoveEstate;
var EstateRemoved = /** @class */ (function () {
    function EstateRemoved(payload) {
        this.payload = payload;
        this.type = EstatesActionTypes.EstateRemoved;
    }
    return EstateRemoved;
}());
exports.EstateRemoved = EstateRemoved;
exports.fromEstatesActions = {
    LoadEstates: LoadEstates,
    EstatesLoaded: EstatesLoaded,
    EstatesLoadError: EstatesLoadError,
    SelectEstate: SelectEstate,
    LoadExcel: LoadExcel,
    LoadDuplicates: LoadDuplicates,
    ResolveDuplicates: ResolveDuplicates,
    LoadTargetColumns: LoadTargetColumns,
    LoadExcelColumns: LoadExcelColumns,
    ResolveColumns: ResolveColumns,
    EditEstate: EditEstate,
    SaveEstate: SaveEstate,
    EstateSaved: EstateSaved,
    RemoveEstate: RemoveEstate,
    EstateRemoved: EstateRemoved,
};
