import { createFeatureSelector, createSelector } from '@ngrx/store';

import { PermissionsState } from './permissions.reducer';

// Lookup the 'Permissions' feature state managed by NgRx
const getPermissionsState = createFeatureSelector<PermissionsState>(
    'permissions'
);

const getLoaded = createSelector(
    getPermissionsState,
    (state: PermissionsState) => state.loaded
);
const getError = createSelector(
    getPermissionsState,
    (state: PermissionsState) => state.error
);

const getAllPermissions = createSelector(
    getPermissionsState,
    getLoaded,
    (state: PermissionsState, isLoaded) => {
        return isLoaded ? state.list : [];
    }
);
const getSelectedId = createSelector(
    getPermissionsState,
    (state: PermissionsState) => state.selectedId
);
const getSelectedPermissions = createSelector(
    getAllPermissions,
    getSelectedId,
    (permissions, id) => {
        const result = permissions.find(it => it['id'] === id);
        return result ? Object.assign({}, result) : undefined;
    }
);
const getPendingChanges = createSelector(
    getPermissionsState,
    state => state.pendingChanges
);

export const permissionsQuery = {
    getLoaded,
    getError,
    getAllPermissions,
    getSelectedPermissions,
    getPendingChanges,
};
