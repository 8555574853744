"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var src_1 = require("@fixiti/actions/src");
var operators_1 = require("rxjs/operators");
var src_2 = require("@fixiti/external/pica/src");
var store_1 = require("@ngrx/store");
var UploadImagesSelectionComponent = /** @class */ (function () {
    /* istanbul ignore next */
    function UploadImagesSelectionComponent(store, imageService) {
        this.store = store;
        this.imageService = imageService;
    }
    UploadImagesSelectionComponent.prototype.onChange = function ($event) {
        var files = [];
        for (var i = 0; i < $event.target.files.length; ++i) {
            files.push($event.target.files[i]);
        }
        this.uploadFiles(files);
        this.resetImageInput();
    };
    UploadImagesSelectionComponent.prototype.uploadFiles = function (files) {
        var _this = this;
        if (files && files.length > 0) {
            var promises = files.map(function (file) {
                return new Promise(function (resolve) {
                    var fr = new FileReader();
                    fr.onloadend = function ($event) {
                        var url = "data:image/jpeg;base64," + btoa($event.target.result);
                        _this.store.dispatch(new src_1.AddFile({
                            file: file,
                            url: url,
                        }));
                        resolve(url);
                    };
                    fr.readAsBinaryString(file);
                });
            });
            var previewsLoaded = Promise.all(promises);
            previewsLoaded.then(function () {
                _this.imageService
                    .resizeImages(files, 512, 512, {
                    aspectRatio: {
                        keepAspectRatio: true,
                    },
                })
                    .pipe(operators_1.mergeMap(function (file) {
                    return new Promise(function (resolve) {
                        var fr = new FileReader();
                        fr.onloadend = function ($event) {
                            var url = "data:image/jpeg;base64," + btoa($event.target.result);
                            _this.store.dispatch(new src_1.UpdateFile({
                                file: file,
                                url: url,
                            }));
                            resolve(url);
                        };
                        fr.readAsBinaryString(file);
                    });
                }), operators_1.toArray(), operators_1.take(1))
                    .toPromise();
            });
            return previewsLoaded;
        }
        else {
            return Promise.resolve([]);
        }
    };
    UploadImagesSelectionComponent.prototype.resetImageInput = function () {
        if (this.cameraInput) {
            this.cameraInput.nativeElement.value = null;
        }
        this.imageInput.nativeElement.value = null;
    };
    UploadImagesSelectionComponent.prototype.selectFile = function (input) {
        // for some reason safari only works when it bubbles up
        // tests for safari and iOS compatability before removing
        if (!/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
            input.click();
        }
    };
    return UploadImagesSelectionComponent;
}());
exports.UploadImagesSelectionComponent = UploadImagesSelectionComponent;
