"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var UploadImagesActionsComponent = /** @class */ (function () {
    /* istanbul ignore next */
    function UploadImagesActionsComponent() {
        this.files = [];
        this.submit = new core_1.EventEmitter();
        this.cancel = new core_1.EventEmitter();
        this.canCancel = true;
        this.canSubmit = true;
        this.minimum = 2;
    }
    return UploadImagesActionsComponent;
}());
exports.UploadImagesActionsComponent = UploadImagesActionsComponent;
