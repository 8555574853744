import { createFeatureSelector, createSelector } from '@ngrx/store';

import { SearchState } from './search.reducer';

// Lookup the 'Search' feature state managed by NgRx
const getSearchState = createFeatureSelector<SearchState>('search');

const getCurrentSearch = createSelector(
    getSearchState,
    (state: SearchState) => state.raw
);
const getSimplifiedCurrentSearch = createSelector(
    getSearchState,
    (state: SearchState) => (state.raw && state.raw.toLowerCase()) || ''
);
const getCurrentSearchTerms = createSelector(
    getSearchState,
    (state: SearchState) => (state.raw && state.raw.split(' ')) || []
);
const getSimplifiedCurrentSearchTerms = createSelector(
    getSearchState,
    (state: SearchState) =>
        (state.raw && state.raw.toLowerCase().split(' ')) || []
);

export const searchQuery = {
    getCurrentSearch,
    getSimplifiedCurrentSearch,
    getCurrentSearchTerms,
    getSimplifiedCurrentSearchTerms,
};
