"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var RoutingModule = /** @class */ (function () {
    function RoutingModule() {
    }
    /* istanbul ignore next */
    RoutingModule.forRoot = function () {
        return {
            /* ts-lint ignore next */
            ngModule: RootRoutingModule,
        };
    };
    return RoutingModule;
}());
exports.RoutingModule = RoutingModule;
var RootRoutingModule = /** @class */ (function () {
    function RootRoutingModule() {
    }
    return RootRoutingModule;
}());
exports.RootRoutingModule = RootRoutingModule;
