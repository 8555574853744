/// <reference path="./api/import-file.ts" />
import { ImportFileMapModel, ImportFileToModel } from '@fixiti/models';

export class ImportFileModel implements ApiModel.ImportFile {
    id?: number;
    statusId: number;
    createdByFirstName: string;
    createdByLastName: string;
    originalFilename: string;
    filesize: number;
    numberDataLines: number;
    numberVerified: number;
    numberInputErrors: number;
    numberProcessed: number;
    numberProcessingErrors: number;
    statusMessage: string;
    columns: ImportFileMapModel[];
    tos: ImportFileToModel[];
    created: Date;
}
