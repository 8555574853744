"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var PhotoCounterIconComponent = /** @class */ (function () {
    /* istanbul ignore next */
    function PhotoCounterIconComponent() {
        this.count = 0;
        this.clicked = new core_1.EventEmitter();
    }
    PhotoCounterIconComponent.prototype.handleClick = function () {
        this.clicked.emit();
    };
    return PhotoCounterIconComponent;
}());
exports.PhotoCounterIconComponent = PhotoCounterIconComponent;
