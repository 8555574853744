"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var DialogBodyComponent = /** @class */ (function () {
    /* istanbul ignore next */
    /* istanbul ignore next */
    function DialogBodyComponent() {
    }
    return DialogBodyComponent;
}());
exports.DialogBodyComponent = DialogBodyComponent;
