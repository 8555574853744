"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["input[type=file][_ngcontent-%COMP%]{display:none}button[_ngcontent-%COMP%]{z-index:20;width:100%;max-width:calc(100vw - 20px)}.mat-file-input[_ngcontent-%COMP%]{display:contents;position:relative}"];
exports.styles = styles;
