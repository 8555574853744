/// <reference path="../../../../models/src/lib/api/negotiation.ts" />

import { Action } from '@ngrx/store';

export enum NegotiationsActionTypes {
    LoadNegotiations = '[Negotiation] Load Data',
    NegotiationsLoaded = '[Negotiation] Data Loaded',
    SaveNegotiation = '[Negotiation] Save Data',
    NegotiationSaved = '[Negotiation] Data Saved',
    ChangeCounterOffer = '[Negotiation] Change Counter',
}

export class LoadNegotiations implements Action {
    readonly type = NegotiationsActionTypes.LoadNegotiations;
    constructor(public payload: { id: number }) {}
}

export class NegotiationsLoaded implements Action {
    readonly type = NegotiationsActionTypes.NegotiationsLoaded;
    constructor(
        public payload: {
            negotiation: ApiModel.Negotiation;
        }
    ) {}
}

export class SaveNegotiation implements Action {
    readonly type = NegotiationsActionTypes.SaveNegotiation;
    constructor(
        public payload: {
            negotiation: ApiModel.UploadNegotiation;
        }
    ) {}
}

export class NegotiationSaved implements Action {
    readonly type = NegotiationsActionTypes.NegotiationSaved;
    constructor(
        public payload: {
            negotiation: ApiModel.Negotiation;
        }
    ) {}
}

export class ChangeCounterOffer implements Action {
    readonly type = NegotiationsActionTypes.ChangeCounterOffer;
    constructor(public payload: { counterOffer: number }) {}
}

export type NegotiationsActions =
    | LoadNegotiations
    | NegotiationsLoaded
    | SaveNegotiation
    | NegotiationSaved
    | ChangeCounterOffer;
