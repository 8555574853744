import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LoadEnvironment } from '@fixiti/actions/src';
import { Store, select } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { Go } from '@fixiti/actions/src';

import { environment } from '@env/environment';
import { userQuery } from '@fixiti/state/user/src';

@Component({
    selector: 'fixiti-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css', './assets/css/style.css'],
    encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
    isLoggedIn$ = this.store.pipe(
        select(userQuery.getUser),
        map(user => !!user)
    );

    constructor(private store: Store<any>, private activatedRoute: ActivatedRoute, private router: Router) {}

    ngOnInit() {
        this.store.dispatch(new LoadEnvironment(environment));
    }

    linkNav(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();
        this.store.dispatch(new Go({ path: [event.srcElement.getAttribute('href')] }));
    }
}
