/// <reference path="../../../../../models/src/lib/api/paginated-server-response.ts" />
/// <reference path="../../../../../models/src/lib/api/person.ts" />
/// <reference path="../../../../../models/src/lib/api/widget.ts" />
/// <reference path="../../../../../models/src/lib/api/negotiation.ts" />
/// <reference path="../../../../../models/src/lib/api/all-query.ts" />

import { HttpClient, HttpParams } from '@angular/common/http';
import { UpdatePageLength } from '@fixiti/actions/src';
import { Observable, zip } from 'rxjs';
import { PropertyModel, WorkOrderModel } from '@fixiti/models';
import { Store, select } from '@ngrx/store';
import { catchError, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';

import { ModalService } from '@fixiti/elements/modal/src';
import { Injectable } from '@angular/core';
import { RestApiBase } from '@fixiti/api/rest/rest-api.base';
import { userQuery } from '@fixiti/state/user/src';

@Injectable({
    providedIn: 'root',
})
export class PmpRestApiService extends RestApiBase {
    constructor(
        protected store: Store<any>,
        protected modalService: ModalService,
        protected http: HttpClient
    ) {
        super(store, modalService);
    }

    login(user: string, password: string) {
        return this.serverObs.pipe(
            mergeMap(server => {
                return this.http
                    .post(`${server}api/1.0/pmp/login`, {
                        username: user,
                        password: password,
                    })
                    .pipe(this.checkForFailure());
            })
        );
    }

    getDashboard() {
        return this.getItemList<ApiModel.Widget>('dashboard/all').pipe(
            map((data: ApiModel.Widget[]) =>
                data.map(
                    item =>
                        <ApiModel.Widget>{
                            id: +item.id,
                            label: item.label,
                        }
                )
            )
        );
    }

    getWidgetTypes() {
        return this.getItemList<string>('dashboard/object_types');
    }

    getWidgetDefinitions() {
        return this.getItem<ApiModel.WidgetDictionary>('dashboard/widgets').pipe(
            map(data => {
                const result: ApiModel.WidgetDictionary = {};
                Object.entries(data).forEach(([key, value]) => {
                    result[key] = <ApiModel.WidgetDefinition>{
                        label: value.label,
                        count: +value.count,
                        object: value.object,
                        report_id: +value.report_id,
                    };
                });
                return result;
            })
        );
    }

    getMyDashboard() {
        return this.getItemList<string>('dashboard/mine').pipe(
            map((data: string[]) => data.map(item => +item))
        );
    }

    updateMyDashboard(array: number[]): Observable<number[]> {
        return this.updateItem<number[], number[]>('dashboard/mine', array).pipe(
            //             tap(() => this.store.dispatch(new LoadWidgetDefinitions())),
            map((data: any[]) => data.map(item => +item) as number[])
        );
    }

    getProperties(query?: ApiModel.AllQuery) {
        return this.getPaginatedItemList<PropertyModel>('properties', query, 'Properties');
    }

    getPropertyItem(id: number) {
        return this.getItemById<PropertyModel>('properties', id);
    }

    createProperty(item: PropertyModel) {
        const property: any = {
            ...item,
            frontElevation: (item.frontElevation || []).map((picture: ApiModel.GalleryImage) => picture.id),
        };
        return this.createItem<PropertyModel, PropertyModel>('properties', property);
    }

    updateProperty(item: PropertyModel) {
        const property: any = {
            ...item,
            frontElevation: (item.frontElevation || []).map((picture: ApiModel.GalleryImage) => picture.id),
        };
        return this.updateItemById<PropertyModel, PropertyModel>('properties', property);
    }

    removeProperty(item: number) {
        return this.removeItem('properties', item);
    }

    getInvestors(query?: ApiModel.AllQuery) {
        return this.getPaginatedItemList<ApiModel.Person>('investors', query, 'Investors');
    }

    getInvestorItem(id: number) {
        return this.getItemById<ApiModel.Person>('investors', id);
    }

    createInvestor(item: ApiModel.Person) {
        return this.createItem<ApiModel.Person, ApiModel.Person>('investors', item);
    }

    updateInvestor(item: ApiModel.Person) {
        return this.updateItemById<ApiModel.Person, ApiModel.Person>('investors', item);
    }

    removeInvestor(item: number) {
        return this.removeItem('investors', item);
    }

    getResidents(query?: ApiModel.AllQuery) {
        return this.getPaginatedItemList<ApiModel.Person>('residents', query, 'Residents');
    }

    getResidentItem(id: number) {
        return this.getItemById<ApiModel.Person>('residents', id);
    }

    createResident(item: ApiModel.Person) {
        return this.createItem<ApiModel.Person, ApiModel.Person>('residents', item);
    }

    updateResident(item: ApiModel.Person) {
        return this.updateItemById<ApiModel.Person, ApiModel.Person>('residents', item);
    }

    removeResident(item: number) {
        return this.removeItem('residents', item);
    }

    getUsers(query?: ApiModel.AllQuery) {
        return this.getPaginatedItemList<ApiModel.Manager>('users', query, 'Users');
    }

    getUserItem(id: number) {
        return this.getItemById<ApiModel.Manager>('users', id);
    }

    createUser(item: ApiModel.Manager) {
        return this.createItem<ApiModel.Manager, ApiModel.Manager>('users', item);
    }

    updateUser(item: ApiModel.Manager) {
        return this.updateItemById<ApiModel.Manager, ApiModel.Manager>('users', item);
    }

    removeUser(item: number) {
        return this.removeItem('users', item);
    }

    getWorkOrders(query?: ApiModel.AllQuery) {
        return this.getPaginatedItemList<WorkOrderModel>('jobs', query, 'Maintenance');
    }

    getWorkOrderItem(id: number) {
        return this.getItemById<WorkOrderModel>('jobs', id);
    }

    approveWorkOrderItem(id: number) {
        return this.updateItemById<
            WorkOrderModel,
            {
                schedulingApproval: boolean;
            }
        >(
            'jobs',
            {
                schedulingApproval: true,
            },
            id
        );
    }

    declineWorkOrderItem(id: number) {
        return this.updateItemById<
            WorkOrderModel,
            {
                schedulingApproval: boolean;
            }
        >(
            'jobs',
            {
                schedulingApproval: false,
            },
            id
        );
    }

    approveWorkOrderItemEstimate(id: number) {
        return this.updateItemById<
            WorkOrderModel,
            {
                estimateApproval: boolean;
            }
        >(
            'jobs',
            {
                estimateApproval: true,
            },
            id
        );
    }

    declineWorkOrderItemEstimate(id: number) {
        return this.updateItemById<
            WorkOrderModel,
            {
                estimateApproval: boolean;
            }
        >(
            'jobs',
            {
                estimateApproval: false,
            },
            id
        );
    }

    getWorkOrdersForProperty(propertyId: number) {
        return this.getPaginatedItemList<WorkOrderModel>('jobs', {
            property_id: propertyId,
        });
    }

    approveWorkOrderItemPricing(id: number) {
        return this.updateItemById<
            ApiModel.Negotiation,
            {
                accept: boolean;
            }
        >(
            'negotiation',
            <any>{
                accept: true,
            },
            id
        );
    }

    counterWorkOrderItemPricing(id: number, negotiatedPrice: number) {
        return this.updateItemById<
            ApiModel.Negotiation,
            {
                negotiation: boolean;
                negotiatedPrice: number;
            }
        >(
            'negotiation',
            {
                negotiation: true,
                negotiatedPrice,
            },
            id
        );
    }

    getWorkOrderItemNegotiation(id: number) {
        return this.getItemById<ApiModel.Negotiation>('negotiation', id);
    }

    createWorkOrderItemNegotiation(jobId: number, customerPrice: number) {
        return this.updateItem<ApiModel.Negotiation, any>('negotiations/create', {
            jobId,
            customerPrice,
        });
    }

    getRoles(query?: ApiModel.AllQuery) {
        return this.getPaginatedItemList<ApiModel.Role>('roles', query, 'Roles');
    }

    getUsersWithRole(roleId: number) {
        return this.getPaginatedItemList<ApiModel.User>(`roles/users/${roleId}`);
    }

    getPermissions(roleId: number, query?: ApiModel.AllQuery) {
        return this.getPaginatedItemList<ApiModel.Permission>(`permissions/index/${roleId}`, query);
    }

    getPermissionForRole(roleId: number, permissionId: number) {
        return this.getItemById<ApiModel.Permission>(`roles/permissions/${roleId}/`, permissionId);
    }

    addRoleToUser(role_id: number, manager_id: number) {
        return this.updateItem<
            ApiModel.SimplifiedUser,
            {
                role_id: number;
                manager_id: number;
            }
        >(`users/role`, { role_id, manager_id });
    }

    removeRoleFroUser(role_id: number, manager_id: number) {
        return this.removeTerm(`users/role`, {
            role_id,
            manager_id,
        }).pipe(map(result => !!result));
    }

    updateRole(roleId: number, name: string) {
        return this.updateItemById<ApiModel.Role, { name: string }>(
            `roles`,
            {
                name,
            },
            roleId
        );
    }

    requestPasswordRecovery(email: string): Observable<boolean> {
        return this.serverObs.pipe(
            switchMap(server => this.http.post(`${server}api/1.0/pmp/login/request_recovery`, { email })),
            this.checkForFailure(),
            map((serverResponse: ApiModel.ServerResponse<any>) => {
                return true;
            }),
            catchError(error => this.handleError(error))
        );
    }

    resetPassword(key: string, password: string) {
        return this.serverObs.pipe(
            switchMap(server => {
                const credentials = {
                    password,
                    verifyPassword: password,
                };
                return this.http.post(`${server}api/1.0/pmp/login/reset_password/${key}`, credentials);
            }),
            this.checkForFailure(),
            map((serverResponse: ApiModel.ServerResponse<any>) => {
                return {
                    errors: serverResponse.errors || [],
                    nErrors: +(serverResponse.nErrors || 0),
                    data: serverResponse.data || {},
                };
            }),
            catchError(error => this.handleError(error))
        );
    }

    updatePermission({
        roleId,
        permissionId,
        read,
        write,
    }: {
        roleId: number;
        permissionId: number;
        read: boolean;
        write: boolean;
    }) {
        return this.updateItem<
            ApiModel.Permission,
            {
                read: boolean;
                write: boolean;
            }
        >(`roles/permissions/${roleId}/${permissionId}`, {
            read,
            write,
        }).pipe(
            map(data => ({
                ...data,
                roleId,
                permissionId,
            }))
        );
    }

    getPaginatedItemList<T>(term: string, queryParameters?: Object, key?: string): Observable<T[]> {
        return zip(this.serverObs, this.defaultHttpHeaders()).pipe(
            switchMap(([server, headers]) =>
                this.http.get(`${server}api/1.0/pmp/${term}`, {
                    headers,
                    params: this.buildQueryParams(queryParameters, true),
                })
            ),
            this.checkForError(),
            tap((json: ApiModel.PaginatedServerResponse<T[]>) => {
                /* istanbul ignore else */
                if (key) {
                    this.store.dispatch(
                        new UpdatePageLength({
                            key,
                            length: +json.data.pagination.num_matches,
                        })
                    );
                }
            }),
            map((json: ApiModel.PaginatedServerResponse<T[]>) => json.data.results),
            catchError(error => this.handleError(error))
        );
    }

    getItemList<T>(term: string, queryParameters?: Object): Observable<T[]> {
        return zip(this.serverObs, this.defaultHttpHeaders()).pipe(
            switchMap(([server, headers]) =>
                this.http.get(`${server}api/1.0/pmp/${term}`, {
                    headers,
                    params: this.buildQueryParams(queryParameters, true),
                })
            ),
            this.checkForError(),
            map((json: ApiModel.ServerResponse<T>) => json.data),
            catchError(error => this.handleError(error))
        );
    }

    getItem<T>(term: string): Observable<T> {
        return zip(this.serverObs, this.defaultHttpHeaders()).pipe(
            switchMap(([server, headers]) =>
                this.http.get(`${server}api/1.0/pmp/${term}?${Math.round(Date.now() / 30000)}`, {
                    headers,
                })
            ),
            this.checkForError(),
            map((json: ApiModel.ServerResponse<T>) => json.data),
            catchError(error => this.handleError(error))
        );
    }

    getItemById<T>(term: string, id: number): Observable<T> {
        return zip(this.serverObs, this.defaultHttpHeaders()).pipe(
            switchMap(([server, headers]) =>
                this.http.get(`${server}api/1.0/pmp/${term}/id/${id}`, {
                    headers,
                    params: this.buildQueryParams(null, true),
                })
            ),
            this.checkForError(),
            map((json: ApiModel.ServerResponse<T>) => json.data),
            catchError(error => this.handleError(error))
        );
    }

    createItem<T, K>(term: string, item: K): Observable<T> {
        return zip(this.serverObs, this.defaultHttpHeaders()).pipe(
            switchMap(([server, headers]) =>
                this.http.post(`${server}api/1.0/pmp/${term}/create`, item, {
                    headers,
                })
            ),
            this.checkForError(),
            map((json: ApiModel.ServerResponse<T>) => JSON.parse(JSON.stringify(json.data))),
            catchError(error => this.handleError(error))
        );
    }

    updateItem<T, K>(term: string, item: K): Observable<T> {
        return zip(this.serverObs, this.defaultHttpHeaders()).pipe(
            switchMap(([server, headers]) =>
                this.http.post(`${server}api/1.0/pmp/${term}`, item, {
                    headers,
                })
            ),
            this.checkForError(),
            map((json: ApiModel.ServerResponse<T>) => json.data),
            catchError(error => this.handleError(error))
        );
    }

    updateItemById<T, K>(term: string, item: K, id?: number): Observable<T> {
        return zip(this.serverObs, this.defaultHttpHeaders()).pipe(
            switchMap(([server, headers]) =>
                this.http.post(`${server}api/1.0/pmp/${term}/id/${id != null ? id : item['id']}`, item, {
                    headers,
                })
            ),
            this.checkForError(),
            map((json: ApiModel.ServerResponse<T>) => json.data),
            catchError(error => this.handleError(error))
        );
    }

    removeItem<T>(term: string, item: number): Observable<ApiModel.ServerResponse<T>> {
        return this.removeTerm(`${term}/id/${item}`);
    }

    removeTerm<T, K>(term: string, body?: K) {
        return zip(this.serverObs, this.defaultHttpHeaders()).pipe(
            switchMap(([server, headers]) => {
                if (body) {
                    return this.http.request('delete', `${server}api/1.0/pmp/${term}`, {
                        headers,
                        body,
                    });
                } else {
                    return this.http.delete(`${server}api/1.0/pmp/${term}`, {
                        headers,
                    });
                }
            }),
            this.checkForError(),
            map((json: ApiModel.ServerResponse<T>) => json),
            catchError(error => this.handleError(error))
        );
    }

    defaultHttpParameters() {
        return this.store.pipe(
            select(userQuery.getUser),
            map(user => ({
                Authorization: `Bearer ${user.apiToken}`,
                MembershipId: user.memberships[0].id,
                CustomerId: user.memberships[0].customerId,
            })),
            take(1)
        );
    }

    buildQueryParams(params: Object, cacheBust = false) {
        let httpParams = new HttpParams();
        if (params) {
            Object.entries(params).forEach(([key, value]) => {
                httpParams = httpParams.set(key, value);
            });
        }
        if (cacheBust) {
            httpParams = httpParams.set(Math.round(Date.now() / 30000).toString(), '');
        }
        return httpParams;
    }
}
