"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".burger[_ngcontent-%COMP%]{position:absolute;top:18px;left:20px;width:37px;height:22px;z-index:250;transition:left .35s ease}@media (max-width:1024px){.burger[_ngcontent-%COMP%]{top:-120px;right:20px;left:auto}}@media (max-width:768px){.burger[_ngcontent-%COMP%]{top:-160px}}.burger[_ngcontent-%COMP%]   span[_ngcontent-%COMP%]{position:absolute;text-indent:-9999px;overflow:hidden;top:50%;left:0;right:0;height:2px;margin-top:-1px;background:#94ba3f}.burger[_ngcontent-%COMP%]:after, .burger[_ngcontent-%COMP%]:before{position:absolute;content:'';left:0;right:0;height:2px;background:#94ba3f;transition:all .35s ease}.burger[_ngcontent-%COMP%]:after{top:-2px}.burger[_ngcontent-%COMP%]:before{bottom:-2px}.burger.active[_ngcontent-%COMP%]{position:fixed;right:20px}.burger.active[_ngcontent-%COMP%]   span[_ngcontent-%COMP%]{background-color:transparent}.burger.active[_ngcontent-%COMP%]:after{margin-top:-1px;top:50%;transform:rotate(45deg)}.burger.active[_ngcontent-%COMP%]:before{margin-bottom:-1px;bottom:50%;transform:rotate(-45deg)}\n/*# sourceMappingURL=main-menu-toggle.component.css.map*/"];
exports.styles = styles;
