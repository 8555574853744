"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var PhotoEventModel = /** @class */ (function () {
    function PhotoEventModel() {
        this.created = new Date();
        this.gallery = [];
    }
    return PhotoEventModel;
}());
exports.PhotoEventModel = PhotoEventModel;
