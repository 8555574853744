"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".drop-menu[_ngcontent-%COMP%]{position:absolute;top:100%;left:0;z-index:150;margin-top:18px;margin-left:5px;border:1px solid #dcdce5;width:100%;font-size:24px;line-height:29px;max-width:388px;box-shadow:4px 20px 30px -8px #e7e7f0;background:#fffdfd}@media (max-width:768px){.drop-menu[_ngcontent-%COMP%]{left:0;max-width:none}}.drop-menu[_ngcontent-%COMP%]   ul[_ngcontent-%COMP%]{padding:20px 0 33px}.drop-menu[_ngcontent-%COMP%]   ul[_ngcontent-%COMP%]   li[_ngcontent-%COMP%]{position:relative;padding:15px 10px 15px 78px}.drop-menu[_ngcontent-%COMP%]   ul[_ngcontent-%COMP%]   li[_ngcontent-%COMP%]   .ico[_ngcontent-%COMP%]{position:absolute;top:50%;left:34px;text-align:center;max-width:30px;transform:translateY(-50%)}@media (max-width:1024px){.drop-menu[_ngcontent-%COMP%]{font-size:16px;line-height:20px}.drop-menu[_ngcontent-%COMP%]   ul[_ngcontent-%COMP%]{padding:20px 0}.drop-menu[_ngcontent-%COMP%]   ul[_ngcontent-%COMP%]   li[_ngcontent-%COMP%]{padding-top:10px;padding-bottom:10px;padding-left:60px}.drop-menu[_ngcontent-%COMP%]   ul[_ngcontent-%COMP%]   li[_ngcontent-%COMP%]   .ico[_ngcontent-%COMP%]{left:20px}.drop-menu[_ngcontent-%COMP%]   ul[_ngcontent-%COMP%]   li[_ngcontent-%COMP%]   .ico[_ngcontent-%COMP%]   img[_ngcontent-%COMP%]{width:20px}}.drop-menu[_ngcontent-%COMP%]   ul[_ngcontent-%COMP%]   a[_ngcontent-%COMP%]{display:block;color:#9b9b9b}.drop-menu[_ngcontent-%COMP%]   ul[_ngcontent-%COMP%]   a[_ngcontent-%COMP%]:hover{color:#000}.click-detector[_ngcontent-%COMP%]{position:fixed;left:0;top:0;width:100%;height:100%;z-index:0}ul[_ngcontent-%COMP%]{z-index:5}\n/*# sourceMappingURL=main-menu.component.css.map*/"];
exports.styles = styles;
