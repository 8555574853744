/// <reference path="../../../../../models/src/lib/api/gallery-image.ts" />
import {
    AddFile,
    FileActions,
    FileTypes,
    PhotoDialogClose,
} from '@fixiti/actions/src';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export interface State {
    items: File[];
    itemUrls: string[];
    itemProgress: number[];
    progress: number;
    isUploading: boolean;
    ids: number[];
    photos: {
        [id: number]: string;
    };
}

export const fileInitialState: State = {
    items: [],
    itemUrls: [],
    itemProgress: [],
    progress: 0,
    isUploading: false,
    ids: [],
    photos: {},
};

export function fileReducer(
    state: State = fileInitialState,
    action: FileActions | PhotoDialogClose
): State {
    switch (action.type) {
        case FileTypes.ADD_FILE: {
            if (fileIsCached(state, action) || !action.payload.file) {
                return state;
            } else if (action.payload.file.url) {
                return {
                    ...state,
                    items: [...state.items, action.payload.file],
                    itemUrls: [...state.itemUrls, action.payload.file.url],
                };
            } else {
                return {
                    ...state,
                    items: [...state.items, action.payload.file],
                    itemUrls: [...state.itemUrls, action.payload.url],
                };
            }
        }
        case FileTypes.UPDATE_FILE: {
            const fileIndex = state.items.findIndex(
                item => item === action.payload.file
            );
            if (fileIndex >= 0) {
                return {
                    ...state,
                    items: [
                        ...state.items.slice(0, fileIndex),
                        action.payload.file,
                        ...state.items.slice(fileIndex + 1),
                    ],
                    itemUrls: [
                        ...state.itemUrls.slice(0, fileIndex),
                        action.payload.url,
                        ...state.itemUrls.slice(fileIndex + 1),
                    ],
                };
            }
            return state;
        }
        case FileTypes.REPLACE_FILE: {
            if (action.payload.index > state.items.length) {
                return {
                    ...state,
                    items: [...state.items, action.payload.file],
                    itemUrls: [...state.itemUrls, action.payload.url],
                };
            } else if (
                state.items.length >= action.payload.index &&
                action.payload.index >= 0
            ) {
                const items = [
                    ...state.items.slice(0, action.payload.index),
                    action.payload.file,
                    ...state.items.slice(action.payload.index + 1),
                ];
                const itemUrls = [
                    ...state.itemUrls.slice(0, action.payload.index),
                    action.payload.url,
                    ...state.itemUrls.slice(action.payload.index + 1),
                ];
                return {
                    ...state,
                    items,
                    itemUrls,
                };
            }
            return state;
        }
        case FileTypes.DELETE_FILE:
            const index = state.items.findIndex(
                item => item === action.payload.file
            );
            if (index >= 0) {
                const items = [
                    ...state.items.slice(0, index),
                    ...state.items.slice(index + 1),
                ];

                return {
                    ...state,
                    items,
                };
            } else {
                return state;
            }
        case FileTypes.SUBMIT_IMAGES: {
            return {
                ...state,
                progress: 0,
                isUploading: true,
                itemProgress: action.payload.images.map(() => 0),
            };
        }
        case FileTypes.SUBMIT_IMAGES_PROGRESS: {
            const newState = {
                ...state,
                itemProgress: [...state.itemProgress],
            };
            if (action.payload.index >= 0) {
                newState.itemProgress[action.payload.index] =
                    action.payload.progress;
            }
            const totalProgress = newState.itemProgress.reduce(
                (previousValue, nextItem) => previousValue + nextItem,
                0
            );
            const length = newState.itemProgress.length || 1;
            newState.progress = Math.round(totalProgress / length);

            return newState;
        }
        case FileTypes.SUBMIT_IMAGES_FAILURE: {
            return {
                ...state,
                progress: 0,
                isUploading: false,
                itemProgress: [],
            };
        }
        case FileTypes.SUBMIT_IMAGES_SUCCESS: {
            const {
                items,
                itemUrls,
                itemProgress,
                progress,
                isUploading,
                ...partialState
            } = state;
            const photos = {
                ...state.photos,
            };
            action.payload.images
                .filter(image => !!image.url)
                .forEach(image => {
                    if (!photos[image.id]) {
                        photos[image.id] = image.url;
                    }
                });
            return {
                ...fileInitialState,
                ...partialState,
                photos,
                ids: action.payload.images.map(image => image.id),
            };
        }
        default:
            return state;
    }
}
export const selectFileState = createFeatureSelector<State>('files');

export const selectAllImages = createSelector(
    selectFileState,
    (state: State) => state.items
);
export const selectAllImageUrls = createSelector(
    selectFileState,
    (state: State) => state.itemUrls
);
export const selectImageUploadProgress = createSelector(
    selectFileState,
    (state: State) => state.progress
);
export const selectIsImageUploading = createSelector(
    selectFileState,
    (state: State) => state.isUploading
);
export const selectLocalPhotoMap = createSelector(
    selectFileState,
    (state: State) => state.photos
);
export const selectLastImageUploadIds = createSelector(
    selectFileState,
    (state: State) => state.ids
);

const fileIsCached = (state: State, action: AddFile) => {
    return (
        state.items.findIndex((item: File | any) => {
            if (item instanceof File) {
                return (
                    item.name === action.payload.file.name &&
                    item.lastModified === action.payload.file.lastModified &&
                    item.size === action.payload.file.size &&
                    item.type === action.payload.file.type
                );
            } else {
                return item === action.payload.file;
            }
        }) !== -1
    );
};
