import { Store, select } from '@ngrx/store';
import { map, tap } from 'rxjs/operators';

import { CanActivate } from '@angular/router';
import { Go } from '@fixiti/actions/src';
import { Injectable } from '@angular/core';
import { userQuery } from '@fixiti/state/user/src';

@Injectable({
    providedIn: 'root',
})
export class AnonymousGuard implements CanActivate {
    constructor(private store: Store<any>) {}

    canActivate() {
        return this.store.pipe(
            select(userQuery.getUser),
            map(user => !user),
            tap(loggedOut => {
                if (!loggedOut) {
                    this.store.dispatch(
                        new Go({
                            path: ['dashboard'],
                        })
                    );
                }
            })
        );
    }
}
