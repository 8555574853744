/// <reference path="../../../../models/src/lib/api/task.ts" />
import { Action } from '@ngrx/store';

export enum TaskPageActionTypes {
    LoadTask = '[TaskPage] Load Data',
    SaveTask = '[TaskPage] Save Task',
}

export class LoadTask implements Action {
    readonly type = TaskPageActionTypes.LoadTask;
    constructor(
        public payload: {
            task: ApiModel.Task;
        }
    ) {}
}

export class SaveTask implements Action {
    readonly type = TaskPageActionTypes.SaveTask;
    constructor(
        public payload: {
            task: ApiModel.Task;
        }
    ) {}
}

export type TaskPageActions = LoadTask | SaveTask;
