"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".popup-holder[_ngcontent-%COMP%]{position:fixed;top:0;left:0;right:0;bottom:0;z-index:1000;overflow-x:hidden;overflow-y:auto}.popup-holder[_ngcontent-%COMP%]   .popup-bg[_ngcontent-%COMP%]{position:fixed;top:0;left:0;right:0;bottom:0;background:#000;opacity:.62;width:100%;height:100%;filter:alpha(opacity=62)}.popup-wrap[_ngcontent-%COMP%]{display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;position:relative;max-width:590px;margin:60px auto;width:auto;min-height:calc(100% - (60px * 2));text-align:center}@media (max-width:768px){.popup-wrap[_ngcontent-%COMP%]{max-width:420px}}@media (max-width:420px){.popup-wrap[_ngcontent-%COMP%]{max-width:310px}}.popup-content[_ngcontent-%COMP%]{width:100%;background:#fff;border:1px solid #979797;padding:20px;position:relative;box-shadow:0 2px 49px 7px #7f7c80}.close-btn[_ngcontent-%COMP%]{position:absolute;top:12px;right:20px;text-indent:-9999px;height:20px;width:20px;border:none;padding:0;z-index:99;background:0 0}.close-btn[_ngcontent-%COMP%]::after, .close-btn[_ngcontent-%COMP%]::before{position:absolute;content:'';top:50%;left:50%;height:2px;width:25px;margin:-1px 0 0 -13px;background:#4a4a4a}.close-btn[_ngcontent-%COMP%]::before{-webkit-transform:rotate(45deg);transform:rotate(45deg)}.close-btn[_ngcontent-%COMP%]::after{-webkit-transform:rotate(-45deg);transform:rotate(-45deg)}\n/*# sourceMappingURL=modal.component.css.map*/"];
exports.styles = styles;
