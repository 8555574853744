/// <reference path="../../../../../models/src/lib/api/user.ts" />

import { UserAction, UserActionTypes } from '@fixiti/actions';

export interface UserState {
    current: ApiModel.User | null;
}

export const userInitialState: UserState = {
    current: null,
};

export function userReducer(
    state: UserState = userInitialState,
    action: UserAction
): UserState {
    switch (action.type) {
        case UserActionTypes.LoadUser: {
            state = {
                ...state,
                current: action.payload,
            };
            break;
        }
        case UserActionTypes.Logout: {
            state = {
                ...state,
                current: null,
            };
            break;
        }
        case UserActionTypes.KickUser: {
            state = {
                ...state,
                current: null,
            };
            break;
        }
    }
    return state;
}
