"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var actions_1 = require("@fixiti/actions");
exports.PAGESETTINGS_FEATURE_KEY = 'pageSettings';
exports.initialState = {
    current: null,
    display: {},
    filter: {},
    pageIndex: {},
    pageLength: {},
    pageSize: {},
    sortDirection: {},
    sortField: {},
    reportId: {},
};
function pageSettingsReducer(state, action) {
    if (state === void 0) { state = exports.initialState; }
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2;
    switch (action.type) {
        case actions_1.PageSettingsActionTypes.ChangePage: {
            state = __assign({}, state, { current: action.payload });
            break;
        }
        case actions_1.PageSettingsActionTypes.ChangePageDisplay: {
            state = __assign({}, state, { display: __assign({}, state.display, (_a = {}, _a[state.current] = action.payload, _a)) });
            break;
        }
        case actions_1.PageSettingsActionTypes.ChangePageFilter: {
            state = __assign({}, state, { filter: __assign({}, state.filter, (_b = {}, _b[state.current] = action.payload, _b)), pageIndex: __assign({}, state.pageIndex, (_c = {}, _c[state.current] = 0, _c)) });
            break;
        }
        case actions_1.PageSettingsActionTypes.ChangePageLength: {
            var max = action.payload || 0;
            var increment = state.pageSize[state.current] || 100;
            var currentIndex = state.pageIndex[state.current] || 0;
            var offset = currentIndex * increment;
            var index = offset > max ? 0 : currentIndex;
            state = __assign({}, state, { pageLength: __assign({}, state.pageLength, (_d = {}, _d[state.current] = action.payload, _d)), pageIndex: __assign({}, state.pageIndex, (_e = {}, _e[state.current] = index, _e)) });
            break;
        }
        case actions_1.PageSettingsActionTypes.UpdatePageLength: {
            var max = action.payload || 0;
            var increment = state.pageSize[action.payload.key] || 100;
            var currentIndex = state.pageIndex[action.payload.key] || 0;
            var offset = currentIndex * increment;
            var index = offset > max ? 0 : currentIndex;
            state = __assign({}, state, { pageLength: __assign({}, state.pageLength, (_f = {}, _f[action.payload.key] = action.payload.length, _f)), pageIndex: __assign({}, state.pageIndex, (_g = {}, _g[action.payload.key] = index, _g)) });
            break;
        }
        case actions_1.PageSettingsActionTypes.ChangePageIndex: {
            var max = state.pageLength[state.current] || 0;
            var increment = state.pageSize[state.current] || 100;
            var offset = action.payload * increment;
            var index = offset > max ? 0 : action.payload;
            state = __assign({}, state, { pageIndex: __assign({}, state.pageIndex, (_h = {}, _h[state.current] = index, _h)) });
            break;
        }
        case actions_1.PageSettingsActionTypes.ChangePageSize: {
            state = __assign({}, state, { pageSize: __assign({}, state.pageSize, (_j = {}, _j[state.current] = action.payload, _j)), pageIndex: __assign({}, state.pageIndex, (_k = {}, _k[state.current] = 0, _k)) });
            break;
        }
        case actions_1.PageSettingsActionTypes.ChangePageSort: {
            state = __assign({}, state, { sortDirection: __assign({}, state.sortDirection, (_l = {}, _l[state.current] = action.payload.direction, _l)), sortField: __assign({}, state.sortField, (_m = {}, _m[state.current] = action.payload.field, _m)), pageIndex: __assign({}, state.pageIndex, (_o = {}, _o[state.current] = 0, _o)) });
            break;
        }
        case actions_1.PageSettingsActionTypes.ChangePageSortDirection: {
            state = __assign({}, state, { sortDirection: __assign({}, state.sortDirection, (_p = {}, _p[state.current] = action.payload, _p)), pageIndex: __assign({}, state.pageIndex, (_q = {}, _q[state.current] = 0, _q)) });
            break;
        }
        case actions_1.PageSettingsActionTypes.ChangePageSortField: {
            state = __assign({}, state, { sortField: __assign({}, state.sortField, (_r = {}, _r[state.current] = action.payload, _r)), pageIndex: __assign({}, state.pageIndex, (_s = {}, _s[state.current] = 0, _s)) });
            break;
        }
        case actions_1.PageSettingsActionTypes.ChangePageReportId: {
            var page = action.payload.page
                ? action.payload.page
                : state.current;
            state = __assign({}, state, { reportId: __assign({}, state.reportId, (_t = {}, _t[page] = action.payload.reportId, _t)), pageIndex: __assign({}, state.pageIndex, (_u = {}, _u[page] = 0, _u)) });
            break;
        }
        case actions_1.PageSettingsActionTypes.HydratePage: {
            var max = action.payload.pageLength || 0;
            var increment = action.payload.pageSize || 100;
            var offset = action.payload.pageIndex * increment;
            var index = offset > max ? 0 : action.payload.pageIndex;
            state = __assign({}, state, { display: __assign({}, state.display, (_v = {}, _v[state.current] = action.payload.display, _v)), filter: __assign({}, state.filter, (_w = {}, _w[state.current] = action.payload.filter, _w)), pageIndex: __assign({}, state.pageIndex, (_x = {}, _x[state.current] = index, _x)), pageLength: __assign({}, state.pageLength, (_y = {}, _y[state.current] = action.payload.pageLength, _y)), pageSize: __assign({}, state.pageSize, (_z = {}, _z[state.current] = action.payload.pageSize, _z)), sortDirection: __assign({}, state.sortDirection, (_0 = {}, _0[state.current] = action.payload.sortDirection, _0)), sortField: __assign({}, state.sortField, (_1 = {}, _1[state.current] = action.payload.sortField, _1)), reportId: __assign({}, state.reportId, (_2 = {}, _2[state.current] = action.payload.reportId, _2)) });
            break;
        }
        case actions_1.PageSettingsActionTypes.UpdatePageUrl: {
            state = __assign({}, state, { updatingUrl: true });
            break;
        }
        case actions_1.PageSettingsActionTypes.PageUrlUpdated: {
            state = __assign({}, state, { updatingUrl: null });
            break;
        }
    }
    return state;
}
exports.pageSettingsReducer = pageSettingsReducer;
