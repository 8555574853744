"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
/// <reference path="../../../../../models/src/lib/api/server-response.ts" />
/// <reference path="../../../../../models/src/lib/api/paginated-server-response.ts" />
/// <reference path="../../../../../models/src/lib/api/all-query.ts" />
var http_1 = require("@angular/common/http");
var store_1 = require("@ngrx/store");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var src_1 = require("@fixiti/actions/src");
var rest_api_base_1 = require("@fixiti/api/rest/rest-api.base");
var src_2 = require("@fixiti/state/user/src");
var shared_api_normalization_service_1 = require("@fixiti/shared/api/normalization/src/lib/shared-api-normalization.service");
var src_3 = require("@fixiti/elements/modal/src");
var i0 = require("@angular/core");
var i1 = require("@ngrx/store");
var i2 = require("../../../../../elements/modal/src/lib/services/modal.service");
var i3 = require("@angular/common/http");
var i4 = require("../../../../../shared/api/normalization/src/lib/shared-api-normalization.service");
var ColumnsAndTos = /** @class */ (function () {
    function ColumnsAndTos() {
    }
    return ColumnsAndTos;
}());
var ApiRestImportService = /** @class */ (function (_super) {
    __extends(ApiRestImportService, _super);
    function ApiRestImportService(store, modalService, http, normalize) {
        var _this = _super.call(this, store, modalService) || this;
        _this.store = store;
        _this.modalService = modalService;
        _this.http = http;
        _this.normalize = normalize;
        return _this;
    }
    ApiRestImportService.prototype.getImportFiles = function (apiSection, query) {
        var _this = this;
        return this.getPaginatedItemList(apiSection, this.translateListViewQuery(query)).pipe(operators_1.map(function (results) {
            return results.map(function (file) {
                var columnsAndTos = _this.normalizeMapping(file);
                return {
                    id: _this.normalize.asInt(file.id),
                    statusId: _this.normalize.asInt(file.statusId),
                    createdByFirstName: _this.normalize.asString(file.createdByFirstName),
                    createdByLastName: _this.normalize.asString(file.createdByLastName),
                    originalFilename: _this.normalize.asString(file.originalFilename),
                    filesize: _this.normalize.asInt(file.filesize),
                    numberDataLines: _this.normalize.asInt(file.numberDataLines),
                    numberVerified: _this.normalize.asInt(file.numberVerified),
                    numberInputErrors: _this.normalize.asInt(file.numberInputErrors),
                    numberProcessed: _this.normalize.asInt(file.numberProcessed),
                    numberProcessingErrors: _this.normalize.asInt(file.numberProcessingErrors),
                    statusMessage: _this.normalize.asString(file.statusMessage),
                    columns: columnsAndTos.columns,
                    tos: columnsAndTos.tos,
                    created: _this.normalize.asDate(file.created),
                };
            });
        }));
    };
    ApiRestImportService.prototype.getMapping = function (apiSection, fileId) {
        var _this = this;
        return rxjs_1.zip(this.serverObs, this.defaultHttpHeaders()).pipe(operators_1.switchMap(function (_a) {
            var server = _a[0], headers = _a[1];
            return _this.http.get(server + "api/1.0/pmp/" + apiSection + "/import?route=map&id=" + fileId, {
                headers: headers,
            });
        }), this.checkForError(), operators_1.map(function (json) {
            return _this.normalizeMapping(json.data);
        }), operators_1.catchError(function (error) { return _this.handleError(error); }));
    };
    ApiRestImportService.prototype.setMapping = function (apiSection, fileId, mapping) {
        var _this = this;
        return rxjs_1.zip(this.serverObs, this.defaultHttpHeaders()).pipe(operators_1.switchMap(function (_a) {
            var server = _a[0], headers = _a[1];
            return _this.http.post(server + "api/1.0/pmp/" + apiSection + "/import?route=map&id=" + fileId, { columns: mapping }, {
                headers: headers,
            });
        }), this.checkForFailure(), operators_1.map(function (json) {
            return {
                nErrors: _this.normalize.asInt(json.nErrors),
                errors: _this.normalizeErrors(json.errors),
                data: _this.normalizeMapping(json.data),
            };
        }), operators_1.catchError(function (error) { return _this.handleError(error); }));
    };
    ApiRestImportService.prototype.deleteFile = function (apiSection, fileId) {
        var _this = this;
        return rxjs_1.zip(this.serverObs, this.defaultHttpHeaders()).pipe(operators_1.switchMap(function (_a) {
            var server = _a[0], headers = _a[1];
            return _this.http.delete(server + "api/1.0/pmp/" + apiSection + "/import?route=delete&id=" + fileId, {
                headers: headers,
            });
        }), this.checkForError(), operators_1.map(function (json) { return json; }), operators_1.catchError(function (error) { return _this.handleError(error); }));
    };
    ApiRestImportService.prototype.createFile = function (apiSection, file) {
        var _this = this;
        return rxjs_1.zip(this.serverObs, this.defaultHttpHeaders()).pipe(operators_1.switchMap(function (_a) {
            var server = _a[0], headers = _a[1];
            return _this.http.post(server + "api/1.0/pmp/" + apiSection + "/import?route=create&filename=" + file.name, file, {
                headers: headers,
            });
        }), this.checkForError(), operators_1.map(function (json) { return json; }), operators_1.catchError(function (error) { return _this.handleError(error); }));
    };
    ApiRestImportService.prototype.normalizeMapping = function (data) {
        var _this = this;
        var columns = 'columns' in data && Array.isArray(data.columns) ? data.columns : [];
        var tos = 'tos' in data && Array.isArray(data.tos) ? data.tos : [];
        return {
            columns: columns.map(function (column) {
                return {
                    id: _this.normalize.asInt(column.id),
                    from: _this.normalize.asString(column.from),
                    to: _this.normalize.asString(column.to),
                };
            }),
            tos: tos.map(function (to) {
                return {
                    name: _this.normalize.asString(to.name),
                    label: _this.normalize.asString(to.label),
                };
            }),
        };
    };
    ApiRestImportService.prototype.normalizeErrors = function (errors) {
        var normalizedErrors = {};
        if (!Array.isArray(errors)) {
            return normalizedErrors;
        }
        var nErrors = 0;
        for (var _i = 0, errors_1 = errors; _i < errors_1.length; _i++) {
            var error = errors_1[_i];
            if (!('field' in error) || !('error' in error)) {
                continue;
            }
            nErrors += 1;
            if (error.field === 'general') {
                if (!('general' in normalizedErrors)) {
                    normalizedErrors.general = '';
                }
                normalizedErrors.general += (normalizedErrors.general ? ' ' : '') + error.error;
                continue;
            }
            normalizedErrors[error.field] = error.error;
        }
        if (!nErrors) {
            return {};
        }
        return { columns: normalizedErrors };
    };
    ApiRestImportService.prototype.defaultHttpParameters = function () {
        return this.store.pipe(store_1.select(src_2.userQuery.getUser), operators_1.map(function (user) {
            return {
                Authorization: "Bearer " + user.apiToken,
                MembershipId: user.memberships[0].id,
                CustomerId: user.memberships[0].customerId,
            };
        }), operators_1.take(1));
    };
    ApiRestImportService.prototype.translateListViewQuery = function (query) {
        return {
            page: query.page,
            limit: query.limit,
            q: '',
            direction: query.direction,
            sort: query.sort,
        };
    };
    ApiRestImportService.prototype.getPaginatedItemList = function (apiSection, queryParameters) {
        var _this = this;
        return rxjs_1.zip(this.serverObs, this.defaultHttpHeaders()).pipe(operators_1.switchMap(function (_a) {
            var server = _a[0], headers = _a[1];
            return _this.http.get(server + "api/1.0/pmp/" + apiSection + "/import?route=files", {
                headers: headers,
                params: _this.buildQueryParams(queryParameters),
            });
        }), this.checkForError(), operators_1.tap(function (json) {
            /* istanbul ignore else */
            _this.store.dispatch(new src_1.ImportSetPaginationData(_this.normalize.asListViewPagination(json.data.pagination)));
        }), operators_1.map(function (json) { return json.data.results; }), operators_1.catchError(function (error) { return _this.handleError(error); }));
    };
    ApiRestImportService.ngInjectableDef = i0.defineInjectable({ factory: function ApiRestImportService_Factory() { return new ApiRestImportService(i0.inject(i1.Store), i0.inject(i2.ModalService), i0.inject(i3.HttpClient), i0.inject(i4.SharedApiNormalizationService)); }, token: ApiRestImportService, providedIn: "root" });
    return ApiRestImportService;
}(rest_api_base_1.RestApiBase));
exports.ApiRestImportService = ApiRestImportService;
