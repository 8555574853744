"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./message.component.css.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("./message.component");
var styles_MessageComponent = [i0.styles];
var RenderType_MessageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MessageComponent, data: {} });
exports.RenderType_MessageComponent = RenderType_MessageComponent;
function View_MessageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); }); }
function View_MessageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessageComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(2, null, ["\n", "\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.message; _ck(_v, 2, 0, currVal_1); }); }
exports.View_MessageComponent_0 = View_MessageComponent_0;
function View_MessageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fixiti-modal-message", [], null, null, null, View_MessageComponent_0, RenderType_MessageComponent)), i1.ɵdid(1, 49152, null, 0, i3.MessageComponent, [], null, null)], null, null); }
exports.View_MessageComponent_Host_0 = View_MessageComponent_Host_0;
var MessageComponentNgFactory = i1.ɵccf("fixiti-modal-message", i3.MessageComponent, View_MessageComponent_Host_0, { title: "title", message: "message" }, {}, []);
exports.MessageComponentNgFactory = MessageComponentNgFactory;
