"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var environment_reducer_1 = require("./environment.reducer");
var store_1 = require("@ngrx/store");
// Lookup the 'Environment' feature state managed by NgRx
var getEnvironmentState = store_1.createFeatureSelector(environment_reducer_1.ENVIRONMENT_FEATURE_KEY);
var ɵ0 = function (state) { return state.loaded; };
exports.ɵ0 = ɵ0;
var getLoaded = store_1.createSelector(getEnvironmentState, ɵ0);
var ɵ1 = function (state, isLoaded) {
    return isLoaded ? state.current.apiUrl : '';
};
exports.ɵ1 = ɵ1;
var getApiUrl = store_1.createSelector(getEnvironmentState, getLoaded, ɵ1);
var ɵ2 = function (state, isLoaded) {
    return isLoaded ? state.current.socketsUrl : '';
};
exports.ɵ2 = ɵ2;
var getSocketsUrl = store_1.createSelector(getEnvironmentState, getLoaded, ɵ2);
var ɵ3 = function (state, isLoaded) {
    return isLoaded ? state.current.production : true;
};
exports.ɵ3 = ɵ3;
var getIsProduction = store_1.createSelector(getEnvironmentState, getLoaded, ɵ3);
exports.environmentQuery = {
    getLoaded: getLoaded,
    getApiUrl: getApiUrl,
    getIsProduction: getIsProduction,
    getSocketsUrl: getSocketsUrl,
};
