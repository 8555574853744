"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var FileTypes;
(function (FileTypes) {
    FileTypes["ADD_FILE"] = "[Files] ADD_FILE";
    FileTypes["UPDATE_FILE"] = "[Files] UPDATE_FILE";
    FileTypes["REPLACE_FILE"] = "[Files] REPLACE_FILE";
    FileTypes["DELETE_FILE"] = "[Files] DELETE_FILE";
    FileTypes["SUBMIT_IMAGES"] = "[Files] SUBMIT_IMAGES";
    FileTypes["SUBMIT_IMAGES_PROGRESS"] = "[Files] SUBMIT_IMAGES PROGESS";
    FileTypes["SUBMIT_IMAGES_SUCCESS"] = "[Files] SUBMIT_IMAGES SUCCESS";
    FileTypes["SUBMIT_IMAGES_FAILURE"] = "[Files] SUBMIT_IMAGES FAILURE";
    FileTypes["RESET_FILES"] = "[Files] Reset Files";
    FileTypes["DIALOG_CLOSE"] = "[Files] Dialog Close";
})(FileTypes = exports.FileTypes || (exports.FileTypes = {}));
/* istanbul ignore next */
var AddFile = /** @class */ (function () {
    function AddFile(payload) {
        this.payload = payload;
        this.type = FileTypes.ADD_FILE;
    }
    return AddFile;
}());
exports.AddFile = AddFile;
/* istanbul ignore next */
var UpdateFile = /** @class */ (function () {
    function UpdateFile(payload) {
        this.payload = payload;
        this.type = FileTypes.UPDATE_FILE;
    }
    return UpdateFile;
}());
exports.UpdateFile = UpdateFile;
/* istanbul ignore next */
var ReplaceFile = /** @class */ (function () {
    function ReplaceFile(payload) {
        this.payload = payload;
        this.type = FileTypes.REPLACE_FILE;
    }
    return ReplaceFile;
}());
exports.ReplaceFile = ReplaceFile;
/* istanbul ignore next */
var DeleteFile = /** @class */ (function () {
    function DeleteFile(payload) {
        this.payload = payload;
        this.type = FileTypes.DELETE_FILE;
    }
    return DeleteFile;
}());
exports.DeleteFile = DeleteFile;
/* istanbul ignore next */
var SubmitImages = /** @class */ (function () {
    function SubmitImages(payload) {
        this.payload = payload;
        this.type = FileTypes.SUBMIT_IMAGES;
    }
    return SubmitImages;
}());
exports.SubmitImages = SubmitImages;
/* istanbul ignore next */
var SubmitImagesProgress = /** @class */ (function () {
    function SubmitImagesProgress(payload) {
        this.payload = payload;
        this.type = FileTypes.SUBMIT_IMAGES_PROGRESS;
    }
    return SubmitImagesProgress;
}());
exports.SubmitImagesProgress = SubmitImagesProgress;
/* istanbul ignore next */
var SubmitImagesSuccess = /** @class */ (function () {
    function SubmitImagesSuccess(payload) {
        this.payload = payload;
        this.type = FileTypes.SUBMIT_IMAGES_SUCCESS;
    }
    return SubmitImagesSuccess;
}());
exports.SubmitImagesSuccess = SubmitImagesSuccess;
/* istanbul ignore next */
var SubmitImagesFailure = /** @class */ (function () {
    function SubmitImagesFailure(error) {
        this.error = error;
        this.type = FileTypes.SUBMIT_IMAGES_FAILURE;
    }
    return SubmitImagesFailure;
}());
exports.SubmitImagesFailure = SubmitImagesFailure;
var ResetFiles = /** @class */ (function () {
    function ResetFiles() {
        this.type = FileTypes.RESET_FILES;
    }
    return ResetFiles;
}());
exports.ResetFiles = ResetFiles;
