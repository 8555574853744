import { Component } from '@angular/core';

@Component({
    selector: 'fixiti-photo-dialog-dialog-header-title',
    templateUrl: './dialog-header-title.component.html',
    styleUrls: ['./dialog-header-title.component.css'],
})
export class DialogHeaderTitleComponent {
    /* istanbul ignore next */
    constructor() {}
}
