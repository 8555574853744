/// <reference path="../../../../models/src/lib/api/provider-settings.ts" />
import { Action } from '@ngrx/store';

export enum ProviderActionTypes {
    LoadProviderSettings = '[Provider] Load Settings',
    ProviderSettingsLoaded = '[Provider] Settings Loaded',
}

export class LoadProviderSettings implements Action {
    readonly type = ProviderActionTypes.LoadProviderSettings;
}
export class ProviderSettingsLoaded implements Action {
    readonly type = ProviderActionTypes.ProviderSettingsLoaded;

    constructor(public payload: { settings: ApiModel.ProviderSettings }) {}
}

export type ProviderActions = LoadProviderSettings | ProviderSettingsLoaded;
