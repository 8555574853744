"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("hammerjs");
// import * as Sentry from '@sentry/browser';
var src_1 = require("@fixiti/guards/auth/src");
var read_1 = require("@fixiti/guards/read");
var router_1 = require("@angular/router");
var app_reducer_1 = require("./+state/app.reducer");
var app_effects_1 = require("./+state/app.effects");
var animations_1 = require("@angular/platform-browser/animations");
var platform_browser_1 = require("@angular/platform-browser");
var ng2_dragula_1 = require("ng2-dragula");
var effects_1 = require("@ngrx/effects");
var src_2 = require("@fixiti/elements/modal/src");
var src_3 = require("@fixiti/state/files/src");
var angular_fontawesome_1 = require("@fortawesome/angular-fontawesome");
var button_1 = require("@angular/material/button");
var list_1 = require("@angular/material/list");
var sidenav_1 = require("@angular/material/sidenav");
var tabs_1 = require("@angular/material/tabs");
var toolbar_1 = require("@angular/material/toolbar");
var tooltip_1 = require("@angular/material/tooltip");
var nx_1 = require("@nrwl/nx");
var src_4 = require("@fixiti/elements/photo-dialog/src");
var src_5 = require("@fixiti/external/pica/src");
var src_6 = require("@fixiti/state/routing/src");
var service_worker_1 = require("@angular/service-worker");
var src_7 = require("@fixiti/state/settings/src");
var src_8 = require("@fixiti/shared/graphics/logo/src");
var src_9 = require("@fixiti/state/environment/src");
var src_10 = require("@fixiti/state/page-settings/src");
var src_11 = require("@fixiti/state/permissions/src");
var src_12 = require("@fixiti/state/roles/src");
var src_13 = require("@fixiti/state/search/src");
var src_14 = require("@fixiti/state/sidenav/src");
var src_15 = require("@fixiti/state/user/src");
var src_16 = require("@fixiti/state/importer/src");
var store_devtools_1 = require("@ngrx/store-devtools");
var store_1 = require("@ngrx/store");
var environment_1 = require("@env/environment");
var faBars_1 = require("@fortawesome/free-solid-svg-icons/faBars");
var faSignOutAlt_1 = require("@fortawesome/free-solid-svg-icons/faSignOutAlt");
var fontawesome_svg_core_1 = require("@fortawesome/fontawesome-svg-core");
// Sentry.init({
//     dsn: 'https://e11270d385704568a176fdfd4ec5242e@sentry.io/1323643',
// });
fontawesome_svg_core_1.library.add(faBars_1.faBars, faSignOutAlt_1.faSignOutAlt);
// @Injectable()
// export class SentryErrorHandler implements ErrorHandler {
//     constructor() {}
//     handleError(error) {
//         console.log(error);
//         Sentry.captureException(error.originalError || error);
//         throw error;
//     }
// }
exports.routes = [
    {
        path: 'properties',
        canActivate: [src_1.AuthGuard],
        loadChildren: '@fixiti/pmp/properties/src/lib/pmp-properties.module#PmpPropertiesModule',
    },
    {
        path: 'dashboard',
        canActivate: [src_1.AuthGuard],
        loadChildren: '@fixiti/pmp/dashboard/src/lib/dashboard.module#DashboardModule',
    },
    {
        path: 'maintenance',
        canActivate: [src_1.AuthGuard],
        loadChildren: '@fixiti/pmp/jobs/src/lib/pmp-jobs.module#PmpJobsModule',
    },
    {
        path: 'residents',
        canActivate: [src_1.AuthGuard],
        loadChildren: '@fixiti/pmp/residents/src/lib/residents.module#ResidentsModule',
    },
    {
        path: 'investors',
        canActivate: [src_1.AuthGuard],
        loadChildren: '@fixiti/pmp/investors/src/lib/investors.module#InvestorsModule',
    },
    {
        path: 'users',
        canActivate: [src_1.AuthGuard],
        loadChildren: '@fixiti/pmp/users/src/lib/pages-users.module#PagesUsersModule',
    },
    {
        path: 'roles',
        canActivate: [src_1.AuthGuard],
        loadChildren: '@fixiti/pmp/roles/src/lib/pages-roles.module#PagesRolesModule',
    },
    {
        path: 'surveys',
        canActivate: [src_1.AuthGuard],
        loadChildren: '@fixiti/pmp/surveys/src/lib/pmp-surveys.module#PmpSurveysModule',
    },
    {
        path: 'profile',
        canActivate: [src_1.AuthGuard],
        loadChildren: '@fixiti/pmp/profile/src/lib/pmp-profile.module#PmpProfileModule',
    },
    {
        path: 'login',
        canActivate: [src_1.AnonymousGuard],
        loadChildren: '@fixiti/pmp/login/src/lib/login.module#LoginModule',
    },
    { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
    { path: '**', redirectTo: 'dashboard' },
];
var MATERIAL = [
    toolbar_1.MatToolbarModule,
    tabs_1.MatTabsModule,
    sidenav_1.MatSidenavModule,
    button_1.MatButtonModule,
    tooltip_1.MatTooltipModule,
    list_1.MatListModule,
];
var imports = MATERIAL.concat([
    platform_browser_1.BrowserModule,
    animations_1.BrowserAnimationsModule,
    src_8.SharedGraphicsLogoModule,
    angular_fontawesome_1.FontAwesomeModule,
    src_2.ElementsModalModule,
    nx_1.NxModule.forRoot(),
    router_1.RouterModule.forRoot(exports.routes, {
        preloadingStrategy: router_1.PreloadAllModules,
    }),
    store_1.StoreModule.forRoot({
        app: app_reducer_1.appReducer,
    }, {
        initialState: { app: app_reducer_1.initialState },
        metaReducers: app_reducer_1.metaReducers,
    }),
    effects_1.EffectsModule.forRoot([app_effects_1.AppEffects]),
    store_devtools_1.StoreDevtoolsModule.instrument({
        name: 'PMP',
        logOnly: environment_1.environment.production,
    }),
    src_1.GuardsAuthModule,
    read_1.GuardsReadModule,
    src_6.RoutingModule.forRoot(),
    src_13.StateSearchModule,
    src_14.StateSidenavModule,
    src_15.StateUserModule,
    //     StateWorkOrdersModule,
    src_12.StateRolesModule,
    src_11.StatePermissionsModule,
    src_10.StatePageSettingsModule,
    src_9.StateEnvironmentModule,
    src_16.StateImporterModule,
    src_5.PicaModule.forRoot(),
    src_3.FilesModule.forRoot(),
    src_4.PhotoDialogModule.forRoot(),
    src_7.SettingsModule.forRoot(),
    //     ApiRestImagesModule.forRoot(),
    //     ApiRestPmpModule.forRoot(),
    ng2_dragula_1.DragulaModule.forRoot(),
    service_worker_1.ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: environment_1.environment.production,
    }),
]);
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
exports.AppModule = AppModule;
