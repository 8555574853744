/// <reference path="./../../../../models/src/lib/api/all-query.ts" />
import { Action } from '@ngrx/store';

export enum InvestorsActionTypes {
    LoadInvestors = '[Investors] Load Investors',
    InvestorsLoaded = '[Investors] Investors Loaded',
    InvestorsLoadError = '[Investors] Investors Load Error',
    SaveInvestor = '[Investors] Save Investor',
    InvestorSaved = '[Investors] Investor Saved',
    RemoveInvestor = '[Investors] Remove Investor',
    InvestorRemoved = '[Investors] Investor Removed',
    SelectInvestor = '[Investors] Select Investor',
    EditInvestor = '[Investors] Edit Investor',
}

export class LoadInvestors implements Action {
    readonly type = InvestorsActionTypes.LoadInvestors;
    constructor(public payload?: ApiModel.AllQuery) {}
}

export class InvestorsLoadError implements Action {
    readonly type = InvestorsActionTypes.InvestorsLoadError;
    constructor(public payload: any) {}
}

export class InvestorsLoaded implements Action {
    readonly type = InvestorsActionTypes.InvestorsLoaded;
    constructor(public payload: ApiModel.Person[]) {}
}

export class SaveInvestor implements Action {
    readonly type = InvestorsActionTypes.SaveInvestor;
    constructor(public payload: ApiModel.Person) {}
}

export class InvestorSaved implements Action {
    readonly type = InvestorsActionTypes.InvestorSaved;
    constructor(public payload: ApiModel.Person) {}
}

export class RemoveInvestor implements Action {
    readonly type = InvestorsActionTypes.RemoveInvestor;
    constructor(public payload: number) {}
}

export class InvestorRemoved implements Action {
    readonly type = InvestorsActionTypes.InvestorRemoved;
    constructor(public payload: number) {}
}

export class SelectInvestor implements Action {
    readonly type = InvestorsActionTypes.SelectInvestor;
    constructor(public payload: number) {}
}

export class EditInvestor implements Action {
    readonly type = InvestorsActionTypes.EditInvestor;
    constructor(public payload: number) {}
}

export type InvestorsAction =
    | LoadInvestors
    | InvestorsLoaded
    | InvestorsLoadError
    | SaveInvestor
    | InvestorSaved
    | RemoveInvestor
    | InvestorRemoved
    | SelectInvestor
    | EditInvestor;

export const fromInvestorsActions = {
    LoadInvestors,
    InvestorsLoaded,
    InvestorsLoadError,
    SaveInvestor,
    InvestorSaved,
    RemoveInvestor,
    InvestorRemoved,
    SelectInvestor,
    EditInvestor,
};
