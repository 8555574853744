"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var EXIF = require("exif-js/exif");
/* istanbul ignore next */
var PicaExifService = /** @class */ (function () {
    function PicaExifService() {
    }
    PicaExifService.prototype.getExifOrientedImage = function (image) {
        return new Promise(function (resolve) {
            EXIF.getData(image, function () {
                var allExifMetaData = EXIF.getAllTags(image), exifOrientation = allExifMetaData.Orientation;
                if (exifOrientation) {
                    if (!/^[1-8]$/.test(exifOrientation)) {
                        throw new Error('orientation should be [1-8]');
                    }
                    var canvas = document.createElement('canvas'), ctx = canvas.getContext('2d');
                    var deg = 0, cx = 0, cy = 0, width = image.width, height = image.height;
                    if ([5, 6, 7, 8].indexOf(exifOrientation) > -1) {
                        width = image.height;
                        height = image.width;
                    }
                    canvas.width = width;
                    canvas.height = height;
                    switch (exifOrientation) {
                        case 3:
                        case 4:
                            cx = -image.width;
                            cy = -image.height;
                            deg = 180;
                            break;
                        case 5:
                        case 6:
                            cy = -image.height;
                            deg = 90;
                            break;
                        case 7:
                        case 8:
                            cx = -image.width;
                            deg = 270;
                            break;
                        default:
                            break;
                    }
                    if ([2, 4, 5, 7].indexOf(exifOrientation) > -1) {
                        ctx.translate(width, 0);
                        ctx.scale(-1, 1);
                    }
                    ctx.rotate((deg / 180) * Math.PI);
                    ctx.drawImage(image, cx, cy);
                    var img_1 = new Image();
                    img_1.width = width;
                    img_1.height = height;
                    img_1.onload = function () {
                        resolve(img_1);
                    };
                    img_1.src = canvas.toDataURL();
                }
                else {
                    resolve(image);
                }
            });
        });
    };
    return PicaExifService;
}());
exports.PicaExifService = PicaExifService;
