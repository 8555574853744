import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
} from '@angular/core';

@Component({
    selector: 'fixiti-gallery-preview-placeholder',
    templateUrl: './gallery-preview-placeholder.component.html',
    styleUrls: ['./gallery-preview-placeholder.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GalleryPreviewPlaceholderComponent implements OnInit {
    @Input() text: string;
    @Input() count = 0;
    @Input() minimum = 0;
    @Input() showBadge = true;
    constructor() {}

    ngOnInit() {}

    getBadgeText(count: number, minimum: number) {
        if (!minimum) {
            return count.toString();
        } else {
            return `${count}/${minimum}`;
        }
    }
}
