import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { CollectionViewer } from '@angular/cdk/collections';
import { DataSource } from '@angular/cdk/table';
import { PageModel } from '../page.model';

export class SpreadSheetDataSource<T> implements DataSource<T> {
    private dataSubject = new BehaviorSubject<T[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    loadingObservable = this.loadingSubject.asObservable();

    constructor(
        private remote: {
            search: (page: PageModel) => Observable<T[]>;
        }
    ) {}

    connect(collectionViewer: CollectionViewer): Observable<T[]> {
        return this.dataSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.dataSubject.complete();
        this.loadingSubject.complete();
    }

    loadData(
        filter: string = null,
        sortDirection: 'asc' | 'desc' = 'desc',
        pageIndex: number = 1,
        pageSize: number = 25
    ) {
        this.loadingSubject.next(true);

        const observable = this.remote
            .search({
                filter,
                sortDirection,
                pageIndex,
                pageSize,
            })
            .pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            );

        const subscription = observable.subscribe(data => {
            this.dataSubject.next(data);
        });

        return { observable, subscription };
    }
}
