"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./error.component.css.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("./error.component");
var styles_ErrorComponent = [i0.styles];
var RenderType_ErrorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ErrorComponent, data: {} });
exports.RenderType_ErrorComponent = RenderType_ErrorComponent;
function View_ErrorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Whoops!"])), (_l()(), i1.ɵted(2, null, ["\n", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.error ? _co.error : _co.defaultError); _ck(_v, 2, 0, currVal_0); }); }
exports.View_ErrorComponent_0 = View_ErrorComponent_0;
function View_ErrorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fixiti-modal-error", [], null, null, null, View_ErrorComponent_0, RenderType_ErrorComponent)), i1.ɵdid(1, 49152, null, 0, i2.ErrorComponent, [], null, null)], null, null); }
exports.View_ErrorComponent_Host_0 = View_ErrorComponent_Host_0;
var ErrorComponentNgFactory = i1.ɵccf("fixiti-modal-error", i2.ErrorComponent, View_ErrorComponent_Host_0, { error: "error" }, {}, []);
exports.ErrorComponentNgFactory = ErrorComponentNgFactory;
