import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'fixiti-photo-dialog-upload-images-actions',
    templateUrl: './upload-images-actions.component.html',
    styleUrls: ['./upload-images-actions.component.css'],
})
export class UploadImagesActionsComponent {
    @Input()
    files = [];
    @Output()
    submit = new EventEmitter();
    @Input()
    onSubmit: VoidFunction | null;
    @Output()
    cancel = new EventEmitter();
    @Input()
    onCancel: VoidFunction | null;
    @Input()
    canCancel = true;
    @Input()
    canSubmit = true;
    @Input()
    minimum = 2;

    /* istanbul ignore next */
    constructor() {}
}
