/// <reference path="./../../../../models/src/lib/api/all-query.ts" />
/// <reference path="../../../../models/src/lib/api/negotiation.ts" />

import { Action } from '@ngrx/store';
import { WorkOrderModel } from '@fixiti/models';

export enum WorkOrdersActionTypes {
    LoadWorkOrders = '[WorkOrders] Load WorkOrders',
    WorkOrdersLoaded = '[WorkOrders] WorkOrders Loaded',
    WorkOrdersLoadError = '[WorkOrders] WorkOrders Load Error',
    SelectWorkOrder = '[WorkOrders] Select WorkOrder',
    ShowComments = '[WorkOrders] Show Comments',
    HideComments = '[WorkOrders] Hide Comments',
    LoadComments = '[WorkOrders] Load Comments',
    CommentsLoaded = '[WorkOrders] Comments Loaded',
    CommentsLoadError = '[WorkOrders] Comments Load Error',
    ApproveWorkOrder = '[WorkOrders] Approve Work Order',
    DeclineWorkOrder = '[WorkOrders] Decline Work Order',
    ApproveWorkOrderEstimate = '[WorkOrders] Approve Work Order Estimate',
    DeclineWorkOrderEstimate = '[WorkOrders] Decline Work Order Estimate',
    ApproveWorkOrderPricing = '[WorkOrders] Approve Work Order Pricing',
    DeclineWorkOrderPricing = '[WorkOrders] Decline Work Order Pricing',
    CounterWorkOrderPricing = '[WorkOrders] Counter Work Order Pricing',
    UpdateWorkOrder = '[WorkOrders] Update Work Order',
    UpdateNegotiation = '[WorkOrders] Update Negotiation',
    LoadNegotiation = '[WorkOrders] Load Negotiation',
    NegotiationLoaded = '[WorkOrders] Negotiation Loaded',
}

export class LoadWorkOrders implements Action {
    readonly type = WorkOrdersActionTypes.LoadWorkOrders;
    constructor(public payload?: ApiModel.AllQuery) {}
}

export class WorkOrdersLoaded implements Action {
    readonly type = WorkOrdersActionTypes.WorkOrdersLoaded;
    constructor(public payload: WorkOrderModel[]) {}
}

export class WorkOrdersLoadError implements Action {
    readonly type = WorkOrdersActionTypes.WorkOrdersLoadError;
    constructor(public payload: any) {}
}

export class SelectWorkOrder implements Action {
    readonly type = WorkOrdersActionTypes.SelectWorkOrder;
    constructor(public payload: number) {}
}

export class ShowComments implements Action {
    readonly type = WorkOrdersActionTypes.ShowComments;
    constructor(public payload: number) {}
}

export class HideComments implements Action {
    readonly type = WorkOrdersActionTypes.HideComments;
    constructor() {}
}

export class LoadComments implements Action {
    readonly type = WorkOrdersActionTypes.LoadComments;
    constructor(public payload: number) {}
}

export class CommentsLoadError implements Action {
    readonly type = WorkOrdersActionTypes.LoadComments;
    constructor(public payload: any) {}
}

export class CommentsLoaded implements Action {
    readonly type = WorkOrdersActionTypes.CommentsLoaded;
    constructor(
        public payload: {
            id: number;
            comments: string[];
        }
    ) {}
}

export class ApproveWorkOrder implements Action {
    readonly type = WorkOrdersActionTypes.ApproveWorkOrder;
    constructor(public payload: number) {}
}

export class DeclineWorkOrder implements Action {
    readonly type = WorkOrdersActionTypes.DeclineWorkOrder;
    constructor(public payload: number) {}
}

export class ApproveWorkOrderEstimate implements Action {
    readonly type = WorkOrdersActionTypes.ApproveWorkOrderEstimate;
    constructor(public payload: number) {}
}

export class DeclineWorkOrderEstimate implements Action {
    readonly type = WorkOrdersActionTypes.DeclineWorkOrderEstimate;
    constructor(public payload: number) {}
}

export class ApproveWorkOrderPricing implements Action {
    readonly type = WorkOrdersActionTypes.ApproveWorkOrderPricing;
    constructor(public payload: number) {}
}

export class DeclineWorkOrderPricing implements Action {
    readonly type = WorkOrdersActionTypes.DeclineWorkOrderPricing;
    constructor(public payload: number) {}
}

export class CounterWorkOrderPricing implements Action {
    readonly type = WorkOrdersActionTypes.CounterWorkOrderPricing;
    constructor(
        public payload: {
            negotiationId: number;
            jobId: number;
            offer: number;
        }
    ) {}
}

export class UpdateWorkOrder implements Action {
    readonly type = WorkOrdersActionTypes.UpdateWorkOrder;
    constructor(public payload: WorkOrderModel) {}
}

export class UpdateNegotiation implements Action {
    readonly type = WorkOrdersActionTypes.UpdateNegotiation;
    constructor(public payload: ApiModel.Negotiation) {}
}

export class LoadNegotiation implements Action {
    readonly type = WorkOrdersActionTypes.LoadNegotiation;
    constructor(public payload: number) {}
}

export class NegotiationLoaded implements Action {
    readonly type = WorkOrdersActionTypes.NegotiationLoaded;
    constructor(public payload: ApiModel.Negotiation) {}
}

export type WorkOrdersAction =
    | LoadWorkOrders
    | WorkOrdersLoaded
    | WorkOrdersLoadError
    | SelectWorkOrder
    | LoadComments
    | ShowComments
    | HideComments
    | CommentsLoaded
    | CommentsLoadError
    | ApproveWorkOrder
    | DeclineWorkOrder
    | ApproveWorkOrderEstimate
    | DeclineWorkOrderEstimate
    | ApproveWorkOrderPricing
    | DeclineWorkOrderPricing
    | CounterWorkOrderPricing
    | UpdateWorkOrder
    | UpdateNegotiation
    | LoadNegotiation
    | NegotiationLoaded;

export const fromWorkOrdersActions = {
    LoadWorkOrders,
    WorkOrdersLoaded,
    WorkOrdersLoadError,
    SelectWorkOrder,
    LoadComments,
    ShowComments,
    HideComments,
    CommentsLoaded,
    CommentsLoadError,
    ApproveWorkOrder,
    DeclineWorkOrder,
    ApproveWorkOrderEstimate,
    DeclineWorkOrderEstimate,
    ApproveWorkOrderPricing,
    DeclineWorkOrderPricing,
    CounterWorkOrderPricing,
    UpdateWorkOrder,
    UpdateNegotiation,
    LoadNegotiation,
    NegotiationLoaded,
};
