"use strict";
/// <reference path="../../../../models/src/lib/api/challenge.ts" />
Object.defineProperty(exports, "__esModule", { value: true });
var ChallengesActionTypes;
(function (ChallengesActionTypes) {
    ChallengesActionTypes["CheckChallenge"] = "[Challenges] Check Challenge";
    ChallengesActionTypes["ResolveChallenge"] = "[Challenges] Resolve Challenge";
    ChallengesActionTypes["ResolveChallengeError"] = "[Challenges] Resolve Challenge Error";
    ChallengesActionTypes["ChallengeResolved"] = "[Challenges] Challenge Resolved";
    ChallengesActionTypes["SaveChallenge"] = "[Challenges] Save Data";
    ChallengesActionTypes["SaveChallengeError"] = "[Challenges] Save Data Error";
    ChallengesActionTypes["ChallengeSaved"] = "[Challenges] Data Saved";
    ChallengesActionTypes["UpdateChallengeGallery"] = "[Challenges] = Update Challenge Gallery";
    ChallengesActionTypes["UpdateChallengeSubject"] = "[Challenges] = Update Challenge Subject";
    ChallengesActionTypes["UpdateChallengeDescription"] = "[Challenges] = Update Challenge Description";
})(ChallengesActionTypes = exports.ChallengesActionTypes || (exports.ChallengesActionTypes = {}));
var CheckChallenge = /** @class */ (function () {
    function CheckChallenge(payload) {
        this.payload = payload;
        this.type = ChallengesActionTypes.CheckChallenge;
    }
    return CheckChallenge;
}());
exports.CheckChallenge = CheckChallenge;
var ResolveChallenge = /** @class */ (function () {
    function ResolveChallenge(payload) {
        this.payload = payload;
        this.type = ChallengesActionTypes.ResolveChallenge;
    }
    return ResolveChallenge;
}());
exports.ResolveChallenge = ResolveChallenge;
var ResolveChallengeError = /** @class */ (function () {
    function ResolveChallengeError(error) {
        this.error = error;
        this.type = ChallengesActionTypes.ResolveChallengeError;
    }
    return ResolveChallengeError;
}());
exports.ResolveChallengeError = ResolveChallengeError;
var ChallengeResolved = /** @class */ (function () {
    function ChallengeResolved(payload) {
        this.payload = payload;
        this.type = ChallengesActionTypes.ChallengeResolved;
    }
    return ChallengeResolved;
}());
exports.ChallengeResolved = ChallengeResolved;
var SaveChallenge = /** @class */ (function () {
    function SaveChallenge(payload) {
        this.payload = payload;
        this.type = ChallengesActionTypes.SaveChallenge;
    }
    return SaveChallenge;
}());
exports.SaveChallenge = SaveChallenge;
var SaveChallengeError = /** @class */ (function () {
    function SaveChallengeError(payload) {
        this.payload = payload;
        this.type = ChallengesActionTypes.SaveChallengeError;
    }
    return SaveChallengeError;
}());
exports.SaveChallengeError = SaveChallengeError;
var ChallengeSaved = /** @class */ (function () {
    function ChallengeSaved(payload) {
        this.payload = payload;
        this.type = ChallengesActionTypes.ChallengeSaved;
    }
    return ChallengeSaved;
}());
exports.ChallengeSaved = ChallengeSaved;
var UpdateChallengeDescription = /** @class */ (function () {
    function UpdateChallengeDescription(payload) {
        this.payload = payload;
        this.type = ChallengesActionTypes.UpdateChallengeDescription;
    }
    return UpdateChallengeDescription;
}());
exports.UpdateChallengeDescription = UpdateChallengeDescription;
var UpdateChallengeGallery = /** @class */ (function () {
    function UpdateChallengeGallery(payload) {
        this.payload = payload;
        this.type = ChallengesActionTypes.UpdateChallengeGallery;
    }
    return UpdateChallengeGallery;
}());
exports.UpdateChallengeGallery = UpdateChallengeGallery;
var UpdateChallengeSubject = /** @class */ (function () {
    function UpdateChallengeSubject(payload) {
        this.payload = payload;
        this.type = ChallengesActionTypes.UpdateChallengeSubject;
    }
    return UpdateChallengeSubject;
}());
exports.UpdateChallengeSubject = UpdateChallengeSubject;
