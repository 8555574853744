"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./upload-images-selection.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory");
var i3 = require("@angular/material/button");
var i4 = require("@angular/cdk/platform");
var i5 = require("@angular/cdk/a11y");
var i6 = require("@angular/platform-browser/animations");
var i7 = require("./upload-images-selection.component");
var i8 = require("@ngrx/store");
var i9 = require("../../../../../../external/pica/src/lib/pica.service");
var styles_UploadImagesSelectionComponent = [i0.styles];
var RenderType_UploadImagesSelectionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UploadImagesSelectionComponent, data: {} });
exports.RenderType_UploadImagesSelectionComponent = RenderType_UploadImagesSelectionComponent;
function View_UploadImagesSelectionComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(402653184, 1, { cameraInput: 0 }), i1.ɵqud(402653184, 2, { imageInput: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 5, "label", [["class", "mat-file-input button mat-raised-button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, [[1, 0], ["cameraSelector", 1]], null, 0, "input", [["accept", "image/jpg,image/JPG,image/jpeg,image/JPEG"], ["capture", "camera"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "button", [["class", "input-button"], ["color", "primary"], ["mat-raised-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectFile(i1.ɵnov(_v, 3)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(5, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(6, 0, null, 0, 1, "span", [["class", "button-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Take More Photos"])), (_l()(), i1.ɵeld(8, 0, null, null, 5, "label", [["class", "mat-file-input button mat-raised-button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, [[2, 0], ["imageSelector", 1]], null, 0, "input", [["accept", "image/jpg,image/JPG,image/jpeg,image/JPEG"], ["class", "multiple"], ["multiple", ""], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 3, "button", [["class", "input-button"], ["color", "accent"], ["mat-raised-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectFile(i1.ɵnov(_v, 9)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(11, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(12, 0, null, 0, 1, "span", [["class", "button-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Choose from Library"]))], function (_ck, _v) { var currVal_2 = "primary"; _ck(_v, 5, 0, currVal_2); var currVal_5 = "accent"; _ck(_v, 11, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 5).disabled || null); var currVal_1 = (i1.ɵnov(_v, 5)._animationMode === "NoopAnimations"); _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_3 = (i1.ɵnov(_v, 11).disabled || null); var currVal_4 = (i1.ɵnov(_v, 11)._animationMode === "NoopAnimations"); _ck(_v, 10, 0, currVal_3, currVal_4); }); }
exports.View_UploadImagesSelectionComponent_0 = View_UploadImagesSelectionComponent_0;
function View_UploadImagesSelectionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fixiti-photo-dialog-upload-images-selection", [], null, null, null, View_UploadImagesSelectionComponent_0, RenderType_UploadImagesSelectionComponent)), i1.ɵdid(1, 49152, null, 0, i7.UploadImagesSelectionComponent, [i8.Store, i9.PicaService], null, null)], null, null); }
exports.View_UploadImagesSelectionComponent_Host_0 = View_UploadImagesSelectionComponent_Host_0;
var UploadImagesSelectionComponentNgFactory = i1.ɵccf("fixiti-photo-dialog-upload-images-selection", i7.UploadImagesSelectionComponent, View_UploadImagesSelectionComponent_Host_0, {}, {}, []);
exports.UploadImagesSelectionComponentNgFactory = UploadImagesSelectionComponentNgFactory;
