"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var store_1 = require("@ngrx/store");
var read_helper_1 = require("./read.helper");
var operators_1 = require("rxjs/operators");
var src_1 = require("@fixiti/state/user/src");
var i0 = require("@angular/core");
var i1 = require("@ngrx/store");
var ReadGuard = /** @class */ (function () {
    function ReadGuard(store) {
        this.store = store;
    }
    ReadGuard.prototype.canActivate = function (_0, state) {
        var _this = this;
        return this.store.pipe(store_1.select(src_1.userQuery.getUser), operators_1.map(function (user) { return _this.checkPermissions(user, state.url); }));
    };
    ReadGuard.prototype.checkPermissions = function (user, url) {
        var permissions = read_helper_1.findPermissions(url);
        return permissions.every(function (permission) {
            return read_helper_1.checkIfPermissionCanRead(user, permission);
        });
    };
    ReadGuard.ngInjectableDef = i0.defineInjectable({ factory: function ReadGuard_Factory() { return new ReadGuard(i0.inject(i1.Store)); }, token: ReadGuard, providedIn: "root" });
    return ReadGuard;
}());
exports.ReadGuard = ReadGuard;
