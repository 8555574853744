import {
    SearchAction,
    SearchActionTypes,
    UserAction,
    UserActionTypes,
} from '@fixiti/actions';

export interface SearchState {
    raw: string;
}

export const searchInitialState: SearchState = {
    raw: '',
};

export function searchReducer(
    state: SearchState = searchInitialState,
    action: SearchAction | UserAction
): SearchState {
    switch (action.type) {
        case SearchActionTypes.ChangeSearch: {
            state = {
                ...state,
                raw: action.payload,
            };
            break;
        }
        case UserActionTypes.Logout: {
            state = {
                ...searchInitialState,
            };
            break;
        }
        case UserActionTypes.KickUser: {
            state = {
                ...searchInitialState,
            };
            break;
        }
    }
    return state;
}
