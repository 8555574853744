"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./gallery-preview-presenter.component.css.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../../../image/src/lib/gallery-preview-image/gallery-preview-image.component.ngfactory");
var i3 = require("../../../../image/src/lib/gallery-preview-image/gallery-preview-image.component");
var i4 = require("../../../../placeholder/src/lib/gallery-preview-placeholder/gallery-preview-placeholder.component.ngfactory");
var i5 = require("../../../../placeholder/src/lib/gallery-preview-placeholder/gallery-preview-placeholder.component");
var i6 = require("@angular/common");
var i7 = require("./gallery-preview-presenter.component");
var styles_GalleryPreviewPresenterComponent = [i0.styles];
var RenderType_GalleryPreviewPresenterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GalleryPreviewPresenterComponent, data: {} });
exports.RenderType_GalleryPreviewPresenterComponent = RenderType_GalleryPreviewPresenterComponent;
function View_GalleryPreviewPresenterComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "fixiti-gallery-preview-image", [], null, null, null, i2.View_GalleryPreviewImageComponent_0, i2.RenderType_GalleryPreviewImageComponent)), i1.ɵdid(2, 114688, null, 0, i3.GalleryPreviewImageComponent, [], { count: [0, "count"], image: [1, "image"], text: [2, "text"], minimum: [3, "minimum"], showBadge: [4, "showBadge"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.gallery && _co.gallery.length) || _co.count) || 0); var currVal_1 = _co.image; var currVal_2 = _co.placeholder; var currVal_3 = _co.minimum; var currVal_4 = _co.showBadge; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_GalleryPreviewPresenterComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fixiti-gallery-preview-placeholder", [], null, null, null, i4.View_GalleryPreviewPlaceholderComponent_0, i4.RenderType_GalleryPreviewPlaceholderComponent)), i1.ɵdid(1, 114688, null, 0, i5.GalleryPreviewPlaceholderComponent, [], { text: [0, "text"], count: [1, "count"], minimum: [2, "minimum"], showBadge: [3, "showBadge"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.placeholder; var currVal_1 = (((_co.gallery && _co.gallery.length) || _co.count) || 0); var currVal_2 = _co.minimum; var currVal_3 = _co.showBadge; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_GalleryPreviewPresenterComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_GalleryPreviewPresenterComponent_1)), i1.ɵdid(1, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["placeholderImage", 2]], null, 0, null, View_GalleryPreviewPresenterComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !!_co.image; var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
exports.View_GalleryPreviewPresenterComponent_0 = View_GalleryPreviewPresenterComponent_0;
function View_GalleryPreviewPresenterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fixiti-gallery-preview-presenter", [], null, null, null, View_GalleryPreviewPresenterComponent_0, RenderType_GalleryPreviewPresenterComponent)), i1.ɵdid(1, 114688, null, 0, i7.GalleryPreviewPresenterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_GalleryPreviewPresenterComponent_Host_0 = View_GalleryPreviewPresenterComponent_Host_0;
var GalleryPreviewPresenterComponentNgFactory = i1.ɵccf("fixiti-gallery-preview-presenter", i7.GalleryPreviewPresenterComponent, View_GalleryPreviewPresenterComponent_Host_0, { count: "count", minimum: "minimum", image: "image", placeholder: "placeholder", gallery: "gallery", showBadge: "showBadge" }, {}, []);
exports.GalleryPreviewPresenterComponentNgFactory = GalleryPreviewPresenterComponentNgFactory;
