/// <reference path="./api//task.ts" />
export class TaskModel implements ApiModel.Task {
    status = '';
    room = '';
    name = '';
    description = '';
    before = [];
    after = [];
    id: number;
    statusId: number;
    estimated: boolean;
    completed: boolean;
    roomId: number;
    labor: number | null;
    material: number | null;
    estimateTime: number | null;
    workDescription: string | null;
}
