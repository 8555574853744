"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var PropertyModel = /** @class */ (function () {
    function PropertyModel() {
        this.jobIds = [];
        this.statusIds = [];
        this.residents = [];
        this.frontElevation = [];
    }
    return PropertyModel;
}());
exports.PropertyModel = PropertyModel;
