/// <reference path="../../../../models/src/lib/api/permission.ts" />

import { Action } from '@ngrx/store';

export enum PermissionsActionTypes {
    LoadPermissions = '[Permissions] Load Permissions',
    PermissionsLoaded = '[Permissions] Permissions Loaded',
    PermissionsLoadError = '[Permissions] Permissions Load Error',
    ChangePermission = '[Permissions] Change Permission',
    DiscardPermissionChange = '[Permissions] Discard Permission Change',
    SavePermissions = '[Permissions] Save Permissions',
    PermissionsSaved = '[Permissions] Permissions Saved',
    PermissionsUpdated = '[Permissions] Permissions Updated',
}

export class LoadPermissions implements Action {
    readonly type = PermissionsActionTypes.LoadPermissions;
    constructor(public payload: number) {}
}

export class PermissionsLoaded implements Action {
    readonly type = PermissionsActionTypes.PermissionsLoaded;
    constructor(public payload: ApiModel.Permission[]) {}
}

export class PermissionsLoadError implements Action {
    readonly type = PermissionsActionTypes.PermissionsLoadError;
    constructor(public payload: any) {}
}

export class ChangePermission implements Action {
    readonly type = PermissionsActionTypes.ChangePermission;
    constructor(
        public payload: {
            roleId: number;
            permissionId: number;
            read: boolean;
            write: boolean;
        }
    ) {}
}

export class DiscardPermissionChange implements Action {
    readonly type = PermissionsActionTypes.DiscardPermissionChange;
    constructor(
        public payload: {
            roleId: number;
            permissionId: number;
        }
    ) {}
}

export class SavePermissions implements Action {
    readonly type = PermissionsActionTypes.SavePermissions;
    constructor(
        public payload: {
            roleId: number;
            permissionId: number;
            read: boolean;
            write: boolean;
        }[]
    ) {}
}

export class PermissionsSaved implements Action {
    readonly type = PermissionsActionTypes.PermissionsSaved;
    constructor(
        public payload: {
            roleId: number;
            permissionId: number;
            read: boolean;
            write: boolean;
        }[]
    ) {}
}

export class PermissionsUpdated implements Action {
    readonly type = PermissionsActionTypes.PermissionsUpdated;
    constructor() {}
}

export type PermissionsAction =
    | LoadPermissions
    | PermissionsLoaded
    | PermissionsLoadError
    | ChangePermission
    | DiscardPermissionChange
    | SavePermissions
    | PermissionsSaved
    | PermissionsUpdated;

export const fromPermissionsActions = {
    LoadPermissions,
    PermissionsLoaded,
    PermissionsLoadError,
    ChangePermission,
    DiscardPermissionChange,
    SavePermissions,
    PermissionsSaved,
    PermissionsUpdated,
};
