export class LandingPageSelectorsModel {
    getComments?: Function;
    getOptions?: Function;
    getPageDisplay: Function;
    getPaginationData: Function;
    getQuerySettings: Function;
    getRecords: Function;
    getSelectedRecord: Function;
    getWidgetFilters?: Function;
    inputErrors: Function;
    isWorking: Function;
    showComments?: Function;
    showCreate?: Function;
    showDeleteRecord?: Function;
    showFilterMenu: Function;
}
