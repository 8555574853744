import {
    PAGESETTINGS_FEATURE_KEY,
    initialState as pageSettingsInitialState,
    pageSettingsReducer,
} from './+state/page-settings.reducer';

import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(PAGESETTINGS_FEATURE_KEY, pageSettingsReducer, {
            initialState: pageSettingsInitialState,
        }),
    ],
})
export class StatePageSettingsModule {}
