/// <reference path="./api/gallery-image.ts" />

import { ResidentModel } from '@fixiti/models';

export class WorkOrderModel {
    id: number;
    address: string;
    city: string;
    state: string;
    zip: string;
    radius: number;
    propertyId: number;
    resident: ResidentModel;
    slotId: number;
    scheduleDate: Date;
    startDate: Date;
    completionDate: Date;
    description: string;
    coordinates: {
        latitude: number;
        longitude: number;
    };
    gallery: ApiModel.GalleryImage[] | string[] = [];
    numberItems: number;
    statusId: number;
    statusDate: Date;
    history: {
        status?: string;
        date: Date;
        notes: string;
    }[];
    created: Date;
    updated: Date;
    canApproveWO: boolean;
    canApproveEstimate: boolean;
    total: number;
    frontElevation: ApiModel.GalleryImage[] | string[] = [];
    activeNegotiation: boolean;
    activeNegotiationId: number | null;
    needsSchedulingApproval?: boolean;
    schedulingApproval?: boolean;
    needsEstimateApproval?: boolean;
    estimateApproval?: boolean;
}
