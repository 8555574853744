/// <reference path="./api/person.ts" />

export class InvestorModel implements ApiModel.Person {
    id: number;
    membershipId: number;
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
    profileUrl: string;
}
