"use strict";
/// <reference path="../../../../../models/src/lib/api/permission.ts" />
/// <reference path="../../../../../models/src/lib/api/user.ts" />
/// <reference path="../../../../../models/src/lib/api/role.ts" />
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var actions_1 = require("@fixiti/actions");
exports.rolesInitialState = {
    list: [],
    loaded: false,
    groups: {},
    addQueue: {},
    removalQueue: {},
    users: [],
    isDirty: false,
};
function rolesReducer(state, action) {
    if (state === void 0) { state = exports.rolesInitialState; }
    var _a, _b, _c, _d, _e, _f;
    switch (action.type) {
        case actions_1.RolesActionTypes.RolesLoaded: {
            state = __assign({}, state, { list: action.payload, loaded: true });
            break;
        }
        case actions_1.RolesActionTypes.SelectRole: {
            state = __assign({}, state, { selectedId: action.payload, addQueue: {}, removalQueue: {}, permission: null, users: [] });
            break;
        }
        case actions_1.RolesActionTypes.EditRole: {
            state = __assign({}, state, { editingId: action.payload });
            break;
        }
        case actions_1.RolesActionTypes.RoleCreated: {
            state = __assign({}, state, { list: state.list.concat([action.payload]) });
            break;
        }
        case actions_1.RolesActionTypes.RoleRemoved: {
            var roleIndex = state.list.findIndex(function (item) { return item.id === action.payload; });
            if (roleIndex >= 0) {
                state = __assign({}, state, { list: state.list.slice(0, roleIndex).concat(state.list.slice(roleIndex + 1)) });
            }
            break;
        }
        case actions_1.RolesActionTypes.RoleUpdated: {
            var roleIndex = state.list.findIndex(function (item) { return item.id === action.payload.id; });
            if (roleIndex >= 0) {
                state = __assign({}, state, { list: state.list.slice(0, roleIndex).concat([
                        action.payload
                    ], state.list.slice(roleIndex + 1)) });
            }
            else {
                state = __assign({}, state, { list: state.list.concat([action.payload]) });
            }
            break;
        }
        case actions_1.RolesActionTypes.UserAddedToRole: {
            var usersWithRole = state.groups[action.payload.roleId] || [];
            var userIndex = usersWithRole.findIndex(function (user) { return user.id === action.payload.user.id; });
            if (userIndex === -1) {
                state = __assign({}, state, { groups: __assign({}, state.groups, (_a = {}, _a[action.payload.roleId] = usersWithRole.concat([
                        action.payload.user,
                    ]), _a)) });
            }
            break;
        }
        case actions_1.RolesActionTypes.UserRemovedFromRole: {
            var usersWithRole = state.groups[action.payload.roleId] || [];
            var userIndex = usersWithRole.findIndex(function (user) { return user.id === action.payload.managerId; });
            if (userIndex !== -1) {
                state = __assign({}, state, { groups: __assign({}, state.groups, (_b = {}, _b[action.payload.roleId] = usersWithRole.slice(0, userIndex).concat(usersWithRole.slice(userIndex + 1)), _b)) });
            }
            break;
        }
        case actions_1.RolesActionTypes.QueueAddUserToRole: {
            var removalQueue = state.removalQueue[action.payload.roleId] || [];
            var removalIndex = removalQueue.indexOf(action.payload.managerId);
            var addQueue = state.addQueue[action.payload.roleId] || [];
            var addIndex = addQueue.indexOf(action.payload.managerId);
            if (removalIndex >= 0) {
                state = __assign({}, state, { removalQueue: __assign({}, state.removalQueue, (_c = {}, _c[action.payload.roleId] = removalQueue.slice(0, removalIndex).concat(removalQueue.slice(removalIndex + 1)), _c)) });
            }
            else if (addIndex === -1) {
                state = __assign({}, state, { addQueue: __assign({}, state.addQueue, (_d = {}, _d[action.payload.roleId] = addQueue.concat([
                        action.payload.managerId,
                    ]), _d)) });
            }
            break;
        }
        case actions_1.RolesActionTypes.QueueRemoveUserFromRole: {
            var addQueue = state.addQueue[action.payload.roleId] || [];
            var addIndex = addQueue.indexOf(action.payload.managerId);
            var removalQueue = state.removalQueue[action.payload.roleId] || [];
            var removalIndex = removalQueue.indexOf(action.payload.managerId);
            if (addIndex >= 0) {
                state = __assign({}, state, { addQueue: __assign({}, state.addQueue, (_e = {}, _e[action.payload.roleId] = addQueue.slice(0, addIndex).concat(addQueue.slice(addIndex + 1)), _e)) });
            }
            else if (removalIndex === -1) {
                state = __assign({}, state, { removalQueue: __assign({}, state.removalQueue, (_f = {}, _f[action.payload.roleId] = removalQueue.concat([
                        action.payload.managerId,
                    ]), _f)) });
            }
            break;
        }
        case actions_1.RolesActionTypes.RolesUpdated: {
            state = __assign({}, state, { addQueue: {}, removalQueue: {} });
            break;
        }
        case actions_1.RolesActionTypes.PermissionForRoleLoaded: {
            state = __assign({}, state, { permission: action.payload });
            break;
        }
        case actions_1.RolesActionTypes.UsersForRoleLoaded: {
            state = __assign({}, state, { users: action.payload });
            break;
        }
        case actions_1.RolesActionTypes.RolesRefreshNeeded: {
            state = __assign({}, state, { isDirty: true });
            break;
        }
        case actions_1.RolesActionTypes.RefreshedRoles: {
            state = __assign({}, state, { isDirty: false });
            break;
        }
    }
    return state;
}
exports.rolesReducer = rolesReducer;
