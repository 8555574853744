import { rolesInitialState, rolesReducer } from './+state/roles.reducer';

import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';
import { RolesEffects } from './+state/roles.effects';
import { StoreModule } from '@ngrx/store';
@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('roles', rolesReducer, {
            initialState: rolesInitialState,
        }),
        EffectsModule.forFeature([RolesEffects]),
    ],
})
export class StateRolesModule {}
