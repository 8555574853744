"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var AppActionTypes;
(function (AppActionTypes) {
    AppActionTypes["AppAction"] = "[App] Action";
    AppActionTypes["LoadApp"] = "[App] Load Data";
    AppActionTypes["AppLoaded"] = "[App] Data Loaded";
})(AppActionTypes = exports.AppActionTypes || (exports.AppActionTypes = {}));
var App = /** @class */ (function () {
    function App() {
        this.type = AppActionTypes.AppAction;
    }
    return App;
}());
exports.App = App;
var LoadApp = /** @class */ (function () {
    function LoadApp(payload) {
        this.payload = payload;
        this.type = AppActionTypes.LoadApp;
    }
    return LoadApp;
}());
exports.LoadApp = LoadApp;
var AppLoaded = /** @class */ (function () {
    function AppLoaded(payload) {
        this.payload = payload;
        this.type = AppActionTypes.AppLoaded;
    }
    return AppLoaded;
}());
exports.AppLoaded = AppLoaded;
