/// <reference path="../../../../../models/src/lib/api/permission.ts" />
/// <reference path="../../../../../models/src/lib/api/user.ts" />
/// <reference path="../../../../../models/src/lib/api/role.ts" />

import { RolesAction, RolesActionTypes } from '@fixiti/actions';

export interface RolesState {
    list: ApiModel.Role[];
    selectedId?: number;
    editingId?: number;
    loaded: boolean;
    error?: any;
    groups: {
        [id: number]: {
            id: number;
            firstName: string;
            lastName: string;
        }[];
    };
    addQueue: {
        [role: number]: number[];
    };
    removalQueue: {
        [role: number]: number[];
    };
    permission?: ApiModel.Permission;
    users: ApiModel.SimplifiedUser[];
    isDirty: boolean;
}

export const rolesInitialState: RolesState = {
    list: [],
    loaded: false,
    groups: {},
    addQueue: {},
    removalQueue: {},
    users: [],
    isDirty: false,
};

export function rolesReducer(
    state: RolesState = rolesInitialState,
    action: RolesAction
): RolesState {
    switch (action.type) {
        case RolesActionTypes.RolesLoaded: {
            state = {
                ...state,
                list: action.payload,
                loaded: true,
            };
            break;
        }
        case RolesActionTypes.SelectRole: {
            state = {
                ...state,
                selectedId: action.payload,
                addQueue: {},
                removalQueue: {},
                permission: null,
                users: [],
            };
            break;
        }
        case RolesActionTypes.EditRole: {
            state = {
                ...state,
                editingId: action.payload,
            };
            break;
        }
        case RolesActionTypes.RoleCreated: {
            state = {
                ...state,
                list: [...state.list, action.payload],
            };
            break;
        }
        case RolesActionTypes.RoleRemoved: {
            const roleIndex = state.list.findIndex(
                item => item.id === action.payload
            );
            if (roleIndex >= 0) {
                state = {
                    ...state,
                    list: [
                        ...state.list.slice(0, roleIndex),
                        ...state.list.slice(roleIndex + 1),
                    ],
                };
            }
            break;
        }
        case RolesActionTypes.RoleUpdated: {
            const roleIndex = state.list.findIndex(
                item => item.id === action.payload.id
            );
            if (roleIndex >= 0) {
                state = {
                    ...state,
                    list: [
                        ...state.list.slice(0, roleIndex),
                        action.payload,
                        ...state.list.slice(roleIndex + 1),
                    ],
                };
            } else {
                state = {
                    ...state,
                    list: [...state.list, action.payload],
                };
            }
            break;
        }
        case RolesActionTypes.UserAddedToRole: {
            const usersWithRole = state.groups[action.payload.roleId] || [];
            const userIndex = usersWithRole.findIndex(
                user => user.id === action.payload.user.id
            );

            if (userIndex === -1) {
                state = {
                    ...state,
                    groups: {
                        ...state.groups,
                        [action.payload.roleId]: [
                            ...usersWithRole,
                            action.payload.user,
                        ],
                    },
                };
            }
            break;
        }
        case RolesActionTypes.UserRemovedFromRole: {
            const usersWithRole = state.groups[action.payload.roleId] || [];
            const userIndex = usersWithRole.findIndex(
                user => user.id === action.payload.managerId
            );

            if (userIndex !== -1) {
                state = {
                    ...state,
                    groups: {
                        ...state.groups,
                        [action.payload.roleId]: [
                            ...usersWithRole.slice(0, userIndex),
                            ...usersWithRole.slice(userIndex + 1),
                        ],
                    },
                };
            }
            break;
        }
        case RolesActionTypes.QueueAddUserToRole: {
            const removalQueue =
                state.removalQueue[action.payload.roleId] || [];
            const removalIndex = removalQueue.indexOf(action.payload.managerId);
            const addQueue = state.addQueue[action.payload.roleId] || [];
            const addIndex = addQueue.indexOf(action.payload.managerId);
            if (removalIndex >= 0) {
                state = {
                    ...state,
                    removalQueue: {
                        ...state.removalQueue,
                        [action.payload.roleId]: [
                            ...removalQueue.slice(0, removalIndex),
                            ...removalQueue.slice(removalIndex + 1),
                        ],
                    },
                };
            } else if (addIndex === -1) {
                state = {
                    ...state,
                    addQueue: {
                        ...state.addQueue,
                        [action.payload.roleId]: [
                            ...addQueue,
                            action.payload.managerId,
                        ],
                    },
                };
            }
            break;
        }
        case RolesActionTypes.QueueRemoveUserFromRole: {
            const addQueue = state.addQueue[action.payload.roleId] || [];
            const addIndex = addQueue.indexOf(action.payload.managerId);
            const removalQueue =
                state.removalQueue[action.payload.roleId] || [];
            const removalIndex = removalQueue.indexOf(action.payload.managerId);
            if (addIndex >= 0) {
                state = {
                    ...state,
                    addQueue: {
                        ...state.addQueue,
                        [action.payload.roleId]: [
                            ...addQueue.slice(0, addIndex),
                            ...addQueue.slice(addIndex + 1),
                        ],
                    },
                };
            } else if (removalIndex === -1) {
                state = {
                    ...state,
                    removalQueue: {
                        ...state.removalQueue,
                        [action.payload.roleId]: [
                            ...removalQueue,
                            action.payload.managerId,
                        ],
                    },
                };
            }
            break;
        }
        case RolesActionTypes.RolesUpdated: {
            state = {
                ...state,
                addQueue: {},
                removalQueue: {},
            };
            break;
        }
        case RolesActionTypes.PermissionForRoleLoaded: {
            state = {
                ...state,
                permission: action.payload,
            };
            break;
        }
        case RolesActionTypes.UsersForRoleLoaded: {
            state = {
                ...state,
                users: action.payload,
            };
            break;
        }
        case RolesActionTypes.RolesRefreshNeeded: {
            state = {
                ...state,
                isDirty: true,
            };
            break;
        }
        case RolesActionTypes.RefreshedRoles: {
            state = {
                ...state,
                isDirty: false,
            };
            break;
        }
    }
    return state;
}
