"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var SharedApiNormalizationService = /** @class */ (function () {
    function SharedApiNormalizationService() {
    }
    SharedApiNormalizationService.prototype.asInt = function (input) {
        if (typeof input === 'undefined' || input === null) {
            return 0;
        }
        return typeof input === 'string' ? parseInt(input, 10) : Math.floor(input);
    };
    SharedApiNormalizationService.prototype.asFloat = function (input) {
        if (typeof input === 'undefined' || input === null) {
            return 0;
        }
        return typeof input === 'string' ? parseFloat(input) : input;
    };
    SharedApiNormalizationService.prototype.asString = function (input) {
        if (typeof input === 'undefined' || input === null) {
            return '';
        }
        return typeof input === 'string' ? input : input.toString();
    };
    SharedApiNormalizationService.prototype.asBoolean = function (input) {
        if (input === '0') {
            return false;
        }
        return !!input;
    };
    SharedApiNormalizationService.prototype.asDate = function (input) {
        if (!input || typeof input === 'undefined' || input === null) {
            return new Date(0);
        }
        if (input instanceof Date) {
            return input;
        }
        // if a number assume a unix timestamp
        if (typeof input === 'number') {
            return new Date(input * 1000);
        }
        // ditto for a string of only numbers
        if (/^\d+$/.test(input)) {
            return new Date(parseInt(input, 10) * 1000);
        }
        return new Date(input);
    };
    SharedApiNormalizationService.prototype.asAnyArray = function (input) {
        if (typeof input === 'undefined' || input === null || !Array.isArray(input)) {
            return [];
        }
        return input;
    };
    SharedApiNormalizationService.prototype.asIntArray = function (input) {
        var _this = this;
        if (typeof input === 'undefined' || input === null || !Array.isArray(input)) {
            return [];
        }
        return input.map(function (val) { return _this.asInt(val); });
    };
    SharedApiNormalizationService.prototype.asGallery = function (images) {
        if (!images) {
            return [];
        }
        var normalized = [];
        var requiredArrays = ['availableSizes', 'thumbnails'];
        for (var _i = 0, images_1 = images; _i < images_1.length; _i++) {
            var image = images_1[_i];
            var id = this.asInt(image.id);
            if (!id) {
                continue;
            }
            if (!('availableSizes' in image) ||
                !Array.isArray(image.availableSizes) ||
                !image.availableSizes.length) {
                continue;
            }
            if (!('thumbnails' in image) || typeof image.thumbnails !== 'object') {
                continue;
            }
            var normalizedImage = {
                id: id,
                availableSizes: [],
                thumbnails: {},
            };
            for (var _a = 0, _b = Object.keys(image.thumbnails); _a < _b.length; _a++) {
                var key = _b[_a];
                normalizedImage.thumbnails[key] = this.asString(image.thumbnails[key]);
            }
            for (var _c = 0, _d = image.availableSizes; _c < _d.length; _c++) {
                var size = _d[_c];
                size = this.asString(size);
                if (!(size in normalizedImage.thumbnails)) {
                    continue;
                }
                normalizedImage.availableSizes.push(size);
            }
            normalized.push(normalizedImage);
        }
        return normalized;
    };
    SharedApiNormalizationService.prototype.asVideoGallery = function (videos) {
        if (!videos) {
            return [];
        }
        var requiredProperties = ['id', 'url', 'thumbnailUrl'];
        var normalized = [];
        for (var _i = 0, videos_1 = videos; _i < videos_1.length; _i++) {
            var rawVideo = videos_1[_i];
            var video = {
                id: this.asInt(rawVideo.id),
                url: this.asString(rawVideo.url),
                thumbnailUrl: this.asString(rawVideo.thumbnailUrl),
            };
            if (!video.id || !video.url || !video.thumbnailUrl) {
                continue;
            }
            normalized.push(video);
        }
        return normalized;
    };
    SharedApiNormalizationService.prototype.asErrors = function (errors) {
        var normalizedErrors = {};
        if (!Array.isArray(errors) || !errors) {
            return normalizedErrors;
        }
        var nErrors = 0;
        for (var _i = 0, errors_1 = errors; _i < errors_1.length; _i++) {
            var error = errors_1[_i];
            if (!('field' in error) || !('error' in error)) {
                continue;
            }
            nErrors += 1;
            normalizedErrors[this.asString(error.field)] = this.asString(error.error);
        }
        return normalizedErrors;
    };
    SharedApiNormalizationService.prototype.asListViewPagination = function (data) {
        return {
            numMatches: this.asInt(data.num_matches),
            numReturned: this.asInt(data.num_returned),
            start: this.asInt(data.start),
            end: this.asInt(data.end),
            isFirstPage: !!data.is_first_page,
            isLastPage: !!data.is_last_page,
            maxPage: this.asInt(data.max_page),
            currentPage: this.asInt(data.current_page),
            nextPage: this.asInt(data.next_page),
            previousPage: this.asInt(data.previous_page),
        };
    };
    SharedApiNormalizationService.prototype.asWidgetFilter = function (filter) {
        return {
            id: this.asInt(filter.id),
            count: this.asInt(filter.count),
            label: this.asString(filter.label),
        };
    };
    SharedApiNormalizationService.prototype.asFormOptions = function (rawOptions) {
        // the form options model is designed for storing all the options
        // for all the fields, so in practice it looks something like
        // {investorId: [{value: 1, label: "hi"}, etc], customerId: [{value: 2, label: "person"},  etc]}
        var formOptions = {};
        for (var _i = 0, _a = Object.keys(rawOptions); _i < _a.length; _i++) {
            var key = _a[_i];
            if (!Array.isArray(rawOptions[key])) {
                continue;
            }
            formOptions[key] = [];
            for (var _b = 0, _c = rawOptions[key]; _b < _c.length; _b++) {
                var option = _c[_b];
                if (!option.label || !option.value) {
                    continue;
                }
                formOptions[key].push({
                    value: this.asString(option.value),
                    label: this.asString(option.label),
                });
            }
        }
        return formOptions;
    };
    SharedApiNormalizationService.prototype.asComments = function (rawComments) {
        var _this = this;
        if (!Array.isArray(rawComments)) {
            return [];
        }
        var cleaned = rawComments.map(function (rawComment) {
            return {
                id: _this.asInt(rawComment.id),
                firstName: _this.asString(rawComment.firstName),
                lastName: _this.asString(rawComment.lastName),
                comment: _this.asString(rawComment.comment),
                created: _this.asDate(rawComment.created),
            };
        });
        return cleaned.filter(function (val) { return !!val.id && !!val.comment; });
    };
    SharedApiNormalizationService.ngInjectableDef = i0.defineInjectable({ factory: function SharedApiNormalizationService_Factory() { return new SharedApiNormalizationService(); }, token: SharedApiNormalizationService, providedIn: "root" });
    return SharedApiNormalizationService;
}());
exports.SharedApiNormalizationService = SharedApiNormalizationService;
