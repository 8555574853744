"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var SearchActionTypes;
(function (SearchActionTypes) {
    SearchActionTypes["ChangeSearch"] = "[Search] Change Search";
})(SearchActionTypes = exports.SearchActionTypes || (exports.SearchActionTypes = {}));
var ChangeSearch = /** @class */ (function () {
    function ChangeSearch(payload) {
        this.payload = payload;
        this.type = SearchActionTypes.ChangeSearch;
    }
    return ChangeSearch;
}());
exports.ChangeSearch = ChangeSearch;
exports.fromSearchActions = {
    ChangeSearch: ChangeSearch,
};
