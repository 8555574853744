/// <reference path="../../../../models/src/lib/api/permission.ts" />
/// <reference path="../../../../models/src/lib/api/user.ts" />

import { PermissionsEnum } from '@fixiti/enums/permissions.enum';

export function findPermissions(url: string) {
    const permissions = [];
    if (url.indexOf('/properties') >= 0) {
        permissions.push(PermissionsEnum.properties);
    } else if (url.indexOf('/maintenance') >= 0) {
        permissions.push(PermissionsEnum.jobs);
    } else if (url.indexOf('/residents') >= 0) {
        permissions.push(PermissionsEnum.residents);
    } else if (url.indexOf('/investors') >= 0) {
        permissions.push(PermissionsEnum.investors);
    } else if (url.indexOf('/leases') >= 0) {
        permissions.push(PermissionsEnum.leases);
    } else if (url.indexOf('/reports') >= 0) {
        permissions.push(PermissionsEnum.reports);
    } else if (url.indexOf('/accounting') >= 0) {
        permissions.push(PermissionsEnum.accounting);
    } else if (url.indexOf('/users') >= 0) {
        permissions.push(PermissionsEnum.users);
    } else if (url.indexOf('/roles') >= 0) {
        permissions.push(PermissionsEnum.permissions);
    } else {
        permissions.push(PermissionsEnum.unknown);
    }
    return permissions;
}

export function canRead(
    permissionName: string,
    permissions: ApiModel.Permission[]
) {
    return permissions.some(
        permission => permission.name === permissionName && permission.read
    );
}

export function checkIfPermissionCanRead(
    user: ApiModel.User,
    permission: PermissionsEnum
) {
    const permissionName = PermissionsEnum[permission];
    return (
        user &&
        user.roles &&
        user.roles.length &&
        user.roles.some(role =>
            canRead(permissionName, (role && role.permissions) || [])
        )
    );
}
