import { Component, Input } from '@angular/core';

@Component({
    selector: 'fixiti-photo-dialog-dialog-header',
    templateUrl: './dialog-header.component.html',
    styleUrls: ['./dialog-header.component.css'],
})
export class DialogHeaderComponent {
    @Input()
    title = 'Overview Scope';
    /* istanbul ignore next */
    constructor() {}
}
