"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var DialogHeaderComponent = /** @class */ (function () {
    /* istanbul ignore next */
    function DialogHeaderComponent() {
        this.title = 'Overview Scope';
    }
    return DialogHeaderComponent;
}());
exports.DialogHeaderComponent = DialogHeaderComponent;
