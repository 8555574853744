<div class="flex-container vertical">
    <ng-container *ngFor="let key of minimumArray; let i = index">
        <div class="flex-container vertical picture">
            <label class="mat-file-input button mat-raised-button">
                <fixiti-gallery-preview-button
                    [class.halo]="images && images.length === i"
                    [image]="(images && images[i]) || null"
                    [gallery]="images || []"
                    [showBadge]="false"
                    (clickChange)="selectFile(imageSelector)"
                >
                </fixiti-gallery-preview-button>
                <span class="fill center"
                    >{{ text | titlecase }} #{{ key }}</span
                >
                <input
                    #imageSelector
                    type="file"
                    accept="image/jpg,image/JPG,image/jpeg,image/JPEG"
                    capture="camera"
                    (change)="onChange($event, i)"
                />
            </label>
        </div>
    </ng-container>
    <div class="flex-container vertical picture">
        <label class="mat-file-input button mat-raised-button">
            <fixiti-gallery-preview-button
                [image]="
                    (images &&
                        images.length >= minimum &&
                        images[images.length - 1].url) ||
                    null
                "
                [class.halo]="images && images.length === minimum - 1"
                [gallery]="images || []"
                [showBadge]="false"
                (clickChange)="selectFile(secondImageSelector)"
            >
            </fixiti-gallery-preview-button>
            <span class="fill center"
                >{{ text | titlecase }}
                {{ getLastString((images && images.length) || 0) }}</span
            >
            <input
                #secondImageSelector
                type="file"
                accept="image/jpg,image/JPG,image/jpeg,image/JPEG"
                capture="camera"
                (change)="onChange($event, getIndex(images.length))"
            />
        </label>
    </div>
</div>
