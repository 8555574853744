"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var store_1 = require("@ngrx/store");
// Lookup the 'PageSettings' feature state managed by NgRx
var getPageSettingsState = store_1.createFeatureSelector('pageSettings');
var ɵ0 = function (state) { return state.current; };
exports.ɵ0 = ɵ0;
var getCurrentPage = store_1.createSelector(getPageSettingsState, ɵ0);
var ɵ1 = function (state) { return state.updatingUrl; };
exports.ɵ1 = ɵ1;
var getPageUrlUpdating = store_1.createSelector(getPageSettingsState, ɵ1);
var ɵ2 = function (state) {
    return Object.freeze(__assign({}, state.display));
};
exports.ɵ2 = ɵ2;
var getAllPagesDisplaySettings = store_1.createSelector(getPageSettingsState, ɵ2);
var ɵ3 = function (page, displaySettings) {
    return displaySettings[page] || 'list';
};
exports.ɵ3 = ɵ3;
var getCurrentDisplaySettings = store_1.createSelector(getCurrentPage, getAllPagesDisplaySettings, ɵ3);
var ɵ4 = function (state) {
    return Object.freeze(__assign({}, state.filter));
};
exports.ɵ4 = ɵ4;
var getAllPagesFilterSettings = store_1.createSelector(getPageSettingsState, ɵ4);
var ɵ5 = function (page, filterSettings) {
    return filterSettings[page];
};
exports.ɵ5 = ɵ5;
var getCurrentFilterSettings = store_1.createSelector(getCurrentPage, getAllPagesFilterSettings, ɵ5);
var ɵ6 = function (state) {
    return Object.freeze(__assign({}, state.pageIndex));
};
exports.ɵ6 = ɵ6;
var getAllPagesIndexSettings = store_1.createSelector(getPageSettingsState, ɵ6);
var ɵ7 = function (page, pageIndexSettings) {
    return pageIndexSettings[page] || 0;
};
exports.ɵ7 = ɵ7;
var getCurrentPageIndexSettings = store_1.createSelector(getCurrentPage, getAllPagesIndexSettings, ɵ7);
var ɵ8 = function (state) {
    return Object.freeze(__assign({}, state.pageLength));
};
exports.ɵ8 = ɵ8;
var getAllPagesLengthSettings = store_1.createSelector(getPageSettingsState, ɵ8);
var ɵ9 = function (page, pageLengthSettings) {
    return pageLengthSettings[page] || 0;
};
exports.ɵ9 = ɵ9;
var getCurrentPageLengthSettings = store_1.createSelector(getCurrentPage, getAllPagesLengthSettings, ɵ9);
var ɵ10 = function (state) {
    return Object.freeze(__assign({}, state.pageSize));
};
exports.ɵ10 = ɵ10;
var getAllPagesSizeSettings = store_1.createSelector(getPageSettingsState, ɵ10);
var ɵ11 = function (page, pageSizeSettings) {
    return pageSizeSettings[page] || 100;
};
exports.ɵ11 = ɵ11;
var getCurrentPageSizeSettings = store_1.createSelector(getCurrentPage, getAllPagesSizeSettings, ɵ11);
var ɵ12 = function (state) {
    return Object.freeze(__assign({}, state.sortDirection));
};
exports.ɵ12 = ɵ12;
var getAllPagesSortDirectionSettings = store_1.createSelector(getPageSettingsState, ɵ12);
var ɵ13 = function (page, sortDirectionSettings) {
    return sortDirectionSettings[page] || 'desc';
};
exports.ɵ13 = ɵ13;
var getCurrentSortDirectionSettings = store_1.createSelector(getCurrentPage, getAllPagesSortDirectionSettings, ɵ13);
var ɵ14 = function (state) {
    return Object.freeze(__assign({}, state.sortField));
};
exports.ɵ14 = ɵ14;
var getAllPagesSortFieldSettings = store_1.createSelector(getPageSettingsState, ɵ14);
var ɵ15 = function (page, sortFieldSettings) {
    return sortFieldSettings[page];
};
exports.ɵ15 = ɵ15;
var getCurrentSortFieldSettings = store_1.createSelector(getCurrentPage, getAllPagesSortFieldSettings, ɵ15);
var ɵ16 = function (state) {
    return Object.freeze(__assign({}, state.reportId));
};
exports.ɵ16 = ɵ16;
var getAllPageReportIds = store_1.createSelector(getPageSettingsState, ɵ16);
var ɵ17 = function (page, pageSizeSettings) {
    return pageSizeSettings[page];
};
exports.ɵ17 = ɵ17;
var getCurrentPageReportIds = store_1.createSelector(getCurrentPage, getAllPageReportIds, ɵ17);
var ɵ18 = function (pageIndex, pageLength, pageSize) {
    return Object.freeze({
        pageIndex: pageIndex,
        pageLength: pageLength,
        pageSize: pageSize,
    });
};
exports.ɵ18 = ɵ18;
var getCurrentPagePaginatorSettings = store_1.createSelector(getCurrentPageIndexSettings, getCurrentPageLengthSettings, getCurrentPageSizeSettings, ɵ18);
var ɵ19 = function (sortDirection, sortField) {
    return Object.freeze({
        sortDirection: sortDirection,
        sortField: sortField,
    });
};
exports.ɵ19 = ɵ19;
var getCurrentPageSorterSettings = store_1.createSelector(getCurrentSortDirectionSettings, getCurrentSortFieldSettings, ɵ19);
var ɵ20 = function (display, filter, reportId, page) {
    return Object.freeze({
        display: display,
        filter: filter,
        reportId: reportId,
        page: page,
    });
};
exports.ɵ20 = ɵ20;
var getCurrentPageOtherSettings = store_1.createSelector(getCurrentDisplaySettings, getCurrentFilterSettings, getCurrentPageReportIds, getCurrentPage, ɵ20);
var ɵ21 = function (paginator, sorter, other) {
    return Object.freeze(__assign({}, paginator, sorter, other));
};
exports.ɵ21 = ɵ21;
var getCurrentPageSettings = store_1.createSelector(getCurrentPagePaginatorSettings, getCurrentPageSorterSettings, getCurrentPageOtherSettings, ɵ21);
exports.pageSettingsQuery = {
    getCurrentPage: getCurrentPage,
    getCurrentPageSettings: getCurrentPageSettings,
    getCurrentDisplaySettings: getCurrentDisplaySettings,
    getCurrentFilterSettings: getCurrentFilterSettings,
    getCurrentPageIndexSettings: getCurrentPageIndexSettings,
    getCurrentPageLengthSettings: getCurrentPageLengthSettings,
    getCurrentPageSizeSettings: getCurrentPageSizeSettings,
    getCurrentSortDirectionSettings: getCurrentSortDirectionSettings,
    getCurrentSortFieldSettings: getCurrentSortFieldSettings,
    getCurrentPageReportIds: getCurrentPageReportIds,
    getPageUrlUpdating: getPageUrlUpdating,
};
