"use strict";
/// <reference path="./api/gallery-image.ts" />
Object.defineProperty(exports, "__esModule", { value: true });
var GalleryImage = /** @class */ (function () {
    function GalleryImage() {
        this.url = '';
    }
    return GalleryImage;
}());
exports.GalleryImage = GalleryImage;
