import { Action } from '@ngrx/store';

export enum EnvironmentActionTypes {
    LoadEnvironment = '[Environment] Load Environment',
}

export class LoadEnvironment implements Action {
    readonly type = EnvironmentActionTypes.LoadEnvironment;
    constructor(
        public payload: {
            production: boolean;
            apiUrl: string;
        }
    ) {}
}

export type EnvironmentAction = LoadEnvironment;

export const fromEnvironmentActions = {
    LoadEnvironment,
};
