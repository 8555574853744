"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ProviderActionTypes;
(function (ProviderActionTypes) {
    ProviderActionTypes["LoadProviderSettings"] = "[Provider] Load Settings";
    ProviderActionTypes["ProviderSettingsLoaded"] = "[Provider] Settings Loaded";
})(ProviderActionTypes = exports.ProviderActionTypes || (exports.ProviderActionTypes = {}));
var LoadProviderSettings = /** @class */ (function () {
    function LoadProviderSettings() {
        this.type = ProviderActionTypes.LoadProviderSettings;
    }
    return LoadProviderSettings;
}());
exports.LoadProviderSettings = LoadProviderSettings;
var ProviderSettingsLoaded = /** @class */ (function () {
    function ProviderSettingsLoaded(payload) {
        this.payload = payload;
        this.type = ProviderActionTypes.ProviderSettingsLoaded;
    }
    return ProviderSettingsLoaded;
}());
exports.ProviderSettingsLoaded = ProviderSettingsLoaded;
