import { Observable, iif, of, throwError } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { concatMap, delay, map, mergeMap, retryWhen, take } from 'rxjs/operators';

import { HttpHeaders, HttpParams } from '@angular/common/http';
import { ModalService } from '@fixiti/elements/modal/src';
import { NotifyErrorComponent } from '@fixiti/shared/dialogs/notify-error/src';
import { environmentQuery } from '@fixiti/state/environment/src';

export abstract class RestApiBase {
    get serverObs() {
        return this.environmentApiUrl().pipe(
            mergeMap(envUrl => {
                if (envUrl) {
                    return of(envUrl);
                } else if (this.window && this.window.location && this.window.location.hostname) {
                    return of(`${this.window.location.protocol}//${this.window.location.hostname}/`);
                } else {
                    return of(`https://bizhub.fixiti.com/`);
                }
            }),
            take(1)
        );
    }
    window: Window;

    constructor(protected store: Store<any>, protected modalService: ModalService) {
        this.window = window;
    }

    isProduction() {
        return this.store.pipe(select(environmentQuery.getIsProduction));
    }

    environmentApiUrl() {
        return this.store.pipe(select(environmentQuery.getApiUrl));
    }

    defaultHttpHeaders() {
        return this.defaultHttpParameters().pipe(
            map(parameters => this.buildHttpHeader(parameters)),
            take(1)
        );
    }

    abstract defaultHttpParameters(): Observable<any>;

    buildHttpHeader(parameters) {
        let header = new HttpHeaders();
        Object.entries(parameters).forEach(([key, value]) => {
            header = header.append(key, <any>value);
        });
        return header;
    }

    checkForError() {
        return map((json: any) => {
            if (json.status === 'error' || json.status === 'failure') {
                console.error(json.errorMessage);
                throw new Error(`We're currently having issues. Please try again later.`);
            } else if (json.data && json.data.inputErrors) {
                throw (json.data.errors && json.data.errors[0]) ||
                    new Error(`We're currently having issues. Please try again later.`);
            }
            return json;
        });
    }

    checkForFailure() {
        return map((json: any) => {
            if (json.status === 'failure') {
                throw new Error();
            }
            return json;
        });
    }

    handleError(error: any): Promise<any> {
        console.error(error);
        this.modalService.error();
        return Promise.reject('');
    }

    setWindowLocationHref(url, window: Window) {
        window.location.href = url;
    }

    retryStrategy(attempts = 5) {
        return retryWhen(errors => {
            return errors.pipe(
                concatMap((e: any, i) => iif(() => i > attempts, throwError(e), of(e).pipe(delay(500))))
            );
        });
    }

    buildQueryParams(params: Object, cacheBust = false) {
        let httpParams = new HttpParams();
        if (params) {
            Object.entries(params).forEach(([key, value]) => {
                if (
                    key === null ||
                    value === null ||
                    typeof value === 'undefined' ||
                    typeof key === 'undefined'
                ) {
                    return;
                }

                httpParams = httpParams.set(key, value);
            });
        }
        if (cacheBust) {
            httpParams = httpParams.set(Math.round(Date.now() / 30000).toString(), '');
        }
        return httpParams;
    }
}
