"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var UploadImagesProgressComponent = /** @class */ (function () {
    function UploadImagesProgressComponent() {
        this.progress = 0;
        this.isVisible = false;
        this.startDate = new Date();
        this.minutes = 0;
        this.seconds = 0;
    }
    UploadImagesProgressComponent.prototype.ngOnInit = function () {
        this.setCountdownTimer();
    };
    UploadImagesProgressComponent.prototype.setCountdownTimer = function () {
        var _this = this;
        var endDate = new Date(this.startDate);
        endDate.setMinutes(endDate.getMinutes() + 5);
        this.endTime = endDate.getTime();
        this.countDownObs = rxjs_1.interval(250).pipe(operators_1.map(function () { return _this.calculate(new Date().getTime()); }));
    };
    UploadImagesProgressComponent.prototype.calculate = function (now) {
        var timeRemaining = (this.endTime - now) / 1000;
        if (timeRemaining >= 0) {
            this.minutes = Math.floor(timeRemaining / 60);
            timeRemaining = timeRemaining % 60;
            this.seconds = Math.floor(timeRemaining);
        }
        else {
            this.minutes = 0;
            this.seconds = 0;
        }
        return this.minutes || this.seconds
            ? this.minutes + ":" + this.seconds
            : '';
    };
    return UploadImagesProgressComponent;
}());
exports.UploadImagesProgressComponent = UploadImagesProgressComponent;
