import {
    ENVIRONMENT_FEATURE_KEY,
    initialState as environmentInitialState,
    environmentReducer,
} from './+state/environment.reducer';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(ENVIRONMENT_FEATURE_KEY, environmentReducer, {
            initialState: environmentInitialState,
        }),
    ],
})
export class StateEnvironmentModule {}
