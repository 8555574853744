import { Action } from '@ngrx/store';
import { PhotoDialogConfig } from '@fixiti/models';

export enum PhotoDialogTypes {
    LOAD_SETTINGS = '[Photo Dialog] Load Settings',
    ADD_IDS = '[Photo Dialog] Add Ids',
    PHOTO_DIALOG_CLOSE = '[Photo Dialog] Dialog Close',
}

/* istanbul ignore next */
export class LoadPhotoDialogConfiguration implements Action {
    readonly type = PhotoDialogTypes.LOAD_SETTINGS;

    constructor(public payload: { config: PhotoDialogConfig }) {}
}

/* istanbul ignore next */
export class AddPhotoDialogIds implements Action {
    readonly type = PhotoDialogTypes.ADD_IDS;

    constructor(public payload: { ids: number[] }) {}
}

export class PhotoDialogClose implements Action {
    readonly type = PhotoDialogTypes.PHOTO_DIALOG_CLOSE;
}

export type PhotoDialogActions =
    | LoadPhotoDialogConfiguration
    | AddPhotoDialogIds
    | PhotoDialogClose;
