"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var SettingsActionTypes;
(function (SettingsActionTypes) {
    SettingsActionTypes["LoadSettings"] = "[Settings] Load Settings";
    SettingsActionTypes["SetTimeout"] = "[Settings] Set Timeout";
})(SettingsActionTypes = exports.SettingsActionTypes || (exports.SettingsActionTypes = {}));
var LoadSettings = /** @class */ (function () {
    function LoadSettings(payload) {
        this.payload = payload;
        this.type = SettingsActionTypes.LoadSettings;
    }
    return LoadSettings;
}());
exports.LoadSettings = LoadSettings;
var SetTimeout = /** @class */ (function () {
    function SetTimeout(payload) {
        this.payload = payload;
        this.type = SettingsActionTypes.SetTimeout;
    }
    return SetTimeout;
}());
exports.SetTimeout = SetTimeout;
