"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var UploadImagesActionsCancelComponent = /** @class */ (function () {
    /* istanbul ignore next */
    function UploadImagesActionsCancelComponent() {
        this.isEnabled = true;
        this.cancel = new core_1.EventEmitter();
    }
    UploadImagesActionsCancelComponent.prototype.handleClick = function () {
        if (this.onCancel) {
            this.onCancel();
        }
        this.cancel.emit();
    };
    return UploadImagesActionsCancelComponent;
}());
exports.UploadImagesActionsCancelComponent = UploadImagesActionsCancelComponent;
