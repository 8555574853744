"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var store_1 = require("@ngrx/store");
// Lookup the 'Sidenav' feature state managed by NgRx
var getSidenavState = store_1.createFeatureSelector('sidenav');
var ɵ0 = function (state) { return state.isOpen; };
exports.ɵ0 = ɵ0;
var getIsSidenavOpen = store_1.createSelector(getSidenavState, ɵ0);
exports.sidenavQuery = {
    getIsSidenavOpen: getIsSidenavOpen,
};
