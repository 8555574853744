"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var store_1 = require("@ngrx/store");
var operators_1 = require("rxjs/operators");
var src_1 = require("@fixiti/actions/src");
var src_2 = require("@fixiti/state/user/src");
var i0 = require("@angular/core");
var i1 = require("@ngrx/store");
var AnonymousGuard = /** @class */ (function () {
    function AnonymousGuard(store) {
        this.store = store;
    }
    AnonymousGuard.prototype.canActivate = function () {
        var _this = this;
        return this.store.pipe(store_1.select(src_2.userQuery.getUser), operators_1.map(function (user) { return !user; }), operators_1.tap(function (loggedOut) {
            if (!loggedOut) {
                _this.store.dispatch(new src_1.Go({
                    path: ['dashboard'],
                }));
            }
        }));
    };
    AnonymousGuard.ngInjectableDef = i0.defineInjectable({ factory: function AnonymousGuard_Factory() { return new AnonymousGuard(i0.inject(i1.Store)); }, token: AnonymousGuard, providedIn: "root" });
    return AnonymousGuard;
}());
exports.AnonymousGuard = AnonymousGuard;
