<button
    #submitButton
    class="submit-button"
    type="button"
    mat-raised-button
    color="primary"
    *ngIf="!isEnabled || (files && files.length > 0)"
    (load)="submitButton.focus()"
    autofocus
    (click)="handleClick()"
>
    Submit
</button>
