/// <reference path="../../../../../../models/src/lib/api/gallery-image.ts" />
import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
} from '@angular/core';

@Component({
    selector: 'fixiti-gallery-preview-presenter',
    templateUrl: './gallery-preview-presenter.component.html',
    styleUrls: ['./gallery-preview-presenter.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GalleryPreviewPresenterComponent implements OnInit {
    @Input()
    count: number;
    @Input()
    minimum = 0;
    @Input()
    image: { src?: File; url: string };
    @Input()
    placeholder: string;
    @Input()
    gallery: ApiModel.GalleryImage[];
    @Input()
    showBadge = true;

    constructor() {}

    ngOnInit() {}
}
