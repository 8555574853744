"use strict";
/// <reference path="../../../../../models/src/lib/api/user.ts" />
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var actions_1 = require("@fixiti/actions");
exports.userInitialState = {
    current: null,
};
function userReducer(state, action) {
    if (state === void 0) { state = exports.userInitialState; }
    switch (action.type) {
        case actions_1.UserActionTypes.LoadUser: {
            state = __assign({}, state, { current: action.payload });
            break;
        }
        case actions_1.UserActionTypes.Logout: {
            state = __assign({}, state, { current: null });
            break;
        }
        case actions_1.UserActionTypes.KickUser: {
            state = __assign({}, state, { current: null });
            break;
        }
    }
    return state;
}
exports.userReducer = userReducer;
