"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var PhotoDialogTypes;
(function (PhotoDialogTypes) {
    PhotoDialogTypes["LOAD_SETTINGS"] = "[Photo Dialog] Load Settings";
    PhotoDialogTypes["ADD_IDS"] = "[Photo Dialog] Add Ids";
    PhotoDialogTypes["PHOTO_DIALOG_CLOSE"] = "[Photo Dialog] Dialog Close";
})(PhotoDialogTypes = exports.PhotoDialogTypes || (exports.PhotoDialogTypes = {}));
/* istanbul ignore next */
var LoadPhotoDialogConfiguration = /** @class */ (function () {
    function LoadPhotoDialogConfiguration(payload) {
        this.payload = payload;
        this.type = PhotoDialogTypes.LOAD_SETTINGS;
    }
    return LoadPhotoDialogConfiguration;
}());
exports.LoadPhotoDialogConfiguration = LoadPhotoDialogConfiguration;
/* istanbul ignore next */
var AddPhotoDialogIds = /** @class */ (function () {
    function AddPhotoDialogIds(payload) {
        this.payload = payload;
        this.type = PhotoDialogTypes.ADD_IDS;
    }
    return AddPhotoDialogIds;
}());
exports.AddPhotoDialogIds = AddPhotoDialogIds;
var PhotoDialogClose = /** @class */ (function () {
    function PhotoDialogClose() {
        this.type = PhotoDialogTypes.PHOTO_DIALOG_CLOSE;
    }
    return PhotoDialogClose;
}());
exports.PhotoDialogClose = PhotoDialogClose;
