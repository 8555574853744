/// <reference path="../../../../../models/src/lib/api/gallery-image.ts" />
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
    AddPhotoDialogIds,
    FileTypes,
    SubmitImages,
    SubmitImagesFailure,
    SubmitImagesSuccess,
} from '@fixiti/actions/src';
import {
    catchError,
    concatMap,
    map,
    mergeMap,
    take,
    tap,
    toArray,
} from 'rxjs/operators';
import { from, of } from 'rxjs';

import { ImagesRestApiService } from '@fixiti/api/rest/images/src';
import { Injectable } from '@angular/core';
import { PicaService } from '@fixiti/external/pica/src';
import { Store } from '@ngrx/store';

export interface FileWithData {
    file: File | ApiModel.GalleryImage;
    data: string;
    index: number;
}

@Injectable()
export class FileEffects {
    @Effect()
    submitImages$ = this.actions$.pipe(
        ofType(FileTypes.SUBMIT_IMAGES),
        map((action: SubmitImages) => action.payload.images),
        mergeMap((files: File[]) => this.processFiles(files)),
        map(images => new SubmitImagesSuccess({ images })),
        catchError(error => of(new SubmitImagesFailure(error)))
    );

    @Effect({ dispatch: false })
    submitImagesSuccess$ = this.actions$.pipe(
        ofType(FileTypes.SUBMIT_IMAGES_SUCCESS),
        map((action: SubmitImagesSuccess) => action.payload.images),
        map(images => images.map(image => image.id)),
        tap(ids => this.store.dispatch(new AddPhotoDialogIds({ ids })))
    );

    processFiles(files: (File | ApiModel.GalleryImage)[]) {
        const processingObs = from(
            files.map((file, index) => ({ file, index }))
        ).pipe(
            this.transformFileToFileWIthData(),
            this.handleUpload()
        );

        return processingObs.pipe(toArray());
    }

    transformFileToFileWIthData() {
        return mergeMap(
            ({
                file,
                index,
            }: {
                file: File | ApiModel.GalleryImage;
                index: number;
            }) =>
                new Promise<FileWithData>(resolve => {
                    if (file instanceof File) {
                        const fr = new FileReader();
                        fr.onloadend = ($event: any) => {
                            resolve({
                                file,
                                data: btoa($event.target.result),
                                index,
                            });
                        };
                        fr.readAsBinaryString(file);
                    } else {
                        resolve({
                            file,
                            data: null,
                            index,
                        });
                    }
                })
        );
    }

    handleUpload() {
        return mergeMap((fileInfo: FileWithData) => {
            if (!fileInfo.data) {
                return of({
                    id: (<ApiModel.GalleryImage>fileInfo.file).id,
                    file: null,
                    url: (<ApiModel.GalleryImage>fileInfo.file).url,
                });
            } else {
                return this.imagesApiService
                    .uploadImage({
                        file: <File>fileInfo.file,
                        data: fileInfo.data,
                        index: fileInfo.index,
                    })
                    .pipe(
                        mergeMap(id =>
                            of(id)
                                .pipe(
                                    map(_ => [fileInfo.file, fileInfo]),
                                    this.processImage()
                                )
                                .pipe(
                                    map(url => ({
                                        id,
                                        file: fileInfo.file,
                                        url,
                                    }))
                                )
                        )
                    );
            }
        });
    }

    processImage() {
        return concatMap(
            ([file, fileInfo]: [File | ApiModel.GalleryImage, FileWithData]) =>
                from(
                    new Promise<string>(resolve => {
                        if (fileInfo && fileInfo.data) {
                            resolve(`data:image/jpeg;base64,${fileInfo.data}`);
                        } else if (file instanceof File) {
                            const fr = new FileReader();

                            fr.onloadend = (item: any) => {
                                resolve(
                                    `data:image/jpeg;base64,${btoa(
                                        item.target.result
                                    )}`
                                );
                            };

                            this.imageService
                                .resizeImage(file, 512, 512, {
                                    aspectRatio: {
                                        keepAspectRatio: true,
                                    },
                                })
                                .pipe(take(1))
                                .subscribe(resizedFile => {
                                    fr.readAsBinaryString(resizedFile);
                                });
                        } else {
                            resolve(file.url);
                        }
                    })
                )
        );
    }

    constructor(
        private actions$: Actions,
        private imagesApiService: ImagesRestApiService,
        private store: Store<any>,
        private imageService: PicaService
    ) {}
}
