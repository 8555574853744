import { ENVIRONMENT_FEATURE_KEY, EnvironmentState } from './environment.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

// Lookup the 'Environment' feature state managed by NgRx
const getEnvironmentState = createFeatureSelector<EnvironmentState>(ENVIRONMENT_FEATURE_KEY);

const getLoaded = createSelector(
    getEnvironmentState,
    (state: EnvironmentState) => state.loaded
);

const getApiUrl = createSelector(
    getEnvironmentState,
    getLoaded,
    (state: EnvironmentState, isLoaded) => {
        return isLoaded ? state.current.apiUrl : '';
    }
);

const getSocketsUrl = createSelector(
    getEnvironmentState,
    getLoaded,
    (state: EnvironmentState, isLoaded) => {
        return isLoaded ? state.current.socketsUrl : '';
    }
);

const getIsProduction = createSelector(
    getEnvironmentState,
    getLoaded,
    (state: EnvironmentState, isLoaded) => {
        return isLoaded ? state.current.production : true;
    }
);

export const environmentQuery = {
    getLoaded,
    getApiUrl,
    getIsProduction,
    getSocketsUrl,
};
