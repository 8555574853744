import { CommonModule } from '@angular/common';
import { GalleryPreviewPresenterComponent } from './gallery-preview-presenter/gallery-preview-presenter.component';
import { NgModule } from '@angular/core';
import { SharedGalleryPreviewImageModule } from '@fixiti/shared/gallery-preview/image/src';
import { SharedGalleryPreviewPlaceholderModule } from '@fixiti/shared/gallery-preview/placeholder/src';

@NgModule({
    imports: [
        CommonModule,
        SharedGalleryPreviewImageModule,
        SharedGalleryPreviewPlaceholderModule,
    ],
    exports: [GalleryPreviewPresenterComponent],
    declarations: [GalleryPreviewPresenterComponent],
})
export class SharedGalleryPreviewPresenterModule {}
