"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ScopesActionTypes;
(function (ScopesActionTypes) {
    ScopesActionTypes["SelectScope"] = "[Scopes] Select Scope";
    ScopesActionTypes["LoadScopes"] = "[Scopes] Load Data";
    ScopesActionTypes["ScopesLoaded"] = "[Scopes] Data Loaded";
    ScopesActionTypes["SaveScope"] = "[Scopes] Save Scope";
    ScopesActionTypes["ScopeSaved"] = "[Scopes] Scope Saved";
})(ScopesActionTypes = exports.ScopesActionTypes || (exports.ScopesActionTypes = {}));
var SelectScope = /** @class */ (function () {
    function SelectScope(payload) {
        this.payload = payload;
        this.type = ScopesActionTypes.SelectScope;
    }
    return SelectScope;
}());
exports.SelectScope = SelectScope;
var LoadScopes = /** @class */ (function () {
    function LoadScopes(payload) {
        this.payload = payload;
        this.type = ScopesActionTypes.LoadScopes;
    }
    return LoadScopes;
}());
exports.LoadScopes = LoadScopes;
var ScopesLoaded = /** @class */ (function () {
    function ScopesLoaded(payload) {
        this.payload = payload;
        this.type = ScopesActionTypes.ScopesLoaded;
    }
    return ScopesLoaded;
}());
exports.ScopesLoaded = ScopesLoaded;
var SaveScope = /** @class */ (function () {
    function SaveScope(payload) {
        this.payload = payload;
        this.type = ScopesActionTypes.SaveScope;
    }
    return SaveScope;
}());
exports.SaveScope = SaveScope;
var ScopeSaved = /** @class */ (function () {
    function ScopeSaved(payload) {
        this.payload = payload;
        this.type = ScopesActionTypes.ScopeSaved;
    }
    return ScopeSaved;
}());
exports.ScopeSaved = ScopeSaved;
