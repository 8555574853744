import { Actions, Effect } from '@ngrx/effects';
import {
    LoadPermissions,
    PermissionsActionTypes,
    PermissionsLoadError,
    PermissionsLoaded,
    PermissionsSaved,
    SavePermissions,
} from '@fixiti/actions';
import { map, mergeMap, toArray } from 'rxjs/operators';

import { DataPersistence } from '@nrwl/nx';
import { Injectable } from '@angular/core';
import { PermissionsState } from './permissions.reducer';
import { PmpRestApiService } from '@fixiti/api/rest/pmp';
import { from } from 'rxjs';

@Injectable()
export class PermissionsEffects {
    @Effect()
    loadPermissions$ = this.dataPersistence.fetch(
        PermissionsActionTypes.LoadPermissions,
        {
            run: (action: LoadPermissions) => {
                return this.dataService
                    .getPermissions(action.payload)
                    .pipe(
                        map(permissions => new PermissionsLoaded(permissions))
                    );
            },

            onError: (_a, error) => {
                return new PermissionsLoadError(error);
            },
        }
    );
    @Effect()
    savePermissions$ = this.dataPersistence.fetch(
        PermissionsActionTypes.SavePermissions,
        {
            run: (action: SavePermissions) => {
                return from(action.payload).pipe(
                    mergeMap(permission =>
                        this.dataService.updatePermission(permission)
                    ),
                    toArray(),
                    map(permissions => new PermissionsSaved(permissions))
                );
            },

            onError: (_a, error) => ({
                type: 'ERROR',
                error,
            }),
        }
    );

    constructor(
        private actions$: Actions,
        private dataPersistence: DataPersistence<PermissionsState>,
        private dataService: PmpRestApiService
    ) {}
}
