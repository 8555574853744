"use strict";
/// <reference path="./../../../../models/src/lib/api/all-query.ts" />
Object.defineProperty(exports, "__esModule", { value: true });
var ImporterActionTypes;
(function (ImporterActionTypes) {
    ImporterActionTypes["ImportLoadFiles"] = "[Importer] Load Import Files";
    ImporterActionTypes["ImportOpenFilterMenu"] = "[Importer] Open Filter Menu";
    ImporterActionTypes["ImportCloseFilterMenu"] = "[Importer] Close Filter Menu";
    ImporterActionTypes["ImportFilesLoaded"] = "[Importer] Importer Files Loaded";
    ImporterActionTypes["ImportFilesLoadError"] = "[Importer] Import Files Load Error";
    ImporterActionTypes["ImportDeSelectFile"] = "[Importer] Deselect Import File";
    ImporterActionTypes["ImportSelectFile"] = "[Importer] Select Import File";
    ImporterActionTypes["ImportMappingLoaded"] = "[Importer] Import File Mapping Loaded";
    ImporterActionTypes["ImportMappingLoadError"] = "[Importer] Import File Mapping Load Error";
    ImporterActionTypes["ImportSaveMapping"] = "[Importer] Import File Save Mapping";
    ImporterActionTypes["ImportMappingSaved"] = "[Importer] Import File Mapping Saved";
    ImporterActionTypes["ImportMappingSaveError"] = "[Importer] Import File Mapping Save Error";
    ImporterActionTypes["ImportMappingInputErrors"] = "[Importer] Import File Mapping Input Errors";
    ImporterActionTypes["ImportDeleteFile"] = "[Importer] Import File Delete";
    ImporterActionTypes["ImportShowDelete"] = "[Importer] Import File Show Delete";
    ImporterActionTypes["ImportHideDelete"] = "[Importer] Import File Hide Delete";
    ImporterActionTypes["ImportFileDeleted"] = "[Importer] Import File Deleted";
    ImporterActionTypes["ImportFileDeleteError"] = "[Importer] Import File Delete Error";
    ImporterActionTypes["ImportShowCreateForm"] = "[Importer] Show Create form";
    ImporterActionTypes["ImportHideCreateForm"] = "[Importer] Hide Create form";
    ImporterActionTypes["ImportCreateFile"] = "[Importer] Import File Create";
    ImporterActionTypes["ImportFileCreated"] = "[Importer] Import File Created";
    ImporterActionTypes["ImportFileCreateError"] = "[Importer] Import File Create Error";
    ImporterActionTypes["ImportSetQuerySetting"] = "[Importer] Set Query Setting";
    ImporterActionTypes["ImportSetPageDisplay"] = "[Importer] Set Page Display";
    ImporterActionTypes["ImportSetPaginationData"] = "[Importer] Set Pagination Data";
})(ImporterActionTypes = exports.ImporterActionTypes || (exports.ImporterActionTypes = {}));
var ImportLoadFiles = /** @class */ (function () {
    function ImportLoadFiles(apiSection) {
        this.apiSection = apiSection;
        this.type = ImporterActionTypes.ImportLoadFiles;
    }
    return ImportLoadFiles;
}());
exports.ImportLoadFiles = ImportLoadFiles;
var ImportOpenFilterMenu = /** @class */ (function () {
    function ImportOpenFilterMenu() {
        this.type = ImporterActionTypes.ImportOpenFilterMenu;
    }
    return ImportOpenFilterMenu;
}());
exports.ImportOpenFilterMenu = ImportOpenFilterMenu;
var ImportCloseFilterMenu = /** @class */ (function () {
    function ImportCloseFilterMenu() {
        this.type = ImporterActionTypes.ImportCloseFilterMenu;
    }
    return ImportCloseFilterMenu;
}());
exports.ImportCloseFilterMenu = ImportCloseFilterMenu;
var ImportFilesLoadError = /** @class */ (function () {
    function ImportFilesLoadError(payload) {
        this.payload = payload;
        this.type = ImporterActionTypes.ImportFilesLoadError;
    }
    return ImportFilesLoadError;
}());
exports.ImportFilesLoadError = ImportFilesLoadError;
var ImportFilesLoaded = /** @class */ (function () {
    function ImportFilesLoaded(payload) {
        this.payload = payload;
        this.type = ImporterActionTypes.ImportFilesLoaded;
    }
    return ImportFilesLoaded;
}());
exports.ImportFilesLoaded = ImportFilesLoaded;
var ImportDeSelectFile = /** @class */ (function () {
    function ImportDeSelectFile() {
        this.type = ImporterActionTypes.ImportDeSelectFile;
    }
    return ImportDeSelectFile;
}());
exports.ImportDeSelectFile = ImportDeSelectFile;
var ImportSelectFile = /** @class */ (function () {
    function ImportSelectFile(payload) {
        this.payload = payload;
        this.type = ImporterActionTypes.ImportSelectFile;
    }
    return ImportSelectFile;
}());
exports.ImportSelectFile = ImportSelectFile;
var ImportMappingLoaded = /** @class */ (function () {
    function ImportMappingLoaded(payload) {
        this.payload = payload;
        this.type = ImporterActionTypes.ImportMappingLoaded;
    }
    return ImportMappingLoaded;
}());
exports.ImportMappingLoaded = ImportMappingLoaded;
var ImportMappingLoadError = /** @class */ (function () {
    function ImportMappingLoadError(payload) {
        this.payload = payload;
        this.type = ImporterActionTypes.ImportMappingLoadError;
    }
    return ImportMappingLoadError;
}());
exports.ImportMappingLoadError = ImportMappingLoadError;
var ImportSaveMapping = /** @class */ (function () {
    function ImportSaveMapping(payload) {
        this.payload = payload;
        this.type = ImporterActionTypes.ImportSaveMapping;
    }
    return ImportSaveMapping;
}());
exports.ImportSaveMapping = ImportSaveMapping;
var ImportMappingSaved = /** @class */ (function () {
    function ImportMappingSaved(payload) {
        this.payload = payload;
        this.type = ImporterActionTypes.ImportMappingSaved;
    }
    return ImportMappingSaved;
}());
exports.ImportMappingSaved = ImportMappingSaved;
var ImportMappingInputErrors = /** @class */ (function () {
    function ImportMappingInputErrors(errors) {
        this.errors = errors;
        this.type = ImporterActionTypes.ImportMappingInputErrors;
    }
    return ImportMappingInputErrors;
}());
exports.ImportMappingInputErrors = ImportMappingInputErrors;
var ImportMappingSaveError = /** @class */ (function () {
    function ImportMappingSaveError(payload) {
        this.payload = payload;
        this.type = ImporterActionTypes.ImportMappingSaveError;
    }
    return ImportMappingSaveError;
}());
exports.ImportMappingSaveError = ImportMappingSaveError;
var ImportShowDelete = /** @class */ (function () {
    function ImportShowDelete(payload) {
        this.payload = payload;
        this.type = ImporterActionTypes.ImportShowDelete;
    }
    return ImportShowDelete;
}());
exports.ImportShowDelete = ImportShowDelete;
var ImportHideDelete = /** @class */ (function () {
    function ImportHideDelete() {
        this.type = ImporterActionTypes.ImportHideDelete;
    }
    return ImportHideDelete;
}());
exports.ImportHideDelete = ImportHideDelete;
var ImportDeleteFile = /** @class */ (function () {
    function ImportDeleteFile(payload) {
        this.payload = payload;
        this.type = ImporterActionTypes.ImportDeleteFile;
    }
    return ImportDeleteFile;
}());
exports.ImportDeleteFile = ImportDeleteFile;
var ImportFileDeleted = /** @class */ (function () {
    function ImportFileDeleted() {
        this.type = ImporterActionTypes.ImportFileDeleted;
    }
    return ImportFileDeleted;
}());
exports.ImportFileDeleted = ImportFileDeleted;
var ImportFileDeleteError = /** @class */ (function () {
    function ImportFileDeleteError(payload) {
        this.payload = payload;
        this.type = ImporterActionTypes.ImportFileDeleteError;
    }
    return ImportFileDeleteError;
}());
exports.ImportFileDeleteError = ImportFileDeleteError;
var ImportShowCreateForm = /** @class */ (function () {
    function ImportShowCreateForm() {
        this.type = ImporterActionTypes.ImportShowCreateForm;
    }
    return ImportShowCreateForm;
}());
exports.ImportShowCreateForm = ImportShowCreateForm;
var ImportHideCreateForm = /** @class */ (function () {
    function ImportHideCreateForm() {
        this.type = ImporterActionTypes.ImportHideCreateForm;
    }
    return ImportHideCreateForm;
}());
exports.ImportHideCreateForm = ImportHideCreateForm;
var ImportCreateFile = /** @class */ (function () {
    function ImportCreateFile(payload) {
        this.payload = payload;
        this.type = ImporterActionTypes.ImportCreateFile;
    }
    return ImportCreateFile;
}());
exports.ImportCreateFile = ImportCreateFile;
var ImportFileCreated = /** @class */ (function () {
    function ImportFileCreated() {
        this.type = ImporterActionTypes.ImportFileCreated;
    }
    return ImportFileCreated;
}());
exports.ImportFileCreated = ImportFileCreated;
var ImportFileCreateError = /** @class */ (function () {
    function ImportFileCreateError(payload) {
        this.payload = payload;
        this.type = ImporterActionTypes.ImportFileCreateError;
    }
    return ImportFileCreateError;
}());
exports.ImportFileCreateError = ImportFileCreateError;
var ImportSetQuerySetting = /** @class */ (function () {
    function ImportSetQuerySetting(name, value) {
        this.name = name;
        this.value = value;
        this.type = ImporterActionTypes.ImportSetQuerySetting;
    }
    return ImportSetQuerySetting;
}());
exports.ImportSetQuerySetting = ImportSetQuerySetting;
var ImportSetPageDisplay = /** @class */ (function () {
    function ImportSetPageDisplay(pageDisplay) {
        this.pageDisplay = pageDisplay;
        this.type = ImporterActionTypes.ImportSetPageDisplay;
    }
    return ImportSetPageDisplay;
}());
exports.ImportSetPageDisplay = ImportSetPageDisplay;
var ImportSetPaginationData = /** @class */ (function () {
    function ImportSetPaginationData(paginationData) {
        this.paginationData = paginationData;
        this.type = ImporterActionTypes.ImportSetPaginationData;
    }
    return ImportSetPaginationData;
}());
exports.ImportSetPaginationData = ImportSetPaginationData;
exports.fromImporterActions = {
    ImportLoadFiles: ImportLoadFiles,
    ImportOpenFilterMenu: ImportOpenFilterMenu,
    ImportCloseFilterMenu: ImportCloseFilterMenu,
    ImportFilesLoaded: ImportFilesLoaded,
    ImportFilesLoadError: ImportFilesLoadError,
    ImportSelectFile: ImportSelectFile,
    ImportDeSelectFile: ImportDeSelectFile,
    ImportMappingLoaded: ImportMappingLoaded,
    ImportMappingLoadError: ImportMappingLoadError,
    ImportSaveMapping: ImportSaveMapping,
    ImportMappingSaved: ImportMappingSaved,
    ImportMappingSaveError: ImportMappingSaveError,
    ImportMappingInputErrors: ImportMappingInputErrors,
    ImportHideDelete: ImportHideDelete,
    ImportShowDelete: ImportShowDelete,
    ImportDeleteFile: ImportDeleteFile,
    ImportFileDeleted: ImportFileDeleted,
    ImportFileDeleteError: ImportFileDeleteError,
    ImportShowCreateForm: ImportShowCreateForm,
    ImportHideCreateForm: ImportHideCreateForm,
    ImportCreateFile: ImportCreateFile,
    ImportFileCreated: ImportFileCreated,
    ImportFileCreateError: ImportFileCreateError,
    ImportSetQuerySetting: ImportSetQuerySetting,
    ImportSetPageDisplay: ImportSetPageDisplay,
    ImportSetPaginationData: ImportSetPaginationData,
};
