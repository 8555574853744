"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./upload-images-actions.component.css.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../upload-images-actions-submit/upload-images-actions-submit.component.ngfactory");
var i3 = require("../upload-images-actions-submit/upload-images-actions-submit.component");
var i4 = require("../upload-images-actions-cancel/upload-images-actions-cancel.component.ngfactory");
var i5 = require("../upload-images-actions-cancel/upload-images-actions-cancel.component");
var i6 = require("@angular/common");
var i7 = require("./upload-images-actions.component");
var styles_UploadImagesActionsComponent = [i0.styles];
var RenderType_UploadImagesActionsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UploadImagesActionsComponent, data: {} });
exports.RenderType_UploadImagesActionsComponent = RenderType_UploadImagesActionsComponent;
function View_UploadImagesActionsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "fixiti-photo-dialog-upload-images-actions-submit", [], null, [[null, "submit"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (_co.submit.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_UploadImagesActionsSubmitComponent_0, i2.RenderType_UploadImagesActionsSubmitComponent)), i1.ɵdid(2, 49152, null, 0, i3.UploadImagesActionsSubmitComponent, [], { files: [0, "files"], onSubmit: [1, "onSubmit"], isEnabled: [2, "isEnabled"] }, { submit: "submit" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.files; var currVal_1 = _co.onSubmit; var currVal_2 = _co.canSubmit; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_UploadImagesActionsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fixiti-photo-dialog-upload-images-actions-cancel", [], null, [[null, "cancel"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cancel" === en)) {
        var pd_0 = (_co.cancel.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_UploadImagesActionsCancelComponent_0, i4.RenderType_UploadImagesActionsCancelComponent)), i1.ɵdid(1, 49152, null, 0, i5.UploadImagesActionsCancelComponent, [], { isEnabled: [0, "isEnabled"], onCancel: [1, "onCancel"] }, { cancel: "cancel" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.canCancel; var currVal_1 = _co.onCancel; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_UploadImagesActionsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadImagesActionsComponent_1)), i1.ɵdid(1, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["showCancel", 2]], null, 0, null, View_UploadImagesActionsComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.files && (_co.files.length >= _co.minimum)); var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
exports.View_UploadImagesActionsComponent_0 = View_UploadImagesActionsComponent_0;
function View_UploadImagesActionsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fixiti-photo-dialog-upload-images-actions", [], null, null, null, View_UploadImagesActionsComponent_0, RenderType_UploadImagesActionsComponent)), i1.ɵdid(1, 49152, null, 0, i7.UploadImagesActionsComponent, [], null, null)], null, null); }
exports.View_UploadImagesActionsComponent_Host_0 = View_UploadImagesActionsComponent_Host_0;
var UploadImagesActionsComponentNgFactory = i1.ɵccf("fixiti-photo-dialog-upload-images-actions", i7.UploadImagesActionsComponent, View_UploadImagesActionsComponent_Host_0, { files: "files", onSubmit: "onSubmit", onCancel: "onCancel", canCancel: "canCancel", canSubmit: "canSubmit", minimum: "minimum" }, { submit: "submit", cancel: "cancel" }, []);
exports.UploadImagesActionsComponentNgFactory = UploadImagesActionsComponentNgFactory;
