/// <reference path="../../../../models/src/lib/api/user.ts" />

import { CanActivate, RouterStateSnapshot } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { checkIfPermissionCanRead, findPermissions } from './read.helper';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { userQuery } from '@fixiti/state/user/src';

@Injectable({
    providedIn: 'root',
})
export class ReadGuard implements CanActivate {
    constructor(private store: Store<any>) {}

    canActivate(_0, state: RouterStateSnapshot): Observable<boolean> {
        return this.store.pipe(
            select(userQuery.getUser),
            map(user => this.checkPermissions(user, state.url))
        );
    }

    checkPermissions(user: ApiModel.User, url: string) {
        const permissions = findPermissions(url);
        return permissions.every(permission =>
            checkIfPermissionCanRead(user, permission)
        );
    }
}
