"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var SlugActionTypes;
(function (SlugActionTypes) {
    SlugActionTypes["LoadSlug"] = "[Slug] Load Slug";
    SlugActionTypes["DecodeSlug"] = "[Slug] Decode Slug";
})(SlugActionTypes = exports.SlugActionTypes || (exports.SlugActionTypes = {}));
var DecodeSlug = /** @class */ (function () {
    function DecodeSlug(payload) {
        this.payload = payload;
        this.type = SlugActionTypes.DecodeSlug;
    }
    return DecodeSlug;
}());
exports.DecodeSlug = DecodeSlug;
var LoadSlug = /** @class */ (function () {
    function LoadSlug(payload) {
        this.payload = payload;
        this.type = SlugActionTypes.LoadSlug;
    }
    return LoadSlug;
}());
exports.LoadSlug = LoadSlug;
