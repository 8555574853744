<div class="drop-menu" *ngIf="(isOpen$ | async)">
    <div class="click-detector" (click)="close($event)"></div>
    <ul>
        <li>
            <span class="ico"> <img src="assets/icons/ico01.svg" alt="" /> </span>
            <a href="/residents" (click)="go('/residents', $event)">Residents</a>
        </li>
        <li>
            <span class="ico"> <img src="assets/icons/ico02.svg" alt="" /> </span>
            <a href="/investors" (click)="go('/investors', $event)">Investors</a>
        </li>
        <li>
            <span class="ico"> <img src="assets/icons/ico03.svg" alt="" /> </span>
            <a href="/users" (click)="go('/users', $event)">Users</a>
        </li>
        <li>
            <span class="ico"> <img src="assets/icons/ico04.svg" alt="" /> </span>
            <a href="/roles" (click)="go('/roles', $event)">Roles</a>
        </li>
        <li>
            <span class="ico"> <img src="assets/icons/ico05.svg" alt="" /> </span>
            <a href="/settings" (click)="go('/settings', $event)">Settings</a>
        </li>
        <li>
            <span class="ico"> <img src="assets/icons/ico06.svg" alt="" /> </span>
            <a href="/surveys" (click)="go('/surveys', $event)">Satisfaction Surveys</a>
        </li>
        <li>
            <span class="ico"> <img src="assets/icons/ico03.svg" alt="" /> </span>
            <a href="/profile" (click)="go('/profile', $event)">Profile</a>
        </li>
        <li>
            <span class="ico"> <img src="assets/icons/ico07.svg" alt="" /> </span>
            <a href="#" (click)="go('/logout', $event)">Log Out</a>
        </li>
    </ul>
</div>
