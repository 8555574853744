import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'fixiti-photo-dialog-photo-counter-icon',
    templateUrl: './photo-counter-icon.component.html',
    styleUrls: ['./photo-counter-icon.component.css'],
})
export class PhotoCounterIconComponent {
    @Input()
    count = 0;
    @Output()
    clicked = new EventEmitter();

    /* istanbul ignore next */
    constructor() {}

    handleClick() {
        this.clicked.emit();
    }
}
