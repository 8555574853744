import { Component, Input } from '@angular/core';

@Component({
    selector: 'fixiti-modal-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.css'],
})
export class ErrorComponent {
    defaultError =
        "We seem to have run into a snag while processing your request. We'll get this fixed ASAP.";

    @Input() error: string;
}
