/// <reference path="./../../../../models/src/lib/api/all-query.ts" />

import {
    ImportFileModel,
    ImportFileMapModel,
    ImportFileToModel,
    ListViewPaginationModel,
} from '@fixiti/models';
import { Action } from '@ngrx/store';

export enum ImporterActionTypes {
    ImportLoadFiles = '[Importer] Load Import Files',
    ImportOpenFilterMenu = '[Importer] Open Filter Menu',
    ImportCloseFilterMenu = '[Importer] Close Filter Menu',
    ImportFilesLoaded = '[Importer] Importer Files Loaded',
    ImportFilesLoadError = '[Importer] Import Files Load Error',
    ImportDeSelectFile = '[Importer] Deselect Import File',
    ImportSelectFile = '[Importer] Select Import File',
    ImportMappingLoaded = '[Importer] Import File Mapping Loaded',
    ImportMappingLoadError = '[Importer] Import File Mapping Load Error',
    ImportSaveMapping = '[Importer] Import File Save Mapping',
    ImportMappingSaved = '[Importer] Import File Mapping Saved',
    ImportMappingSaveError = '[Importer] Import File Mapping Save Error',
    ImportMappingInputErrors = '[Importer] Import File Mapping Input Errors',
    ImportDeleteFile = '[Importer] Import File Delete',
    ImportShowDelete = '[Importer] Import File Show Delete',
    ImportHideDelete = '[Importer] Import File Hide Delete',
    ImportFileDeleted = '[Importer] Import File Deleted',
    ImportFileDeleteError = '[Importer] Import File Delete Error',
    ImportShowCreateForm = '[Importer] Show Create form',
    ImportHideCreateForm = '[Importer] Hide Create form',
    ImportCreateFile = '[Importer] Import File Create',
    ImportFileCreated = '[Importer] Import File Created',
    ImportFileCreateError = '[Importer] Import File Create Error',
    ImportSetQuerySetting = '[Importer] Set Query Setting',
    ImportSetPageDisplay = '[Importer] Set Page Display',
    ImportSetPaginationData = '[Importer] Set Pagination Data',
}

export class ImportLoadFiles implements Action {
    readonly type = ImporterActionTypes.ImportLoadFiles;
    constructor(public apiSection: string) {}
}

export class ImportOpenFilterMenu implements Action {
    readonly type = ImporterActionTypes.ImportOpenFilterMenu;
}

export class ImportCloseFilterMenu implements Action {
    readonly type = ImporterActionTypes.ImportCloseFilterMenu;
}

export class ImportFilesLoadError implements Action {
    readonly type = ImporterActionTypes.ImportFilesLoadError;
    constructor(public payload: any) {}
}

export class ImportFilesLoaded implements Action {
    readonly type = ImporterActionTypes.ImportFilesLoaded;
    constructor(public payload: ImportFileModel[]) {}
}

export class ImportDeSelectFile implements Action {
    readonly type = ImporterActionTypes.ImportDeSelectFile;
}

export class ImportSelectFile implements Action {
    readonly type = ImporterActionTypes.ImportSelectFile;
    constructor(public payload: number) {}
}

export class ImportMappingLoaded implements Action {
    readonly type = ImporterActionTypes.ImportMappingLoaded;
    constructor(public payload: { columns: ImportFileMapModel[]; tos: ImportFileToModel[] }) {}
}

export class ImportMappingLoadError implements Action {
    readonly type = ImporterActionTypes.ImportMappingLoadError;
    constructor(public payload: any) {}
}

export class ImportSaveMapping implements Action {
    readonly type = ImporterActionTypes.ImportSaveMapping;
    constructor(public payload: ImportFileModel) {}
}

export class ImportMappingSaved implements Action {
    readonly type = ImporterActionTypes.ImportMappingSaved;
    constructor(public payload: { columns: ImportFileMapModel[]; tos: ImportFileToModel[] }) {}
}

export class ImportMappingInputErrors implements Action {
    readonly type = ImporterActionTypes.ImportMappingInputErrors;
    constructor(public errors: { [key: string]: string }) {}
}

export class ImportMappingSaveError implements Action {
    readonly type = ImporterActionTypes.ImportMappingSaveError;
    constructor(public payload: any) {}
}

export class ImportShowDelete implements Action {
    readonly type = ImporterActionTypes.ImportShowDelete;
    constructor(public payload: number) {}
}

export class ImportHideDelete implements Action {
    readonly type = ImporterActionTypes.ImportHideDelete;
    constructor() {}
}

export class ImportDeleteFile implements Action {
    readonly type = ImporterActionTypes.ImportDeleteFile;
    constructor(public payload: number) {}
}

export class ImportFileDeleted implements Action {
    readonly type = ImporterActionTypes.ImportFileDeleted;
}

export class ImportFileDeleteError implements Action {
    readonly type = ImporterActionTypes.ImportFileDeleteError;
    constructor(public payload: any) {}
}

export class ImportShowCreateForm implements Action {
    readonly type = ImporterActionTypes.ImportShowCreateForm;
    constructor() {}
}

export class ImportHideCreateForm implements Action {
    readonly type = ImporterActionTypes.ImportHideCreateForm;
    constructor() {}
}

export class ImportCreateFile implements Action {
    readonly type = ImporterActionTypes.ImportCreateFile;
    constructor(public payload: any) {}
}

export class ImportFileCreated implements Action {
    readonly type = ImporterActionTypes.ImportFileCreated;
}

export class ImportFileCreateError implements Action {
    readonly type = ImporterActionTypes.ImportFileCreateError;
    constructor(public payload: any) {}
}

export class ImportSetQuerySetting implements Action {
    readonly type = ImporterActionTypes.ImportSetQuerySetting;
    constructor(public name: string, public value: any) {}
}

export class ImportSetPageDisplay implements Action {
    readonly type = ImporterActionTypes.ImportSetPageDisplay;
    constructor(public pageDisplay: string) {}
}

export class ImportSetPaginationData implements Action {
    readonly type = ImporterActionTypes.ImportSetPaginationData;
    constructor(public paginationData: ListViewPaginationModel) {}
}

export type ImporterAction =
    | ImportLoadFiles
    | ImportOpenFilterMenu
    | ImportCloseFilterMenu
    | ImportFilesLoaded
    | ImportFilesLoadError
    | ImportSelectFile
    | ImportDeSelectFile
    | ImportMappingLoaded
    | ImportMappingLoadError
    | ImportSaveMapping
    | ImportMappingSaved
    | ImportMappingSaveError
    | ImportMappingInputErrors
    | ImportHideDelete
    | ImportShowDelete
    | ImportDeleteFile
    | ImportFileDeleted
    | ImportFileDeleteError
    | ImportShowCreateForm
    | ImportHideCreateForm
    | ImportCreateFile
    | ImportFileCreated
    | ImportFileCreateError
    | ImportSetQuerySetting
    | ImportSetPageDisplay
    | ImportSetPaginationData;

export const fromImporterActions = {
    ImportLoadFiles,
    ImportOpenFilterMenu,
    ImportCloseFilterMenu,
    ImportFilesLoaded,
    ImportFilesLoadError,
    ImportSelectFile,
    ImportDeSelectFile,
    ImportMappingLoaded,
    ImportMappingLoadError,
    ImportSaveMapping,
    ImportMappingSaved,
    ImportMappingSaveError,
    ImportMappingInputErrors,
    ImportHideDelete,
    ImportShowDelete,
    ImportDeleteFile,
    ImportFileDeleted,
    ImportFileDeleteError,
    ImportShowCreateForm,
    ImportHideCreateForm,
    ImportCreateFile,
    ImportFileCreated,
    ImportFileCreateError,
    ImportSetQuerySetting,
    ImportSetPageDisplay,
    ImportSetPaginationData,
};
