"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var PermissionsEnum;
(function (PermissionsEnum) {
    PermissionsEnum[PermissionsEnum["properties"] = 1] = "properties";
    PermissionsEnum[PermissionsEnum["jobs"] = 2] = "jobs";
    PermissionsEnum[PermissionsEnum["residents"] = 3] = "residents";
    PermissionsEnum[PermissionsEnum["investors"] = 4] = "investors";
    PermissionsEnum[PermissionsEnum["leases"] = 5] = "leases";
    PermissionsEnum[PermissionsEnum["reports"] = 6] = "reports";
    PermissionsEnum[PermissionsEnum["accounting"] = 7] = "accounting";
    PermissionsEnum[PermissionsEnum["users"] = 8] = "users";
    PermissionsEnum[PermissionsEnum["permissions"] = 9] = "permissions";
    PermissionsEnum[PermissionsEnum["unknown"] = 9999] = "unknown";
})(PermissionsEnum = exports.PermissionsEnum || (exports.PermissionsEnum = {}));
