/// <reference path="./../../../../models/src/lib/api/all-query.ts" />
/// <reference path="../../../../models/src/lib/api/permission.ts" />
/// <reference path="../../../../models/src/lib/api/user.ts" />
/// <reference path="../../../../models/src/lib/api/role.ts" />

import { Action } from '@ngrx/store';

export enum RolesActionTypes {
    LoadRoles = '[Roles] Load Roles',
    RolesLoaded = '[Roles] Roles Loaded',
    RolesLoadError = '[Roles] Roles Load Error',
    SelectRole = '[Roles] Select Role',
    EditRole = '[Roles] Edit Role',
    UpdateRole = '[Roles] Update Role',
    RoleUpdated = '[Roles] Role Updated',
    CreateRole = '[Roles] Create Role',
    RoleCreated = '[Roles] Role Created',
    RemoveRole = '[Roles] Remove Role',
    RoleRemoved = '[Roles] Role Remove',
    QueueAddUserToRole = '[Roles] Queue Add User To Role',
    AddUserToRole = '[Roles] Add User To Role',
    UserAddedToRole = '[Roles] User Added To Role',
    QueueRemoveUserFromRole = '[Roles] Queue Remove User From Role',
    RemoveUserFromRole = '[Roles] Remove User From Role',
    UserRemovedFromRole = '[Roles] User Removed From Role',
    RolesUpdated = '[Roles] Roles Updated',
    LoadPermissionForRole = '[Roles] Load Permission for Role',
    PermissionForRoleLoaded = '[Roles] Permission for Role loaded',
    LoadUsersForRole = '[Roles] Load Users for Role',
    UsersForRoleLoaded = '[Roles] Users for Role loaded',
    RolesRefreshNeeded = '[Roles] Refresh needed',
    RefreshedRoles = '[Roles] Refreshed roles',
}

export class LoadRoles implements Action {
    readonly type = RolesActionTypes.LoadRoles;
    constructor(public payload?: ApiModel.AllQuery) {}
}

export class RolesLoadError implements Action {
    readonly type = RolesActionTypes.RolesLoadError;
    constructor(public payload: any) {}
}

export class RolesLoaded implements Action {
    readonly type = RolesActionTypes.RolesLoaded;
    constructor(public payload: ApiModel.Role[]) {}
}

export class EditRole implements Action {
    readonly type = RolesActionTypes.EditRole;
    constructor(public payload: number) {}
}

export class SelectRole implements Action {
    readonly type = RolesActionTypes.SelectRole;
    constructor(public payload: number) {}
}

export class UpdateRole implements Action {
    readonly type = RolesActionTypes.UpdateRole;
    constructor(public payload: { id: number; name: string }) {}
}

export class RoleUpdated implements Action {
    readonly type = RolesActionTypes.RoleUpdated;
    constructor(public payload: ApiModel.Role) {}
}

export class CreateRole implements Action {
    readonly type = RolesActionTypes.CreateRole;
    constructor(public payload: ApiModel.Role) {}
}

export class RoleCreated implements Action {
    readonly type = RolesActionTypes.RoleCreated;
    constructor(public payload: ApiModel.Role) {}
}

export class RemoveRole implements Action {
    readonly type = RolesActionTypes.RemoveRole;
    constructor(public payload: number) {}
}

export class RoleRemoved implements Action {
    readonly type = RolesActionTypes.RoleRemoved;
    constructor(public payload: number) {}
}

export class QueueAddUserToRole implements Action {
    readonly type = RolesActionTypes.QueueAddUserToRole;
    constructor(
        public payload: {
            roleId: number;
            managerId: number;
        }
    ) {}
}

export class AddUserToRole implements Action {
    readonly type = RolesActionTypes.AddUserToRole;
    constructor(
        public payload: {
            roleId: number;
            managerId: number;
        }
    ) {}
}

export class UserAddedToRole implements Action {
    readonly type = RolesActionTypes.UserAddedToRole;
    constructor(
        public payload: {
            roleId: number;
            user: ApiModel.SimplifiedUser;
        }
    ) {}
}

export class QueueRemoveUserFromRole implements Action {
    readonly type = RolesActionTypes.QueueRemoveUserFromRole;
    constructor(
        public payload: {
            roleId: number;
            managerId: number;
        }
    ) {}
}

export class RemoveUserFromRole implements Action {
    readonly type = RolesActionTypes.RemoveUserFromRole;
    constructor(
        public payload: {
            roleId: number;
            managerId: number;
        }
    ) {}
}

export class UserRemovedFromRole implements Action {
    readonly type = RolesActionTypes.UserRemovedFromRole;
    constructor(
        public payload: {
            roleId: number;
            managerId: number;
        }
    ) {}
}

export class RolesUpdated implements Action {
    readonly type = RolesActionTypes.RolesUpdated;
}

export class RolesRefreshNeeded implements Action {
    readonly type = RolesActionTypes.RolesRefreshNeeded;
}

export class RefreshedRoles implements Action {
    readonly type = RolesActionTypes.RefreshedRoles;
}

export class LoadPermissionForRole implements Action {
    readonly type = RolesActionTypes.LoadPermissionForRole;
    constructor(
        public payload: {
            roleId: number;
            permissionId: number;
        }
    ) {}
}

export class PermissionForRoleLoaded implements Action {
    readonly type = RolesActionTypes.PermissionForRoleLoaded;
    constructor(public payload: ApiModel.Permission) {}
}

export class LoadUsersForRole implements Action {
    readonly type = RolesActionTypes.LoadUsersForRole;
    constructor(public payload: number) {}
}

export class UsersForRoleLoaded implements Action {
    readonly type = RolesActionTypes.UsersForRoleLoaded;
    constructor(public payload: ApiModel.SimplifiedUser[]) {}
}

export type RolesAction =
    | LoadRoles
    | RolesLoaded
    | RolesLoadError
    | EditRole
    | SelectRole
    | UpdateRole
    | RoleUpdated
    | CreateRole
    | RoleCreated
    | RemoveRole
    | RoleRemoved
    | QueueAddUserToRole
    | AddUserToRole
    | UserAddedToRole
    | QueueRemoveUserFromRole
    | RemoveUserFromRole
    | UserRemovedFromRole
    | RolesUpdated
    | RolesRefreshNeeded
    | RefreshedRoles
    | LoadPermissionForRole
    | PermissionForRoleLoaded
    | LoadUsersForRole
    | UsersForRoleLoaded;

export const fromRolesActions = {
    LoadRoles,
    RolesLoaded,
    RolesLoadError,
    EditRole,
    SelectRole,
    UpdateRole,
    RoleUpdated,
    CreateRole,
    RoleCreated,
    RemoveRole,
    RoleRemoved,
    QueueAddUserToRole,
    AddUserToRole,
    UserAddedToRole,
    RemoveUserFromRole,
    QueueRemoveUserFromRole,
    UserRemovedFromRole,
    RolesUpdated,
    RolesRefreshNeeded,
    RefreshedRoles,
    LoadPermissionForRole,
    PermissionForRoleLoaded,
    LoadUsersForRole,
    UsersForRoleLoaded,
};
