"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/// <reference path="../../../../../../models/src/lib/api/gallery-image.ts" />
var core_1 = require("@angular/core");
var GalleryPreviewButtonComponent = /** @class */ (function () {
    function GalleryPreviewButtonComponent() {
        this.minimum = 0;
        this.galleryChange = new core_1.EventEmitter();
        this.isEnabled = true;
        this.showBadge = true;
        this.clickChange = new core_1.EventEmitter();
    }
    GalleryPreviewButtonComponent.prototype.ngOnInit = function () { };
    GalleryPreviewButtonComponent.prototype.onClick = function ($event) {
        $event.preventDefault();
        this.clickChange.emit();
    };
    return GalleryPreviewButtonComponent;
}());
exports.GalleryPreviewButtonComponent = GalleryPreviewButtonComponent;
