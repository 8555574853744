import { Action } from '@ngrx/store';

export enum SidenavActionTypes {
    OpenSidenav = '[Sidenav] Open Sidenav',
    CloseSidenav = '[Sidenav] Close Sidenav',
    ToggleSidenav = '[Sidenav] Toggle Sidenav',
}

export class ToggleSidenav implements Action {
    readonly type = SidenavActionTypes.ToggleSidenav;
}

export class OpenSidenav implements Action {
    readonly type = SidenavActionTypes.OpenSidenav;
}

export class CloseSidenav implements Action {
    readonly type = SidenavActionTypes.CloseSidenav;
}

export type SidenavAction = OpenSidenav | CloseSidenav | ToggleSidenav;

export const fromSidenavActions = {
    OpenSidenav,
    CloseSidenav,
    ToggleSidenav,
};
