import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { ToggleSidenav } from '@fixiti/actions';

@Component({
    selector: 'fixiti-main-menu-toggle',
    templateUrl: './main-menu-toggle.component.html',
    styleUrls: ['./main-menu-toggle.component.css'],
})
export class MainMenuToggleComponent {
    constructor(private store: Store<any>) {}

    toggle(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();
        this.store.dispatch(new ToggleSidenav());
    }
}
