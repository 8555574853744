import { searchInitialState, searchReducer } from './+state/search.reducer';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('search', searchReducer, {
            initialState: searchInitialState,
        }),
    ],
})
export class StateSearchModule {}
