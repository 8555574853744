<button
    class="cancel-button"
    type="button"
    mat-raised-button
    color="warn"
    [disabled]="!isEnabled"
    (click)="handleClick()"
>
    Cancel
</button>
