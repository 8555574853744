"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var src_1 = require("@fixiti/actions/src");
var src_2 = require("@fixiti/external/pica/src");
var store_1 = require("@ngrx/store");
var operators_1 = require("rxjs/operators");
var UploadImagesGalleryComponent = /** @class */ (function () {
    /* istanbul ignore next */
    function UploadImagesGalleryComponent(store, imageService) {
        this.store = store;
        this.imageService = imageService;
        this.window = window;
        this.gallery = [];
        this.images = [];
        this._canDelete = true;
        this.conf = {
            imageOffset: '0px',
            showDeleteControl: this.canDelete,
            showCloseControl: false,
            showImageTitle: true,
            inline: true,
        };
        this.text = 'before';
        this.minimum = 2;
        this.minimumArray = [1];
    }
    Object.defineProperty(UploadImagesGalleryComponent.prototype, "canDelete", {
        get: function () {
            return this._canDelete;
        },
        set: function (value) {
            this._canDelete = value;
            this.conf = __assign({}, this.conf, { showDeleteControl: value });
        },
        enumerable: true,
        configurable: true
    });
    UploadImagesGalleryComponent.prototype.ngOnInit = function () {
        if (this.minimum < 0) {
            throw new Error('minimum must be 0 or greater');
        }
        if (this.minimum !== 2) {
            var array = [];
            for (var i = 1; i < this.minimum; ++i) {
                array.push(i);
            }
            this.minimumArray = array;
        }
    };
    UploadImagesGalleryComponent.prototype.deleteImage = function (index) {
        var file = this.images[index]['src'];
        this.store.dispatch(new src_1.DeleteFile({
            file: file,
        }));
    };
    UploadImagesGalleryComponent.prototype.getIndex = function (length) {
        if (length <= this.minimum) {
            return this.minimum - 1;
        }
        else {
            return length - 1;
        }
    };
    UploadImagesGalleryComponent.prototype.getLastString = function (length) {
        if (length <= this.minimum) {
            return "#" + this.minimum;
        }
        else {
            return "#" + length;
        }
    };
    UploadImagesGalleryComponent.prototype.onChange = function ($event, index) {
        if (index === void 0) { index = 0; }
        this.replaceFile($event.target.files[0], index);
    };
    UploadImagesGalleryComponent.prototype.replaceFile = function (file, index) {
        var _this = this;
        return new Promise(function (resolve) {
            var fr = new FileReader();
            fr.onloadend = function ($event) {
                var url = "data:image/jpeg;base64," + btoa($event.target.result);
                _this.store.dispatch(new src_1.ReplaceFile({
                    file: file,
                    index: index,
                    url: url,
                }));
                resolve(url);
                _this.loadSmallerThumbnail(file);
            };
            fr.readAsBinaryString(file);
        });
    };
    UploadImagesGalleryComponent.prototype.loadSmallerThumbnail = function (file) {
        var _this = this;
        return new Promise(function (resolve) {
            var fr = new FileReader();
            fr.onloadend = function (item) {
                var url = "data:image/jpeg;base64," + btoa(item.target.result);
                _this.store.dispatch(new src_1.UpdateFile({
                    file: file,
                    url: url,
                }));
                resolve({
                    src: file,
                    url: url,
                });
            };
            _this.imageService
                .resizeImage(file, 512, 512, {
                aspectRatio: {
                    keepAspectRatio: true,
                },
            })
                .pipe(operators_1.take(1))
                .subscribe(function (resizedFile) {
                fr.readAsBinaryString(resizedFile);
            });
        });
    };
    UploadImagesGalleryComponent.prototype.isMobile = function () {
        if (!this.window || !this.window.navigator) {
            return false;
        }
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(this.window.navigator.userAgent);
    };
    UploadImagesGalleryComponent.prototype.selectFile = function (input) {
        // for some reason safari only works when it bubbles up
        // tests for safari and iOS compatability before removing
        if (!/^((?!chrome|android).)*safari/i.test(this.window.navigator.userAgent)) {
        }
        input.click();
    };
    return UploadImagesGalleryComponent;
}());
exports.UploadImagesGalleryComponent = UploadImagesGalleryComponent;
