"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var EnvironmentActionTypes;
(function (EnvironmentActionTypes) {
    EnvironmentActionTypes["LoadEnvironment"] = "[Environment] Load Environment";
})(EnvironmentActionTypes = exports.EnvironmentActionTypes || (exports.EnvironmentActionTypes = {}));
var LoadEnvironment = /** @class */ (function () {
    function LoadEnvironment(payload) {
        this.payload = payload;
        this.type = EnvironmentActionTypes.LoadEnvironment;
    }
    return LoadEnvironment;
}());
exports.LoadEnvironment = LoadEnvironment;
exports.fromEnvironmentActions = {
    LoadEnvironment: LoadEnvironment,
};
