/// <reference path="./api/job.ts" />

export class JobModel implements ApiModel.Job {
    id: number;
    activeNegotiationId: number;
    address: string;
    canStart: boolean;
    city: string;
    description: string;
    details: string[];
    completed: boolean;
    estimated: boolean;
    gallery: ApiModel.GalleryImage[];
    latitude: number;
    longitude: number;
    resident: {
        name: string;
    };
    state: string;
    zip: string;
}
