"use strict";
/// <reference path="./../../../../models/src/lib/api/all-query.ts" />
/// <reference path="../../../../models/src/lib/api/permission.ts" />
/// <reference path="../../../../models/src/lib/api/user.ts" />
/// <reference path="../../../../models/src/lib/api/role.ts" />
Object.defineProperty(exports, "__esModule", { value: true });
var RolesActionTypes;
(function (RolesActionTypes) {
    RolesActionTypes["LoadRoles"] = "[Roles] Load Roles";
    RolesActionTypes["RolesLoaded"] = "[Roles] Roles Loaded";
    RolesActionTypes["RolesLoadError"] = "[Roles] Roles Load Error";
    RolesActionTypes["SelectRole"] = "[Roles] Select Role";
    RolesActionTypes["EditRole"] = "[Roles] Edit Role";
    RolesActionTypes["UpdateRole"] = "[Roles] Update Role";
    RolesActionTypes["RoleUpdated"] = "[Roles] Role Updated";
    RolesActionTypes["CreateRole"] = "[Roles] Create Role";
    RolesActionTypes["RoleCreated"] = "[Roles] Role Created";
    RolesActionTypes["RemoveRole"] = "[Roles] Remove Role";
    RolesActionTypes["RoleRemoved"] = "[Roles] Role Remove";
    RolesActionTypes["QueueAddUserToRole"] = "[Roles] Queue Add User To Role";
    RolesActionTypes["AddUserToRole"] = "[Roles] Add User To Role";
    RolesActionTypes["UserAddedToRole"] = "[Roles] User Added To Role";
    RolesActionTypes["QueueRemoveUserFromRole"] = "[Roles] Queue Remove User From Role";
    RolesActionTypes["RemoveUserFromRole"] = "[Roles] Remove User From Role";
    RolesActionTypes["UserRemovedFromRole"] = "[Roles] User Removed From Role";
    RolesActionTypes["RolesUpdated"] = "[Roles] Roles Updated";
    RolesActionTypes["LoadPermissionForRole"] = "[Roles] Load Permission for Role";
    RolesActionTypes["PermissionForRoleLoaded"] = "[Roles] Permission for Role loaded";
    RolesActionTypes["LoadUsersForRole"] = "[Roles] Load Users for Role";
    RolesActionTypes["UsersForRoleLoaded"] = "[Roles] Users for Role loaded";
    RolesActionTypes["RolesRefreshNeeded"] = "[Roles] Refresh needed";
    RolesActionTypes["RefreshedRoles"] = "[Roles] Refreshed roles";
})(RolesActionTypes = exports.RolesActionTypes || (exports.RolesActionTypes = {}));
var LoadRoles = /** @class */ (function () {
    function LoadRoles(payload) {
        this.payload = payload;
        this.type = RolesActionTypes.LoadRoles;
    }
    return LoadRoles;
}());
exports.LoadRoles = LoadRoles;
var RolesLoadError = /** @class */ (function () {
    function RolesLoadError(payload) {
        this.payload = payload;
        this.type = RolesActionTypes.RolesLoadError;
    }
    return RolesLoadError;
}());
exports.RolesLoadError = RolesLoadError;
var RolesLoaded = /** @class */ (function () {
    function RolesLoaded(payload) {
        this.payload = payload;
        this.type = RolesActionTypes.RolesLoaded;
    }
    return RolesLoaded;
}());
exports.RolesLoaded = RolesLoaded;
var EditRole = /** @class */ (function () {
    function EditRole(payload) {
        this.payload = payload;
        this.type = RolesActionTypes.EditRole;
    }
    return EditRole;
}());
exports.EditRole = EditRole;
var SelectRole = /** @class */ (function () {
    function SelectRole(payload) {
        this.payload = payload;
        this.type = RolesActionTypes.SelectRole;
    }
    return SelectRole;
}());
exports.SelectRole = SelectRole;
var UpdateRole = /** @class */ (function () {
    function UpdateRole(payload) {
        this.payload = payload;
        this.type = RolesActionTypes.UpdateRole;
    }
    return UpdateRole;
}());
exports.UpdateRole = UpdateRole;
var RoleUpdated = /** @class */ (function () {
    function RoleUpdated(payload) {
        this.payload = payload;
        this.type = RolesActionTypes.RoleUpdated;
    }
    return RoleUpdated;
}());
exports.RoleUpdated = RoleUpdated;
var CreateRole = /** @class */ (function () {
    function CreateRole(payload) {
        this.payload = payload;
        this.type = RolesActionTypes.CreateRole;
    }
    return CreateRole;
}());
exports.CreateRole = CreateRole;
var RoleCreated = /** @class */ (function () {
    function RoleCreated(payload) {
        this.payload = payload;
        this.type = RolesActionTypes.RoleCreated;
    }
    return RoleCreated;
}());
exports.RoleCreated = RoleCreated;
var RemoveRole = /** @class */ (function () {
    function RemoveRole(payload) {
        this.payload = payload;
        this.type = RolesActionTypes.RemoveRole;
    }
    return RemoveRole;
}());
exports.RemoveRole = RemoveRole;
var RoleRemoved = /** @class */ (function () {
    function RoleRemoved(payload) {
        this.payload = payload;
        this.type = RolesActionTypes.RoleRemoved;
    }
    return RoleRemoved;
}());
exports.RoleRemoved = RoleRemoved;
var QueueAddUserToRole = /** @class */ (function () {
    function QueueAddUserToRole(payload) {
        this.payload = payload;
        this.type = RolesActionTypes.QueueAddUserToRole;
    }
    return QueueAddUserToRole;
}());
exports.QueueAddUserToRole = QueueAddUserToRole;
var AddUserToRole = /** @class */ (function () {
    function AddUserToRole(payload) {
        this.payload = payload;
        this.type = RolesActionTypes.AddUserToRole;
    }
    return AddUserToRole;
}());
exports.AddUserToRole = AddUserToRole;
var UserAddedToRole = /** @class */ (function () {
    function UserAddedToRole(payload) {
        this.payload = payload;
        this.type = RolesActionTypes.UserAddedToRole;
    }
    return UserAddedToRole;
}());
exports.UserAddedToRole = UserAddedToRole;
var QueueRemoveUserFromRole = /** @class */ (function () {
    function QueueRemoveUserFromRole(payload) {
        this.payload = payload;
        this.type = RolesActionTypes.QueueRemoveUserFromRole;
    }
    return QueueRemoveUserFromRole;
}());
exports.QueueRemoveUserFromRole = QueueRemoveUserFromRole;
var RemoveUserFromRole = /** @class */ (function () {
    function RemoveUserFromRole(payload) {
        this.payload = payload;
        this.type = RolesActionTypes.RemoveUserFromRole;
    }
    return RemoveUserFromRole;
}());
exports.RemoveUserFromRole = RemoveUserFromRole;
var UserRemovedFromRole = /** @class */ (function () {
    function UserRemovedFromRole(payload) {
        this.payload = payload;
        this.type = RolesActionTypes.UserRemovedFromRole;
    }
    return UserRemovedFromRole;
}());
exports.UserRemovedFromRole = UserRemovedFromRole;
var RolesUpdated = /** @class */ (function () {
    function RolesUpdated() {
        this.type = RolesActionTypes.RolesUpdated;
    }
    return RolesUpdated;
}());
exports.RolesUpdated = RolesUpdated;
var RolesRefreshNeeded = /** @class */ (function () {
    function RolesRefreshNeeded() {
        this.type = RolesActionTypes.RolesRefreshNeeded;
    }
    return RolesRefreshNeeded;
}());
exports.RolesRefreshNeeded = RolesRefreshNeeded;
var RefreshedRoles = /** @class */ (function () {
    function RefreshedRoles() {
        this.type = RolesActionTypes.RefreshedRoles;
    }
    return RefreshedRoles;
}());
exports.RefreshedRoles = RefreshedRoles;
var LoadPermissionForRole = /** @class */ (function () {
    function LoadPermissionForRole(payload) {
        this.payload = payload;
        this.type = RolesActionTypes.LoadPermissionForRole;
    }
    return LoadPermissionForRole;
}());
exports.LoadPermissionForRole = LoadPermissionForRole;
var PermissionForRoleLoaded = /** @class */ (function () {
    function PermissionForRoleLoaded(payload) {
        this.payload = payload;
        this.type = RolesActionTypes.PermissionForRoleLoaded;
    }
    return PermissionForRoleLoaded;
}());
exports.PermissionForRoleLoaded = PermissionForRoleLoaded;
var LoadUsersForRole = /** @class */ (function () {
    function LoadUsersForRole(payload) {
        this.payload = payload;
        this.type = RolesActionTypes.LoadUsersForRole;
    }
    return LoadUsersForRole;
}());
exports.LoadUsersForRole = LoadUsersForRole;
var UsersForRoleLoaded = /** @class */ (function () {
    function UsersForRoleLoaded(payload) {
        this.payload = payload;
        this.type = RolesActionTypes.UsersForRoleLoaded;
    }
    return UsersForRoleLoaded;
}());
exports.UsersForRoleLoaded = UsersForRoleLoaded;
exports.fromRolesActions = {
    LoadRoles: LoadRoles,
    RolesLoaded: RolesLoaded,
    RolesLoadError: RolesLoadError,
    EditRole: EditRole,
    SelectRole: SelectRole,
    UpdateRole: UpdateRole,
    RoleUpdated: RoleUpdated,
    CreateRole: CreateRole,
    RoleCreated: RoleCreated,
    RemoveRole: RemoveRole,
    RoleRemoved: RoleRemoved,
    QueueAddUserToRole: QueueAddUserToRole,
    AddUserToRole: AddUserToRole,
    UserAddedToRole: UserAddedToRole,
    RemoveUserFromRole: RemoveUserFromRole,
    QueueRemoveUserFromRole: QueueRemoveUserFromRole,
    UserRemovedFromRole: UserRemovedFromRole,
    RolesUpdated: RolesUpdated,
    RolesRefreshNeeded: RolesRefreshNeeded,
    RefreshedRoles: RefreshedRoles,
    LoadPermissionForRole: LoadPermissionForRole,
    PermissionForRoleLoaded: PermissionForRoleLoaded,
    LoadUsersForRole: LoadUsersForRole,
    UsersForRoleLoaded: UsersForRoleLoaded,
};
