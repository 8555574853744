/// <reference path="./../../../../models/src/lib/api/job.ts" />

import { Action } from '@ngrx/store';

export enum JobsActionTypes {
    LoadJobs = '[Jobs] Load Data',
    JobsLoaded = '[Jobs] Data Loaded',
    SaveJob = '[Jobs] Save Job',
    JobSaved = '[Jobs] Job Saved',
}

export class LoadJobs implements Action {
    readonly type = JobsActionTypes.LoadJobs;
    constructor(public payload: { id: number }) {}
}

export class JobsLoaded implements Action {
    readonly type = JobsActionTypes.JobsLoaded;
    constructor(
        public payload: {
            job: ApiModel.Job;
        }
    ) {}
}

export class SaveJob implements Action {
    readonly type = JobsActionTypes.SaveJob;
    constructor(
        public payload: {
            job: ApiModel.Job;
        }
    ) {}
}

export class JobSaved implements Action {
    readonly type = JobsActionTypes.JobSaved;
    constructor(
        public payload: {
            job: ApiModel.Job;
        }
    ) {}
}

export type JobsActions = LoadJobs | JobsLoaded | SaveJob | JobSaved;
