import { ModuleWithProviders, NgModule } from '@angular/core';
import {
    fileInitialState,
    fileReducer,
} from '@fixiti/state/files/src/lib/reducers/file.reducer';

import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { FileEffects } from '@fixiti/state/files/src/lib/effects/file.effects';
import { PicaModule } from '@fixiti/external/pica/src';
import { StoreModule } from '@ngrx/store';

@NgModule({
    imports: [CommonModule],
})
export class FilesModule {
    /* istanbul ignore next */
    static forRoot(): ModuleWithProviders {
        return {
            /* ts-lint ignore next */
            ngModule: RootFilesModule,
        };
    }
}

@NgModule({
    imports: [
        StoreModule.forFeature('files', fileReducer, {
            initialState: fileInitialState,
        }),
        EffectsModule.forFeature([FileEffects]),
        FilesModule,
        PicaModule,
    ],
    exports: [FilesModule],
})
export class RootFilesModule {}
