<ng-container *ngIf="files && files.length >= minimum; else: showCancel">
    <fixiti-photo-dialog-upload-images-actions-submit
        [files]="files"
        [isEnabled]="canSubmit"
        [onSubmit]="onSubmit"
        (submit)="submit.emit()"
    >
    </fixiti-photo-dialog-upload-images-actions-submit>
</ng-container>
<ng-template #showCancel>
    <fixiti-photo-dialog-upload-images-actions-cancel
        [isEnabled]="canCancel"
        [onCancel]="onCancel"
        (cancel)="cancel.emit()"
    >
    </fixiti-photo-dialog-upload-images-actions-cancel>
</ng-template>
