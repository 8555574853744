"use strict";
/// <reference path="../../../../models/src/lib/api/permission.ts" />
/// <reference path="../../../../models/src/lib/api/user.ts" />
Object.defineProperty(exports, "__esModule", { value: true });
var permissions_enum_1 = require("@fixiti/enums/permissions.enum");
function findPermissions(url) {
    var permissions = [];
    if (url.indexOf('/properties') >= 0) {
        permissions.push(permissions_enum_1.PermissionsEnum.properties);
    }
    else if (url.indexOf('/maintenance') >= 0) {
        permissions.push(permissions_enum_1.PermissionsEnum.jobs);
    }
    else if (url.indexOf('/residents') >= 0) {
        permissions.push(permissions_enum_1.PermissionsEnum.residents);
    }
    else if (url.indexOf('/investors') >= 0) {
        permissions.push(permissions_enum_1.PermissionsEnum.investors);
    }
    else if (url.indexOf('/leases') >= 0) {
        permissions.push(permissions_enum_1.PermissionsEnum.leases);
    }
    else if (url.indexOf('/reports') >= 0) {
        permissions.push(permissions_enum_1.PermissionsEnum.reports);
    }
    else if (url.indexOf('/accounting') >= 0) {
        permissions.push(permissions_enum_1.PermissionsEnum.accounting);
    }
    else if (url.indexOf('/users') >= 0) {
        permissions.push(permissions_enum_1.PermissionsEnum.users);
    }
    else if (url.indexOf('/roles') >= 0) {
        permissions.push(permissions_enum_1.PermissionsEnum.permissions);
    }
    else {
        permissions.push(permissions_enum_1.PermissionsEnum.unknown);
    }
    return permissions;
}
exports.findPermissions = findPermissions;
function canRead(permissionName, permissions) {
    return permissions.some(function (permission) { return permission.name === permissionName && permission.read; });
}
exports.canRead = canRead;
function checkIfPermissionCanRead(user, permission) {
    var permissionName = permissions_enum_1.PermissionsEnum[permission];
    return (user &&
        user.roles &&
        user.roles.length &&
        user.roles.some(function (role) {
            return canRead(permissionName, (role && role.permissions) || []);
        }));
}
exports.checkIfPermissionCanRead = checkIfPermissionCanRead;
