"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./dialog-footer.component.css.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../upload-images/upload-images.component.ngfactory");
var i3 = require("../upload-images/upload-images.component");
var i4 = require("@ngrx/store");
var i5 = require("@angular/material/dialog");
var i6 = require("../../../../../../external/pica/src/lib/pica.service");
var i7 = require("./dialog-footer.component");
var styles_DialogFooterComponent = [i0.styles];
var RenderType_DialogFooterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DialogFooterComponent, data: {} });
exports.RenderType_DialogFooterComponent = RenderType_DialogFooterComponent;
function View_DialogFooterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fixiti-photo-dialog-upload-images", [], null, null, null, i2.View_UploadImagesComponent_0, i2.RenderType_UploadImagesComponent)), i1.ɵdid(1, 245760, null, 0, i3.UploadImagesComponent, [i4.Store, i5.MatDialog, i6.PicaService], { dialogReference: [0, "dialogReference"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dialogReference; _ck(_v, 1, 0, currVal_0); }, null); }
exports.View_DialogFooterComponent_0 = View_DialogFooterComponent_0;
function View_DialogFooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fixiti-photo-dialog-dialog-footer", [], null, null, null, View_DialogFooterComponent_0, RenderType_DialogFooterComponent)), i1.ɵdid(1, 49152, null, 0, i7.DialogFooterComponent, [], null, null)], null, null); }
exports.View_DialogFooterComponent_Host_0 = View_DialogFooterComponent_Host_0;
var DialogFooterComponentNgFactory = i1.ɵccf("fixiti-photo-dialog-dialog-footer", i7.DialogFooterComponent, View_DialogFooterComponent_Host_0, { dialogReference: "dialogReference" }, {}, []);
exports.DialogFooterComponentNgFactory = DialogFooterComponentNgFactory;
