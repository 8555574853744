import { ActionReducer, MetaReducer } from '@ngrx/store';
import { AppActionTypes, AppActions } from './app.actions';

import { environment } from '@env/environment';
import { localStorageSync } from 'ngrx-store-localstorage';
import { storeFreeze } from 'ngrx-store-freeze';

export function localStorageSyncReducer(
    reducer: ActionReducer<any>
): ActionReducer<any> {
    return localStorageSync({
        keys: [
            'user',
            'pageSettings',
            'estates',
            'workOrders',
            'dashboard',
            'residents',
            'investors',
            'permissions',
            'roles',
        ],
        rehydrate: true,
    })(reducer);
}

/**
 * Interface for the 'App' data used in
 *  - AppState, and
 *  - appReducer
 */
export interface AppData {
    id: number | null;
}

/**
 * Interface to the part of the Store containing AppState
 * and other information related to AppData.
 */
export interface AppState {
    readonly app: AppData;
}

export const initialState: AppData = {
    id: null,
};

export function appReducer(state = initialState, action: AppActions): AppData {
    switch (action.type) {
        case AppActionTypes.AppAction:
            return state;

        case AppActionTypes.AppLoaded: {
            return { ...state, ...action.payload };
        }

        default:
            return state;
    }
}

export function logger(
    reducer: ActionReducer<AppState>
): ActionReducer<AppState> {
    return function(state: AppState, action: any): AppState {
        //         console.log('state', state);
        //         console.log('action', action);

        return reducer(state, action);
    };
}

export function getMetaReducers(production) {
    return !production
        ? [logger, storeFreeze, localStorageSyncReducer]
        : [localStorageSyncReducer];
}

export const metaReducers: MetaReducer<AppState>[] = getMetaReducers(
    environment.production
);
