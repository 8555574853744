"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var store_1 = require("@ngrx/store");
var actions_1 = require("@fixiti/actions");
var MainMenuToggleComponent = /** @class */ (function () {
    function MainMenuToggleComponent(store) {
        this.store = store;
    }
    MainMenuToggleComponent.prototype.toggle = function (event) {
        event.preventDefault();
        event.stopPropagation();
        this.store.dispatch(new actions_1.ToggleSidenav());
    };
    return MainMenuToggleComponent;
}());
exports.MainMenuToggleComponent = MainMenuToggleComponent;
