"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var dialog_1 = require("@angular/material/dialog");
var DialogFooterComponent = /** @class */ (function () {
    /* istanbul ignore next */
    function DialogFooterComponent() {
    }
    return DialogFooterComponent;
}());
exports.DialogFooterComponent = DialogFooterComponent;
