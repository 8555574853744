"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var effects_1 = require("@ngrx/effects");
var actions_1 = require("@fixiti/actions");
var operators_1 = require("rxjs/operators");
var nx_1 = require("@nrwl/nx");
var pmp_1 = require("@fixiti/api/rest/pmp");
var rxjs_1 = require("rxjs");
var PermissionsEffects = /** @class */ (function () {
    function PermissionsEffects(actions$, dataPersistence, dataService) {
        var _this = this;
        this.actions$ = actions$;
        this.dataPersistence = dataPersistence;
        this.dataService = dataService;
        this.loadPermissions$ = this.dataPersistence.fetch(actions_1.PermissionsActionTypes.LoadPermissions, {
            run: function (action) {
                return _this.dataService
                    .getPermissions(action.payload)
                    .pipe(operators_1.map(function (permissions) { return new actions_1.PermissionsLoaded(permissions); }));
            },
            onError: function (_a, error) {
                return new actions_1.PermissionsLoadError(error);
            },
        });
        this.savePermissions$ = this.dataPersistence.fetch(actions_1.PermissionsActionTypes.SavePermissions, {
            run: function (action) {
                return rxjs_1.from(action.payload).pipe(operators_1.mergeMap(function (permission) {
                    return _this.dataService.updatePermission(permission);
                }), operators_1.toArray(), operators_1.map(function (permissions) { return new actions_1.PermissionsSaved(permissions); }));
            },
            onError: function (_a, error) { return ({
                type: 'ERROR',
                error: error,
            }); },
        });
    }
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], PermissionsEffects.prototype, "loadPermissions$", void 0);
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], PermissionsEffects.prototype, "savePermissions$", void 0);
    return PermissionsEffects;
}());
exports.PermissionsEffects = PermissionsEffects;
