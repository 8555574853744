"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var actions_1 = require("@fixiti/actions");
var core_1 = require("@angular/core");
var dialog_1 = require("@angular/material/dialog");
var models_1 = require("@fixiti/models");
var store_1 = require("@ngrx/store");
var DialogContainerComponent = /** @class */ (function () {
    function DialogContainerComponent(dialogRef, data, store) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.store = store;
    }
    DialogContainerComponent.prototype.ngOnInit = function () {
        this.setConfig(this.data);
    };
    DialogContainerComponent.prototype.ngOnDestroy = function () {
        this.store.dispatch(new actions_1.PhotoDialogClose());
    };
    DialogContainerComponent.prototype.setConfig = function (data) {
        var _this = this;
        if (data != null) {
            this.store.dispatch(new actions_1.LoadPhotoDialogConfiguration({
                config: data,
            }));
            if (data.gallery) {
                data.gallery.forEach(function (item) {
                    _this.store.dispatch(new actions_1.AddFile({
                        file: item,
                    }));
                });
            }
        }
    };
    return DialogContainerComponent;
}());
exports.DialogContainerComponent = DialogContainerComponent;
