import { ModuleWithProviders, NgModule } from '@angular/core';
import {
    RouterStateSerializer,
    StoreRouterConnectingModule,
    routerReducer,
} from '@ngrx/router-store';

import { CommonModule } from '@angular/common';
import { CustomSerializer } from './+state/router.reducer';
import { EffectsModule } from '@ngrx/effects';
import { RouterEffects } from './+state/router.effects';
import { StoreModule } from '@ngrx/store';
@NgModule({
    imports: [CommonModule],
})
export class RoutingModule {
    /* istanbul ignore next */
    static forRoot(): ModuleWithProviders {
        return {
            /* ts-lint ignore next */
            ngModule: RootRoutingModule,
        };
    }
}

@NgModule({
    imports: [
        StoreModule.forFeature('router', routerReducer),
        StoreRouterConnectingModule.forRoot({
            stateKey: 'router',
        }),
        EffectsModule.forFeature([RouterEffects]),
        RoutingModule,
    ],
    exports: [RoutingModule],
    providers: [{ provide: RouterStateSerializer, useClass: CustomSerializer }],
})
export class RootRoutingModule {}
