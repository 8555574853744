"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var SettingsModule = /** @class */ (function () {
    function SettingsModule() {
    }
    /* istanbul ignore next */
    SettingsModule.forRoot = function () {
        return {
            /* ts-lint ignore next */
            ngModule: RootSettingsModule,
        };
    };
    return SettingsModule;
}());
exports.SettingsModule = SettingsModule;
var RootSettingsModule = /** @class */ (function () {
    function RootSettingsModule() {
    }
    return RootSettingsModule;
}());
exports.RootSettingsModule = RootSettingsModule;
