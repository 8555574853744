"use strict";
/// <reference path="./api/all-query.ts" />
Object.defineProperty(exports, "__esModule", { value: true });
var PageConverter = /** @class */ (function () {
    function PageConverter() {
    }
    PageConverter.convert = function (item) {
        var allApiObject = {};
        if (item.filter != null) {
            allApiObject.q = item.filter;
        }
        if (item.sortDirection != null) {
            allApiObject.direction = item.sortDirection.toUpperCase();
        }
        if (item.pageIndex != null) {
            allApiObject.page = item.pageIndex ? item.pageIndex + 1 : 0;
        }
        if (item.pageSize != null) {
            allApiObject.limit = item.pageSize;
        }
        if (item.sortField != null) {
            allApiObject.sort = item.sortField;
        }
        if (item.reportId != null) {
            allApiObject.report_id = item.reportId;
        }
        return allApiObject;
    };
    return PageConverter;
}());
exports.PageConverter = PageConverter;
var QueryConverter = /** @class */ (function () {
    function QueryConverter() {
    }
    QueryConverter.convert = function (item) {
        return {
            filter: item.q,
            sortDirection: item.direction.toLowerCase(),
            pageIndex: item.page,
            pageSize: item.limit,
            sortField: item.sort,
            reportId: item.report_id,
        };
    };
    return QueryConverter;
}());
exports.QueryConverter = QueryConverter;
