"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var store_1 = require("@ngrx/store");
// Lookup the 'User' feature state managed by NgRx
var getUserState = store_1.createFeatureSelector('user');
var ɵ0 = function (state) { return state.current; };
exports.ɵ0 = ɵ0;
var getUser = store_1.createSelector(getUserState, ɵ0);
exports.userQuery = {
    getUser: getUser,
};
