import { Action } from '@ngrx/store';

export enum SettingsActionTypes {
    LoadSettings = '[Settings] Load Settings',
    SetTimeout = '[Settings] Set Timeout',
}

export class LoadSettings implements Action {
    readonly type = SettingsActionTypes.LoadSettings;

    constructor(public payload: { configuration: any }) {}
}

export class SetTimeout implements Action {
    readonly type = SettingsActionTypes.SetTimeout;

    constructor(public payload: number) {}
}

export type SettingsActions = LoadSettings | SetTimeout;
