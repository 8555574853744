"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var effects_1 = require("@ngrx/effects");
var nx_1 = require("@nrwl/nx");
var actions_1 = require("@fixiti/actions");
var operators_1 = require("rxjs/operators");
var api_rest_import_service_1 = require("@fixiti/api/rest/import/src/lib/api-rest-import.service");
var models_1 = require("@fixiti/models");
var ImporterEffects = /** @class */ (function () {
    function ImporterEffects(actions$, apiRestImportService, dataPersistence) {
        var _this = this;
        this.actions$ = actions$;
        this.apiRestImportService = apiRestImportService;
        this.dataPersistence = dataPersistence;
        this.loadImportFiles$ = this.dataPersistence.fetch(actions_1.ImporterActionTypes.ImportLoadFiles, {
            run: function (action, state) {
                return _this.apiRestImportService
                    .getImportFiles(action.apiSection, state.importer.querySettings)
                    .pipe(operators_1.map(function (importFiles) {
                    return new actions_1.ImportFilesLoaded(importFiles);
                }));
            },
            onError: function (action, error) {
                return new actions_1.ImportFilesLoadError(error);
            },
        });
        this.selectImportFile$ = this.dataPersistence.fetch(actions_1.ImporterActionTypes.ImportSelectFile, {
            run: function (action, state) {
                return _this.apiRestImportService
                    .getMapping(state.importer.apiSection, action.payload)
                    .pipe(operators_1.map(function (mapping) { return new actions_1.ImportMappingLoaded(mapping); }));
            },
            onError: function (action, error) {
                return new actions_1.ImportMappingLoadError(error);
            },
        });
        this.saveImportMapping$ = this.dataPersistence.fetch(actions_1.ImporterActionTypes.ImportSaveMapping, {
            run: function (action, state) {
                return _this.apiRestImportService
                    .setMapping(state.importer.apiSection, state.importer.selectedId, action.payload.columns)
                    .pipe(operators_1.map(function (response) {
                    if ('errors' in response && Object.keys(response.errors).length) {
                        return new actions_1.ImportMappingInputErrors(response.errors);
                    }
                    else {
                        return new actions_1.ImportMappingSaved({
                            columns: response.data.columns,
                            tos: response.data.tos,
                        });
                    }
                }));
            },
            onError: function (action, error) {
                return new actions_1.ImportMappingSaveError(error);
            },
        });
        this.deleteImportFile$ = this.dataPersistence.fetch(actions_1.ImporterActionTypes.ImportDeleteFile, {
            run: function (action, state) {
                return _this.apiRestImportService
                    .deleteFile(state.importer.apiSection, action.payload)
                    .pipe(operators_1.map(function (serverResponse) { return new actions_1.ImportFileDeleted(); }));
            },
            onError: function (action, error) {
                return new actions_1.ImportFileDeleteError(error);
            },
        });
        this.importFileDeleted$ = this.dataPersistence.fetch(actions_1.ImporterActionTypes.ImportFileDeleted, {
            run: function (action, state) {
                return new actions_1.ImportLoadFiles(state.importer.apiSection);
            },
        });
        this.importFileCreate$ = this.dataPersistence.fetch(actions_1.ImporterActionTypes.ImportCreateFile, {
            run: function (action, state) {
                return _this.apiRestImportService
                    .createFile(state.importer.apiSection, action.payload.file)
                    .pipe(operators_1.map(function (serverResponse) { return new actions_1.ImportFileCreated(); }));
            },
            onError: function (action, error) {
                return new actions_1.ImportFileCreateError(error);
            },
        });
        this.importFileCreated$ = this.dataPersistence.fetch(actions_1.ImporterActionTypes.ImportFileCreated, {
            run: function (action, state) {
                return new actions_1.ImportLoadFiles(state.importer.apiSection);
            },
        });
        this.importSetQuerySetting$ = this.dataPersistence.fetch(actions_1.ImporterActionTypes.ImportSetQuerySetting, {
            run: function (action, state) {
                return new actions_1.ImportLoadFiles(state.importer.apiSection);
            },
        });
    }
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], ImporterEffects.prototype, "loadImportFiles$", void 0);
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], ImporterEffects.prototype, "selectImportFile$", void 0);
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], ImporterEffects.prototype, "saveImportMapping$", void 0);
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], ImporterEffects.prototype, "deleteImportFile$", void 0);
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], ImporterEffects.prototype, "importFileDeleted$", void 0);
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], ImporterEffects.prototype, "importFileCreate$", void 0);
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], ImporterEffects.prototype, "importFileCreated$", void 0);
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], ImporterEffects.prototype, "importSetQuerySetting$", void 0);
    return ImporterEffects;
}());
exports.ImporterEffects = ImporterEffects;
