"use strict";
/// <reference path="../../../../models/src/lib/api/negotiation.ts" />
Object.defineProperty(exports, "__esModule", { value: true });
var NegotiationsActionTypes;
(function (NegotiationsActionTypes) {
    NegotiationsActionTypes["LoadNegotiations"] = "[Negotiation] Load Data";
    NegotiationsActionTypes["NegotiationsLoaded"] = "[Negotiation] Data Loaded";
    NegotiationsActionTypes["SaveNegotiation"] = "[Negotiation] Save Data";
    NegotiationsActionTypes["NegotiationSaved"] = "[Negotiation] Data Saved";
    NegotiationsActionTypes["ChangeCounterOffer"] = "[Negotiation] Change Counter";
})(NegotiationsActionTypes = exports.NegotiationsActionTypes || (exports.NegotiationsActionTypes = {}));
var LoadNegotiations = /** @class */ (function () {
    function LoadNegotiations(payload) {
        this.payload = payload;
        this.type = NegotiationsActionTypes.LoadNegotiations;
    }
    return LoadNegotiations;
}());
exports.LoadNegotiations = LoadNegotiations;
var NegotiationsLoaded = /** @class */ (function () {
    function NegotiationsLoaded(payload) {
        this.payload = payload;
        this.type = NegotiationsActionTypes.NegotiationsLoaded;
    }
    return NegotiationsLoaded;
}());
exports.NegotiationsLoaded = NegotiationsLoaded;
var SaveNegotiation = /** @class */ (function () {
    function SaveNegotiation(payload) {
        this.payload = payload;
        this.type = NegotiationsActionTypes.SaveNegotiation;
    }
    return SaveNegotiation;
}());
exports.SaveNegotiation = SaveNegotiation;
var NegotiationSaved = /** @class */ (function () {
    function NegotiationSaved(payload) {
        this.payload = payload;
        this.type = NegotiationsActionTypes.NegotiationSaved;
    }
    return NegotiationSaved;
}());
exports.NegotiationSaved = NegotiationSaved;
var ChangeCounterOffer = /** @class */ (function () {
    function ChangeCounterOffer(payload) {
        this.payload = payload;
        this.type = NegotiationsActionTypes.ChangeCounterOffer;
    }
    return ChangeCounterOffer;
}());
exports.ChangeCounterOffer = ChangeCounterOffer;
