import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'fixiti-photo-dialog-upload-images-actions-submit',
    templateUrl: './upload-images-actions-submit.component.html',
    styleUrls: ['./upload-images-actions-submit.component.css'],
})
export class UploadImagesActionsSubmitComponent {
    @Input()
    files = [];
    @Output()
    submit = new EventEmitter();
    @Input()
    onSubmit: VoidFunction | null;
    @Input()
    isEnabled = true;

    /* istanbul ignore next */
    constructor() {}

    handleClick() {
        if (this.onSubmit != null) {
            this.onSubmit();
        }
        this.submit.emit();
    }
}
