"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ResidentsActionTypes;
(function (ResidentsActionTypes) {
    ResidentsActionTypes["LoadResidents"] = "[Residents] Load Residents";
    ResidentsActionTypes["ResidentsLoaded"] = "[Residents] Residents Loaded";
    ResidentsActionTypes["ResidentsLoadError"] = "[Residents] Residents Load Error";
    ResidentsActionTypes["SaveResident"] = "[Residents] Save Resident";
    ResidentsActionTypes["ResidentSaved"] = "[Residents] Resident Saved";
    ResidentsActionTypes["RemoveResident"] = "[Residents] Remove Resident";
    ResidentsActionTypes["ResidentRemoved"] = "[Residents] Resident Removed";
    ResidentsActionTypes["SelectResident"] = "[Residents] Select Resident";
    ResidentsActionTypes["EditResident"] = "[Residents] Edit Resident";
})(ResidentsActionTypes = exports.ResidentsActionTypes || (exports.ResidentsActionTypes = {}));
var LoadResidents = /** @class */ (function () {
    function LoadResidents(payload) {
        this.payload = payload;
        this.type = ResidentsActionTypes.LoadResidents;
    }
    return LoadResidents;
}());
exports.LoadResidents = LoadResidents;
var ResidentsLoadError = /** @class */ (function () {
    function ResidentsLoadError(payload) {
        this.payload = payload;
        this.type = ResidentsActionTypes.ResidentsLoadError;
    }
    return ResidentsLoadError;
}());
exports.ResidentsLoadError = ResidentsLoadError;
var ResidentsLoaded = /** @class */ (function () {
    function ResidentsLoaded(payload) {
        this.payload = payload;
        this.type = ResidentsActionTypes.ResidentsLoaded;
    }
    return ResidentsLoaded;
}());
exports.ResidentsLoaded = ResidentsLoaded;
var SaveResident = /** @class */ (function () {
    function SaveResident(payload) {
        this.payload = payload;
        this.type = ResidentsActionTypes.SaveResident;
    }
    return SaveResident;
}());
exports.SaveResident = SaveResident;
var ResidentSaved = /** @class */ (function () {
    function ResidentSaved(payload) {
        this.payload = payload;
        this.type = ResidentsActionTypes.ResidentSaved;
    }
    return ResidentSaved;
}());
exports.ResidentSaved = ResidentSaved;
var RemoveResident = /** @class */ (function () {
    function RemoveResident(payload) {
        this.payload = payload;
        this.type = ResidentsActionTypes.RemoveResident;
    }
    return RemoveResident;
}());
exports.RemoveResident = RemoveResident;
var ResidentRemoved = /** @class */ (function () {
    function ResidentRemoved(payload) {
        this.payload = payload;
        this.type = ResidentsActionTypes.ResidentRemoved;
    }
    return ResidentRemoved;
}());
exports.ResidentRemoved = ResidentRemoved;
var SelectResident = /** @class */ (function () {
    function SelectResident(payload) {
        this.payload = payload;
        this.type = ResidentsActionTypes.SelectResident;
    }
    return SelectResident;
}());
exports.SelectResident = SelectResident;
var EditResident = /** @class */ (function () {
    function EditResident(payload) {
        this.payload = payload;
        this.type = ResidentsActionTypes.EditResident;
    }
    return EditResident;
}());
exports.EditResident = EditResident;
exports.fromResidentsActions = {
    LoadResidents: LoadResidents,
    ResidentsLoaded: ResidentsLoaded,
    ResidentsLoadError: ResidentsLoadError,
    SaveResident: SaveResident,
    ResidentSaved: ResidentSaved,
    RemoveResident: RemoveResident,
    ResidentRemoved: ResidentRemoved,
    SelectResident: SelectResident,
    EditResident: EditResident,
};
