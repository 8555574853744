import { CommonModule } from '@angular/common';
import { LogoComponent } from './logo/logo.component';
import { NgModule } from '@angular/core';

@NgModule({
    imports: [CommonModule],
    exports: [LogoComponent],
    declarations: [LogoComponent],
})
export class SharedGraphicsLogoModule {}
