/// <reference path="./../../../../models/src/lib/api/all-query.ts" />

import { Action } from '@ngrx/store';
import { PropertyModel } from '@fixiti/models';

export enum EstatesActionTypes {
    LoadEstates = '[Estates] Load Estates',
    EstatesLoaded = '[Estates] Estates Loaded',
    EstatesLoadError = '[Estates] Estates Load Error',
    SelectEstate = '[Estates] Select Estate',
    LoadExcel = '[Estates] Load Excel',
    LoadDuplicates = '[Estates] Load Duplicates',
    ResolveDuplicates = '[Estates] Resolve Duplicates',
    LoadTargetColumns = '[Estates] Load Target Columns',
    LoadExcelColumns = '[Estates] Load Excel Columns',
    ResolveColumns = '[Estates] Resolve Columns',
    EditEstate = '[Estates] Edit Estate',
    SaveEstate = '[Estates] Save Estate',
    EstateSaved = '[Estates] Estate Saved',
    RemoveEstate = '[Estates] Remove Estate',
    EstateRemoved = '[Estates] Estate Removed',
}

export class LoadEstates implements Action {
    readonly type = EstatesActionTypes.LoadEstates;
    constructor(public payload?: ApiModel.AllQuery) {}
}

export class EstatesLoadError implements Action {
    readonly type = EstatesActionTypes.EstatesLoadError;
    constructor(public payload: any) {}
}

export class EstatesLoaded implements Action {
    readonly type = EstatesActionTypes.EstatesLoaded;
    constructor(public payload: PropertyModel[]) {}
}

export class SelectEstate implements Action {
    readonly type = EstatesActionTypes.SelectEstate;
    constructor(public payload: number) {}
}

export class LoadExcel implements Action {
    readonly type = EstatesActionTypes.LoadExcel;
    constructor(public payload: File) {}
}

export class LoadDuplicates implements Action {
    readonly type = EstatesActionTypes.LoadDuplicates;
    constructor(public payload: string[]) {}
}

export class ResolveDuplicates implements Action {
    readonly type = EstatesActionTypes.ResolveDuplicates;
    constructor(public payload: any[]) {}
}

export class LoadExcelColumns implements Action {
    readonly type = EstatesActionTypes.LoadExcelColumns;
    constructor(public payload: string[]) {}
}

export class LoadTargetColumns implements Action {
    readonly type = EstatesActionTypes.LoadTargetColumns;
    constructor(public payload: string[]) {}
}

export class ResolveColumns implements Action {
    readonly type = EstatesActionTypes.ResolveColumns;
    constructor(public payload: [string, string][]) {}
}

export class EditEstate implements Action {
    readonly type = EstatesActionTypes.EditEstate;
    constructor(public payload: number) {}
}

export class SaveEstate implements Action {
    readonly type = EstatesActionTypes.SaveEstate;
    constructor(public payload: PropertyModel) {}
}

export class EstateSaved implements Action {
    readonly type = EstatesActionTypes.EstateSaved;
    constructor(public payload: PropertyModel) {}
}

export class RemoveEstate implements Action {
    readonly type = EstatesActionTypes.RemoveEstate;
    constructor(public payload: number) {}
}

export class EstateRemoved implements Action {
    readonly type = EstatesActionTypes.EstateRemoved;
    constructor(public payload: number) {}
}

export type EstatesAction =
    | LoadEstates
    | EstatesLoaded
    | EstatesLoadError
    | SelectEstate
    | LoadExcel
    | LoadDuplicates
    | ResolveDuplicates
    | LoadTargetColumns
    | LoadExcelColumns
    | ResolveColumns
    | EditEstate
    | SaveEstate
    | EstateSaved
    | RemoveEstate
    | EstateRemoved;

export const fromEstatesActions = {
    LoadEstates,
    EstatesLoaded,
    EstatesLoadError,
    SelectEstate,
    LoadExcel,
    LoadDuplicates,
    ResolveDuplicates,
    LoadTargetColumns,
    LoadExcelColumns,
    ResolveColumns,
    EditEstate,
    SaveEstate,
    EstateSaved,
    RemoveEstate,
    EstateRemoved,
};
