import { PageSettingsAction, PageSettingsActionTypes } from '@fixiti/actions';

export const PAGESETTINGS_FEATURE_KEY = 'pageSettings';

export interface PageSettingsState {
    current: string;
    updatingUrl?: boolean;
    display: {
        [key: string]: string;
    };
    filter: {
        [key: string]: string;
    };
    pageIndex: {
        [key: string]: number;
    };
    pageLength: {
        [key: string]: number;
    };
    pageSize: {
        [key: string]: number;
    };
    sortDirection: {
        [key: string]: string;
    };
    sortField: {
        [key: string]: string;
    };
    reportId: {
        [key: string]: number;
    };
}

export interface PageSettingsPartialState {
    readonly [PAGESETTINGS_FEATURE_KEY]: PageSettingsState;
}

export const initialState: PageSettingsState = {
    current: null,
    display: {},
    filter: {},
    pageIndex: {},
    pageLength: {},
    pageSize: {},
    sortDirection: {},
    sortField: {},
    reportId: {},
};

export function pageSettingsReducer(
    state: PageSettingsState = initialState,
    action: PageSettingsAction
): PageSettingsState {
    switch (action.type) {
        case PageSettingsActionTypes.ChangePage: {
            state = {
                ...state,
                current: action.payload,
            };
            break;
        }
        case PageSettingsActionTypes.ChangePageDisplay: {
            state = {
                ...state,
                display: {
                    ...state.display,
                    [state.current]: action.payload,
                },
            };
            break;
        }
        case PageSettingsActionTypes.ChangePageFilter: {
            state = {
                ...state,
                filter: {
                    ...state.filter,
                    [state.current]: action.payload,
                },
                pageIndex: {
                    ...state.pageIndex,
                    [state.current]: 0,
                },
            };
            break;
        }
        case PageSettingsActionTypes.ChangePageLength: {
            const max = action.payload || 0;
            const increment = state.pageSize[state.current] || 100;
            const currentIndex = state.pageIndex[state.current] || 0;
            const offset = currentIndex * increment;
            const index = offset > max ? 0 : currentIndex;
            state = {
                ...state,
                pageLength: {
                    ...state.pageLength,
                    [state.current]: action.payload,
                },
                pageIndex: {
                    ...state.pageIndex,
                    [state.current]: index,
                },
            };
            break;
        }
        case PageSettingsActionTypes.UpdatePageLength: {
            const max = action.payload || 0;
            const increment = state.pageSize[action.payload.key] || 100;
            const currentIndex = state.pageIndex[action.payload.key] || 0;
            const offset = currentIndex * increment;
            const index = offset > max ? 0 : currentIndex;
            state = {
                ...state,
                pageLength: {
                    ...state.pageLength,
                    [action.payload.key]: action.payload.length,
                },
                pageIndex: {
                    ...state.pageIndex,
                    [action.payload.key]: index,
                },
            };
            break;
        }
        case PageSettingsActionTypes.ChangePageIndex: {
            const max = state.pageLength[state.current] || 0;
            const increment = state.pageSize[state.current] || 100;
            const offset = action.payload * increment;
            const index = offset > max ? 0 : action.payload;
            state = {
                ...state,
                pageIndex: {
                    ...state.pageIndex,
                    [state.current]: index,
                },
            };
            break;
        }
        case PageSettingsActionTypes.ChangePageSize: {
            state = {
                ...state,
                pageSize: {
                    ...state.pageSize,
                    [state.current]: action.payload,
                },
                pageIndex: {
                    ...state.pageIndex,
                    [state.current]: 0,
                },
            };
            break;
        }
        case PageSettingsActionTypes.ChangePageSort: {
            state = {
                ...state,
                sortDirection: {
                    ...state.sortDirection,
                    [state.current]: action.payload.direction,
                },
                sortField: {
                    ...state.sortField,
                    [state.current]: action.payload.field,
                },
                pageIndex: {
                    ...state.pageIndex,
                    [state.current]: 0,
                },
            };
            break;
        }
        case PageSettingsActionTypes.ChangePageSortDirection: {
            state = {
                ...state,
                sortDirection: {
                    ...state.sortDirection,
                    [state.current]: action.payload,
                },
                pageIndex: {
                    ...state.pageIndex,
                    [state.current]: 0,
                },
            };
            break;
        }
        case PageSettingsActionTypes.ChangePageSortField: {
            state = {
                ...state,
                sortField: {
                    ...state.sortField,
                    [state.current]: action.payload,
                },
                pageIndex: {
                    ...state.pageIndex,
                    [state.current]: 0,
                },
            };
            break;
        }
        case PageSettingsActionTypes.ChangePageReportId: {
            const page = action.payload.page
                ? action.payload.page
                : state.current;
            state = {
                ...state,
                reportId: {
                    ...state.reportId,
                    [page]: action.payload.reportId,
                },
                pageIndex: {
                    ...state.pageIndex,
                    [page]: 0,
                },
            };
            break;
        }
        case PageSettingsActionTypes.HydratePage: {
            const max = action.payload.pageLength || 0;
            const increment = action.payload.pageSize || 100;
            const offset = action.payload.pageIndex * increment;
            const index = offset > max ? 0 : action.payload.pageIndex;
            state = {
                ...state,
                display: {
                    ...state.display,
                    [state.current]: action.payload.display,
                },
                filter: {
                    ...state.filter,
                    [state.current]: action.payload.filter,
                },
                pageIndex: {
                    ...state.pageIndex,
                    [state.current]: index,
                },
                pageLength: {
                    ...state.pageLength,
                    [state.current]: action.payload.pageLength,
                },
                pageSize: {
                    ...state.pageSize,
                    [state.current]: action.payload.pageSize,
                },
                sortDirection: {
                    ...state.sortDirection,
                    [state.current]: action.payload.sortDirection,
                },
                sortField: {
                    ...state.sortField,
                    [state.current]: action.payload.sortField,
                },
                reportId: {
                    ...state.reportId,
                    [state.current]: action.payload.reportId,
                },
            };
            break;
        }
        case PageSettingsActionTypes.UpdatePageUrl: {
            state = {
                ...state,
                updatingUrl: true,
            };
            break;
        }
        case PageSettingsActionTypes.PageUrlUpdated: {
            state = {
                ...state,
                updatingUrl: null,
            };
            break;
        }
    }
    return state;
}
