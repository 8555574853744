<div *ngIf="!!image; else: placeholderImage">
    <fixiti-gallery-preview-image
        [image]="image"
        [text]="placeholder"
        [count]="(gallery && gallery.length) || count || 0"
        [minimum]="minimum"
        [showBadge]="showBadge"
    >
    </fixiti-gallery-preview-image>
</div>
<ng-template #placeholderImage>
    <fixiti-gallery-preview-placeholder
        [text]="placeholder"
        [count]="(gallery && gallery.length) || count || 0"
        [minimum]="minimum"
        [showBadge]="showBadge"
    >
    </fixiti-gallery-preview-placeholder>
</ng-template>
