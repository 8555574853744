/// <reference path="./api/gallery-image.ts" />
import { ResidentModel } from '@fixiti/models';

export class PropertyModel {
    id: number;
    address: string;
    city: string;
    state: string;
    zip: string;
    jobIds?: number[] = [];
    statusIds?: number[] = [];
    residents?: ResidentModel[] = [];
    frontElevation: (ApiModel.GalleryImage | string)[] = [];
    created: Date;
    updated: Date;
    investorId?: string;
}
