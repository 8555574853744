"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var faCamera_1 = require("@fortawesome/free-solid-svg-icons/faCamera");
var fontawesome_svg_core_1 = require("@fortawesome/fontawesome-svg-core");
fontawesome_svg_core_1.library.add(faCamera_1.faCamera);
var SharedGalleryPreviewPlaceholderModule = /** @class */ (function () {
    function SharedGalleryPreviewPlaceholderModule() {
    }
    return SharedGalleryPreviewPlaceholderModule;
}());
exports.SharedGalleryPreviewPlaceholderModule = SharedGalleryPreviewPlaceholderModule;
