import { FieldModel } from './field.model';

export class LandingPageFieldsModel {
    edit: FieldModel[];
    card?: FieldModel[];
    create: FieldModel[];
    display?: FieldModel[];
    sheet?: FieldModel[];
    sort: FieldModel[];
}
