import { SettingsActionTypes, SettingsActions } from '@fixiti/actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export interface SettingsState {
    configuration: any;
    timeout?: number;
}

export const settingsInitialState: SettingsState = {
    configuration: {},
};

export function settingsReducer(
    state = settingsInitialState,
    action: SettingsActions
): SettingsState {
    switch (action.type) {
        case SettingsActionTypes.LoadSettings:
            return {
                ...state,
                configuration: action.payload.configuration,
            };
        case SettingsActionTypes.SetTimeout:
            return {
                ...state,
                timeout: action.payload,
            };
        default:
            return state;
    }
}
export const selectSettingsState = createFeatureSelector<SettingsState>(
    'settings'
);

export const selectConfiguration = createSelector(
    selectSettingsState,
    state => state.configuration
);

export const selectSettingsTimeout = createSelector(
    selectSettingsState,
    state => state.timeout
);
