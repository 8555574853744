import { SidenavAction, SidenavActionTypes } from '@fixiti/actions';

export interface SidenavState {
    isOpen: boolean;
}

export const initialState: SidenavState = {
    isOpen: false,
};

export function sidenavReducer(state: SidenavState = initialState, action: SidenavAction): SidenavState {
    switch (action.type) {
        case SidenavActionTypes.ToggleSidenav: {
            state = {
                ...state,
                isOpen: !state.isOpen,
            };
            break;
        }
        case SidenavActionTypes.CloseSidenav: {
            state = {
                ...state,
                isOpen: false,
            };
            break;
        }
        case SidenavActionTypes.OpenSidenav: {
            state = {
                ...state,
                isOpen: true,
            };
            break;
        }
    }
    return state;
}
