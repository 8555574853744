"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./upload-images-actions-submit.component.css.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory");
var i3 = require("@angular/material/button");
var i4 = require("@angular/cdk/platform");
var i5 = require("@angular/cdk/a11y");
var i6 = require("@angular/platform-browser/animations");
var i7 = require("@angular/common");
var i8 = require("./upload-images-actions-submit.component");
var styles_UploadImagesActionsSubmitComponent = [i0.styles];
var RenderType_UploadImagesActionsSubmitComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UploadImagesActionsSubmitComponent, data: {} });
exports.RenderType_UploadImagesActionsSubmitComponent = RenderType_UploadImagesActionsSubmitComponent;
function View_UploadImagesActionsSubmitComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["autofocus", ""], ["class", "submit-button"], ["color", "primary"], ["mat-raised-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "load"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("load" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).focus() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.handleClick() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, [["submitButton", 4]], 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, [" Submit\n"]))], function (_ck, _v) { var currVal_2 = "primary"; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_UploadImagesActionsSubmitComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadImagesActionsSubmitComponent_1)), i1.ɵdid(1, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.isEnabled || (_co.files && (_co.files.length > 0))); _ck(_v, 1, 0, currVal_0); }, null); }
exports.View_UploadImagesActionsSubmitComponent_0 = View_UploadImagesActionsSubmitComponent_0;
function View_UploadImagesActionsSubmitComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fixiti-photo-dialog-upload-images-actions-submit", [], null, null, null, View_UploadImagesActionsSubmitComponent_0, RenderType_UploadImagesActionsSubmitComponent)), i1.ɵdid(1, 49152, null, 0, i8.UploadImagesActionsSubmitComponent, [], null, null)], null, null); }
exports.View_UploadImagesActionsSubmitComponent_Host_0 = View_UploadImagesActionsSubmitComponent_Host_0;
var UploadImagesActionsSubmitComponentNgFactory = i1.ɵccf("fixiti-photo-dialog-upload-images-actions-submit", i8.UploadImagesActionsSubmitComponent, View_UploadImagesActionsSubmitComponent_Host_0, { files: "files", onSubmit: "onSubmit", isEnabled: "isEnabled" }, { submit: "submit" }, []);
exports.UploadImagesActionsSubmitComponentNgFactory = UploadImagesActionsSubmitComponentNgFactory;
