import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core';

@Component({
    selector: 'fixiti-gallery-preview-image',
    templateUrl: './gallery-preview-image.component.html',
    styleUrls: ['./gallery-preview-image.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GalleryPreviewImageComponent implements OnInit {
    @Input()
    count = 0;
    @Input()
    image: { src?: File; url: string };
    @Input()
    text: string;
    @Input()
    minimum = 0;
    @Input()
    showBadge = true;
    @ViewChild('imageContainer')
    imageContainer;
    constructor() {}

    ngOnInit() {}

    getBadgeText(count: number, minimum: number) {
        if (!minimum) {
            return count.toString();
        } else {
            return `${count}/${minimum}`;
        }
    }

    sanitize(image: { src?: File; url: string } | string) {
        if (typeof image === 'string') {
            return image;
        }
        return (image && image.url) || '';
    }
}
