"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/// <reference path="./api//task.ts" />
var TaskModel = /** @class */ (function () {
    function TaskModel() {
        this.status = '';
        this.room = '';
        this.name = '';
        this.description = '';
        this.before = [];
        this.after = [];
    }
    return TaskModel;
}());
exports.TaskModel = TaskModel;
