/// <reference path="../../../../../models/src/lib/api/server-response.ts" />
/// <reference path="../../../../../models/src/lib/api/paginated-server-response.ts" />
/// <reference path="../../../../../models/src/lib/api/all-query.ts" />

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, zip } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';

import { ImportSetPaginationData } from '@fixiti/actions/src';
import { RestApiBase } from '@fixiti/api/rest/rest-api.base';
import { userQuery } from '@fixiti/state/user/src';
import { ImportFileModel, ImportFileMapModel, ImportFileToModel, ListViewQueryModel } from '@fixiti/models';
import { SharedApiNormalizationService } from '@fixiti/shared/api/normalization/src/lib/shared-api-normalization.service';
import { ModalService } from '@fixiti/elements/modal/src';

class ColumnsAndTos {
    columns: ImportFileMapModel[];
    tos: ImportFileToModel[];
}

@Injectable({
    providedIn: 'root',
})
export class ApiRestImportService extends RestApiBase {
    constructor(
        protected store: Store<any>,
        protected modalService: ModalService,
        protected http: HttpClient,
        protected normalize: SharedApiNormalizationService
    ) {
        super(store, modalService);
    }

    getImportFiles(apiSection: string, query: ListViewQueryModel): Observable<ImportFileModel[]> {
        return this.getPaginatedItemList<ImportFileModel>(
            apiSection,
            this.translateListViewQuery(query)
        ).pipe(
            map(results => {
                return results.map(file => {
                    const columnsAndTos = this.normalizeMapping(file);
                    return <ImportFileModel>{
                        id: this.normalize.asInt(file.id),
                        statusId: this.normalize.asInt(file.statusId),
                        createdByFirstName: this.normalize.asString(file.createdByFirstName),
                        createdByLastName: this.normalize.asString(file.createdByLastName),
                        originalFilename: this.normalize.asString(file.originalFilename),
                        filesize: this.normalize.asInt(file.filesize),
                        numberDataLines: this.normalize.asInt(file.numberDataLines),
                        numberVerified: this.normalize.asInt(file.numberVerified),
                        numberInputErrors: this.normalize.asInt(file.numberInputErrors),
                        numberProcessed: this.normalize.asInt(file.numberProcessed),
                        numberProcessingErrors: this.normalize.asInt(file.numberProcessingErrors),
                        statusMessage: this.normalize.asString(file.statusMessage),
                        columns: columnsAndTos.columns,
                        tos: columnsAndTos.tos,
                        created: this.normalize.asDate(file.created),
                    };
                });
            })
        );
    }

    getMapping(apiSection: string, fileId: number): Observable<ColumnsAndTos> {
        return zip(this.serverObs, this.defaultHttpHeaders()).pipe(
            switchMap(([server, headers]) =>
                this.http.get(`${server}api/1.0/pmp/${apiSection}/import?route=map&id=${fileId}`, {
                    headers,
                })
            ),
            this.checkForError(),
            map(
                (json): ColumnsAndTos => {
                    return this.normalizeMapping(json.data);
                }
            ),
            catchError(error => this.handleError(error))
        );
    }

    setMapping(apiSection: string, fileId: number, mapping: ImportFileMapModel[]): Observable<any> {
        return zip(this.serverObs, this.defaultHttpHeaders()).pipe(
            switchMap(([server, headers]) =>
                this.http.post(
                    `${server}api/1.0/pmp/${apiSection}/import?route=map&id=${fileId}`,
                    { columns: mapping },
                    {
                        headers,
                    }
                )
            ),
            this.checkForFailure(),
            map(json => {
                return {
                    nErrors: this.normalize.asInt(json.nErrors),
                    errors: this.normalizeErrors(json.errors),
                    data: this.normalizeMapping(json.data),
                };
            }),
            catchError(error => this.handleError(error))
        );
    }

    deleteFile(apiSection: string, fileId: number): Observable<ApiModel.ServerResponse<any>> {
        return zip(this.serverObs, this.defaultHttpHeaders()).pipe(
            switchMap(([server, headers]) => {
                return this.http.delete(
                    `${server}api/1.0/pmp/${apiSection}/import?route=delete&id=${fileId}`,
                    {
                        headers,
                    }
                );
            }),
            this.checkForError(),
            map((json: ApiModel.ServerResponse<any>) => json),
            catchError(error => this.handleError(error))
        );
    }

    createFile(apiSection: string, file: any): Observable<ApiModel.ServerResponse<any>> {
        return zip(this.serverObs, this.defaultHttpHeaders()).pipe(
            switchMap(([server, headers]) => {
                return this.http.post(
                    `${server}api/1.0/pmp/${apiSection}/import?route=create&filename=${file.name}`,
                    file,
                    {
                        headers,
                    }
                );
            }),
            this.checkForError(),
            map((json: ApiModel.ServerResponse<any>) => json),
            catchError(error => this.handleError(error))
        );
    }

    normalizeMapping(data: any): ColumnsAndTos {
        const columns = 'columns' in data && Array.isArray(data.columns) ? data.columns : [];
        const tos = 'tos' in data && Array.isArray(data.tos) ? data.tos : [];
        return {
            columns: columns.map(
                (column): ImportFileMapModel => {
                    return <ImportFileMapModel>{
                        id: this.normalize.asInt(column.id),
                        from: this.normalize.asString(column.from),
                        to: this.normalize.asString(column.to),
                    };
                }
            ),
            tos: tos.map(
                (to): ImportFileToModel => {
                    return <ImportFileToModel>{
                        name: this.normalize.asString(to.name),
                        label: this.normalize.asString(to.label),
                    };
                }
            ),
        };
    }

    normalizeErrors(errors: any): any {
        const normalizedErrors: { [key: string]: string } = {};
        if (!Array.isArray(errors)) {
            return normalizedErrors;
        }

        let nErrors = 0;
        for (const error of errors) {
            if (!('field' in error) || !('error' in error)) {
                continue;
            }

            nErrors += 1;
            if (error.field === 'general') {
                if (!('general' in normalizedErrors)) {
                    normalizedErrors.general = '';
                }
                normalizedErrors.general += (normalizedErrors.general ? ' ' : '') + error.error;
                continue;
            }

            normalizedErrors[error.field] = error.error;
        }

        if (!nErrors) {
            return {};
        }
        return { columns: normalizedErrors };
    }

    defaultHttpParameters() {
        return this.store.pipe(
            select(userQuery.getUser),
            map(user => {
                return {
                    Authorization: `Bearer ${user.apiToken}`,
                    MembershipId: user.memberships[0].id,
                    CustomerId: user.memberships[0].customerId,
                };
            }),
            take(1)
        );
    }

    translateListViewQuery(query: ListViewQueryModel): ApiModel.AllQuery {
        return {
            page: query.page,
            limit: query.limit,
            q: '',
            direction: query.direction,
            sort: query.sort,
        };
    }

    getPaginatedItemList<T>(apiSection: string, queryParameters?: Object): Observable<T[]> {
        return zip(this.serverObs, this.defaultHttpHeaders()).pipe(
            switchMap(([server, headers]) =>
                this.http.get(`${server}api/1.0/pmp/${apiSection}/import?route=files`, {
                    headers,
                    params: this.buildQueryParams(queryParameters),
                })
            ),
            this.checkForError(),
            tap((json: any) => {
                /* istanbul ignore else */
                this.store.dispatch(
                    new ImportSetPaginationData(this.normalize.asListViewPagination(json.data.pagination))
                );
            }),
            map((json: ApiModel.PaginatedServerResponse<T[]>) => json.data.results),
            catchError(error => this.handleError(error))
        );
    }
}
