"use strict";
/// <reference path="./../../../../models/src/lib/api/all-query.ts" />
/// <reference path="../../../../models/src/lib/api/manager.ts" />
Object.defineProperty(exports, "__esModule", { value: true });
var UsersActionTypes;
(function (UsersActionTypes) {
    UsersActionTypes["LoadUsers"] = "[Users] Load Users";
    UsersActionTypes["UsersLoaded"] = "[Users] Users Loaded";
    UsersActionTypes["UsersLoadError"] = "[Users] Users Load Error";
    UsersActionTypes["SaveUser"] = "[Users] Save User";
    UsersActionTypes["UserSaved"] = "[Users] User Saved";
    UsersActionTypes["RemoveUser"] = "[Users] Remove User";
    UsersActionTypes["UserRemoved"] = "[Users] User Removed";
    UsersActionTypes["SelectUser"] = "[Users] Select User";
    UsersActionTypes["EditUser"] = "[Users] Edit User";
})(UsersActionTypes = exports.UsersActionTypes || (exports.UsersActionTypes = {}));
var LoadUsers = /** @class */ (function () {
    function LoadUsers(payload) {
        this.payload = payload;
        this.type = UsersActionTypes.LoadUsers;
    }
    return LoadUsers;
}());
exports.LoadUsers = LoadUsers;
var UsersLoadError = /** @class */ (function () {
    function UsersLoadError(payload) {
        this.payload = payload;
        this.type = UsersActionTypes.UsersLoadError;
    }
    return UsersLoadError;
}());
exports.UsersLoadError = UsersLoadError;
var UsersLoaded = /** @class */ (function () {
    function UsersLoaded(payload) {
        this.payload = payload;
        this.type = UsersActionTypes.UsersLoaded;
    }
    return UsersLoaded;
}());
exports.UsersLoaded = UsersLoaded;
var SaveUser = /** @class */ (function () {
    function SaveUser(payload) {
        this.payload = payload;
        this.type = UsersActionTypes.SaveUser;
    }
    return SaveUser;
}());
exports.SaveUser = SaveUser;
var UserSaved = /** @class */ (function () {
    function UserSaved(payload) {
        this.payload = payload;
        this.type = UsersActionTypes.UserSaved;
    }
    return UserSaved;
}());
exports.UserSaved = UserSaved;
var RemoveUser = /** @class */ (function () {
    function RemoveUser(payload) {
        this.payload = payload;
        this.type = UsersActionTypes.RemoveUser;
    }
    return RemoveUser;
}());
exports.RemoveUser = RemoveUser;
var UserRemoved = /** @class */ (function () {
    function UserRemoved(payload) {
        this.payload = payload;
        this.type = UsersActionTypes.UserRemoved;
    }
    return UserRemoved;
}());
exports.UserRemoved = UserRemoved;
var SelectUser = /** @class */ (function () {
    function SelectUser(payload) {
        this.payload = payload;
        this.type = UsersActionTypes.SelectUser;
    }
    return SelectUser;
}());
exports.SelectUser = SelectUser;
var EditUser = /** @class */ (function () {
    function EditUser(payload) {
        this.payload = payload;
        this.type = UsersActionTypes.EditUser;
    }
    return EditUser;
}());
exports.EditUser = EditUser;
exports.fromUsersActions = {
    LoadUsers: LoadUsers,
    UsersLoaded: UsersLoaded,
    UsersLoadError: UsersLoadError,
    SaveUser: SaveUser,
    UserSaved: UserSaved,
    RemoveUser: RemoveUser,
    UserRemoved: UserRemoved,
    SelectUser: SelectUser,
    EditUser: EditUser,
};
