"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var dialog_body_component_1 = require("./components/dialog-body/dialog-body.component");
var dialog_container_component_1 = require("./components/dialog-container/dialog-container.component");
var dialog_footer_component_1 = require("./components/dialog-footer/dialog-footer.component");
var dialog_header_component_1 = require("./components/dialog-header/dialog-header.component");
var dialog_header_icon_component_1 = require("./components/dialog-header-icon/dialog-header-icon.component");
var dialog_header_title_component_1 = require("./components/dialog-header-title/dialog-header-title.component");
var button_1 = require("@angular/material/button");
var dialog_1 = require("@angular/material/dialog");
var progress_bar_1 = require("@angular/material/progress-bar");
var photo_counter_icon_component_1 = require("./components/photo-counter-icon/photo-counter-icon.component");
var photo_summary_component_1 = require("./components/photo-summary/photo-summary.component");
var upload_images_actions_cancel_component_1 = require("./components/upload-images-actions-cancel/upload-images-actions-cancel.component");
var upload_images_actions_component_1 = require("./components/upload-images-actions/upload-images-actions.component");
var upload_images_actions_submit_component_1 = require("./components/upload-images-actions-submit/upload-images-actions-submit.component");
var upload_images_component_1 = require("./components/upload-images/upload-images.component");
var upload_images_gallery_component_1 = require("./components/upload-images-gallery/upload-images-gallery.component");
var upload_images_progress_component_1 = require("./components/upload-images-progress/upload-images-progress.component");
var upload_images_selection_component_1 = require("./components/upload-images-selection/upload-images-selection.component");
var faFileAlt_1 = require("@fortawesome/free-solid-svg-icons/faFileAlt");
var fontawesome_svg_core_1 = require("@fortawesome/fontawesome-svg-core");
fontawesome_svg_core_1.library.add(faFileAlt_1.faFileAlt);
var MATERIAL = [dialog_1.MatDialogModule, button_1.MatButtonModule, progress_bar_1.MatProgressBarModule];
var EXPORT_COMPONENTS = [dialog_container_component_1.DialogContainerComponent];
var INTERNAL_COMPONENTS = [
    dialog_header_component_1.DialogHeaderComponent,
    dialog_body_component_1.DialogBodyComponent,
    dialog_footer_component_1.DialogFooterComponent,
    photo_counter_icon_component_1.PhotoCounterIconComponent,
    photo_summary_component_1.PhotoSummaryComponent,
    dialog_header_icon_component_1.DialogHeaderIconComponent,
    dialog_header_title_component_1.DialogHeaderTitleComponent,
    upload_images_component_1.UploadImagesComponent,
    upload_images_actions_component_1.UploadImagesActionsComponent,
    upload_images_gallery_component_1.UploadImagesGalleryComponent,
    upload_images_selection_component_1.UploadImagesSelectionComponent,
    upload_images_progress_component_1.UploadImagesProgressComponent,
    upload_images_actions_submit_component_1.UploadImagesActionsSubmitComponent,
    upload_images_actions_cancel_component_1.UploadImagesActionsCancelComponent,
];
var PhotoDialogModule = /** @class */ (function () {
    function PhotoDialogModule() {
    }
    /* istanbul ignore next */
    PhotoDialogModule.forRoot = function () {
        return {
            /* ts-lint ignore next */
            ngModule: RootPhotoDialogModule,
        };
    };
    return PhotoDialogModule;
}());
exports.PhotoDialogModule = PhotoDialogModule;
var RootPhotoDialogModule = /** @class */ (function () {
    function RootPhotoDialogModule() {
    }
    return RootPhotoDialogModule;
}());
exports.RootPhotoDialogModule = RootPhotoDialogModule;
