"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var GalleryPreviewPlaceholderComponent = /** @class */ (function () {
    function GalleryPreviewPlaceholderComponent() {
        this.count = 0;
        this.minimum = 0;
        this.showBadge = true;
    }
    GalleryPreviewPlaceholderComponent.prototype.ngOnInit = function () { };
    GalleryPreviewPlaceholderComponent.prototype.getBadgeText = function (count, minimum) {
        if (!minimum) {
            return count.toString();
        }
        else {
            return count + "/" + minimum;
        }
    };
    return GalleryPreviewPlaceholderComponent;
}());
exports.GalleryPreviewPlaceholderComponent = GalleryPreviewPlaceholderComponent;
