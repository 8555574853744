"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var app_actions_1 = require("./app.actions");
var environment_1 = require("@env/environment");
var ngrx_store_localstorage_1 = require("ngrx-store-localstorage");
var ngrx_store_freeze_1 = require("ngrx-store-freeze");
function localStorageSyncReducer(reducer) {
    return ngrx_store_localstorage_1.localStorageSync({
        keys: [
            'user',
            'pageSettings',
            'estates',
            'workOrders',
            'dashboard',
            'residents',
            'investors',
            'permissions',
            'roles',
        ],
        rehydrate: true,
    })(reducer);
}
exports.localStorageSyncReducer = localStorageSyncReducer;
exports.initialState = {
    id: null,
};
function appReducer(state, action) {
    if (state === void 0) { state = exports.initialState; }
    switch (action.type) {
        case app_actions_1.AppActionTypes.AppAction:
            return state;
        case app_actions_1.AppActionTypes.AppLoaded: {
            return __assign({}, state, action.payload);
        }
        default:
            return state;
    }
}
exports.appReducer = appReducer;
function logger(reducer) {
    return function (state, action) {
        //         console.log('state', state);
        //         console.log('action', action);
        return reducer(state, action);
    };
}
exports.logger = logger;
function getMetaReducers(production) {
    return !production
        ? [logger, ngrx_store_freeze_1.storeFreeze, localStorageSyncReducer]
        : [localStorageSyncReducer];
}
exports.getMetaReducers = getMetaReducers;
exports.metaReducers = getMetaReducers(environment_1.environment.production);
