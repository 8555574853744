"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
/*
*** Based on https://github.com/digitalascetic/ngx-pica
*** remade module here to fix issue with circular logic
*/
var PicaModule = /** @class */ (function () {
    function PicaModule() {
    }
    /* istanbul ignore next */
    PicaModule.forRoot = function () {
        return {
            /* ts-lint ignore next */
            ngModule: RootPicaModule,
        };
    };
    return PicaModule;
}());
exports.PicaModule = PicaModule;
var RootPicaModule = /** @class */ (function () {
    function RootPicaModule() {
    }
    return RootPicaModule;
}());
exports.RootPicaModule = RootPicaModule;
