<mat-card
    *ngIf="showBadge; else: badgeless"
    class="flex-container preview-gallery-placeholder"
    matBadge="{{getBadgeText(count, minimum)}}"
    matBadgePosition="below"
    matBadgeOverlap="true"
    matBadgeSize="large"
    matBadgeColor="{{ !count || count < minimum ? 'warn' : 'primary' }}"
>
    <ng-container *ngTemplateOutlet="imageContent"></ng-container>
</mat-card>

<ng-template #badgeless
    ><mat-card class="flex-container preview-gallery-placeholder">
        <ng-container *ngTemplateOutlet="imageContent"></ng-container>
    </mat-card>
</ng-template>

<ng-template #imageContent>
    <img #imageContainer src="{{sanitize(image)}}" />
    <span>{{ text | uppercase }}</span>
</ng-template>
