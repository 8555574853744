"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var effects_1 = require("@ngrx/effects");
var actions_1 = require("@fixiti/actions");
var operators_1 = require("rxjs/operators");
var actions_2 = require("@fixiti/actions");
var store_1 = require("@ngrx/store");
var SettingsEffects = /** @class */ (function () {
    function SettingsEffects(actions$, store) {
        var _this = this;
        this.actions$ = actions$;
        this.store = store;
        this.loadJobsEffect$ = this.actions$.pipe(effects_1.ofType(actions_1.SettingsActionTypes.LoadSettings), operators_1.map(function (action) { return action.payload.configuration; }), operators_1.map(function (configuration) { return configuration.resourceId; }), operators_1.distinctUntilChanged(), operators_1.filter(function (id) { return id != null; }), operators_1.map(function (id) { return new actions_2.LoadJobs({ id: id }); }));
        this.loadProviderSettingsEffect$ = this.actions$.pipe(effects_1.ofType(actions_1.SettingsActionTypes.LoadSettings), operators_1.map(function (action) { return action.payload.configuration; }), operators_1.tap(function (configuration) {
            return _this.store.dispatch(new actions_1.LoadUser({
                apiToken: configuration.apiKey,
                membershipId: configuration.membershipId,
                resourceId: configuration.resourceId,
            }));
        }), operators_1.map(function (configuration) { return configuration.resourceId; }), operators_1.distinctUntilChanged(), operators_1.filter(function (id) { return id != null; }), operators_1.map(function () { return new actions_1.LoadProviderSettings(); }));
    }
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], SettingsEffects.prototype, "loadJobsEffect$", void 0);
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], SettingsEffects.prototype, "loadProviderSettingsEffect$", void 0);
    return SettingsEffects;
}());
exports.SettingsEffects = SettingsEffects;
