import { Action } from '@ngrx/store';

export enum SearchActionTypes {
    ChangeSearch = '[Search] Change Search',
}

export class ChangeSearch implements Action {
    readonly type = SearchActionTypes.ChangeSearch;
    constructor(public payload: string) {}
}

export type SearchAction = ChangeSearch;

export const fromSearchActions = {
    ChangeSearch,
};
