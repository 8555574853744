import { Actions, Effect } from '@ngrx/effects';
import {
    AddUserToRole,
    CreateRole,
    LoadPermissionForRole,
    LoadRoles,
    LoadUsersForRole,
    PermissionForRoleLoaded,
    RemoveRole,
    RemoveUserFromRole,
    RoleCreated,
    RoleRemoved,
    RoleUpdated,
    RolesActionTypes,
    RolesLoadError,
    RolesLoaded,
    UpdateRole,
    UserAddedToRole,
    UserRemovedFromRole,
    UsersForRoleLoaded,
} from '@fixiti/actions';

import { DataPersistence } from '@nrwl/nx';
import { Injectable } from '@angular/core';
import { PmpRestApiService } from '@fixiti/api/rest/pmp/src';
import { RolesState } from './roles.reducer';
import { map } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable()
export class RolesEffects {
    @Effect()
    loadRoles$ = this.dataPersistence.fetch(RolesActionTypes.LoadRoles, {
        run: () =>
            this.dataService
                .getRoles()
                .pipe(map(roles => new RolesLoaded(roles))),

        onError: (_a, error) => {
            return new RolesLoadError(error);
        },
    });
    @Effect()
    updateRole$ = this.dataPersistence.fetch(RolesActionTypes.UpdateRole, {
        run: (action: UpdateRole) => {
            return this.dataService
                .updateRole(action.payload.id, action.payload.name)
                .pipe(map(role => new RoleUpdated(role)));
        },

        onError: (_a, error) => ({
            type: 'ERROR',
            error,
        }),
    });
    @Effect()
    addUserToRole$ = this.dataPersistence.fetch(
        RolesActionTypes.AddUserToRole,
        {
            run: (action: AddUserToRole) => {
                return this.dataService
                    .addRoleToUser(
                        action.payload.roleId,
                        action.payload.managerId
                    )
                    .pipe(
                        map(
                            result =>
                                new UserAddedToRole({
                                    roleId: action.payload.roleId,
                                    user: result,
                                })
                        )
                    );
            },

            onError: (_a, error) => ({
                type: 'ERROR',
                error,
            }),
        }
    );
    @Effect()
    removeUserFromRole$ = this.dataPersistence.fetch(
        RolesActionTypes.RemoveUserFromRole,
        {
            run: (action: RemoveUserFromRole) => {
                return this.dataService
                    .removeRoleFroUser(
                        action.payload.roleId,
                        action.payload.managerId
                    )
                    .pipe(map(() => new UserRemovedFromRole(action.payload)));
            },

            onError: (_a, error) => ({
                type: 'ERROR',
                error,
            }),
        }
    );

    @Effect()
    loadPermissionsForRole$ = this.dataPersistence.fetch(
        RolesActionTypes.LoadPermissionForRole,
        {
            run: (action: LoadPermissionForRole) =>
                this.dataService
                    .getPermissionForRole(
                        action.payload.roleId,
                        action.payload.permissionId
                    )
                    .pipe(
                        map(
                            permission =>
                                new PermissionForRoleLoaded(permission)
                        )
                    ),

            onError: (_a, error) => ({
                type: 'ERROR',
                error,
            }),
        }
    );

    @Effect()
    loadUsersForRole$ = this.dataPersistence.fetch(
        RolesActionTypes.LoadUsersForRole,
        {
            run: (action: LoadUsersForRole) =>
                this.dataService
                    .getUsersWithRole(action.payload)
                    .pipe(map(users => new UsersForRoleLoaded(users))),

            onError: (_a, error) => ({
                type: 'ERROR',
                error,
            }),
        }
    );

    constructor(
        private actions$: Actions,
        private dataPersistence: DataPersistence<RolesState>,
        private dataService: PmpRestApiService
    ) {}
}
