/// <reference path="../../../../models/src/lib/api/challenge.ts" />

import { Action } from '@ngrx/store';

export enum ChallengesActionTypes {
    CheckChallenge = '[Challenges] Check Challenge',
    ResolveChallenge = '[Challenges] Resolve Challenge',
    ResolveChallengeError = '[Challenges] Resolve Challenge Error',
    ChallengeResolved = '[Challenges] Challenge Resolved',
    SaveChallenge = '[Challenges] Save Data',
    SaveChallengeError = '[Challenges] Save Data Error',
    ChallengeSaved = '[Challenges] Data Saved',
    UpdateChallengeGallery = '[Challenges] = Update Challenge Gallery',
    UpdateChallengeSubject = '[Challenges] = Update Challenge Subject',
    UpdateChallengeDescription = '[Challenges] = Update Challenge Description',
}
export class CheckChallenge implements Action {
    readonly type = ChallengesActionTypes.CheckChallenge;
    constructor(
        public payload: {
            challengeId: number;
        }
    ) {}
}

export class ResolveChallenge implements Action {
    readonly type = ChallengesActionTypes.ResolveChallenge;
    constructor(
        public payload: {
            challengeId: number;
            message?: string;
        }
    ) {}
}

export class ResolveChallengeError implements Action {
    readonly type = ChallengesActionTypes.ResolveChallengeError;
    constructor(public error: any) {}
}

export class ChallengeResolved implements Action {
    readonly type = ChallengesActionTypes.ChallengeResolved;
    constructor(
        public payload: {
            challenge: ApiModel.SubmitBlocker;
        }
    ) {}
}

export class SaveChallenge implements Action {
    readonly type = ChallengesActionTypes.SaveChallenge;
    constructor(
        public payload: {
            challenge: ApiModel.SubmitChallenge;
        }
    ) {}
}

export class SaveChallengeError implements Action {
    readonly type = ChallengesActionTypes.SaveChallengeError;
    constructor(
        public payload: {
            error: any;
        }
    ) {}
}

export class ChallengeSaved implements Action {
    readonly type = ChallengesActionTypes.ChallengeSaved;
    constructor(
        public payload: {
            challenge: ApiModel.SubmitBlocker;
        }
    ) {}
}

export class UpdateChallengeDescription implements Action {
    readonly type = ChallengesActionTypes.UpdateChallengeDescription;
    constructor(
        public payload: {
            description: string;
        }
    ) {}
}

export class UpdateChallengeGallery implements Action {
    readonly type = ChallengesActionTypes.UpdateChallengeGallery;
    constructor(
        public payload: {
            gallery: { id: number; url: string }[];
        }
    ) {}
}

export class UpdateChallengeSubject implements Action {
    readonly type = ChallengesActionTypes.UpdateChallengeSubject;
    constructor(
        public payload: {
            subject: string;
        }
    ) {}
}

export type ChallengesActions =
    | CheckChallenge
    | ResolveChallenge
    | ResolveChallengeError
    | ChallengeResolved
    | SaveChallenge
    | SaveChallengeError
    | ChallengeSaved
    | UpdateChallengeDescription
    | UpdateChallengeGallery
    | UpdateChallengeSubject;
