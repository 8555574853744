"use strict";
/// <reference path="./api/gallery-image.ts" />
Object.defineProperty(exports, "__esModule", { value: true });
var WorkOrderModel = /** @class */ (function () {
    function WorkOrderModel() {
        this.gallery = [];
        this.frontElevation = [];
    }
    return WorkOrderModel;
}());
exports.WorkOrderModel = WorkOrderModel;
