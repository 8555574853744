/// <reference path="../../../../../models/src/lib/api/permission.ts" />

import {
    PermissionsAction,
    PermissionsActionTypes,
    RolesActionTypes,
    SelectRole,
} from '@fixiti/actions';

export interface PermissionsState {
    list: ApiModel.Permission[]; // list of Permissions; analogous to a sql normalized table
    selectedId?: string | number; // which Permissions record has been selected
    loaded: boolean; // has the Permissions list been loaded
    error?: any; // last none error (if any)
    pendingChanges: {
        roleId: number;
        permissionId: number;
        read: boolean;
        write: boolean;
    }[];
}

export const permissionsInitialState: PermissionsState = {
    list: [],
    loaded: false,
    pendingChanges: [],
};

export function permissionsReducer(
    state: PermissionsState = permissionsInitialState,
    action: PermissionsAction | SelectRole
): PermissionsState {
    switch (action.type) {
        case PermissionsActionTypes.PermissionsLoaded: {
            state = {
                ...state,
                list: action.payload,
                loaded: true,
            };
            break;
        }
        case PermissionsActionTypes.PermissionsSaved: {
            state = {
                ...state,
                pendingChanges: [],
            };
            break;
        }
        case PermissionsActionTypes.ChangePermission: {
            const index = state.pendingChanges.findIndex(
                item =>
                    item.roleId === action.payload.roleId &&
                    item.permissionId === action.payload.roleId
            );

            if (index >= 0) {
                state = {
                    ...state,
                    pendingChanges: [
                        ...state.pendingChanges.slice(0, index),
                        action.payload,
                        ...state.pendingChanges.slice(index + 1),
                    ],
                };
            } else {
                state = {
                    ...state,
                    pendingChanges: [...state.pendingChanges, action.payload],
                };
            }

            break;
        }
        case PermissionsActionTypes.DiscardPermissionChange: {
            const index = state.pendingChanges.findIndex(
                item =>
                    item.roleId === action.payload.roleId &&
                    item.permissionId === action.payload.roleId
            );

            if (index >= 0) {
                state = {
                    ...state,
                    pendingChanges: [
                        ...state.pendingChanges.slice(0, index),
                        ...state.pendingChanges.slice(index + 1),
                    ],
                };
            }

            break;
        }

        case PermissionsActionTypes.PermissionsUpdated: {
            state = {
                ...state,
                pendingChanges: [],
            };
            break;
        }

        case RolesActionTypes.SelectRole: {
            state = {
                ...state,
                list: [],
                loaded: false,
            };
            break;
        }
    }
    return state;
}
