import { Actions, Effect, ofType } from '@ngrx/effects';
import { AppActionTypes, AppLoaded, LoadApp } from './app.actions';

import { AppState } from './app.reducer';
import { DataPersistence } from '@nrwl/nx';
import { Injectable } from '@angular/core';

@Injectable()
export class AppEffects {
    @Effect()
    effect$ = this.actions$.pipe(ofType(AppActionTypes.AppAction));

    @Effect()
    loadApp$ = this.dataPersistence.fetch(AppActionTypes.LoadApp, {
        run: (action: LoadApp, state: AppState) => {
            return new AppLoaded(state);
        },
    });

    constructor(
        private actions$: Actions,
        private dataPersistence: DataPersistence<AppState>
    ) {}
}
