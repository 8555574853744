import { Component, Input, OnInit } from '@angular/core';
import { Observable, interval } from 'rxjs';

import { map } from 'rxjs/operators';

@Component({
    selector: 'fixiti-photo-dialog-upload-images-progress',
    templateUrl: './upload-images-progress.component.html',
    styleUrls: ['./upload-images-progress.component.css'],
})
export class UploadImagesProgressComponent implements OnInit {
    @Input()
    progress = 0;
    @Input()
    isVisible = false;
    @Input()
    startDate = new Date();
    endTime: number;
    minutes = 0;
    seconds = 0;
    countDownObs: Observable<string>;

    constructor() {}

    ngOnInit() {
        this.setCountdownTimer();
    }

    setCountdownTimer() {
        const endDate = new Date(this.startDate);
        endDate.setMinutes(endDate.getMinutes() + 5);
        this.endTime = endDate.getTime();
        this.countDownObs = interval(250).pipe(
            map(() => this.calculate(new Date().getTime()))
        );
    }

    calculate(now: number) {
        let timeRemaining = (this.endTime - now) / 1000;

        if (timeRemaining >= 0) {
            this.minutes = Math.floor(timeRemaining / 60);
            timeRemaining = timeRemaining % 60;

            this.seconds = Math.floor(timeRemaining);
        } else {
            this.minutes = 0;
            this.seconds = 0;
        }
        return this.minutes || this.seconds
            ? `${this.minutes}:${this.seconds}`
            : '';
    }
}
