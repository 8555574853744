import { CommonModule } from '@angular/common';
import { GalleryPreviewButtonComponent } from './gallery-preview-button/gallery-preview-button.component';
import { MatButtonModule } from '@angular/material/button';
import { NgModule } from '@angular/core';
import { SharedGalleryPreviewPresenterModule } from '@fixiti/shared/gallery-preview/presenter/src';

@NgModule({
    imports: [
        CommonModule,
        MatButtonModule,
        SharedGalleryPreviewPresenterModule,
    ],
    exports: [GalleryPreviewButtonComponent],
    declarations: [GalleryPreviewButtonComponent],
})
export class SharedGalleryPreviewButtonModule {}
