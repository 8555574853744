import { CommonModule } from '@angular/common';
import { GalleryPreviewImageComponent } from './gallery-preview-image/gallery-preview-image.component';
import { MatBadgeModule } from '@angular/material/badge';
import { MatCardModule } from '@angular/material/card';
import { NgModule } from '@angular/core';

@NgModule({
    imports: [CommonModule, MatCardModule, MatBadgeModule],
    exports: [GalleryPreviewImageComponent],
    declarations: [GalleryPreviewImageComponent],
})
export class SharedGalleryPreviewImageModule {}
