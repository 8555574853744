import { Action } from '@ngrx/store';

export enum PageSettingsActionTypes {
    ChangePage = '[PageSettings] Change Page',
    ChangePageDisplay = '[PageSettings] Change Page Display',
    ChangePageFilter = '[PageSettings] Change Page Filter',
    ChangePageIndex = '[PageSettings] Change Page Index',
    ChangePageSize = '[PageSettings] Change Page Size',
    ChangePageLength = '[PageSettings] Change Page Length',
    UpdatePageLength = '[PageSettings] Update Page Length',
    ChangePageSort = '[PageSettings] Change Page Sort',
    ChangePageSortDirection = '[PageSettings] Change Page Sort Direction',
    ChangePageSortField = '[PageSettings] Change Page Sort Field',
    ChangePageReportId = '[PageSettings] Change Page Report Id',
    HydratePage = '[PageSettings] Hydrate Page',
    UpdatePageUrl = '[PageSettings] Update Url',
    PageUrlUpdated = '[PageSettings] Page Url Updated',
}

export class ChangePage implements Action {
    readonly type = PageSettingsActionTypes.ChangePage;
    constructor(public payload: string) {}
}
export class ChangePageDisplay implements Action {
    readonly type = PageSettingsActionTypes.ChangePageDisplay;
    constructor(public payload: 'list' | 'table' | 'card') {}
}
export class ChangePageFilter implements Action {
    readonly type = PageSettingsActionTypes.ChangePageFilter;
    constructor(public payload: string) {}
}
export class ChangePageIndex implements Action {
    readonly type = PageSettingsActionTypes.ChangePageIndex;
    constructor(public payload: number) {}
}
export class ChangePageSize implements Action {
    readonly type = PageSettingsActionTypes.ChangePageSize;
    constructor(public payload: number) {}
}
export class UpdatePageLength implements Action {
    readonly type = PageSettingsActionTypes.UpdatePageLength;
    constructor(
        public payload: {
            key: string;
            length: number;
        }
    ) {}
}
export class ChangePageLength implements Action {
    readonly type = PageSettingsActionTypes.ChangePageLength;
    constructor(public payload: number) {}
}
export class ChangePageSort implements Action {
    readonly type = PageSettingsActionTypes.ChangePageSort;
    constructor(
        public payload: {
            direction: string;
            field: string;
        }
    ) {}
}
export class ChangePageSortDirection implements Action {
    readonly type = PageSettingsActionTypes.ChangePageSortDirection;
    constructor(public payload: string) {}
}
export class ChangePageSortField implements Action {
    readonly type = PageSettingsActionTypes.ChangePageSortField;
    constructor(public payload: string) {}
}
export class ChangePageReportId implements Action {
    readonly type = PageSettingsActionTypes.ChangePageReportId;
    constructor(
        public payload: {
            page?: string;
            reportId: number;
        }
    ) {}
}
export class HydratePage implements Action {
    readonly type = PageSettingsActionTypes.HydratePage;
    constructor(
        public payload: {
            display: string;
            filter: string;
            pageIndex: number;
            pageLength: number;
            pageSize: number;
            sortDirection: string;
            sortField: string;
            reportId: number;
        }
    ) {}
}

export class UpdatePageUrl implements Action {
    readonly type = PageSettingsActionTypes.UpdatePageUrl;
}

export class PageUrlUpdated implements Action {
    readonly type = PageSettingsActionTypes.PageUrlUpdated;
}

export type PageSettingsAction =
    | ChangePage
    | ChangePageDisplay
    | ChangePageFilter
    | ChangePageIndex
    | ChangePageSize
    | ChangePageLength
    | UpdatePageLength
    | ChangePageSort
    | ChangePageSortDirection
    | ChangePageSortField
    | ChangePageReportId
    | HydratePage
    | UpdatePageUrl
    | PageUrlUpdated;

export const fromPageSettingsActions = {
    ChangePage,
    ChangePageDisplay,
    ChangePageFilter,
    ChangePageIndex,
    ChangePageSize,
    ChangePageLength,
    UpdatePageLength,
    ChangePageSort,
    ChangePageSortDirection,
    ChangePageSortField,
    ChangePageReportId,
    HydratePage,
    UpdatePageUrl,
    PageUrlUpdated,
};
