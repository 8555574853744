"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./upload-images-gallery.component.css.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../../../../../shared/gallery-preview/button/src/lib/gallery-preview-button/gallery-preview-button.component.ngfactory");
var i3 = require("../../../../../../shared/gallery-preview/button/src/lib/gallery-preview-button/gallery-preview-button.component");
var i4 = require("@angular/common");
var i5 = require("./upload-images-gallery.component");
var i6 = require("@ngrx/store");
var i7 = require("../../../../../../external/pica/src/lib/pica.service");
var styles_UploadImagesGalleryComponent = [i0.styles];
var RenderType_UploadImagesGalleryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UploadImagesGalleryComponent, data: {} });
exports.RenderType_UploadImagesGalleryComponent = RenderType_UploadImagesGalleryComponent;
function View_UploadImagesGalleryComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "flex-container vertical picture"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "label", [["class", "mat-file-input button mat-raised-button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "fixiti-gallery-preview-button", [], [[2, "halo", null]], [[null, "clickChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickChange" === en)) {
        var pd_0 = (_co.selectFile(i1.ɵnov(_v, 8)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_GalleryPreviewButtonComponent_0, i2.RenderType_GalleryPreviewButtonComponent)), i1.ɵdid(4, 114688, null, 0, i3.GalleryPreviewButtonComponent, [], { image: [0, "image"], gallery: [1, "gallery"], showBadge: [2, "showBadge"] }, { clickChange: "clickChange" }), (_l()(), i1.ɵeld(5, 0, null, null, 2, "span", [["class", "fill center"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", " #", ""])), i1.ɵppd(7, 1), (_l()(), i1.ɵeld(8, 0, [["imageSelector", 1]], null, 0, "input", [["accept", "image/jpg,image/JPG,image/jpeg,image/JPEG"], ["capture", "camera"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onChange($event, _v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.images && _co.images[_v.context.index]) || null); var currVal_2 = (_co.images || i1.ɵEMPTY_ARRAY); var currVal_3 = false; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.images && (_co.images.length === _v.context.index)); _ck(_v, 3, 0, currVal_0); var currVal_4 = i1.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i1.ɵnov(_v.parent, 0), _co.text)); var currVal_5 = _v.context.$implicit; _ck(_v, 6, 0, currVal_4, currVal_5); }); }
function View_UploadImagesGalleryComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.TitleCasePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "flex-container vertical"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadImagesGalleryComponent_1)), i1.ɵdid(3, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 7, "div", [["class", "flex-container vertical picture"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 6, "label", [["class", "mat-file-input button mat-raised-button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "fixiti-gallery-preview-button", [], [[2, "halo", null]], [[null, "clickChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickChange" === en)) {
        var pd_0 = (_co.selectFile(i1.ɵnov(_v, 11)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_GalleryPreviewButtonComponent_0, i2.RenderType_GalleryPreviewButtonComponent)), i1.ɵdid(7, 114688, null, 0, i3.GalleryPreviewButtonComponent, [], { image: [0, "image"], gallery: [1, "gallery"], showBadge: [2, "showBadge"] }, { clickChange: "clickChange" }), (_l()(), i1.ɵeld(8, 0, null, null, 2, "span", [["class", "fill center"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", " ", ""])), i1.ɵppd(10, 1), (_l()(), i1.ɵeld(11, 0, [["secondImageSelector", 1]], null, 0, "input", [["accept", "image/jpg,image/JPG,image/jpeg,image/JPEG"], ["capture", "camera"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onChange($event, _co.getIndex(_co.images.length)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.minimumArray; _ck(_v, 3, 0, currVal_0); var currVal_2 = (((_co.images && (_co.images.length >= _co.minimum)) && _co.images[(_co.images.length - 1)].url) || null); var currVal_3 = (_co.images || i1.ɵEMPTY_ARRAY); var currVal_4 = false; _ck(_v, 7, 0, currVal_2, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.images && (_co.images.length === (_co.minimum - 1))); _ck(_v, 6, 0, currVal_1); var currVal_5 = i1.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i1.ɵnov(_v, 0), _co.text)); var currVal_6 = _co.getLastString(((_co.images && _co.images.length) || 0)); _ck(_v, 9, 0, currVal_5, currVal_6); }); }
exports.View_UploadImagesGalleryComponent_0 = View_UploadImagesGalleryComponent_0;
function View_UploadImagesGalleryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fixiti-photo-dialog-upload-images-gallery", [], null, null, null, View_UploadImagesGalleryComponent_0, RenderType_UploadImagesGalleryComponent)), i1.ɵdid(1, 114688, null, 0, i5.UploadImagesGalleryComponent, [i6.Store, i7.PicaService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_UploadImagesGalleryComponent_Host_0 = View_UploadImagesGalleryComponent_Host_0;
var UploadImagesGalleryComponentNgFactory = i1.ɵccf("fixiti-photo-dialog-upload-images-gallery", i5.UploadImagesGalleryComponent, View_UploadImagesGalleryComponent_Host_0, { gallery: "gallery", images: "images", canDelete: "canDelete", conf: "conf", text: "text", minimum: "minimum" }, {}, []);
exports.UploadImagesGalleryComponentNgFactory = UploadImagesGalleryComponentNgFactory;
