"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var SpreadSheetDataSource = /** @class */ (function () {
    function SpreadSheetDataSource(remote) {
        this.remote = remote;
        this.dataSubject = new rxjs_1.BehaviorSubject([]);
        this.loadingSubject = new rxjs_1.BehaviorSubject(false);
        this.loadingObservable = this.loadingSubject.asObservable();
    }
    SpreadSheetDataSource.prototype.connect = function (collectionViewer) {
        return this.dataSubject.asObservable();
    };
    SpreadSheetDataSource.prototype.disconnect = function (collectionViewer) {
        this.dataSubject.complete();
        this.loadingSubject.complete();
    };
    SpreadSheetDataSource.prototype.loadData = function (filter, sortDirection, pageIndex, pageSize) {
        var _this = this;
        if (filter === void 0) { filter = null; }
        if (sortDirection === void 0) { sortDirection = 'desc'; }
        if (pageIndex === void 0) { pageIndex = 1; }
        if (pageSize === void 0) { pageSize = 25; }
        this.loadingSubject.next(true);
        var observable = this.remote
            .search({
            filter: filter,
            sortDirection: sortDirection,
            pageIndex: pageIndex,
            pageSize: pageSize,
        })
            .pipe(operators_1.catchError(function () { return rxjs_1.of([]); }), operators_1.finalize(function () { return _this.loadingSubject.next(false); }));
        var subscription = observable.subscribe(function (data) {
            _this.dataSubject.next(data);
        });
        return { observable: observable, subscription: subscription };
    };
    return SpreadSheetDataSource;
}());
exports.SpreadSheetDataSource = SpreadSheetDataSource;
