export enum PermissionsEnum {
    properties = 1,
    jobs = 2,
    residents = 3,
    investors = 4,
    leases = 5,
    reports = 6,
    accounting = 7,
    users = 8,
    permissions = 9,
    unknown = 9999,
}
