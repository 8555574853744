import { ModuleWithProviders, NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { PicaExifService } from './pica-exif.service';
import { PicaService } from './pica.service';

/*
*** Based on https://github.com/digitalascetic/ngx-pica
*** remade module here to fix issue with circular logic
*/
@NgModule({
    imports: [CommonModule],
})
export class PicaModule {
    /* istanbul ignore next */
    static forRoot(): ModuleWithProviders {
        return {
            /* ts-lint ignore next */
            ngModule: RootPicaModule,
        };
    }
}

@NgModule({
    imports: [PicaModule],
    exports: [PicaModule],
    providers: [PicaExifService, PicaService],
})
export class RootPicaModule {}
