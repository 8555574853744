import { userInitialState, userReducer } from './+state/user.reducer';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('user', userReducer, {
            initialState: userInitialState,
        }),
    ],
})
export class StateUserModule {}
