/// <reference path="../../../../../../models/src/lib/api/gallery-image.ts" />
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject, from } from 'rxjs';
import { Store, select } from '@ngrx/store';
import {
    concatMap,
    distinctUntilChanged,
    filter,
    map,
    switchMap,
    take,
    takeUntil,
    toArray,
} from 'rxjs/operators';
import {
    selectAllImages,
    selectImageUploadProgress,
    selectIsImageUploading,
} from '@fixiti/state/files/src';
import {
    selectPhotoDialogConfig,
    selectPhotoDialogPlaceHolder,
} from '../../reducers/photo-dialog.reducer';

import { PicaService } from '@fixiti/external/pica/src';
import { SubmitImages } from '@fixiti/actions/src';

@Component({
    selector: 'fixiti-photo-dialog-upload-images',
    templateUrl: './upload-images.component.html',
    styleUrls: ['./upload-images.component.css'],
})
export class UploadImagesComponent implements OnInit, OnDestroy {
    ngUnsubscribe = new Subject<void>();
    gallery: Observable<any[]>;
    images: Observable<{ src?: File; url: string }[]>;
    progressObs = this.store.pipe(select(selectImageUploadProgress));
    isUploadingObs = this.store.pipe(select(selectIsImageUploading));
    placeholderObs = this.store.pipe(select(selectPhotoDialogPlaceHolder));
    minimumObs = this.store.pipe(
        select(selectPhotoDialogConfig),
        map(options => options.minimum)
    );
    @Input()
    dialogReference: MatDialogRef<any>;

    /* istanbul ignore next */
    constructor(
        private store: Store<any>,
        private dialog: MatDialog,
        private imageService: PicaService
    ) {}

    ngOnInit() {
        this.images = this.store.pipe(
            select(selectAllImages),
            filter(items => !!items),
            distinctUntilChanged(),
            switchMap(items => {
                return this.processImages(items);
            }),
            takeUntil(this.ngUnsubscribe)
        );
        this.gallery = this.store.pipe(
            select(selectPhotoDialogConfig),
            map(config => config.gallery)
        );
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    processImages(items: File[]) {
        return from(items).pipe(
            concatMap((file: File | ApiModel.GalleryImage) =>
                from(
                    new Promise<any>(resolve => {
                        if (file instanceof File) {
                            const fr = new FileReader();
                            fr.onloadend = (item: any) => {
                                resolve({
                                    url: `data:image/jpeg;base64,${btoa(
                                        item.target.result
                                    )}`,
                                    src: file,
                                });
                                this.loadSmallerThumbnail(file);
                            };
                            fr.readAsBinaryString(file);
                        } else {
                            resolve(file);
                        }
                    })
                )
            ),
            toArray()
        );
    }

    loadSmallerThumbnail(file: File) {
        return new Promise<{
            url: string;
            src: File;
        }>(resolve => {
            const fr = new FileReader();
            fr.onloadend = (item: any) => {
                resolve({
                    url: `data:image/jpeg;base64,${btoa(item.target.result)}`,
                    src: file,
                });
            };
            this.imageService
                .resizeImage(file, 512, 512, {
                    aspectRatio: {
                        keepAspectRatio: true,
                    },
                })
                .pipe(take(1))
                .subscribe(resizedFile => {
                    fr.readAsBinaryString(resizedFile);
                });
        });
    }

    async onCancel() {
        await this.close();
    }

    async onSubmit() {
        const images = await this.store
            .pipe(
                select(selectAllImages),
                map(storeImages =>
                    storeImages.filter(image => image instanceof File)
                ),
                take(1)
            )
            .toPromise();

        this.store.dispatch(new SubmitImages({ images }));
        await this.close();
    }

    async close() {
        if (this.dialogReference) {
            await this.closeUsingDialogReference();
        } else {
            this.dialog.closeAll();
        }
    }

    async closeUsingDialogReference() {
        const isUploading = await this.store
            .pipe(
                select(selectIsImageUploading),
                take(1)
            )
            .toPromise();
        this.dialogReference.close({
            isUploading,
        });
    }
}
