"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var actions_1 = require("@fixiti/actions");
exports.IMPORTER_FEATURE_KEY = 'importer';
exports.initialState = {
    files: [],
    showFilterMenu: false,
    selectedId: null,
    loaded: false,
    loadingImportFiles: false,
    error: null,
    apiSection: '',
    loadingImportMap: false,
    loadingImportMapError: null,
    selectedImportFileMapping: [],
    selectedImportFileMappingTos: [],
    savingImportMapping: false,
    importMappingSaved: null,
    importMappingSaveError: '',
    importMappingInputErrors: {},
    showDelete: null,
    deletingImportFile: false,
    importFileDeleted: false,
    importFileDeleteError: false,
    showCreateImportFileForm: false,
    creatingImportFile: false,
    importFileCreated: false,
    importFileCreateError: null,
    querySettings: {
        page: 1,
        direction: 'desc',
        sort: 'created',
        limit: 100,
        reportId: null,
    },
    paginationData: {
        numMatches: 0,
        numReturned: 0,
        start: 0,
        end: 0,
        isFirstPage: true,
        isLastPage: true,
        maxPage: 1,
        currentPage: 1,
        nextPage: 1,
        previousPage: 1,
    },
    pageDisplay: 'card',
};
function importerReducer(state, action) {
    if (state === void 0) { state = exports.initialState; }
    var _a;
    switch (action.type) {
        case actions_1.ImporterActionTypes.ImportLoadFiles: {
            state = __assign({}, state, { apiSection: action.apiSection, loadingImportFiles: true });
            break;
        }
        case actions_1.ImporterActionTypes.ImportOpenFilterMenu: {
            state = __assign({}, state, { showFilterMenu: true });
            break;
        }
        case actions_1.ImporterActionTypes.ImportCloseFilterMenu: {
            state = __assign({}, state, { showFilterMenu: false });
            break;
        }
        case actions_1.ImporterActionTypes.ImportFilesLoaded: {
            state = __assign({}, state, { files: action.payload, loadingImportFiles: false, loaded: true });
            break;
        }
        case actions_1.ImporterActionTypes.ImportFilesLoadError: {
            state = __assign({}, state, { error: action.payload, loadingImportFiles: false });
            break;
        }
        case actions_1.ImporterActionTypes.ImportSelectFile: {
            state = __assign({}, state, { selectedId: action.payload, loadingImportMap: true, importMappingInputErrors: {} });
            break;
        }
        case actions_1.ImporterActionTypes.ImportDeSelectFile: {
            state = __assign({}, state, { selectedId: null, loadingImportMap: false });
            break;
        }
        case actions_1.ImporterActionTypes.ImportMappingLoadError: {
            state = __assign({}, state, { loadingImportMapError: action.payload, loadingImportMap: false });
            break;
        }
        case actions_1.ImporterActionTypes.ImportMappingLoaded: {
            state = __assign({}, state, { selectedImportFileMapping: action.payload.columns, selectedImportFileMappingTos: action.payload.tos, loadingImportMap: false });
            break;
        }
        case actions_1.ImporterActionTypes.ImportSaveMapping: {
            state = __assign({}, state, { savingImportMapping: true, importMappingSaved: false, importMappingSaveError: '' });
            break;
        }
        case actions_1.ImporterActionTypes.ImportMappingSaved: {
            state = __assign({}, state, { savingImportMapping: false, importMappingSaved: true, importMappingSaveError: false, selectedImportFileMapping: action.payload.columns, selectedImportFileMappingTos: action.payload.tos, importMappingInputErrors: {} });
            break;
        }
        case actions_1.ImporterActionTypes.ImportMappingSaveError: {
            state = __assign({}, state, { savingImportMapping: false, importMappingSaved: false, importMappingSaveError: action.payload });
            break;
        }
        case actions_1.ImporterActionTypes.ImportMappingInputErrors: {
            state = __assign({}, state, { savingImportMapping: false, importMappingSaved: false, importMappingInputErrors: action.errors });
            break;
        }
        case actions_1.ImporterActionTypes.ImportDeleteFile: {
            state = __assign({}, state, { deletingImportFile: true, importFileDeleted: false, importFileDeleteError: '' });
            break;
        }
        case actions_1.ImporterActionTypes.ImportFileDeleted: {
            state = __assign({}, state, { deletingImportFile: false, importFileDeleted: true, importFileDeleteError: '', showDelete: null });
            break;
        }
        case actions_1.ImporterActionTypes.ImportHideDelete: {
            state = __assign({}, state, { showDelete: null });
            break;
        }
        case actions_1.ImporterActionTypes.ImportShowDelete: {
            state = __assign({}, state, { showDelete: action.payload });
            break;
        }
        case actions_1.ImporterActionTypes.ImportFileDeleteError: {
            state = __assign({}, state, { deletingImportFile: false, importFileDeleted: false, importFileDeleteError: action.payload });
            break;
        }
        case actions_1.ImporterActionTypes.ImportShowCreateForm: {
            state = __assign({}, state, { showCreateImportFileForm: true });
            break;
        }
        case actions_1.ImporterActionTypes.ImportHideCreateForm: {
            state = __assign({}, state, { showCreateImportFileForm: false });
            break;
        }
        case actions_1.ImporterActionTypes.ImportCreateFile: {
            state = __assign({}, state, { creatingImportFile: true, importFileCreated: false, importFileCreateError: '' });
            break;
        }
        case actions_1.ImporterActionTypes.ImportFileCreated: {
            state = __assign({}, state, { creatingImportFile: false, importFileCreated: true, importFileCreateError: '', showCreateImportFileForm: false });
            break;
        }
        case actions_1.ImporterActionTypes.ImportFileCreateError: {
            state = __assign({}, state, { creatingImportFile: false, importFileCreated: false, importFileCreateError: action.payload });
            break;
        }
        case actions_1.ImporterActionTypes.ImportSetQuerySetting: {
            state = __assign({}, state, { querySettings: __assign({}, state.querySettings, (_a = {}, _a[action.name] = action.value, _a)) });
            break;
        }
        case actions_1.ImporterActionTypes.ImportSetPageDisplay: {
            state = __assign({}, state, { pageDisplay: action.pageDisplay });
            break;
        }
        case actions_1.ImporterActionTypes.ImportSetPaginationData: {
            state = __assign({}, state, { paginationData: action.paginationData });
            break;
        }
    }
    return state;
}
exports.importerReducer = importerReducer;
