import { Action } from '@ngrx/store';

export enum SlugActionTypes {
    LoadSlug = '[Slug] Load Slug',
    DecodeSlug = '[Slug] Decode Slug',
}
export class DecodeSlug implements Action {
    readonly type = SlugActionTypes.DecodeSlug;

    constructor(public payload: { slug: string }) {}
}

export class LoadSlug implements Action {
    readonly type = SlugActionTypes.LoadSlug;
    constructor(public payload: { slug: string }) {}
}

export type SlugActions = LoadSlug | DecodeSlug;
