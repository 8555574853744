"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var InvestorsActionTypes;
(function (InvestorsActionTypes) {
    InvestorsActionTypes["LoadInvestors"] = "[Investors] Load Investors";
    InvestorsActionTypes["InvestorsLoaded"] = "[Investors] Investors Loaded";
    InvestorsActionTypes["InvestorsLoadError"] = "[Investors] Investors Load Error";
    InvestorsActionTypes["SaveInvestor"] = "[Investors] Save Investor";
    InvestorsActionTypes["InvestorSaved"] = "[Investors] Investor Saved";
    InvestorsActionTypes["RemoveInvestor"] = "[Investors] Remove Investor";
    InvestorsActionTypes["InvestorRemoved"] = "[Investors] Investor Removed";
    InvestorsActionTypes["SelectInvestor"] = "[Investors] Select Investor";
    InvestorsActionTypes["EditInvestor"] = "[Investors] Edit Investor";
})(InvestorsActionTypes = exports.InvestorsActionTypes || (exports.InvestorsActionTypes = {}));
var LoadInvestors = /** @class */ (function () {
    function LoadInvestors(payload) {
        this.payload = payload;
        this.type = InvestorsActionTypes.LoadInvestors;
    }
    return LoadInvestors;
}());
exports.LoadInvestors = LoadInvestors;
var InvestorsLoadError = /** @class */ (function () {
    function InvestorsLoadError(payload) {
        this.payload = payload;
        this.type = InvestorsActionTypes.InvestorsLoadError;
    }
    return InvestorsLoadError;
}());
exports.InvestorsLoadError = InvestorsLoadError;
var InvestorsLoaded = /** @class */ (function () {
    function InvestorsLoaded(payload) {
        this.payload = payload;
        this.type = InvestorsActionTypes.InvestorsLoaded;
    }
    return InvestorsLoaded;
}());
exports.InvestorsLoaded = InvestorsLoaded;
var SaveInvestor = /** @class */ (function () {
    function SaveInvestor(payload) {
        this.payload = payload;
        this.type = InvestorsActionTypes.SaveInvestor;
    }
    return SaveInvestor;
}());
exports.SaveInvestor = SaveInvestor;
var InvestorSaved = /** @class */ (function () {
    function InvestorSaved(payload) {
        this.payload = payload;
        this.type = InvestorsActionTypes.InvestorSaved;
    }
    return InvestorSaved;
}());
exports.InvestorSaved = InvestorSaved;
var RemoveInvestor = /** @class */ (function () {
    function RemoveInvestor(payload) {
        this.payload = payload;
        this.type = InvestorsActionTypes.RemoveInvestor;
    }
    return RemoveInvestor;
}());
exports.RemoveInvestor = RemoveInvestor;
var InvestorRemoved = /** @class */ (function () {
    function InvestorRemoved(payload) {
        this.payload = payload;
        this.type = InvestorsActionTypes.InvestorRemoved;
    }
    return InvestorRemoved;
}());
exports.InvestorRemoved = InvestorRemoved;
var SelectInvestor = /** @class */ (function () {
    function SelectInvestor(payload) {
        this.payload = payload;
        this.type = InvestorsActionTypes.SelectInvestor;
    }
    return SelectInvestor;
}());
exports.SelectInvestor = SelectInvestor;
var EditInvestor = /** @class */ (function () {
    function EditInvestor(payload) {
        this.payload = payload;
        this.type = InvestorsActionTypes.EditInvestor;
    }
    return EditInvestor;
}());
exports.EditInvestor = EditInvestor;
exports.fromInvestorsActions = {
    LoadInvestors: LoadInvestors,
    InvestorsLoaded: InvestorsLoaded,
    InvestorsLoadError: InvestorsLoadError,
    SaveInvestor: SaveInvestor,
    InvestorSaved: InvestorSaved,
    RemoveInvestor: RemoveInvestor,
    InvestorRemoved: InvestorRemoved,
    SelectInvestor: SelectInvestor,
    EditInvestor: EditInvestor,
};
