import {
    ImportFileModel,
    ImportFileMapModel,
    ImportFileToModel,
    ListViewQueryModel,
    ListViewPaginationModel,
} from '@fixiti/models';
import { ImporterAction, ImporterActionTypes } from '@fixiti/actions';

export const IMPORTER_FEATURE_KEY = 'importer';

export interface ImporterState {
    files: ImportFileModel[];
    showFilterMenu: boolean;
    selectedId?: number;
    loaded: boolean;
    loadingImportFiles: boolean;
    error?: any;
    apiSection: string;
    loadingImportMap: boolean;
    loadingImportMapError?: any;
    selectedImportFileMapping: ImportFileMapModel[];
    selectedImportFileMappingTos: ImportFileToModel[];
    savingImportMapping: boolean;
    importMappingSaved: boolean;
    importMappingSaveError: any;
    importMappingInputErrors: any;
    showDelete: number;
    deletingImportFile: boolean;
    importFileDeleted: boolean;
    importFileDeleteError: any;
    showCreateImportFileForm: boolean;
    creatingImportFile: boolean;
    importFileCreated: boolean;
    importFileCreateError: any;
    querySettings: ListViewQueryModel;
    paginationData: ListViewPaginationModel;
    pageDisplay: string;
}

export interface ImporterPartialState {
    readonly [IMPORTER_FEATURE_KEY]: ImporterState;
}

export const initialState: ImporterState = {
    files: [],
    showFilterMenu: false,
    selectedId: null,
    loaded: false,
    loadingImportFiles: false,
    error: null,
    apiSection: '',
    loadingImportMap: false,
    loadingImportMapError: null,
    selectedImportFileMapping: [],
    selectedImportFileMappingTos: [],
    savingImportMapping: false,
    importMappingSaved: null,
    importMappingSaveError: '',
    importMappingInputErrors: {},
    showDelete: null,
    deletingImportFile: false,
    importFileDeleted: false,
    importFileDeleteError: false,
    showCreateImportFileForm: false,
    creatingImportFile: false,
    importFileCreated: false,
    importFileCreateError: null,
    querySettings: {
        page: 1,
        direction: 'desc',
        sort: 'created',
        limit: 100,
        reportId: null,
    },
    paginationData: {
        numMatches: 0,
        numReturned: 0,
        start: 0,
        end: 0,
        isFirstPage: true,
        isLastPage: true,
        maxPage: 1,
        currentPage: 1,
        nextPage: 1,
        previousPage: 1,
    },
    pageDisplay: 'card',
};

export function importerReducer(state: ImporterState = initialState, action: ImporterAction): ImporterState {
    switch (action.type) {
        case ImporterActionTypes.ImportLoadFiles: {
            state = {
                ...state,
                apiSection: action.apiSection,
                loadingImportFiles: true,
            };
            break;
        }
        case ImporterActionTypes.ImportOpenFilterMenu: {
            state = {
                ...state,
                showFilterMenu: true,
            };
            break;
        }
        case ImporterActionTypes.ImportCloseFilterMenu: {
            state = {
                ...state,
                showFilterMenu: false,
            };
            break;
        }
        case ImporterActionTypes.ImportFilesLoaded: {
            state = {
                ...state,
                files: action.payload,
                loadingImportFiles: false,
                loaded: true,
            };
            break;
        }
        case ImporterActionTypes.ImportFilesLoadError: {
            state = {
                ...state,
                error: action.payload,
                loadingImportFiles: false,
            };
            break;
        }
        case ImporterActionTypes.ImportSelectFile: {
            state = {
                ...state,
                selectedId: action.payload,
                loadingImportMap: true,
                importMappingInputErrors: {},
            };
            break;
        }
        case ImporterActionTypes.ImportDeSelectFile: {
            state = {
                ...state,
                selectedId: null,
                loadingImportMap: false,
            };
            break;
        }
        case ImporterActionTypes.ImportMappingLoadError: {
            state = {
                ...state,
                loadingImportMapError: action.payload,
                loadingImportMap: false,
            };
            break;
        }
        case ImporterActionTypes.ImportMappingLoaded: {
            state = {
                ...state,
                selectedImportFileMapping: action.payload.columns,
                selectedImportFileMappingTos: action.payload.tos,
                loadingImportMap: false,
            };
            break;
        }
        case ImporterActionTypes.ImportSaveMapping: {
            state = {
                ...state,
                savingImportMapping: true,
                importMappingSaved: false,
                importMappingSaveError: '',
            };
            break;
        }
        case ImporterActionTypes.ImportMappingSaved: {
            state = {
                ...state,
                savingImportMapping: false,
                importMappingSaved: true,
                importMappingSaveError: false,
                selectedImportFileMapping: action.payload.columns,
                selectedImportFileMappingTos: action.payload.tos,
                importMappingInputErrors: {},
            };
            break;
        }
        case ImporterActionTypes.ImportMappingSaveError: {
            state = {
                ...state,
                savingImportMapping: false,
                importMappingSaved: false,
                importMappingSaveError: action.payload,
            };
            break;
        }
        case ImporterActionTypes.ImportMappingInputErrors: {
            state = {
                ...state,
                savingImportMapping: false,
                importMappingSaved: false,
                importMappingInputErrors: action.errors,
            };
            break;
        }
        case ImporterActionTypes.ImportDeleteFile: {
            state = {
                ...state,
                deletingImportFile: true,
                importFileDeleted: false,
                importFileDeleteError: '',
            };
            break;
        }
        case ImporterActionTypes.ImportFileDeleted: {
            state = {
                ...state,
                deletingImportFile: false,
                importFileDeleted: true,
                importFileDeleteError: '',
                showDelete: null,
            };
            break;
        }
        case ImporterActionTypes.ImportHideDelete: {
            state = {
                ...state,
                showDelete: null,
            };
            break;
        }
        case ImporterActionTypes.ImportShowDelete: {
            state = {
                ...state,
                showDelete: action.payload,
            };
            break;
        }
        case ImporterActionTypes.ImportFileDeleteError: {
            state = {
                ...state,
                deletingImportFile: false,
                importFileDeleted: false,
                importFileDeleteError: action.payload,
            };
            break;
        }
        case ImporterActionTypes.ImportShowCreateForm: {
            state = {
                ...state,
                showCreateImportFileForm: true,
            };
            break;
        }
        case ImporterActionTypes.ImportHideCreateForm: {
            state = {
                ...state,
                showCreateImportFileForm: false,
            };
            break;
        }
        case ImporterActionTypes.ImportCreateFile: {
            state = {
                ...state,
                creatingImportFile: true,
                importFileCreated: false,
                importFileCreateError: '',
            };
            break;
        }
        case ImporterActionTypes.ImportFileCreated: {
            state = {
                ...state,
                creatingImportFile: false,
                importFileCreated: true,
                importFileCreateError: '',
                showCreateImportFileForm: false,
            };
            break;
        }
        case ImporterActionTypes.ImportFileCreateError: {
            state = {
                ...state,
                creatingImportFile: false,
                importFileCreated: false,
                importFileCreateError: action.payload,
            };
            break;
        }
        case ImporterActionTypes.ImportSetQuerySetting: {
            state = {
                ...state,
                querySettings: {
                    ...state.querySettings,
                    [action.name]: action.value,
                },
            };
            break;
        }
        case ImporterActionTypes.ImportSetPageDisplay: {
            state = {
                ...state,
                pageDisplay: action.pageDisplay,
            };
            break;
        }
        case ImporterActionTypes.ImportSetPaginationData: {
            state = {
                ...state,
                paginationData: action.paginationData,
            };
            break;
        }
    }
    return state;
}
