"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./upload-images-actions-cancel.component.css.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory");
var i3 = require("@angular/material/button");
var i4 = require("@angular/cdk/platform");
var i5 = require("@angular/cdk/a11y");
var i6 = require("@angular/platform-browser/animations");
var i7 = require("./upload-images-actions-cancel.component");
var styles_UploadImagesActionsCancelComponent = [i0.styles];
var RenderType_UploadImagesActionsCancelComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UploadImagesActionsCancelComponent, data: {} });
exports.RenderType_UploadImagesActionsCancelComponent = RenderType_UploadImagesActionsCancelComponent;
function View_UploadImagesActionsCancelComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "cancel-button"], ["color", "warn"], ["mat-raised-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"], color: [1, "color"] }, null), (_l()(), i1.ɵted(-1, 0, [" Cancel\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = !_co.isEnabled; var currVal_3 = "warn"; _ck(_v, 1, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
exports.View_UploadImagesActionsCancelComponent_0 = View_UploadImagesActionsCancelComponent_0;
function View_UploadImagesActionsCancelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fixiti-photo-dialog-upload-images-actions-cancel", [], null, null, null, View_UploadImagesActionsCancelComponent_0, RenderType_UploadImagesActionsCancelComponent)), i1.ɵdid(1, 49152, null, 0, i7.UploadImagesActionsCancelComponent, [], null, null)], null, null); }
exports.View_UploadImagesActionsCancelComponent_Host_0 = View_UploadImagesActionsCancelComponent_Host_0;
var UploadImagesActionsCancelComponentNgFactory = i1.ɵccf("fixiti-photo-dialog-upload-images-actions-cancel", i7.UploadImagesActionsCancelComponent, View_UploadImagesActionsCancelComponent_Host_0, { isEnabled: "isEnabled", onCancel: "onCancel" }, { cancel: "cancel" }, []);
exports.UploadImagesActionsCancelComponentNgFactory = UploadImagesActionsCancelComponentNgFactory;
