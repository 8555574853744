import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { GalleryPreviewPlaceholderComponent } from './gallery-preview-placeholder/gallery-preview-placeholder.component';
import { MatBadgeModule } from '@angular/material/badge';
import { MatCardModule } from '@angular/material/card';
import { NgModule } from '@angular/core';
import { faCamera } from '@fortawesome/free-solid-svg-icons/faCamera';
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(faCamera);

@NgModule({
    imports: [CommonModule, MatCardModule, MatBadgeModule, FontAwesomeModule],
    exports: [GalleryPreviewPlaceholderComponent],
    declarations: [GalleryPreviewPlaceholderComponent],
})
export class SharedGalleryPreviewPlaceholderModule {}
