"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["mat-progress-bar[_ngcontent-%COMP%]{height:5mm}.timeout[_ngcontent-%COMP%]{width:100%;font-size:1.5em;text-align:center}\n/*# sourceMappingURL=upload-images-progress.component.css.map*/"];
exports.styles = styles;
