"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".flex-container[_ngcontent-%COMP%]{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-align-items:center;align-items:center;-webkit-justify-content:center;justify-content:center}mat-card[_ngcontent-%COMP%]{margin-right:0!important;padding:0}mat-card[_ngcontent-%COMP%]     .mat-badge-content{width:48px;height:48px;line-height:48px;font-size:1.3rem}img[_ngcontent-%COMP%]{object-fit:scale-down;width:135px;height:135px;padding-top:10px}\n/*# sourceMappingURL=gallery-preview-image.component.css.map*/"];
exports.styles = styles;
