/// <reference path="./../../../../models/src/lib/api/all-query.ts" />
/// <reference path="../../../../models/src/lib/api/manager.ts" />

import { Action } from '@ngrx/store';

export enum UsersActionTypes {
    LoadUsers = '[Users] Load Users',
    UsersLoaded = '[Users] Users Loaded',
    UsersLoadError = '[Users] Users Load Error',
    SaveUser = '[Users] Save User',
    UserSaved = '[Users] User Saved',
    RemoveUser = '[Users] Remove User',
    UserRemoved = '[Users] User Removed',
    SelectUser = '[Users] Select User',
    EditUser = '[Users] Edit User',
}

export class LoadUsers implements Action {
    readonly type = UsersActionTypes.LoadUsers;
    constructor(public payload?: ApiModel.AllQuery) {}
}

export class UsersLoadError implements Action {
    readonly type = UsersActionTypes.UsersLoadError;
    constructor(public payload: any) {}
}

export class UsersLoaded implements Action {
    readonly type = UsersActionTypes.UsersLoaded;
    constructor(public payload: ApiModel.Manager[]) {}
}

export class SaveUser implements Action {
    readonly type = UsersActionTypes.SaveUser;
    constructor(public payload: ApiModel.Manager) {}
}

export class UserSaved implements Action {
    readonly type = UsersActionTypes.UserSaved;
    constructor(public payload: ApiModel.Manager) {}
}

export class RemoveUser implements Action {
    readonly type = UsersActionTypes.RemoveUser;
    constructor(public payload: number) {}
}

export class UserRemoved implements Action {
    readonly type = UsersActionTypes.UserRemoved;
    constructor(public payload: number) {}
}

export class SelectUser implements Action {
    readonly type = UsersActionTypes.SelectUser;
    constructor(public payload: number) {}
}

export class EditUser implements Action {
    readonly type = UsersActionTypes.EditUser;
    constructor(public payload: number) {}
}

export type UsersAction =
    | LoadUsers
    | UsersLoaded
    | UsersLoadError
    | SaveUser
    | UserSaved
    | RemoveUser
    | UserRemoved
    | SelectUser
    | EditUser;

export const fromUsersActions = {
    LoadUsers,
    UsersLoaded,
    UsersLoadError,
    SaveUser,
    UserSaved,
    RemoveUser,
    UserRemoved,
    SelectUser,
    EditUser,
};
