import { Component, Input } from '@angular/core';

import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'fixiti-photo-dialog-dialog-footer',
    templateUrl: './dialog-footer.component.html',
    styleUrls: ['./dialog-footer.component.css'],
})
export class DialogFooterComponent {
    /* istanbul ignore next */
    @Input()
    dialogReference: MatDialogRef<any>;
    /* istanbul ignore next */
    constructor() {}
}
