import { createFeatureSelector, createSelector } from '@ngrx/store';

import { PageSettingsState } from './page-settings.reducer';

// Lookup the 'PageSettings' feature state managed by NgRx
const getPageSettingsState = createFeatureSelector<PageSettingsState>(
    'pageSettings'
);

const getCurrentPage = createSelector(
    getPageSettingsState,
    (state: PageSettingsState) => state.current
);
const getPageUrlUpdating = createSelector(
    getPageSettingsState,
    (state: PageSettingsState) => state.updatingUrl
);

const getAllPagesDisplaySettings = createSelector(
    getPageSettingsState,
    (state: PageSettingsState) => {
        return Object.freeze({ ...state.display });
    }
);

const getCurrentDisplaySettings = createSelector(
    getCurrentPage,
    getAllPagesDisplaySettings,
    (page, displaySettings) => {
        return displaySettings[page] || 'list';
    }
);

const getAllPagesFilterSettings = createSelector(
    getPageSettingsState,
    (state: PageSettingsState) => {
        return Object.freeze({ ...state.filter });
    }
);

const getCurrentFilterSettings = createSelector(
    getCurrentPage,
    getAllPagesFilterSettings,
    (page, filterSettings) => {
        return filterSettings[page];
    }
);

const getAllPagesIndexSettings = createSelector(
    getPageSettingsState,
    (state: PageSettingsState) => {
        return Object.freeze({ ...state.pageIndex });
    }
);

const getCurrentPageIndexSettings = createSelector(
    getCurrentPage,
    getAllPagesIndexSettings,
    (page, pageIndexSettings) => {
        return pageIndexSettings[page] || 0;
    }
);

const getAllPagesLengthSettings = createSelector(
    getPageSettingsState,
    (state: PageSettingsState) => {
        return Object.freeze({ ...state.pageLength });
    }
);

const getCurrentPageLengthSettings = createSelector(
    getCurrentPage,
    getAllPagesLengthSettings,
    (page, pageLengthSettings) => {
        return pageLengthSettings[page] || 0;
    }
);

const getAllPagesSizeSettings = createSelector(
    getPageSettingsState,
    (state: PageSettingsState) => {
        return Object.freeze({ ...state.pageSize });
    }
);

const getCurrentPageSizeSettings = createSelector(
    getCurrentPage,
    getAllPagesSizeSettings,
    (page, pageSizeSettings) => {
        return pageSizeSettings[page] || 100;
    }
);

const getAllPagesSortDirectionSettings = createSelector(
    getPageSettingsState,
    (state: PageSettingsState) => {
        return Object.freeze({ ...state.sortDirection });
    }
);

const getCurrentSortDirectionSettings = createSelector(
    getCurrentPage,
    getAllPagesSortDirectionSettings,
    (page, sortDirectionSettings) => {
        return sortDirectionSettings[page] || 'desc';
    }
);

const getAllPagesSortFieldSettings = createSelector(
    getPageSettingsState,
    (state: PageSettingsState) => {
        return Object.freeze({ ...state.sortField });
    }
);

const getCurrentSortFieldSettings = createSelector(
    getCurrentPage,
    getAllPagesSortFieldSettings,
    (page, sortFieldSettings) => {
        return sortFieldSettings[page];
    }
);

const getAllPageReportIds = createSelector(
    getPageSettingsState,
    (state: PageSettingsState) => {
        return Object.freeze({ ...state.reportId });
    }
);

const getCurrentPageReportIds = createSelector(
    getCurrentPage,
    getAllPageReportIds,
    (page, pageSizeSettings) => {
        return pageSizeSettings[page];
    }
);

const getCurrentPagePaginatorSettings = createSelector(
    getCurrentPageIndexSettings,
    getCurrentPageLengthSettings,
    getCurrentPageSizeSettings,
    (pageIndex, pageLength, pageSize) => {
        return Object.freeze({
            pageIndex,
            pageLength,
            pageSize,
        });
    }
);

const getCurrentPageSorterSettings = createSelector(
    getCurrentSortDirectionSettings,
    getCurrentSortFieldSettings,
    (sortDirection, sortField) => {
        return Object.freeze({
            sortDirection,
            sortField,
        });
    }
);

const getCurrentPageOtherSettings = createSelector(
    getCurrentDisplaySettings,
    getCurrentFilterSettings,
    getCurrentPageReportIds,
    getCurrentPage,
    (display, filter, reportId, page) => {
        return Object.freeze({
            display,
            filter,
            reportId,
            page,
        });
    }
);

const getCurrentPageSettings = createSelector(
    getCurrentPagePaginatorSettings,
    getCurrentPageSorterSettings,
    getCurrentPageOtherSettings,
    (paginator, sorter, other) => {
        return Object.freeze({
            ...paginator,
            ...sorter,
            ...other,
        });
    }
);

export const pageSettingsQuery = {
    getCurrentPage,
    getCurrentPageSettings,
    getCurrentDisplaySettings,
    getCurrentFilterSettings,
    getCurrentPageIndexSettings,
    getCurrentPageLengthSettings,
    getCurrentPageSizeSettings,
    getCurrentSortDirectionSettings,
    getCurrentSortFieldSettings,
    getCurrentPageReportIds,
    getPageUrlUpdating,
};
