"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var store_1 = require("@ngrx/store");
// Lookup the 'Roles' feature state managed by NgRx
var getRolesState = store_1.createFeatureSelector('roles');
var ɵ0 = function (state) { return state.loaded; };
exports.ɵ0 = ɵ0;
var getLoaded = store_1.createSelector(getRolesState, ɵ0);
var ɵ1 = function (state) { return state.error; };
exports.ɵ1 = ɵ1;
var getError = store_1.createSelector(getRolesState, ɵ1);
var ɵ2 = function (state, isLoaded) {
    return isLoaded ? state.list : [];
};
exports.ɵ2 = ɵ2;
var getAllRoles = store_1.createSelector(getRolesState, getLoaded, ɵ2);
var ɵ3 = function (state) { return state.selectedId; };
exports.ɵ3 = ɵ3;
var getSelectedId = store_1.createSelector(getRolesState, ɵ3);
var ɵ4 = function (roles, id) {
    var result = roles.find(function (it) { return it['id'] === id; });
    return result ? Object.assign({}, result) : undefined;
};
exports.ɵ4 = ɵ4;
var getSelectedRole = store_1.createSelector(getAllRoles, getSelectedId, ɵ4);
var ɵ5 = function (state) { return state.editingId; };
exports.ɵ5 = ɵ5;
var getEditingId = store_1.createSelector(getRolesState, ɵ5);
var ɵ6 = function (roles, id) {
    var result = roles.find(function (it) { return it['id'] === id; });
    return result ? Object.assign({}, result) : undefined;
};
exports.ɵ6 = ɵ6;
var getEditingRole = store_1.createSelector(getAllRoles, getEditingId, ɵ6);
var ɵ7 = function (state) { return state.groups; };
exports.ɵ7 = ɵ7;
var getGroups = store_1.createSelector(getRolesState, ɵ7);
var ɵ8 = function (groups, id) {
    return groups[id];
};
exports.ɵ8 = ɵ8;
var getSelectedGroup = store_1.createSelector(getGroups, getSelectedId, ɵ8);
var ɵ9 = function (groups, id) {
    return groups[id];
};
exports.ɵ9 = ɵ9;
var getEditingGroup = store_1.createSelector(getGroups, getEditingId, ɵ9);
var ɵ10 = function (state) {
    return Object.entries(state.addQueue).map(function (_a) {
        var roleId = _a[0], users = _a[1];
        return ({
            roleId: +roleId,
            users: users,
        });
    });
};
exports.ɵ10 = ɵ10;
var getUsersToAdd = store_1.createSelector(getRolesState, ɵ10);
var ɵ11 = function (state) {
    return Object.entries(state.removalQueue).map(function (_a) {
        var roleId = _a[0], users = _a[1];
        return ({
            roleId: +roleId,
            users: users,
        });
    });
};
exports.ɵ11 = ɵ11;
var getUsersToRemove = store_1.createSelector(getRolesState, ɵ11);
var ɵ12 = function (add, removal) { return ({
    add: add,
    removal: removal,
}); };
exports.ɵ12 = ɵ12;
var getRoleChanges = store_1.createSelector(getUsersToAdd, getUsersToRemove, ɵ12);
var ɵ13 = function (state) { return state.permission; };
exports.ɵ13 = ɵ13;
var getPermissionForRole = store_1.createSelector(getRolesState, ɵ13);
var ɵ14 = function (state) { return state.users; };
exports.ɵ14 = ɵ14;
var getUsersForRole = store_1.createSelector(getRolesState, ɵ14);
var ɵ15 = function (state) { return state.isDirty; };
exports.ɵ15 = ɵ15;
var getRoleIsDirty = store_1.createSelector(getRolesState, ɵ15);
exports.rolesQuery = {
    getLoaded: getLoaded,
    getError: getError,
    getAllRoles: getAllRoles,
    getSelectedRole: getSelectedRole,
    getEditingRole: getEditingRole,
    getSelectedGroup: getSelectedGroup,
    getEditingGroup: getEditingGroup,
    getRoleChanges: getRoleChanges,
    getPermissionForRole: getPermissionForRole,
    getUsersForRole: getUsersForRole,
    getRoleIsDirty: getRoleIsDirty,
};
