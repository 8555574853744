"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var pica = require("pica");
var rxjs_1 = require("rxjs");
var pica_error_interface_1 = require("./pica-error.interface");
var pica_exif_service_1 = require("./pica-exif.service");
/* istanbul ignore next */
var PicaService = /** @class */ (function () {
    function PicaService(_picaExifService) {
        this._picaExifService = _picaExifService;
        this.picaResizer = new pica();
        this.MAX_STEPS = 20;
        if (!this.picaResizer || !this.picaResizer.resize) {
            this.picaResizer = new window.pica();
        }
    }
    PicaService.prototype.resizeImages = function (files, width, height, options) {
        var _this = this;
        var resizedImage = new rxjs_1.Subject();
        var totalFiles = files.length;
        if (totalFiles > 0) {
            var nextFile_1 = new rxjs_1.Subject();
            var index_1 = 0;
            var subscription_1 = nextFile_1.subscribe(function (file) {
                _this.resizeImage(file, width, height, options).subscribe(function (imageResized) {
                    index_1++;
                    resizedImage.next(imageResized);
                    if (index_1 < totalFiles) {
                        nextFile_1.next(files[index_1]);
                    }
                    else {
                        resizedImage.complete();
                        subscription_1.unsubscribe();
                    }
                }, function (err) {
                    var picaError = {
                        file: file,
                        err: err,
                    };
                    resizedImage.error(picaError);
                });
            });
            nextFile_1.next(files[index_1]);
        }
        else {
            var picaError = {
                err: pica_error_interface_1.PicaErrorType.NO_FILES_RECEIVED,
            };
            resizedImage.error(picaError);
            resizedImage.complete();
        }
        return resizedImage.asObservable();
    };
    PicaService.prototype.resizeImage = function (file, width, height, options) {
        var _this = this;
        var resizedImage = new rxjs_1.Subject();
        var originCanvas = document.createElement('canvas');
        var ctx = originCanvas.getContext('2d');
        var img = new Image();
        if (ctx) {
            img.onload = function () {
                _this._picaExifService
                    .getExifOrientedImage(img)
                    .then(function (orientedImage) {
                    window.URL.revokeObjectURL(img.src);
                    originCanvas.width = orientedImage.width;
                    originCanvas.height = orientedImage.height;
                    ctx.drawImage(orientedImage, 0, 0);
                    var imageData = ctx.getImageData(0, 0, orientedImage.width, orientedImage.height);
                    if (options &&
                        options.aspectRatio &&
                        options.aspectRatio.keepAspectRatio) {
                        var ratio = 0;
                        if (options.aspectRatio.forceMinDimensions) {
                            ratio = Math.max(width / imageData.width, height / imageData.height);
                        }
                        else {
                            ratio = Math.min(width / imageData.width, height / imageData.height);
                        }
                        width = Math.round(imageData.width * ratio);
                        height = Math.round(imageData.height * ratio);
                    }
                    var destinationCanvas = document.createElement('canvas');
                    destinationCanvas.width = width;
                    destinationCanvas.height = height;
                    _this.picaResize(file, originCanvas, destinationCanvas, options)
                        .catch(function (err) { return resizedImage.error(err); })
                        .then(function (imgResized) {
                        resizedImage.next(imgResized);
                    });
                });
            };
            img.src = window.URL.createObjectURL(file);
        }
        else {
            resizedImage.error(pica_error_interface_1.PicaErrorType.CANVAS_CONTEXT_IDENTIFIER_NOT_SUPPORTED);
        }
        return resizedImage.asObservable();
    };
    PicaService.prototype.compressImages = function (files, sizeInMB) {
        var _this = this;
        var compressedImage = new rxjs_1.Subject();
        var totalFiles = files.length;
        if (totalFiles > 0) {
            var nextFile_2 = new rxjs_1.Subject();
            var index_2 = 0;
            var subscription_2 = nextFile_2.subscribe(function (file) {
                _this.compressImage(file, sizeInMB).subscribe(function (imageCompressed) {
                    index_2++;
                    compressedImage.next(imageCompressed);
                    if (index_2 < totalFiles) {
                        nextFile_2.next(files[index_2]);
                    }
                    else {
                        compressedImage.complete();
                        subscription_2.unsubscribe();
                    }
                }, function (err) {
                    var picaError = {
                        file: file,
                        err: err,
                    };
                    compressedImage.error(picaError);
                });
            });
            nextFile_2.next(files[index_2]);
        }
        else {
            var picaError = {
                err: pica_error_interface_1.PicaErrorType.NO_FILES_RECEIVED,
            };
            compressedImage.error(picaError);
            compressedImage.complete();
        }
        return compressedImage.asObservable();
    };
    PicaService.prototype.compressImage = function (file, sizeInMB) {
        var _this = this;
        var compressedImage = new rxjs_1.Subject();
        if (this.bytesToMB(file.size) <= sizeInMB) {
            setTimeout(function () {
                compressedImage.next(file);
            });
        }
        else {
            var originCanvas_1 = document.createElement('canvas');
            var ctx_1 = originCanvas_1.getContext('2d');
            var img_1 = new Image();
            if (ctx_1) {
                img_1.onload = function () {
                    _this._picaExifService
                        .getExifOrientedImage(img_1)
                        .then(function (orientedImage) {
                        window.URL.revokeObjectURL(img_1.src);
                        originCanvas_1.width = orientedImage.width;
                        originCanvas_1.height = orientedImage.height;
                        ctx_1.drawImage(orientedImage, 0, 0);
                        _this.getCompressedImage(originCanvas_1, file.type, 1, sizeInMB, 0)
                            .catch(function (err) { return compressedImage.error(err); })
                            .then(function (blob) {
                            var imgCompressed = _this.blobToFile(blob, file.name, file.type, new Date().getTime());
                            compressedImage.next(imgCompressed);
                        });
                    });
                };
                img_1.src = window.URL.createObjectURL(file);
            }
            else {
                compressedImage.error(pica_error_interface_1.PicaErrorType.CANVAS_CONTEXT_IDENTIFIER_NOT_SUPPORTED);
            }
        }
        return compressedImage.asObservable();
    };
    PicaService.prototype.getCompressedImage = function (canvas, type, quality, sizeInMB, step) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.picaResizer
                .toBlob(canvas, type, quality)
                .catch(function (err) { return reject(err); })
                .then(function (blob) {
                _this.checkCompressedImageSize(canvas, blob, quality, sizeInMB, step)
                    .catch(function (err) { return reject(err); })
                    .then(function (compressedBlob) {
                    resolve(compressedBlob);
                });
            });
        });
    };
    PicaService.prototype.checkCompressedImageSize = function (canvas, blob, quality, sizeInMB, step) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (step > _this.MAX_STEPS) {
                reject(pica_error_interface_1.PicaErrorType.NOT_BE_ABLE_TO_COMPRESS_ENOUGH);
            }
            else if (_this.bytesToMB(blob.size) < sizeInMB) {
                resolve(blob);
            }
            else {
                var newQuality = quality - quality * 0.1;
                var newStep = step + 1;
                // recursively compression
                resolve(_this.getCompressedImage(canvas, blob.type, newQuality, sizeInMB, newStep));
            }
        });
    };
    PicaService.prototype.picaResize = function (file, from, to, options) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.picaResizer
                .resize(from, to, options)
                .catch(function (err) { return reject(err); })
                .then(function (resizedCanvas) {
                return _this.picaResizer.toBlob(resizedCanvas, file.type);
            })
                .then(function (blob) {
                var fileResized = _this.blobToFile(blob, file.name, file.type, new Date().getTime());
                resolve(fileResized);
            });
        });
    };
    PicaService.prototype.blobToFile = function (blob, name, type, lastModified) {
        return new File([blob], name, {
            type: type,
            lastModified: lastModified,
        });
    };
    PicaService.prototype.bytesToMB = function (bytes) {
        return bytes / 1048576;
    };
    return PicaService;
}());
exports.PicaService = PicaService;
