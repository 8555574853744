import {
    initialState as sidenavInitialState,
    sidenavReducer,
} from './+state/sidenav.reducer';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('sidenav', sidenavReducer, {
            initialState: sidenavInitialState,
        }),
    ],
})
export class StateSidenavModule {}
