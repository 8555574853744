import { Actions, Effect, ofType } from '@ngrx/effects';
import {
    LoadProviderSettings,
    LoadSettings,
    LoadUser,
    SettingsActionTypes,
} from '@fixiti/actions';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { LoadJobs } from '@fixiti/actions';
import { Store } from '@ngrx/store';

@Injectable()
export class SettingsEffects {
    @Effect()
    loadJobsEffect$ = this.actions$.pipe(
        ofType(SettingsActionTypes.LoadSettings),
        map((action: LoadSettings) => action.payload.configuration),
        map(configuration => configuration.resourceId),
        distinctUntilChanged(),
        filter(id => id != null),
        map(id => new LoadJobs({ id }))
    );

    @Effect()
    loadProviderSettingsEffect$ = this.actions$.pipe(
        ofType(SettingsActionTypes.LoadSettings),
        map((action: LoadSettings) => action.payload.configuration),
        tap(configuration =>
            this.store.dispatch(
                new LoadUser(<any>{
                    apiToken: configuration.apiKey,
                    membershipId: configuration.membershipId,
                    resourceId: configuration.resourceId,
                })
            )
        ),
        map(configuration => configuration.resourceId),
        distinctUntilChanged(),
        filter(id => id != null),
        map(() => new LoadProviderSettings())
    );

    constructor(private actions$: Actions, private store: Store<any>) {}
}
