import {
    PhotoDialogActions,
    PhotoDialogClose,
    PhotoDialogTypes,
} from '@fixiti/actions/src';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export class PhotoDialogJobSettings {
    id: number;
    status: string;
}

export class PhotoDialogApiSettings {
    url: string;
    key: string;
}
export class PhotoDialogMembershipSettings {
    id: number;
}

export class PhotoDialogPhotoEventsSettings {
    shouldLoad: boolean;
    shouldSave: boolean;
}
export interface PhotoDialogState {
    api: PhotoDialogApiSettings;
    ids: number[];
    job: PhotoDialogJobSettings;
    membership: PhotoDialogMembershipSettings;
    photoEvents: PhotoDialogPhotoEventsSettings;
    gallery: any[];
    placeHolder: string;
    minimum: number;
}

export const photoDialogInitialState: PhotoDialogState = {
    api: {
        url: null,
        key: null,
    },
    ids: [],
    job: {
        id: null,
        status: null,
    },
    membership: {
        id: null,
    },
    photoEvents: {
        shouldLoad: false,
        shouldSave: false,
    },
    placeHolder: '',
    gallery: [],
    minimum: 2,
};

export function photoDialogReducer(
    state: PhotoDialogState = photoDialogInitialState,
    action: PhotoDialogActions | PhotoDialogClose
): PhotoDialogState {
    switch (action.type) {
        case PhotoDialogTypes.LOAD_SETTINGS:
            if (!action.payload.config) {
                return state;
            }
            const config = action.payload.config;
            return {
                ...state,
                api: {
                    ...state.api,
                    url: config.ApiUrl,
                    key: config.ApiKey,
                    ...config.api,
                },
                job: {
                    ...state.job,
                    id: config.JobId,
                    ...config.job,
                },
                membership: {
                    ...state.membership,
                    id: config.MembershipId,
                },
                photoEvents: {
                    ...state.photoEvents,
                    ...config.photoEvents,
                },
                placeHolder: config.placeHolder,
                gallery: config.gallery,
                minimum: config.minimum,
            };
        case PhotoDialogTypes.ADD_IDS:
            return {
                ...state,
                ids: action.payload.ids,
            };
        case PhotoDialogTypes.PHOTO_DIALOG_CLOSE:
            return photoDialogInitialState;
        default:
            return state;
    }
}

export const selectSettingsState = createFeatureSelector<PhotoDialogState>(
    'photo-dialog'
);

export const selectPhotoDialogConfig = createSelector(
    selectSettingsState,
    (state: PhotoDialogState) => {
        const { ids, ...config } = state;
        return Object.freeze(config);
    }
);
export const selectPhotoDialogIds = createSelector(
    selectSettingsState,
    (state: PhotoDialogState) => state.ids
);
export const selectPhotoDialogPlaceHolder = createSelector(
    selectSettingsState,
    (state: PhotoDialogState) => state.placeHolder
);
