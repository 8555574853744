"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var button_1 = require("@angular/material/button");
var card_1 = require("@angular/material/card");
var dialog_1 = require("@angular/material/dialog");
var MATERIAL = [dialog_1.MatDialogModule, card_1.MatCardModule, button_1.MatButtonModule];
var ApiRestImagesModule = /** @class */ (function () {
    function ApiRestImagesModule() {
    }
    /* istanbul ignore next */
    ApiRestImagesModule.forRoot = function () {
        return {
            /* ts-lint ignore next */
            ngModule: RootApiRestImagesModule,
        };
    };
    return ApiRestImagesModule;
}());
exports.ApiRestImagesModule = ApiRestImagesModule;
var RootApiRestImagesModule = /** @class */ (function () {
    function RootApiRestImagesModule() {
    }
    return RootApiRestImagesModule;
}());
exports.RootApiRestImagesModule = RootApiRestImagesModule;
