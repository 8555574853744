"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var PhotoDialogConfig = /** @class */ (function () {
    function PhotoDialogConfig() {
    }
    return PhotoDialogConfig;
}());
exports.PhotoDialogConfig = PhotoDialogConfig;
var PhotoDialogJob = /** @class */ (function () {
    function PhotoDialogJob() {
    }
    return PhotoDialogJob;
}());
exports.PhotoDialogJob = PhotoDialogJob;
var PhotoDialogApi = /** @class */ (function () {
    function PhotoDialogApi() {
    }
    return PhotoDialogApi;
}());
exports.PhotoDialogApi = PhotoDialogApi;
var PhotoDialogPhotoEvents = /** @class */ (function () {
    function PhotoDialogPhotoEvents() {
    }
    return PhotoDialogPhotoEvents;
}());
exports.PhotoDialogPhotoEvents = PhotoDialogPhotoEvents;
