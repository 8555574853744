"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var UploadImagesActionsSubmitComponent = /** @class */ (function () {
    /* istanbul ignore next */
    function UploadImagesActionsSubmitComponent() {
        this.files = [];
        this.submit = new core_1.EventEmitter();
        this.isEnabled = true;
    }
    UploadImagesActionsSubmitComponent.prototype.handleClick = function () {
        if (this.onSubmit != null) {
            this.onSubmit();
        }
        this.submit.emit();
    };
    return UploadImagesActionsSubmitComponent;
}());
exports.UploadImagesActionsSubmitComponent = UploadImagesActionsSubmitComponent;
