import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IMPORTER_FEATURE_KEY, ImporterState } from './importer.reducer';

// Lookup the 'Importer' feature state managed by NgRx
const getImporterState = createFeatureSelector<ImporterState>('importer');

const getLoaded = createSelector(
    getImporterState,
    (state: ImporterState) => {
        return state.loaded;
    }
);

const showFilterMenu = createSelector(
    getImporterState,
    (state: ImporterState) => {
        return state.showFilterMenu;
    }
);

const getError = createSelector(
    getImporterState,
    (state: ImporterState) => state.error
);
const showCreateImportFileForm = createSelector(
    getImporterState,
    (state: ImporterState) => state.showCreateImportFileForm
);

const getAllImportFiles = createSelector(
    getImporterState,
    getLoaded,
    (state: ImporterState, isLoaded) => {
        return isLoaded ? state.files : [];
    }
);
const getSelectedId = createSelector(
    getImporterState,
    (state: ImporterState) => state.selectedId
);
const getSelectedImportFile = createSelector(
    getAllImportFiles,
    getSelectedId,
    (importFiles, id) => {
        const result = importFiles.find(it => it['id'] === id);
        return result ? Object.assign({}, result) : undefined;
    }
);

const getSelectedImportFileMapping = createSelector(
    getImporterState,
    (state: ImporterState) => {
        return state.selectedImportFileMapping;
    }
);

const getSelectedImportFileMappingTos = createSelector(
    getImporterState,
    (state: ImporterState) => {
        return state.selectedImportFileMappingTos;
    }
);

const getInputErrors = createSelector(
    getImporterState,
    (state: ImporterState) => {
        return state.importMappingInputErrors;
    }
);

const isWorking = createSelector(
    getImporterState,
    (state: ImporterState) => {
        for (const key of [
            'loadingImportMap',
            'savingImportMapping',
            'deletingImportFile',
            'creatingImportFile',
        ]) {
            if (key in state && state[key]) {
                return true;
            }
        }

        return false;
    }
);

const getPageDisplay = createSelector(
    getImporterState,
    (state: ImporterState) => {
        return state.pageDisplay;
    }
);

const getPaginationData = createSelector(
    getImporterState,
    (state: ImporterState) => {
        return state.paginationData;
    }
);

const showDelete = createSelector(
    getImporterState,
    (state: ImporterState) => state.showDelete
);
const showDeleteRecord = createSelector(
    getAllImportFiles,
    showDelete,
    (importFiles, id) => {
        const result = importFiles.find(it => it.id === id);
        return result ? Object.assign({}, result) : undefined;
    }
);

const getQuerySettings = createSelector(
    getImporterState,
    (state: ImporterState) => {
        return state.querySettings;
    }
);

export const importerQuery = {
    getLoaded,
    showFilterMenu,
    getError,
    showCreateImportFileForm,
    getAllImportFiles,
    getSelectedImportFile,
    getSelectedImportFileMapping,
    getSelectedImportFileMappingTos,
    isWorking,
    getInputErrors,
    getPageDisplay,
    getPaginationData,
    getQuerySettings,
    showDelete,
    showDeleteRecord,
};
