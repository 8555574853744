"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var GalleryPreviewImageComponent = /** @class */ (function () {
    function GalleryPreviewImageComponent() {
        this.count = 0;
        this.minimum = 0;
        this.showBadge = true;
    }
    GalleryPreviewImageComponent.prototype.ngOnInit = function () { };
    GalleryPreviewImageComponent.prototype.getBadgeText = function (count, minimum) {
        if (!minimum) {
            return count.toString();
        }
        else {
            return count + "/" + minimum;
        }
    };
    GalleryPreviewImageComponent.prototype.sanitize = function (image) {
        if (typeof image === 'string') {
            return image;
        }
        return (image && image.url) || '';
    };
    return GalleryPreviewImageComponent;
}());
exports.GalleryPreviewImageComponent = GalleryPreviewImageComponent;
