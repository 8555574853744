import { Component } from '@angular/core';

@Component({
    selector: 'fixiti-photo-dialog-photo-summary',
    templateUrl: './photo-summary.component.html',
    styleUrls: ['./photo-summary.component.css'],
})
export class PhotoSummaryComponent {
    /* istanbul ignore next */
    constructor() {}
}
