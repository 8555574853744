"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/// <reference path="../../../../../../models/src/lib/api/gallery-image.ts" />
var core_1 = require("@angular/core");
var GalleryPreviewPresenterComponent = /** @class */ (function () {
    function GalleryPreviewPresenterComponent() {
        this.minimum = 0;
        this.showBadge = true;
    }
    GalleryPreviewPresenterComponent.prototype.ngOnInit = function () { };
    return GalleryPreviewPresenterComponent;
}());
exports.GalleryPreviewPresenterComponent = GalleryPreviewPresenterComponent;
