import { Injectable } from '@angular/core';
import {
    CommentModel,
    FormOptionsModel,
    ImageModel,
    ListViewPaginationModel,
    VideoModel,
    WidgetFilterModel,
} from '@fixiti/models';

@Injectable({
    providedIn: 'root',
})
export class SharedApiNormalizationService {
    constructor() {}

    asInt(input: number | string): number {
        if (typeof input === 'undefined' || input === null) {
            return 0;
        }

        return typeof input === 'string' ? parseInt(input, 10) : Math.floor(input);
    }

    asFloat(input: number | string): number {
        if (typeof input === 'undefined' || input === null) {
            return 0;
        }

        return typeof input === 'string' ? parseFloat(input) : input;
    }

    asString(input: number | string): string {
        if (typeof input === 'undefined' || input === null) {
            return '';
        }

        return typeof input === 'string' ? input : input.toString();
    }

    asBoolean(input: number | string | boolean): boolean {
        if (input === '0') {
            return false;
        }

        return !!input;
    }

    asDate(input: number | string | Date): Date {
        if (!input || typeof input === 'undefined' || input === null) {
            return new Date(0);
        }

        if (input instanceof Date) {
            return input;
        }

        // if a number assume a unix timestamp
        if (typeof input === 'number') {
            return new Date(input * 1000);
        }

        // ditto for a string of only numbers
        if (/^\d+$/.test(input)) {
            return new Date(parseInt(input, 10) * 1000);
        }

        return new Date(input);
    }

    asAnyArray(input): any[] {
        if (typeof input === 'undefined' || input === null || !Array.isArray(input)) {
            return [];
        }

        return input;
    }

    asIntArray(input: any[]): number[] {
        if (typeof input === 'undefined' || input === null || !Array.isArray(input)) {
            return [];
        }

        return input.map(val => this.asInt(val));
    }

    asGallery(images: any[]): ImageModel[] {
        if (!images) {
            return [];
        }

        const normalized = [];
        const requiredArrays = ['availableSizes', 'thumbnails'];
        for (const image of images) {
            const id = this.asInt(image.id);
            if (!id) {
                continue;
            }
            if (
                !('availableSizes' in image) ||
                !Array.isArray(image.availableSizes) ||
                !image.availableSizes.length
            ) {
                continue;
            }
            if (!('thumbnails' in image) || typeof image.thumbnails !== 'object') {
                continue;
            }

            const normalizedImage = <ImageModel>{
                id: id,
                availableSizes: [],
                thumbnails: {},
            };
            for (const key of Object.keys(image.thumbnails)) {
                normalizedImage.thumbnails[key] = this.asString(image.thumbnails[key]);
            }

            for (let size of image.availableSizes) {
                size = this.asString(size);
                if (!(size in normalizedImage.thumbnails)) {
                    continue;
                }

                normalizedImage.availableSizes.push(size);
            }
            normalized.push(normalizedImage);
        }

        return normalized;
    }

    asVideoGallery(videos: any[]): VideoModel[] {
        if (!videos) {
            return [];
        }

        const requiredProperties = ['id', 'url', 'thumbnailUrl'];
        const normalized = [];
        for (const rawVideo of videos) {
            const video = {
                id: this.asInt(rawVideo.id),
                url: this.asString(rawVideo.url),
                thumbnailUrl: this.asString(rawVideo.thumbnailUrl),
            };
            if (!video.id || !video.url || !video.thumbnailUrl) {
                continue;
            }

            normalized.push(video);
        }

        return normalized;
    }

    asErrors(errors: any[]): { [key: string]: string } {
        const normalizedErrors: { [key: string]: string } = {};
        if (!Array.isArray(errors) || !errors) {
            return normalizedErrors;
        }

        let nErrors = 0;
        for (const error of errors) {
            if (!('field' in error) || !('error' in error)) {
                continue;
            }

            nErrors += 1;
            normalizedErrors[this.asString(error.field)] = this.asString(error.error);
        }

        return normalizedErrors;
    }

    asListViewPagination(data: any): ListViewPaginationModel {
        return <ListViewPaginationModel>{
            numMatches: this.asInt(data.num_matches),
            numReturned: this.asInt(data.num_returned),
            start: this.asInt(data.start),
            end: this.asInt(data.end),
            isFirstPage: !!data.is_first_page,
            isLastPage: !!data.is_last_page,
            maxPage: this.asInt(data.max_page),
            currentPage: this.asInt(data.current_page),
            nextPage: this.asInt(data.next_page),
            previousPage: this.asInt(data.previous_page),
        };
    }

    asWidgetFilter(filter: any): WidgetFilterModel {
        return {
            id: this.asInt(filter.id),
            count: this.asInt(filter.count),
            label: this.asString(filter.label),
        };
    }

    asFormOptions(rawOptions: any): FormOptionsModel {
        // the form options model is designed for storing all the options
        // for all the fields, so in practice it looks something like
        // {investorId: [{value: 1, label: "hi"}, etc], customerId: [{value: 2, label: "person"},  etc]}
        const formOptions: FormOptionsModel = {};
        for (const key of Object.keys(rawOptions)) {
            if (!Array.isArray(rawOptions[key])) {
                continue;
            }

            formOptions[key] = [];
            for (const option of rawOptions[key]) {
                if (!option.label || !option.value) {
                    continue;
                }

                formOptions[key].push({
                    value: this.asString(option.value),
                    label: this.asString(option.label),
                });
            }
        }

        return formOptions;
    }

    asComments(rawComments: any): CommentModel[] {
        if (!Array.isArray(rawComments)) {
            return [];
        }

        const cleaned = rawComments.map(rawComment => {
            return {
                id: this.asInt(rawComment.id),
                firstName: this.asString(rawComment.firstName),
                lastName: this.asString(rawComment.lastName),
                comment: this.asString(rawComment.comment),
                created: this.asDate(rawComment.created),
            };
        });

        return cleaned.filter(val => !!val.id && !!val.comment);
    }
}
