import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'fixiti-photo-dialog-upload-images-actions-cancel',
    templateUrl: './upload-images-actions-cancel.component.html',
    styleUrls: ['./upload-images-actions-cancel.component.css'],
})
export class UploadImagesActionsCancelComponent {
    @Input()
    isEnabled = true;
    @Output()
    cancel = new EventEmitter();
    @Input()
    onCancel: VoidFunction | null;

    /* istanbul ignore next */
    constructor() {}

    handleClick() {
        if (this.onCancel) {
            this.onCancel();
        }
        this.cancel.emit();
    }
}
