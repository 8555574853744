import { Component } from '@angular/core';

@Component({
    selector: 'fixiti-photo-dialog-dialog-header-icon',
    templateUrl: './dialog-header-icon.component.html',
    styleUrls: ['./dialog-header-icon.component.css'],
})
export class DialogHeaderIconComponent {
    /* istanbul ignore next */
    constructor() {}
}
