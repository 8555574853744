"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./gallery-preview-button.component.css.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory");
var i3 = require("@angular/material/button");
var i4 = require("@angular/cdk/platform");
var i5 = require("@angular/cdk/a11y");
var i6 = require("@angular/platform-browser/animations");
var i7 = require("../../../../presenter/src/lib/gallery-preview-presenter/gallery-preview-presenter.component.ngfactory");
var i8 = require("../../../../presenter/src/lib/gallery-preview-presenter/gallery-preview-presenter.component");
var i9 = require("./gallery-preview-button.component");
var styles_GalleryPreviewButtonComponent = [i0.styles];
var RenderType_GalleryPreviewButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GalleryPreviewButtonComponent, data: {} });
exports.RenderType_GalleryPreviewButtonComponent = RenderType_GalleryPreviewButtonComponent;
function View_GalleryPreviewButtonComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "fixiti-gallery-preview-presenter", [], null, null, null, i7.View_GalleryPreviewPresenterComponent_0, i7.RenderType_GalleryPreviewPresenterComponent)), i1.ɵdid(3, 114688, null, 0, i8.GalleryPreviewPresenterComponent, [], { count: [0, "count"], minimum: [1, "minimum"], image: [2, "image"], placeholder: [3, "placeholder"], gallery: [4, "gallery"], showBadge: [5, "showBadge"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = !_co.isEnabled; _ck(_v, 1, 0, currVal_2); var currVal_3 = _co.count; var currVal_4 = _co.minimum; var currVal_5 = _co.image; var currVal_6 = _co.placeholder; var currVal_7 = _co.gallery; var currVal_8 = _co.showBadge; _ck(_v, 3, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
exports.View_GalleryPreviewButtonComponent_0 = View_GalleryPreviewButtonComponent_0;
function View_GalleryPreviewButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fixiti-gallery-preview-button", [], null, null, null, View_GalleryPreviewButtonComponent_0, RenderType_GalleryPreviewButtonComponent)), i1.ɵdid(1, 114688, null, 0, i9.GalleryPreviewButtonComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_GalleryPreviewButtonComponent_Host_0 = View_GalleryPreviewButtonComponent_Host_0;
var GalleryPreviewButtonComponentNgFactory = i1.ɵccf("fixiti-gallery-preview-button", i9.GalleryPreviewButtonComponent, View_GalleryPreviewButtonComponent_Host_0, { id: "id", count: "count", minimum: "minimum", image: "image", placeholder: "placeholder", gallery: "gallery", isEnabled: "isEnabled", showBadge: "showBadge" }, { galleryChange: "galleryChange", clickChange: "clickChange" }, []);
exports.GalleryPreviewButtonComponentNgFactory = GalleryPreviewButtonComponentNgFactory;
