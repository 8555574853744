import { Action } from '@ngrx/store';

export enum FileTypes {
    ADD_FILE = '[Files] ADD_FILE',
    UPDATE_FILE = '[Files] UPDATE_FILE',
    REPLACE_FILE = '[Files] REPLACE_FILE',
    DELETE_FILE = '[Files] DELETE_FILE',
    SUBMIT_IMAGES = '[Files] SUBMIT_IMAGES',
    SUBMIT_IMAGES_PROGRESS = '[Files] SUBMIT_IMAGES PROGESS',
    SUBMIT_IMAGES_SUCCESS = '[Files] SUBMIT_IMAGES SUCCESS',
    SUBMIT_IMAGES_FAILURE = '[Files] SUBMIT_IMAGES FAILURE',
    RESET_FILES = '[Files] Reset Files',
    DIALOG_CLOSE = '[Files] Dialog Close',
}

/* istanbul ignore next */
export class AddFile implements Action {
    readonly type = FileTypes.ADD_FILE;

    constructor(
        public payload: {
            file: File | any;
            url?: string;
        }
    ) {}
}
/* istanbul ignore next */
export class UpdateFile implements Action {
    readonly type = FileTypes.UPDATE_FILE;

    constructor(
        public payload: {
            file: File | any;
            url?: string;
        }
    ) {}
}
/* istanbul ignore next */
export class ReplaceFile implements Action {
    readonly type = FileTypes.REPLACE_FILE;

    constructor(
        public payload: {
            file: File;
            index: number;
            url: string;
        }
    ) {}
}

/* istanbul ignore next */
export class DeleteFile implements Action {
    readonly type = FileTypes.DELETE_FILE;

    constructor(public payload: { file: File }) {}
}

/* istanbul ignore next */
export class SubmitImages implements Action {
    readonly type = FileTypes.SUBMIT_IMAGES;

    constructor(public payload: { images: File[] }) {}
}

/* istanbul ignore next */
export class SubmitImagesProgress implements Action {
    readonly type = FileTypes.SUBMIT_IMAGES_PROGRESS;

    constructor(public payload: { index: number; progress: number }) {}
}

/* istanbul ignore next */
export class SubmitImagesSuccess implements Action {
    readonly type = FileTypes.SUBMIT_IMAGES_SUCCESS;

    constructor(
        public payload: {
            images: {
                id: number;
                file: File;
                url: string;
            }[];
        }
    ) {}
}

/* istanbul ignore next */
export class SubmitImagesFailure implements Action {
    readonly type = FileTypes.SUBMIT_IMAGES_FAILURE;

    constructor(public error: any) {}
}

export class ResetFiles implements Action {
    readonly type = FileTypes.RESET_FILES;
}

export type FileActions =
    | AddFile
    | UpdateFile
    | ReplaceFile
    | DeleteFile
    | ResetFiles
    | SubmitImages
    | SubmitImagesProgress
    | SubmitImagesSuccess
    | SubmitImagesFailure;
