"use strict";
/// <reference path="../../../../models/src/lib/api/permission.ts" />
Object.defineProperty(exports, "__esModule", { value: true });
var PermissionsActionTypes;
(function (PermissionsActionTypes) {
    PermissionsActionTypes["LoadPermissions"] = "[Permissions] Load Permissions";
    PermissionsActionTypes["PermissionsLoaded"] = "[Permissions] Permissions Loaded";
    PermissionsActionTypes["PermissionsLoadError"] = "[Permissions] Permissions Load Error";
    PermissionsActionTypes["ChangePermission"] = "[Permissions] Change Permission";
    PermissionsActionTypes["DiscardPermissionChange"] = "[Permissions] Discard Permission Change";
    PermissionsActionTypes["SavePermissions"] = "[Permissions] Save Permissions";
    PermissionsActionTypes["PermissionsSaved"] = "[Permissions] Permissions Saved";
    PermissionsActionTypes["PermissionsUpdated"] = "[Permissions] Permissions Updated";
})(PermissionsActionTypes = exports.PermissionsActionTypes || (exports.PermissionsActionTypes = {}));
var LoadPermissions = /** @class */ (function () {
    function LoadPermissions(payload) {
        this.payload = payload;
        this.type = PermissionsActionTypes.LoadPermissions;
    }
    return LoadPermissions;
}());
exports.LoadPermissions = LoadPermissions;
var PermissionsLoaded = /** @class */ (function () {
    function PermissionsLoaded(payload) {
        this.payload = payload;
        this.type = PermissionsActionTypes.PermissionsLoaded;
    }
    return PermissionsLoaded;
}());
exports.PermissionsLoaded = PermissionsLoaded;
var PermissionsLoadError = /** @class */ (function () {
    function PermissionsLoadError(payload) {
        this.payload = payload;
        this.type = PermissionsActionTypes.PermissionsLoadError;
    }
    return PermissionsLoadError;
}());
exports.PermissionsLoadError = PermissionsLoadError;
var ChangePermission = /** @class */ (function () {
    function ChangePermission(payload) {
        this.payload = payload;
        this.type = PermissionsActionTypes.ChangePermission;
    }
    return ChangePermission;
}());
exports.ChangePermission = ChangePermission;
var DiscardPermissionChange = /** @class */ (function () {
    function DiscardPermissionChange(payload) {
        this.payload = payload;
        this.type = PermissionsActionTypes.DiscardPermissionChange;
    }
    return DiscardPermissionChange;
}());
exports.DiscardPermissionChange = DiscardPermissionChange;
var SavePermissions = /** @class */ (function () {
    function SavePermissions(payload) {
        this.payload = payload;
        this.type = PermissionsActionTypes.SavePermissions;
    }
    return SavePermissions;
}());
exports.SavePermissions = SavePermissions;
var PermissionsSaved = /** @class */ (function () {
    function PermissionsSaved(payload) {
        this.payload = payload;
        this.type = PermissionsActionTypes.PermissionsSaved;
    }
    return PermissionsSaved;
}());
exports.PermissionsSaved = PermissionsSaved;
var PermissionsUpdated = /** @class */ (function () {
    function PermissionsUpdated() {
        this.type = PermissionsActionTypes.PermissionsUpdated;
    }
    return PermissionsUpdated;
}());
exports.PermissionsUpdated = PermissionsUpdated;
exports.fromPermissionsActions = {
    LoadPermissions: LoadPermissions,
    PermissionsLoaded: PermissionsLoaded,
    PermissionsLoadError: PermissionsLoadError,
    ChangePermission: ChangePermission,
    DiscardPermissionChange: DiscardPermissionChange,
    SavePermissions: SavePermissions,
    PermissionsSaved: PermissionsSaved,
    PermissionsUpdated: PermissionsUpdated,
};
