"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
/// <reference path="../../../../../models/src/lib/api/gallery-image.ts" />
var effects_1 = require("@ngrx/effects");
var src_1 = require("@fixiti/actions/src");
var operators_1 = require("rxjs/operators");
var rxjs_1 = require("rxjs");
var src_2 = require("@fixiti/api/rest/images/src");
var src_3 = require("@fixiti/external/pica/src");
var store_1 = require("@ngrx/store");
var FileEffects = /** @class */ (function () {
    function FileEffects(actions$, imagesApiService, store, imageService) {
        var _this = this;
        this.actions$ = actions$;
        this.imagesApiService = imagesApiService;
        this.store = store;
        this.imageService = imageService;
        this.submitImages$ = this.actions$.pipe(effects_1.ofType(src_1.FileTypes.SUBMIT_IMAGES), operators_1.map(function (action) { return action.payload.images; }), operators_1.mergeMap(function (files) { return _this.processFiles(files); }), operators_1.map(function (images) { return new src_1.SubmitImagesSuccess({ images: images }); }), operators_1.catchError(function (error) { return rxjs_1.of(new src_1.SubmitImagesFailure(error)); }));
        this.submitImagesSuccess$ = this.actions$.pipe(effects_1.ofType(src_1.FileTypes.SUBMIT_IMAGES_SUCCESS), operators_1.map(function (action) { return action.payload.images; }), operators_1.map(function (images) { return images.map(function (image) { return image.id; }); }), operators_1.tap(function (ids) { return _this.store.dispatch(new src_1.AddPhotoDialogIds({ ids: ids })); }));
    }
    FileEffects.prototype.processFiles = function (files) {
        var processingObs = rxjs_1.from(files.map(function (file, index) { return ({ file: file, index: index }); })).pipe(this.transformFileToFileWIthData(), this.handleUpload());
        return processingObs.pipe(operators_1.toArray());
    };
    FileEffects.prototype.transformFileToFileWIthData = function () {
        return operators_1.mergeMap(function (_a) {
            var file = _a.file, index = _a.index;
            return new Promise(function (resolve) {
                if (file instanceof File) {
                    var fr = new FileReader();
                    fr.onloadend = function ($event) {
                        resolve({
                            file: file,
                            data: btoa($event.target.result),
                            index: index,
                        });
                    };
                    fr.readAsBinaryString(file);
                }
                else {
                    resolve({
                        file: file,
                        data: null,
                        index: index,
                    });
                }
            });
        });
    };
    FileEffects.prototype.handleUpload = function () {
        var _this = this;
        return operators_1.mergeMap(function (fileInfo) {
            if (!fileInfo.data) {
                return rxjs_1.of({
                    id: fileInfo.file.id,
                    file: null,
                    url: fileInfo.file.url,
                });
            }
            else {
                return _this.imagesApiService
                    .uploadImage({
                    file: fileInfo.file,
                    data: fileInfo.data,
                    index: fileInfo.index,
                })
                    .pipe(operators_1.mergeMap(function (id) {
                    return rxjs_1.of(id)
                        .pipe(operators_1.map(function (_) { return [fileInfo.file, fileInfo]; }), _this.processImage())
                        .pipe(operators_1.map(function (url) { return ({
                        id: id,
                        file: fileInfo.file,
                        url: url,
                    }); }));
                }));
            }
        });
    };
    FileEffects.prototype.processImage = function () {
        var _this = this;
        return operators_1.concatMap(function (_a) {
            var file = _a[0], fileInfo = _a[1];
            return rxjs_1.from(new Promise(function (resolve) {
                if (fileInfo && fileInfo.data) {
                    resolve("data:image/jpeg;base64," + fileInfo.data);
                }
                else if (file instanceof File) {
                    var fr_1 = new FileReader();
                    fr_1.onloadend = function (item) {
                        resolve("data:image/jpeg;base64," + btoa(item.target.result));
                    };
                    _this.imageService
                        .resizeImage(file, 512, 512, {
                        aspectRatio: {
                            keepAspectRatio: true,
                        },
                    })
                        .pipe(operators_1.take(1))
                        .subscribe(function (resizedFile) {
                        fr_1.readAsBinaryString(resizedFile);
                    });
                }
                else {
                    resolve(file.url);
                }
            }));
        });
    };
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], FileEffects.prototype, "submitImages$", void 0);
    __decorate([
        effects_1.Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], FileEffects.prototype, "submitImagesSuccess$", void 0);
    return FileEffects;
}());
exports.FileEffects = FileEffects;
