import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
    IMPORTER_FEATURE_KEY,
    initialState as importerInitialState,
    importerReducer,
} from './+state/importer.reducer';
import { ImporterEffects } from './+state/importer.effects';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(IMPORTER_FEATURE_KEY, importerReducer, {
            initialState: importerInitialState,
        }),
        EffectsModule.forFeature([ImporterEffects]),
    ],
})
export class StateImporterModule {}
