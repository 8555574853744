"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var store_1 = require("@ngrx/store");
var operators_1 = require("rxjs/operators");
var http_1 = require("@angular/common/http");
var src_1 = require("@fixiti/state/environment/src");
var RestApiBase = /** @class */ (function () {
    function RestApiBase(store, modalService) {
        this.store = store;
        this.modalService = modalService;
        this.window = window;
    }
    Object.defineProperty(RestApiBase.prototype, "serverObs", {
        get: function () {
            var _this = this;
            return this.environmentApiUrl().pipe(operators_1.mergeMap(function (envUrl) {
                if (envUrl) {
                    return rxjs_1.of(envUrl);
                }
                else if (_this.window && _this.window.location && _this.window.location.hostname) {
                    return rxjs_1.of(_this.window.location.protocol + "//" + _this.window.location.hostname + "/");
                }
                else {
                    return rxjs_1.of("https://bizhub.fixiti.com/");
                }
            }), operators_1.take(1));
        },
        enumerable: true,
        configurable: true
    });
    RestApiBase.prototype.isProduction = function () {
        return this.store.pipe(store_1.select(src_1.environmentQuery.getIsProduction));
    };
    RestApiBase.prototype.environmentApiUrl = function () {
        return this.store.pipe(store_1.select(src_1.environmentQuery.getApiUrl));
    };
    RestApiBase.prototype.defaultHttpHeaders = function () {
        var _this = this;
        return this.defaultHttpParameters().pipe(operators_1.map(function (parameters) { return _this.buildHttpHeader(parameters); }), operators_1.take(1));
    };
    RestApiBase.prototype.buildHttpHeader = function (parameters) {
        var header = new http_1.HttpHeaders();
        Object.entries(parameters).forEach(function (_a) {
            var key = _a[0], value = _a[1];
            header = header.append(key, value);
        });
        return header;
    };
    RestApiBase.prototype.checkForError = function () {
        return operators_1.map(function (json) {
            if (json.status === 'error' || json.status === 'failure') {
                console.error(json.errorMessage);
                throw new Error("We're currently having issues. Please try again later.");
            }
            else if (json.data && json.data.inputErrors) {
                throw (json.data.errors && json.data.errors[0]) ||
                    new Error("We're currently having issues. Please try again later.");
            }
            return json;
        });
    };
    RestApiBase.prototype.checkForFailure = function () {
        return operators_1.map(function (json) {
            if (json.status === 'failure') {
                throw new Error();
            }
            return json;
        });
    };
    RestApiBase.prototype.handleError = function (error) {
        console.error(error);
        this.modalService.error();
        return Promise.reject('');
    };
    RestApiBase.prototype.setWindowLocationHref = function (url, window) {
        window.location.href = url;
    };
    RestApiBase.prototype.retryStrategy = function (attempts) {
        if (attempts === void 0) { attempts = 5; }
        return operators_1.retryWhen(function (errors) {
            return errors.pipe(operators_1.concatMap(function (e, i) { return rxjs_1.iif(function () { return i > attempts; }, rxjs_1.throwError(e), rxjs_1.of(e).pipe(operators_1.delay(500))); }));
        });
    };
    RestApiBase.prototype.buildQueryParams = function (params, cacheBust) {
        if (cacheBust === void 0) { cacheBust = false; }
        var httpParams = new http_1.HttpParams();
        if (params) {
            Object.entries(params).forEach(function (_a) {
                var key = _a[0], value = _a[1];
                if (key === null ||
                    value === null ||
                    typeof value === 'undefined' ||
                    typeof key === 'undefined') {
                    return;
                }
                httpParams = httpParams.set(key, value);
            });
        }
        if (cacheBust) {
            httpParams = httpParams.set(Math.round(Date.now() / 30000).toString(), '');
        }
        return httpParams;
    };
    return RestApiBase;
}());
exports.RestApiBase = RestApiBase;
