<button mat-button [disabled]="!isEnabled" (click)="onClick($event)">
    <fixiti-gallery-preview-presenter
        [image]="image"
        [placeholder]="placeholder"
        [gallery]="gallery"
        [count]="count"
        [minimum]="minimum"
        [showBadge]="showBadge"
    >
    </fixiti-gallery-preview-presenter>
</button>
