import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from './components/modal/modal.component';
import { ModalService } from './services/modal.service';
import { ErrorComponent } from './components/error/error.component';
import { MessageComponent } from './components/message/message.component';

@NgModule({
    imports: [CommonModule],
    providers: [ModalService],
    declarations: [ErrorComponent, MessageComponent, ModalComponent],
    entryComponents: [ErrorComponent, MessageComponent, ModalComponent],
    exports: [ModalComponent],
})
export class ElementsModalModule {}
