"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
/// <reference path="../../../../../models/src/lib/api/gallery-image.ts" />
var src_1 = require("@fixiti/actions/src");
var store_1 = require("@ngrx/store");
exports.fileInitialState = {
    items: [],
    itemUrls: [],
    itemProgress: [],
    progress: 0,
    isUploading: false,
    ids: [],
    photos: {},
};
function fileReducer(state, action) {
    if (state === void 0) { state = exports.fileInitialState; }
    switch (action.type) {
        case src_1.FileTypes.ADD_FILE: {
            if (fileIsCached(state, action) || !action.payload.file) {
                return state;
            }
            else if (action.payload.file.url) {
                return __assign({}, state, { items: state.items.concat([action.payload.file]), itemUrls: state.itemUrls.concat([action.payload.file.url]) });
            }
            else {
                return __assign({}, state, { items: state.items.concat([action.payload.file]), itemUrls: state.itemUrls.concat([action.payload.url]) });
            }
        }
        case src_1.FileTypes.UPDATE_FILE: {
            var fileIndex = state.items.findIndex(function (item) { return item === action.payload.file; });
            if (fileIndex >= 0) {
                return __assign({}, state, { items: state.items.slice(0, fileIndex).concat([
                        action.payload.file
                    ], state.items.slice(fileIndex + 1)), itemUrls: state.itemUrls.slice(0, fileIndex).concat([
                        action.payload.url
                    ], state.itemUrls.slice(fileIndex + 1)) });
            }
            return state;
        }
        case src_1.FileTypes.REPLACE_FILE: {
            if (action.payload.index > state.items.length) {
                return __assign({}, state, { items: state.items.concat([action.payload.file]), itemUrls: state.itemUrls.concat([action.payload.url]) });
            }
            else if (state.items.length >= action.payload.index &&
                action.payload.index >= 0) {
                var items = state.items.slice(0, action.payload.index).concat([
                    action.payload.file
                ], state.items.slice(action.payload.index + 1));
                var itemUrls = state.itemUrls.slice(0, action.payload.index).concat([
                    action.payload.url
                ], state.itemUrls.slice(action.payload.index + 1));
                return __assign({}, state, { items: items,
                    itemUrls: itemUrls });
            }
            return state;
        }
        case src_1.FileTypes.DELETE_FILE:
            var index = state.items.findIndex(function (item) { return item === action.payload.file; });
            if (index >= 0) {
                var items = state.items.slice(0, index).concat(state.items.slice(index + 1));
                return __assign({}, state, { items: items });
            }
            else {
                return state;
            }
        case src_1.FileTypes.SUBMIT_IMAGES: {
            return __assign({}, state, { progress: 0, isUploading: true, itemProgress: action.payload.images.map(function () { return 0; }) });
        }
        case src_1.FileTypes.SUBMIT_IMAGES_PROGRESS: {
            var newState = __assign({}, state, { itemProgress: state.itemProgress.slice() });
            if (action.payload.index >= 0) {
                newState.itemProgress[action.payload.index] =
                    action.payload.progress;
            }
            var totalProgress = newState.itemProgress.reduce(function (previousValue, nextItem) { return previousValue + nextItem; }, 0);
            var length_1 = newState.itemProgress.length || 1;
            newState.progress = Math.round(totalProgress / length_1);
            return newState;
        }
        case src_1.FileTypes.SUBMIT_IMAGES_FAILURE: {
            return __assign({}, state, { progress: 0, isUploading: false, itemProgress: [] });
        }
        case src_1.FileTypes.SUBMIT_IMAGES_SUCCESS: {
            var items = state.items, itemUrls = state.itemUrls, itemProgress = state.itemProgress, progress = state.progress, isUploading = state.isUploading, partialState = __rest(state, ["items", "itemUrls", "itemProgress", "progress", "isUploading"]);
            var photos_1 = __assign({}, state.photos);
            action.payload.images
                .filter(function (image) { return !!image.url; })
                .forEach(function (image) {
                if (!photos_1[image.id]) {
                    photos_1[image.id] = image.url;
                }
            });
            return __assign({}, exports.fileInitialState, partialState, { photos: photos_1, ids: action.payload.images.map(function (image) { return image.id; }) });
        }
        default:
            return state;
    }
}
exports.fileReducer = fileReducer;
exports.selectFileState = store_1.createFeatureSelector('files');
exports.selectAllImages = store_1.createSelector(exports.selectFileState, function (state) { return state.items; });
exports.selectAllImageUrls = store_1.createSelector(exports.selectFileState, function (state) { return state.itemUrls; });
exports.selectImageUploadProgress = store_1.createSelector(exports.selectFileState, function (state) { return state.progress; });
exports.selectIsImageUploading = store_1.createSelector(exports.selectFileState, function (state) { return state.isUploading; });
exports.selectLocalPhotoMap = store_1.createSelector(exports.selectFileState, function (state) { return state.photos; });
exports.selectLastImageUploadIds = store_1.createSelector(exports.selectFileState, function (state) { return state.ids; });
var fileIsCached = function (state, action) {
    return (state.items.findIndex(function (item) {
        if (item instanceof File) {
            return (item.name === action.payload.file.name &&
                item.lastModified === action.payload.file.lastModified &&
                item.size === action.payload.file.size &&
                item.type === action.payload.file.type);
        }
        else {
            return item === action.payload.file;
        }
    }) !== -1);
};
var ɵ0 = fileIsCached;
exports.ɵ0 = ɵ0;
