import { ModuleWithProviders, NgModule } from '@angular/core';
import {
    photoDialogInitialState,
    photoDialogReducer,
} from './reducers/photo-dialog.reducer';

import { CommonModule } from '@angular/common';
import { DialogBodyComponent } from './components/dialog-body/dialog-body.component';
import { DialogContainerComponent } from './components/dialog-container/dialog-container.component';
import { DialogFooterComponent } from './components/dialog-footer/dialog-footer.component';
import { DialogHeaderComponent } from './components/dialog-header/dialog-header.component';
import { DialogHeaderIconComponent } from './components/dialog-header-icon/dialog-header-icon.component';
import { DialogHeaderTitleComponent } from './components/dialog-header-title/dialog-header-title.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HttpClientModule } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { PhotoCounterIconComponent } from './components/photo-counter-icon/photo-counter-icon.component';
import { PhotoSummaryComponent } from './components/photo-summary/photo-summary.component';
import { PicaModule } from '@fixiti/external/pica/src';
import { SharedGalleryPreviewButtonModule } from '@fixiti/shared/gallery-preview/button/src';
import { StoreModule } from '@ngrx/store';
import { UploadImagesActionsCancelComponent } from './components/upload-images-actions-cancel/upload-images-actions-cancel.component';
import { UploadImagesActionsComponent } from './components/upload-images-actions/upload-images-actions.component';
import { UploadImagesActionsSubmitComponent } from './components/upload-images-actions-submit/upload-images-actions-submit.component';
import { UploadImagesComponent } from './components/upload-images/upload-images.component';
import { UploadImagesGalleryComponent } from './components/upload-images-gallery/upload-images-gallery.component';
import { UploadImagesProgressComponent } from './components/upload-images-progress/upload-images-progress.component';
import { UploadImagesSelectionComponent } from './components/upload-images-selection/upload-images-selection.component';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons/faFileAlt';
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(faFileAlt);

const MATERIAL = [MatDialogModule, MatButtonModule, MatProgressBarModule];

const EXPORT_COMPONENTS = [DialogContainerComponent];

const INTERNAL_COMPONENTS = [
    DialogHeaderComponent,
    DialogBodyComponent,
    DialogFooterComponent,
    PhotoCounterIconComponent,
    PhotoSummaryComponent,
    DialogHeaderIconComponent,
    DialogHeaderTitleComponent,
    UploadImagesComponent,
    UploadImagesActionsComponent,
    UploadImagesGalleryComponent,
    UploadImagesSelectionComponent,
    UploadImagesProgressComponent,
    UploadImagesActionsSubmitComponent,
    UploadImagesActionsCancelComponent,
];

@NgModule({
    imports: [
        CommonModule,
        SharedGalleryPreviewButtonModule,
        ...MATERIAL,
        HttpClientModule,
        FontAwesomeModule,
        PicaModule,
    ],
    exports: [...EXPORT_COMPONENTS, ...MATERIAL],
    declarations: [...EXPORT_COMPONENTS, ...INTERNAL_COMPONENTS],
    entryComponents: [...EXPORT_COMPONENTS],
})
export class PhotoDialogModule {
    /* istanbul ignore next */
    static forRoot(): ModuleWithProviders {
        return {
            /* ts-lint ignore next */
            ngModule: RootPhotoDialogModule,
        };
    }
}
@NgModule({
    imports: [
        StoreModule.forFeature('photo-dialog', photoDialogReducer, {
            initialState: photoDialogInitialState,
        }),
    ],
    exports: [PhotoDialogModule],
})
export class RootPhotoDialogModule {}
