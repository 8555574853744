import { createFeatureSelector, createSelector } from '@ngrx/store';

import { UserState } from './user.reducer';

// Lookup the 'User' feature state managed by NgRx
const getUserState = createFeatureSelector<UserState>('user');

const getUser = createSelector(
    getUserState,
    (state: UserState) => state.current
);

export const userQuery = {
    getUser,
};
