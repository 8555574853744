"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
/// <reference path="../../../../../../models/src/lib/api/gallery-image.ts" />
var core_1 = require("@angular/core");
var dialog_1 = require("@angular/material/dialog");
var rxjs_1 = require("rxjs");
var store_1 = require("@ngrx/store");
var operators_1 = require("rxjs/operators");
var src_1 = require("@fixiti/state/files/src");
var photo_dialog_reducer_1 = require("../../reducers/photo-dialog.reducer");
var src_2 = require("@fixiti/external/pica/src");
var src_3 = require("@fixiti/actions/src");
var UploadImagesComponent = /** @class */ (function () {
    /* istanbul ignore next */
    function UploadImagesComponent(store, dialog, imageService) {
        this.store = store;
        this.dialog = dialog;
        this.imageService = imageService;
        this.ngUnsubscribe = new rxjs_1.Subject();
        this.progressObs = this.store.pipe(store_1.select(src_1.selectImageUploadProgress));
        this.isUploadingObs = this.store.pipe(store_1.select(src_1.selectIsImageUploading));
        this.placeholderObs = this.store.pipe(store_1.select(photo_dialog_reducer_1.selectPhotoDialogPlaceHolder));
        this.minimumObs = this.store.pipe(store_1.select(photo_dialog_reducer_1.selectPhotoDialogConfig), operators_1.map(function (options) { return options.minimum; }));
    }
    UploadImagesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.images = this.store.pipe(store_1.select(src_1.selectAllImages), operators_1.filter(function (items) { return !!items; }), operators_1.distinctUntilChanged(), operators_1.switchMap(function (items) {
            return _this.processImages(items);
        }), operators_1.takeUntil(this.ngUnsubscribe));
        this.gallery = this.store.pipe(store_1.select(photo_dialog_reducer_1.selectPhotoDialogConfig), operators_1.map(function (config) { return config.gallery; }));
    };
    UploadImagesComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    UploadImagesComponent.prototype.processImages = function (items) {
        var _this = this;
        return rxjs_1.from(items).pipe(operators_1.concatMap(function (file) {
            return rxjs_1.from(new Promise(function (resolve) {
                if (file instanceof File) {
                    var fr = new FileReader();
                    fr.onloadend = function (item) {
                        resolve({
                            url: "data:image/jpeg;base64," + btoa(item.target.result),
                            src: file,
                        });
                        _this.loadSmallerThumbnail(file);
                    };
                    fr.readAsBinaryString(file);
                }
                else {
                    resolve(file);
                }
            }));
        }), operators_1.toArray());
    };
    UploadImagesComponent.prototype.loadSmallerThumbnail = function (file) {
        var _this = this;
        return new Promise(function (resolve) {
            var fr = new FileReader();
            fr.onloadend = function (item) {
                resolve({
                    url: "data:image/jpeg;base64," + btoa(item.target.result),
                    src: file,
                });
            };
            _this.imageService
                .resizeImage(file, 512, 512, {
                aspectRatio: {
                    keepAspectRatio: true,
                },
            })
                .pipe(operators_1.take(1))
                .subscribe(function (resizedFile) {
                fr.readAsBinaryString(resizedFile);
            });
        });
    };
    UploadImagesComponent.prototype.onCancel = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.close()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    UploadImagesComponent.prototype.onSubmit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var images;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.store
                            .pipe(store_1.select(src_1.selectAllImages), operators_1.map(function (storeImages) {
                            return storeImages.filter(function (image) { return image instanceof File; });
                        }), operators_1.take(1))
                            .toPromise()];
                    case 1:
                        images = _a.sent();
                        this.store.dispatch(new src_3.SubmitImages({ images: images }));
                        return [4 /*yield*/, this.close()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    UploadImagesComponent.prototype.close = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.dialogReference) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.closeUsingDialogReference()];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        this.dialog.closeAll();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    UploadImagesComponent.prototype.closeUsingDialogReference = function () {
        return __awaiter(this, void 0, void 0, function () {
            var isUploading;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.store
                            .pipe(store_1.select(src_1.selectIsImageUploading), operators_1.take(1))
                            .toPromise()];
                    case 1:
                        isUploading = _a.sent();
                        this.dialogReference.close({
                            isUploading: isUploading,
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    return UploadImagesComponent;
}());
exports.UploadImagesComponent = UploadImagesComponent;
