import {
    AddFile,
    LoadPhotoDialogConfiguration,
    PhotoDialogClose,
} from '@fixiti/actions';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { PhotoDialogConfig } from '@fixiti/models';
import { Store } from '@ngrx/store';

@Component({
    selector: 'fixiti-photo-dialog-dialog-container',
    templateUrl: './dialog-container.component.html',
    styleUrls: ['./dialog-container.component.css'],
})
export class DialogContainerComponent implements OnInit, OnDestroy {
    constructor(
        public dialogRef: MatDialogRef<DialogContainerComponent>,
        @Inject(MAT_DIALOG_DATA) public data: PhotoDialogConfig,
        private store: Store<any>
    ) {}

    ngOnInit() {
        this.setConfig(this.data);
    }

    ngOnDestroy() {
        this.store.dispatch(new PhotoDialogClose());
    }

    setConfig(data: PhotoDialogConfig) {
        if (data != null) {
            this.store.dispatch(
                new LoadPhotoDialogConfiguration({
                    config: data,
                })
            );
            if (data.gallery) {
                data.gallery.forEach(item => {
                    this.store.dispatch(
                        new AddFile({
                            file: item,
                        })
                    );
                });
            }
        }
    }
}
