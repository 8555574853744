export class ListViewPaginationModel {
    numMatches: number;
    numReturned: number;
    start: number;
    end: number;
    isFirstPage: boolean;
    isLastPage: boolean;
    maxPage: number;
    currentPage: number;
    nextPage: number;
    previousPage: number;
}
