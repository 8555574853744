<label class="mat-file-input button mat-raised-button">
    <input
        #cameraSelector
        type="file"
        accept="image/jpg,image/JPG,image/jpeg,image/JPEG"
        capture="camera"
        (change)="onChange($event)"
    />
    <button
        class="input-button"
        type="button"
        mat-raised-button
        color="primary"
        (click)="selectFile(cameraSelector)"
    >
        <span class="button-text">Take More Photos</span>
    </button>
</label>
<label class="mat-file-input button mat-raised-button">
    <input
        class="multiple"
        #imageSelector
        type="file"
        accept="image/jpg,image/JPG,image/jpeg,image/JPEG"
        multiple
        (change)="onChange($event)"
    />
    <button
        class="input-button"
        type="button"
        mat-raised-button
        color="accent"
        (click)="selectFile(imageSelector)"
    >
        <span class="button-text">Choose from Library</span>
    </button>
</label>
