/// <reference path="./../../../../models/src/lib/api/all-query.ts" />
import { Action } from '@ngrx/store';

export enum ResidentsActionTypes {
    LoadResidents = '[Residents] Load Residents',
    ResidentsLoaded = '[Residents] Residents Loaded',
    ResidentsLoadError = '[Residents] Residents Load Error',
    SaveResident = '[Residents] Save Resident',
    ResidentSaved = '[Residents] Resident Saved',
    RemoveResident = '[Residents] Remove Resident',
    ResidentRemoved = '[Residents] Resident Removed',
    SelectResident = '[Residents] Select Resident',
    EditResident = '[Residents] Edit Resident',
}

export class LoadResidents implements Action {
    readonly type = ResidentsActionTypes.LoadResidents;
    constructor(public payload?: ApiModel.AllQuery) {}
}

export class ResidentsLoadError implements Action {
    readonly type = ResidentsActionTypes.ResidentsLoadError;
    constructor(public payload: any) {}
}

export class ResidentsLoaded implements Action {
    readonly type = ResidentsActionTypes.ResidentsLoaded;
    constructor(public payload: ApiModel.Person[]) {}
}

export class SaveResident implements Action {
    readonly type = ResidentsActionTypes.SaveResident;
    constructor(public payload: ApiModel.Person) {}
}

export class ResidentSaved implements Action {
    readonly type = ResidentsActionTypes.ResidentSaved;
    constructor(public payload: ApiModel.Person) {}
}

export class RemoveResident implements Action {
    readonly type = ResidentsActionTypes.RemoveResident;
    constructor(public payload: number) {}
}

export class ResidentRemoved implements Action {
    readonly type = ResidentsActionTypes.ResidentRemoved;
    constructor(public payload: number) {}
}

export class SelectResident implements Action {
    readonly type = ResidentsActionTypes.SelectResident;
    constructor(public payload: number) {}
}

export class EditResident implements Action {
    readonly type = ResidentsActionTypes.EditResident;
    constructor(public payload: number) {}
}

export type ResidentsAction =
    | LoadResidents
    | ResidentsLoaded
    | ResidentsLoadError
    | SaveResident
    | ResidentSaved
    | RemoveResident
    | ResidentRemoved
    | SelectResident
    | EditResident;

export const fromResidentsActions = {
    LoadResidents,
    ResidentsLoaded,
    ResidentsLoadError,
    SaveResident,
    ResidentSaved,
    RemoveResident,
    ResidentRemoved,
    SelectResident,
    EditResident,
};
