/// <reference path="../../../../models/src/lib/api/task.ts" />
import { Action } from '@ngrx/store';

export enum ScopesActionTypes {
    SelectScope = '[Scopes] Select Scope',
    LoadScopes = '[Scopes] Load Data',
    ScopesLoaded = '[Scopes] Data Loaded',
    SaveScope = '[Scopes] Save Scope',
    ScopeSaved = '[Scopes] Scope Saved',
}

export class SelectScope implements Action {
    readonly type = ScopesActionTypes.SelectScope;
    constructor(
        public payload: {
            id: number;
        }
    ) {}
}

export class LoadScopes implements Action {
    readonly type = ScopesActionTypes.LoadScopes;
    constructor(
        public payload: {
            id: number;
            items?: ApiModel.Task[];
        }
    ) {}
}

export class ScopesLoaded implements Action {
    readonly type = ScopesActionTypes.ScopesLoaded;
    constructor(
        public payload: {
            items: ApiModel.Task[];
        }
    ) {}
}

export class SaveScope implements Action {
    readonly type = ScopesActionTypes.SaveScope;
    constructor(
        public payload: {
            scope: ApiModel.Task;
            diff: ApiModel.Task;
        }
    ) {}
}

export class ScopeSaved implements Action {
    readonly type = ScopesActionTypes.ScopeSaved;
    constructor(
        public payload: {
            scopes: ApiModel.Task[];
        }
    ) {}
}

export type ScopesActions =
    | LoadScopes
    | ScopesLoaded
    | SelectScope
    | SaveScope
    | ScopeSaved;
