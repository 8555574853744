"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var store_1 = require("@ngrx/store");
// Lookup the 'Search' feature state managed by NgRx
var getSearchState = store_1.createFeatureSelector('search');
var ɵ0 = function (state) { return state.raw; };
exports.ɵ0 = ɵ0;
var getCurrentSearch = store_1.createSelector(getSearchState, ɵ0);
var ɵ1 = function (state) { return (state.raw && state.raw.toLowerCase()) || ''; };
exports.ɵ1 = ɵ1;
var getSimplifiedCurrentSearch = store_1.createSelector(getSearchState, ɵ1);
var ɵ2 = function (state) { return (state.raw && state.raw.split(' ')) || []; };
exports.ɵ2 = ɵ2;
var getCurrentSearchTerms = store_1.createSelector(getSearchState, ɵ2);
var ɵ3 = function (state) {
    return (state.raw && state.raw.toLowerCase().split(' ')) || [];
};
exports.ɵ3 = ɵ3;
var getSimplifiedCurrentSearchTerms = store_1.createSelector(getSearchState, ɵ3);
exports.searchQuery = {
    getCurrentSearch: getCurrentSearch,
    getSimplifiedCurrentSearch: getSimplifiedCurrentSearch,
    getCurrentSearchTerms: getCurrentSearchTerms,
    getSimplifiedCurrentSearchTerms: getSimplifiedCurrentSearchTerms,
};
