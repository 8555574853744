/// <reference path="./api/all-query.ts" />

export interface PageModel {
    filter?: string;
    sortDirection: string;
    sortField?: string;
    pageIndex: number;
    pageSize: number;
    reportId?: number;
}

export class PageConverter {
    static convert(item: PageModel): ApiModel.AllQuery {
        const allApiObject: ApiModel.AllQuery = {};
        if (item.filter != null) {
            allApiObject.q = item.filter;
        }
        if (item.sortDirection != null) {
            allApiObject.direction = item.sortDirection.toUpperCase();
        }
        if (item.pageIndex != null) {
            allApiObject.page = item.pageIndex ? item.pageIndex + 1 : 0;
        }
        if (item.pageSize != null) {
            allApiObject.limit = item.pageSize;
        }
        if (item.sortField != null) {
            allApiObject.sort = item.sortField;
        }
        if (item.reportId != null) {
            allApiObject.report_id = item.reportId;
        }
        return allApiObject;
    }
}

export class QueryConverter {
    static convert(item: ApiModel.AllQuery): PageModel {
        return {
            filter: item.q,
            sortDirection: item.direction.toLowerCase(),
            pageIndex: item.page,
            pageSize: item.limit,
            sortField: item.sort,
            reportId: item.report_id,
        };
    }
}
