"use strict";
/// <reference path="./../../../../models/src/lib/api/all-query.ts" />
/// <reference path="../../../../models/src/lib/api/negotiation.ts" />
Object.defineProperty(exports, "__esModule", { value: true });
var WorkOrdersActionTypes;
(function (WorkOrdersActionTypes) {
    WorkOrdersActionTypes["LoadWorkOrders"] = "[WorkOrders] Load WorkOrders";
    WorkOrdersActionTypes["WorkOrdersLoaded"] = "[WorkOrders] WorkOrders Loaded";
    WorkOrdersActionTypes["WorkOrdersLoadError"] = "[WorkOrders] WorkOrders Load Error";
    WorkOrdersActionTypes["SelectWorkOrder"] = "[WorkOrders] Select WorkOrder";
    WorkOrdersActionTypes["ShowComments"] = "[WorkOrders] Show Comments";
    WorkOrdersActionTypes["HideComments"] = "[WorkOrders] Hide Comments";
    WorkOrdersActionTypes["LoadComments"] = "[WorkOrders] Load Comments";
    WorkOrdersActionTypes["CommentsLoaded"] = "[WorkOrders] Comments Loaded";
    WorkOrdersActionTypes["CommentsLoadError"] = "[WorkOrders] Comments Load Error";
    WorkOrdersActionTypes["ApproveWorkOrder"] = "[WorkOrders] Approve Work Order";
    WorkOrdersActionTypes["DeclineWorkOrder"] = "[WorkOrders] Decline Work Order";
    WorkOrdersActionTypes["ApproveWorkOrderEstimate"] = "[WorkOrders] Approve Work Order Estimate";
    WorkOrdersActionTypes["DeclineWorkOrderEstimate"] = "[WorkOrders] Decline Work Order Estimate";
    WorkOrdersActionTypes["ApproveWorkOrderPricing"] = "[WorkOrders] Approve Work Order Pricing";
    WorkOrdersActionTypes["DeclineWorkOrderPricing"] = "[WorkOrders] Decline Work Order Pricing";
    WorkOrdersActionTypes["CounterWorkOrderPricing"] = "[WorkOrders] Counter Work Order Pricing";
    WorkOrdersActionTypes["UpdateWorkOrder"] = "[WorkOrders] Update Work Order";
    WorkOrdersActionTypes["UpdateNegotiation"] = "[WorkOrders] Update Negotiation";
    WorkOrdersActionTypes["LoadNegotiation"] = "[WorkOrders] Load Negotiation";
    WorkOrdersActionTypes["NegotiationLoaded"] = "[WorkOrders] Negotiation Loaded";
})(WorkOrdersActionTypes = exports.WorkOrdersActionTypes || (exports.WorkOrdersActionTypes = {}));
var LoadWorkOrders = /** @class */ (function () {
    function LoadWorkOrders(payload) {
        this.payload = payload;
        this.type = WorkOrdersActionTypes.LoadWorkOrders;
    }
    return LoadWorkOrders;
}());
exports.LoadWorkOrders = LoadWorkOrders;
var WorkOrdersLoaded = /** @class */ (function () {
    function WorkOrdersLoaded(payload) {
        this.payload = payload;
        this.type = WorkOrdersActionTypes.WorkOrdersLoaded;
    }
    return WorkOrdersLoaded;
}());
exports.WorkOrdersLoaded = WorkOrdersLoaded;
var WorkOrdersLoadError = /** @class */ (function () {
    function WorkOrdersLoadError(payload) {
        this.payload = payload;
        this.type = WorkOrdersActionTypes.WorkOrdersLoadError;
    }
    return WorkOrdersLoadError;
}());
exports.WorkOrdersLoadError = WorkOrdersLoadError;
var SelectWorkOrder = /** @class */ (function () {
    function SelectWorkOrder(payload) {
        this.payload = payload;
        this.type = WorkOrdersActionTypes.SelectWorkOrder;
    }
    return SelectWorkOrder;
}());
exports.SelectWorkOrder = SelectWorkOrder;
var ShowComments = /** @class */ (function () {
    function ShowComments(payload) {
        this.payload = payload;
        this.type = WorkOrdersActionTypes.ShowComments;
    }
    return ShowComments;
}());
exports.ShowComments = ShowComments;
var HideComments = /** @class */ (function () {
    function HideComments() {
        this.type = WorkOrdersActionTypes.HideComments;
    }
    return HideComments;
}());
exports.HideComments = HideComments;
var LoadComments = /** @class */ (function () {
    function LoadComments(payload) {
        this.payload = payload;
        this.type = WorkOrdersActionTypes.LoadComments;
    }
    return LoadComments;
}());
exports.LoadComments = LoadComments;
var CommentsLoadError = /** @class */ (function () {
    function CommentsLoadError(payload) {
        this.payload = payload;
        this.type = WorkOrdersActionTypes.LoadComments;
    }
    return CommentsLoadError;
}());
exports.CommentsLoadError = CommentsLoadError;
var CommentsLoaded = /** @class */ (function () {
    function CommentsLoaded(payload) {
        this.payload = payload;
        this.type = WorkOrdersActionTypes.CommentsLoaded;
    }
    return CommentsLoaded;
}());
exports.CommentsLoaded = CommentsLoaded;
var ApproveWorkOrder = /** @class */ (function () {
    function ApproveWorkOrder(payload) {
        this.payload = payload;
        this.type = WorkOrdersActionTypes.ApproveWorkOrder;
    }
    return ApproveWorkOrder;
}());
exports.ApproveWorkOrder = ApproveWorkOrder;
var DeclineWorkOrder = /** @class */ (function () {
    function DeclineWorkOrder(payload) {
        this.payload = payload;
        this.type = WorkOrdersActionTypes.DeclineWorkOrder;
    }
    return DeclineWorkOrder;
}());
exports.DeclineWorkOrder = DeclineWorkOrder;
var ApproveWorkOrderEstimate = /** @class */ (function () {
    function ApproveWorkOrderEstimate(payload) {
        this.payload = payload;
        this.type = WorkOrdersActionTypes.ApproveWorkOrderEstimate;
    }
    return ApproveWorkOrderEstimate;
}());
exports.ApproveWorkOrderEstimate = ApproveWorkOrderEstimate;
var DeclineWorkOrderEstimate = /** @class */ (function () {
    function DeclineWorkOrderEstimate(payload) {
        this.payload = payload;
        this.type = WorkOrdersActionTypes.DeclineWorkOrderEstimate;
    }
    return DeclineWorkOrderEstimate;
}());
exports.DeclineWorkOrderEstimate = DeclineWorkOrderEstimate;
var ApproveWorkOrderPricing = /** @class */ (function () {
    function ApproveWorkOrderPricing(payload) {
        this.payload = payload;
        this.type = WorkOrdersActionTypes.ApproveWorkOrderPricing;
    }
    return ApproveWorkOrderPricing;
}());
exports.ApproveWorkOrderPricing = ApproveWorkOrderPricing;
var DeclineWorkOrderPricing = /** @class */ (function () {
    function DeclineWorkOrderPricing(payload) {
        this.payload = payload;
        this.type = WorkOrdersActionTypes.DeclineWorkOrderPricing;
    }
    return DeclineWorkOrderPricing;
}());
exports.DeclineWorkOrderPricing = DeclineWorkOrderPricing;
var CounterWorkOrderPricing = /** @class */ (function () {
    function CounterWorkOrderPricing(payload) {
        this.payload = payload;
        this.type = WorkOrdersActionTypes.CounterWorkOrderPricing;
    }
    return CounterWorkOrderPricing;
}());
exports.CounterWorkOrderPricing = CounterWorkOrderPricing;
var UpdateWorkOrder = /** @class */ (function () {
    function UpdateWorkOrder(payload) {
        this.payload = payload;
        this.type = WorkOrdersActionTypes.UpdateWorkOrder;
    }
    return UpdateWorkOrder;
}());
exports.UpdateWorkOrder = UpdateWorkOrder;
var UpdateNegotiation = /** @class */ (function () {
    function UpdateNegotiation(payload) {
        this.payload = payload;
        this.type = WorkOrdersActionTypes.UpdateNegotiation;
    }
    return UpdateNegotiation;
}());
exports.UpdateNegotiation = UpdateNegotiation;
var LoadNegotiation = /** @class */ (function () {
    function LoadNegotiation(payload) {
        this.payload = payload;
        this.type = WorkOrdersActionTypes.LoadNegotiation;
    }
    return LoadNegotiation;
}());
exports.LoadNegotiation = LoadNegotiation;
var NegotiationLoaded = /** @class */ (function () {
    function NegotiationLoaded(payload) {
        this.payload = payload;
        this.type = WorkOrdersActionTypes.NegotiationLoaded;
    }
    return NegotiationLoaded;
}());
exports.NegotiationLoaded = NegotiationLoaded;
exports.fromWorkOrdersActions = {
    LoadWorkOrders: LoadWorkOrders,
    WorkOrdersLoaded: WorkOrdersLoaded,
    WorkOrdersLoadError: WorkOrdersLoadError,
    SelectWorkOrder: SelectWorkOrder,
    LoadComments: LoadComments,
    ShowComments: ShowComments,
    HideComments: HideComments,
    CommentsLoaded: CommentsLoaded,
    CommentsLoadError: CommentsLoadError,
    ApproveWorkOrder: ApproveWorkOrder,
    DeclineWorkOrder: DeclineWorkOrder,
    ApproveWorkOrderEstimate: ApproveWorkOrderEstimate,
    DeclineWorkOrderEstimate: DeclineWorkOrderEstimate,
    ApproveWorkOrderPricing: ApproveWorkOrderPricing,
    DeclineWorkOrderPricing: DeclineWorkOrderPricing,
    CounterWorkOrderPricing: CounterWorkOrderPricing,
    UpdateWorkOrder: UpdateWorkOrder,
    UpdateNegotiation: UpdateNegotiation,
    LoadNegotiation: LoadNegotiation,
    NegotiationLoaded: NegotiationLoaded,
};
