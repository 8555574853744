"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var button_1 = require("@angular/material/button");
var card_1 = require("@angular/material/card");
var dialog_1 = require("@angular/material/dialog");
var MATERIAL = [dialog_1.MatDialogModule, card_1.MatCardModule, button_1.MatButtonModule];
var ApiRestPmpModule = /** @class */ (function () {
    function ApiRestPmpModule() {
    }
    /* istanbul ignore next */
    ApiRestPmpModule.forRoot = function () {
        return {
            /* ts-lint ignore next */
            ngModule: RootRestApiPmpModule,
        };
    };
    return ApiRestPmpModule;
}());
exports.ApiRestPmpModule = ApiRestPmpModule;
var RootRestApiPmpModule = /** @class */ (function () {
    function RootRestApiPmpModule() {
    }
    return RootRestApiPmpModule;
}());
exports.RootRestApiPmpModule = RootRestApiPmpModule;
