import { Component, Input } from '@angular/core';

@Component({
    selector: 'fixiti-modal-message',
    templateUrl: './message.component.html',
    styleUrls: ['./message.component.css'],
})
export class MessageComponent {
    @Input() title: string;
    @Input() message: string;
}
