import { Observable } from 'rxjs';

export class FilterModel {
    id: number;
    name: string;
    filter: (a) => boolean;
}

export class ObservableFilterModel<T> extends FilterModel {
    observable: Observable<T[]>;
}
