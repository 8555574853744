"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./upload-images.component.css.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../upload-images-gallery/upload-images-gallery.component.ngfactory");
var i3 = require("../upload-images-gallery/upload-images-gallery.component");
var i4 = require("@ngrx/store");
var i5 = require("../../../../../../external/pica/src/lib/pica.service");
var i6 = require("@angular/common");
var i7 = require("../upload-images-selection/upload-images-selection.component.ngfactory");
var i8 = require("../upload-images-selection/upload-images-selection.component");
var i9 = require("../upload-images-progress/upload-images-progress.component.ngfactory");
var i10 = require("../upload-images-progress/upload-images-progress.component");
var i11 = require("../upload-images-actions/upload-images-actions.component.ngfactory");
var i12 = require("../upload-images-actions/upload-images-actions.component");
var i13 = require("./upload-images.component");
var i14 = require("@angular/material/dialog");
var styles_UploadImagesComponent = [i0.styles];
var RenderType_UploadImagesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UploadImagesComponent, data: {} });
exports.RenderType_UploadImagesComponent = RenderType_UploadImagesComponent;
function View_UploadImagesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "fixiti-photo-dialog-upload-images-gallery", [["class", "preview"]], null, null, null, i2.View_UploadImagesGalleryComponent_0, i2.RenderType_UploadImagesGalleryComponent)), i1.ɵdid(1, 114688, null, 0, i3.UploadImagesGalleryComponent, [i4.Store, i5.PicaService], { images: [0, "images"], text: [1, "text"], minimum: [2, "minimum"] }, null), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.images)); var currVal_1 = i1.ɵunv(_v, 1, 1, i1.ɵnov(_v, 3).transform(_co.placeholderObs)); var currVal_2 = i1.ɵunv(_v, 1, 2, i1.ɵnov(_v, 4).transform(_co.minimumObs)); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_UploadImagesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_UploadImagesComponent_1)), i1.ɵdid(1, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 1, "fixiti-photo-dialog-upload-images-selection", [["class", "input"]], null, null, null, i7.View_UploadImagesSelectionComponent_0, i7.RenderType_UploadImagesSelectionComponent)), i1.ɵdid(4, 49152, null, 0, i8.UploadImagesSelectionComponent, [i4.Store, i5.PicaService], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 3, "fixiti-photo-dialog-upload-images-progress", [], null, null, null, i9.View_UploadImagesProgressComponent_0, i9.RenderType_UploadImagesProgressComponent)), i1.ɵdid(6, 114688, null, 0, i10.UploadImagesProgressComponent, [], { progress: [0, "progress"], isVisible: [1, "isVisible"] }, null), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(9, 0, null, null, 5, "fixiti-photo-dialog-upload-images-actions", [["class", "actions"]], null, [[null, "submit"], [null, "cancel"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (_co.onSubmit() !== false);
        ad = (pd_0 && ad);
    } if (("cancel" === en)) {
        var pd_1 = (_co.onCancel() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i11.View_UploadImagesActionsComponent_0, i11.RenderType_UploadImagesActionsComponent)), i1.ɵdid(10, 49152, null, 0, i12.UploadImagesActionsComponent, [], { files: [0, "files"], canCancel: [1, "canCancel"], canSubmit: [2, "canSubmit"], minimum: [3, "minimum"] }, { submit: "submit", cancel: "cancel" }), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = !i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.isUploadingObs)); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_co.progressObs)); var currVal_2 = i1.ɵunv(_v, 6, 1, i1.ɵnov(_v, 8).transform(_co.isUploadingObs)); _ck(_v, 6, 0, currVal_1, currVal_2); var currVal_3 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform(_co.images)); var currVal_4 = !i1.ɵunv(_v, 10, 1, i1.ɵnov(_v, 12).transform(_co.isUploadingObs)); var currVal_5 = !i1.ɵunv(_v, 10, 2, i1.ɵnov(_v, 13).transform(_co.isUploadingObs)); var currVal_6 = i1.ɵunv(_v, 10, 3, i1.ɵnov(_v, 14).transform(_co.minimumObs)); _ck(_v, 10, 0, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
exports.View_UploadImagesComponent_0 = View_UploadImagesComponent_0;
function View_UploadImagesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fixiti-photo-dialog-upload-images", [], null, null, null, View_UploadImagesComponent_0, RenderType_UploadImagesComponent)), i1.ɵdid(1, 245760, null, 0, i13.UploadImagesComponent, [i4.Store, i14.MatDialog, i5.PicaService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_UploadImagesComponent_Host_0 = View_UploadImagesComponent_Host_0;
var UploadImagesComponentNgFactory = i1.ɵccf("fixiti-photo-dialog-upload-images", i13.UploadImagesComponent, View_UploadImagesComponent_Host_0, { dialogReference: "dialogReference" }, {}, []);
exports.UploadImagesComponentNgFactory = UploadImagesComponentNgFactory;
