/// <reference path="../../../../../../models/src/lib/api/gallery-image.ts" />
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

@Component({
    selector: 'fixiti-gallery-preview-button',
    templateUrl: './gallery-preview-button.component.html',
    styleUrls: ['./gallery-preview-button.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GalleryPreviewButtonComponent implements OnInit {
    @Input()
    id: number;
    @Input()
    count: number;
    @Input()
    minimum = 0;
    @Input()
    image: { src?: File; url: string };
    @Input()
    placeholder: string;
    @Input()
    gallery: ApiModel.GalleryImage[];
    @Output()
    galleryChange = new EventEmitter<any[]>();
    @Input()
    isEnabled = true;
    @Input()
    showBadge = true;
    @Output()
    clickChange = new EventEmitter();

    constructor() {}

    ngOnInit() {}

    onClick($event: MouseEvent) {
        $event.preventDefault();
        this.clickChange.emit();
    }
}
