import { createFeatureSelector, createSelector } from '@ngrx/store';

import { SidenavState } from './sidenav.reducer';

// Lookup the 'Sidenav' feature state managed by NgRx
const getSidenavState = createFeatureSelector<SidenavState>('sidenav');

const getIsSidenavOpen = createSelector(
    getSidenavState,
    (state: SidenavState) => state.isOpen
);

export const sidenavQuery = {
    getIsSidenavOpen,
};
