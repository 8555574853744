"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var FilesModule = /** @class */ (function () {
    function FilesModule() {
    }
    /* istanbul ignore next */
    FilesModule.forRoot = function () {
        return {
            /* ts-lint ignore next */
            ngModule: RootFilesModule,
        };
    };
    return FilesModule;
}());
exports.FilesModule = FilesModule;
var RootFilesModule = /** @class */ (function () {
    function RootFilesModule() {
    }
    return RootFilesModule;
}());
exports.RootFilesModule = RootFilesModule;
