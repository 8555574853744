import { AddFile, UpdateFile } from '@fixiti/actions/src';
import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { mergeMap, take, toArray } from 'rxjs/operators';

import { PicaService } from '@fixiti/external/pica/src';
import { Store } from '@ngrx/store';

@Component({
    selector: 'fixiti-photo-dialog-upload-images-selection',
    templateUrl: './upload-images-selection.component.html',
    styleUrls: ['./upload-images-selection.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadImagesSelectionComponent {
    @ViewChild('cameraSelector')
    cameraInput;
    @ViewChild('imageSelector')
    imageInput;

    /* istanbul ignore next */
    constructor(private store: Store<any>, private imageService: PicaService) {}

    onChange($event): void {
        const files = [];
        for (let i = 0; i < $event.target.files.length; ++i) {
            files.push($event.target.files[i]);
        }
        this.uploadFiles(files);
        this.resetImageInput();
    }

    uploadFiles(files: File[]) {
        if (files && files.length > 0) {
            const promises = files.map(
                file =>
                    new Promise<string>(resolve => {
                        const fr = new FileReader();
                        fr.onloadend = ($event: any) => {
                            const url = `data:image/jpeg;base64,${btoa(
                                $event.target.result
                            )}`;
                            this.store.dispatch(
                                new AddFile({
                                    file,
                                    url,
                                })
                            );
                            resolve(url);
                        };
                        fr.readAsBinaryString(file);
                    })
            );
            const previewsLoaded = Promise.all(promises);

            previewsLoaded.then(() => {
                this.imageService
                    .resizeImages(files, 512, 512, {
                        aspectRatio: {
                            keepAspectRatio: true,
                        },
                    })
                    .pipe(
                        mergeMap(
                            file =>
                                new Promise<string>(resolve => {
                                    const fr = new FileReader();
                                    fr.onloadend = ($event: any) => {
                                        const url = `data:image/jpeg;base64,${btoa(
                                            $event.target.result
                                        )}`;
                                        this.store.dispatch(
                                            new UpdateFile({
                                                file,
                                                url,
                                            })
                                        );
                                        resolve(url);
                                    };
                                    fr.readAsBinaryString(file);
                                })
                        ),
                        toArray(),
                        take(1)
                    )
                    .toPromise();
            });
            return previewsLoaded;
        } else {
            return Promise.resolve([]);
        }
    }

    resetImageInput() {
        if (this.cameraInput) {
            this.cameraInput.nativeElement.value = null;
        }
        this.imageInput.nativeElement.value = null;
    }

    selectFile(input: HTMLInputElement) {
        // for some reason safari only works when it bubbles up
        // tests for safari and iOS compatability before removing
        if (!/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
            input.click();
        }
    }
}
