"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var src_1 = require("@fixiti/actions/src");
exports.ENVIRONMENT_FEATURE_KEY = 'environment';
exports.initialState = {
    current: {},
    loaded: false,
};
function environmentReducer(state, action) {
    if (state === void 0) { state = exports.initialState; }
    switch (action.type) {
        case src_1.EnvironmentActionTypes.LoadEnvironment: {
            state = __assign({}, state, { current: action.payload, loaded: true });
            break;
        }
    }
    return state;
}
exports.environmentReducer = environmentReducer;
