"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var core_1 = require("@angular/core");
var src_1 = require("@fixiti/actions/src");
var store_1 = require("@ngrx/store");
var operators_1 = require("rxjs/operators");
var src_2 = require("@fixiti/actions/src");
var environment_1 = require("@env/environment");
var src_3 = require("@fixiti/state/user/src");
var AppComponent = /** @class */ (function () {
    function AppComponent(store, activatedRoute, router) {
        this.store = store;
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.isLoggedIn$ = this.store.pipe(store_1.select(src_3.userQuery.getUser), operators_1.map(function (user) { return !!user; }));
    }
    AppComponent.prototype.ngOnInit = function () {
        this.store.dispatch(new src_1.LoadEnvironment(environment_1.environment));
    };
    AppComponent.prototype.linkNav = function (event) {
        event.preventDefault();
        event.stopPropagation();
        this.store.dispatch(new src_2.Go({ path: [event.srcElement.getAttribute('href')] }));
    };
    return AppComponent;
}());
exports.AppComponent = AppComponent;
