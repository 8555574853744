"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
/// <reference path="../../../../../models/src/lib/api/paginated-server-response.ts" />
/// <reference path="../../../../../models/src/lib/api/person.ts" />
/// <reference path="../../../../../models/src/lib/api/widget.ts" />
/// <reference path="../../../../../models/src/lib/api/negotiation.ts" />
/// <reference path="../../../../../models/src/lib/api/all-query.ts" />
var http_1 = require("@angular/common/http");
var src_1 = require("@fixiti/actions/src");
var rxjs_1 = require("rxjs");
var store_1 = require("@ngrx/store");
var operators_1 = require("rxjs/operators");
var src_2 = require("@fixiti/elements/modal/src");
var rest_api_base_1 = require("@fixiti/api/rest/rest-api.base");
var src_3 = require("@fixiti/state/user/src");
var i0 = require("@angular/core");
var i1 = require("@ngrx/store");
var i2 = require("../../../../../elements/modal/src/lib/services/modal.service");
var i3 = require("@angular/common/http");
var PmpRestApiService = /** @class */ (function (_super) {
    __extends(PmpRestApiService, _super);
    function PmpRestApiService(store, modalService, http) {
        var _this = _super.call(this, store, modalService) || this;
        _this.store = store;
        _this.modalService = modalService;
        _this.http = http;
        return _this;
    }
    PmpRestApiService.prototype.login = function (user, password) {
        var _this = this;
        return this.serverObs.pipe(operators_1.mergeMap(function (server) {
            return _this.http
                .post(server + "api/1.0/pmp/login", {
                username: user,
                password: password,
            })
                .pipe(_this.checkForFailure());
        }));
    };
    PmpRestApiService.prototype.getDashboard = function () {
        return this.getItemList('dashboard/all').pipe(operators_1.map(function (data) {
            return data.map(function (item) {
                return ({
                    id: +item.id,
                    label: item.label,
                });
            });
        }));
    };
    PmpRestApiService.prototype.getWidgetTypes = function () {
        return this.getItemList('dashboard/object_types');
    };
    PmpRestApiService.prototype.getWidgetDefinitions = function () {
        return this.getItem('dashboard/widgets').pipe(operators_1.map(function (data) {
            var result = {};
            Object.entries(data).forEach(function (_a) {
                var key = _a[0], value = _a[1];
                result[key] = {
                    label: value.label,
                    count: +value.count,
                    object: value.object,
                    report_id: +value.report_id,
                };
            });
            return result;
        }));
    };
    PmpRestApiService.prototype.getMyDashboard = function () {
        return this.getItemList('dashboard/mine').pipe(operators_1.map(function (data) { return data.map(function (item) { return +item; }); }));
    };
    PmpRestApiService.prototype.updateMyDashboard = function (array) {
        return this.updateItem('dashboard/mine', array).pipe(
        //             tap(() => this.store.dispatch(new LoadWidgetDefinitions())),
        operators_1.map(function (data) { return data.map(function (item) { return +item; }); }));
    };
    PmpRestApiService.prototype.getProperties = function (query) {
        return this.getPaginatedItemList('properties', query, 'Properties');
    };
    PmpRestApiService.prototype.getPropertyItem = function (id) {
        return this.getItemById('properties', id);
    };
    PmpRestApiService.prototype.createProperty = function (item) {
        var property = __assign({}, item, { frontElevation: (item.frontElevation || []).map(function (picture) { return picture.id; }) });
        return this.createItem('properties', property);
    };
    PmpRestApiService.prototype.updateProperty = function (item) {
        var property = __assign({}, item, { frontElevation: (item.frontElevation || []).map(function (picture) { return picture.id; }) });
        return this.updateItemById('properties', property);
    };
    PmpRestApiService.prototype.removeProperty = function (item) {
        return this.removeItem('properties', item);
    };
    PmpRestApiService.prototype.getInvestors = function (query) {
        return this.getPaginatedItemList('investors', query, 'Investors');
    };
    PmpRestApiService.prototype.getInvestorItem = function (id) {
        return this.getItemById('investors', id);
    };
    PmpRestApiService.prototype.createInvestor = function (item) {
        return this.createItem('investors', item);
    };
    PmpRestApiService.prototype.updateInvestor = function (item) {
        return this.updateItemById('investors', item);
    };
    PmpRestApiService.prototype.removeInvestor = function (item) {
        return this.removeItem('investors', item);
    };
    PmpRestApiService.prototype.getResidents = function (query) {
        return this.getPaginatedItemList('residents', query, 'Residents');
    };
    PmpRestApiService.prototype.getResidentItem = function (id) {
        return this.getItemById('residents', id);
    };
    PmpRestApiService.prototype.createResident = function (item) {
        return this.createItem('residents', item);
    };
    PmpRestApiService.prototype.updateResident = function (item) {
        return this.updateItemById('residents', item);
    };
    PmpRestApiService.prototype.removeResident = function (item) {
        return this.removeItem('residents', item);
    };
    PmpRestApiService.prototype.getUsers = function (query) {
        return this.getPaginatedItemList('users', query, 'Users');
    };
    PmpRestApiService.prototype.getUserItem = function (id) {
        return this.getItemById('users', id);
    };
    PmpRestApiService.prototype.createUser = function (item) {
        return this.createItem('users', item);
    };
    PmpRestApiService.prototype.updateUser = function (item) {
        return this.updateItemById('users', item);
    };
    PmpRestApiService.prototype.removeUser = function (item) {
        return this.removeItem('users', item);
    };
    PmpRestApiService.prototype.getWorkOrders = function (query) {
        return this.getPaginatedItemList('jobs', query, 'Maintenance');
    };
    PmpRestApiService.prototype.getWorkOrderItem = function (id) {
        return this.getItemById('jobs', id);
    };
    PmpRestApiService.prototype.approveWorkOrderItem = function (id) {
        return this.updateItemById('jobs', {
            schedulingApproval: true,
        }, id);
    };
    PmpRestApiService.prototype.declineWorkOrderItem = function (id) {
        return this.updateItemById('jobs', {
            schedulingApproval: false,
        }, id);
    };
    PmpRestApiService.prototype.approveWorkOrderItemEstimate = function (id) {
        return this.updateItemById('jobs', {
            estimateApproval: true,
        }, id);
    };
    PmpRestApiService.prototype.declineWorkOrderItemEstimate = function (id) {
        return this.updateItemById('jobs', {
            estimateApproval: false,
        }, id);
    };
    PmpRestApiService.prototype.getWorkOrdersForProperty = function (propertyId) {
        return this.getPaginatedItemList('jobs', {
            property_id: propertyId,
        });
    };
    PmpRestApiService.prototype.approveWorkOrderItemPricing = function (id) {
        return this.updateItemById('negotiation', {
            accept: true,
        }, id);
    };
    PmpRestApiService.prototype.counterWorkOrderItemPricing = function (id, negotiatedPrice) {
        return this.updateItemById('negotiation', {
            negotiation: true,
            negotiatedPrice: negotiatedPrice,
        }, id);
    };
    PmpRestApiService.prototype.getWorkOrderItemNegotiation = function (id) {
        return this.getItemById('negotiation', id);
    };
    PmpRestApiService.prototype.createWorkOrderItemNegotiation = function (jobId, customerPrice) {
        return this.updateItem('negotiations/create', {
            jobId: jobId,
            customerPrice: customerPrice,
        });
    };
    PmpRestApiService.prototype.getRoles = function (query) {
        return this.getPaginatedItemList('roles', query, 'Roles');
    };
    PmpRestApiService.prototype.getUsersWithRole = function (roleId) {
        return this.getPaginatedItemList("roles/users/" + roleId);
    };
    PmpRestApiService.prototype.getPermissions = function (roleId, query) {
        return this.getPaginatedItemList("permissions/index/" + roleId, query);
    };
    PmpRestApiService.prototype.getPermissionForRole = function (roleId, permissionId) {
        return this.getItemById("roles/permissions/" + roleId + "/", permissionId);
    };
    PmpRestApiService.prototype.addRoleToUser = function (role_id, manager_id) {
        return this.updateItem("users/role", { role_id: role_id, manager_id: manager_id });
    };
    PmpRestApiService.prototype.removeRoleFroUser = function (role_id, manager_id) {
        return this.removeTerm("users/role", {
            role_id: role_id,
            manager_id: manager_id,
        }).pipe(operators_1.map(function (result) { return !!result; }));
    };
    PmpRestApiService.prototype.updateRole = function (roleId, name) {
        return this.updateItemById("roles", {
            name: name,
        }, roleId);
    };
    PmpRestApiService.prototype.requestPasswordRecovery = function (email) {
        var _this = this;
        return this.serverObs.pipe(operators_1.switchMap(function (server) { return _this.http.post(server + "api/1.0/pmp/login/request_recovery", { email: email }); }), this.checkForFailure(), operators_1.map(function (serverResponse) {
            return true;
        }), operators_1.catchError(function (error) { return _this.handleError(error); }));
    };
    PmpRestApiService.prototype.resetPassword = function (key, password) {
        var _this = this;
        return this.serverObs.pipe(operators_1.switchMap(function (server) {
            var credentials = {
                password: password,
                verifyPassword: password,
            };
            return _this.http.post(server + "api/1.0/pmp/login/reset_password/" + key, credentials);
        }), this.checkForFailure(), operators_1.map(function (serverResponse) {
            return {
                errors: serverResponse.errors || [],
                nErrors: +(serverResponse.nErrors || 0),
                data: serverResponse.data || {},
            };
        }), operators_1.catchError(function (error) { return _this.handleError(error); }));
    };
    PmpRestApiService.prototype.updatePermission = function (_a) {
        var roleId = _a.roleId, permissionId = _a.permissionId, read = _a.read, write = _a.write;
        return this.updateItem("roles/permissions/" + roleId + "/" + permissionId, {
            read: read,
            write: write,
        }).pipe(operators_1.map(function (data) { return (__assign({}, data, { roleId: roleId,
            permissionId: permissionId })); }));
    };
    PmpRestApiService.prototype.getPaginatedItemList = function (term, queryParameters, key) {
        var _this = this;
        return rxjs_1.zip(this.serverObs, this.defaultHttpHeaders()).pipe(operators_1.switchMap(function (_a) {
            var server = _a[0], headers = _a[1];
            return _this.http.get(server + "api/1.0/pmp/" + term, {
                headers: headers,
                params: _this.buildQueryParams(queryParameters, true),
            });
        }), this.checkForError(), operators_1.tap(function (json) {
            /* istanbul ignore else */
            if (key) {
                _this.store.dispatch(new src_1.UpdatePageLength({
                    key: key,
                    length: +json.data.pagination.num_matches,
                }));
            }
        }), operators_1.map(function (json) { return json.data.results; }), operators_1.catchError(function (error) { return _this.handleError(error); }));
    };
    PmpRestApiService.prototype.getItemList = function (term, queryParameters) {
        var _this = this;
        return rxjs_1.zip(this.serverObs, this.defaultHttpHeaders()).pipe(operators_1.switchMap(function (_a) {
            var server = _a[0], headers = _a[1];
            return _this.http.get(server + "api/1.0/pmp/" + term, {
                headers: headers,
                params: _this.buildQueryParams(queryParameters, true),
            });
        }), this.checkForError(), operators_1.map(function (json) { return json.data; }), operators_1.catchError(function (error) { return _this.handleError(error); }));
    };
    PmpRestApiService.prototype.getItem = function (term) {
        var _this = this;
        return rxjs_1.zip(this.serverObs, this.defaultHttpHeaders()).pipe(operators_1.switchMap(function (_a) {
            var server = _a[0], headers = _a[1];
            return _this.http.get(server + "api/1.0/pmp/" + term + "?" + Math.round(Date.now() / 30000), {
                headers: headers,
            });
        }), this.checkForError(), operators_1.map(function (json) { return json.data; }), operators_1.catchError(function (error) { return _this.handleError(error); }));
    };
    PmpRestApiService.prototype.getItemById = function (term, id) {
        var _this = this;
        return rxjs_1.zip(this.serverObs, this.defaultHttpHeaders()).pipe(operators_1.switchMap(function (_a) {
            var server = _a[0], headers = _a[1];
            return _this.http.get(server + "api/1.0/pmp/" + term + "/id/" + id, {
                headers: headers,
                params: _this.buildQueryParams(null, true),
            });
        }), this.checkForError(), operators_1.map(function (json) { return json.data; }), operators_1.catchError(function (error) { return _this.handleError(error); }));
    };
    PmpRestApiService.prototype.createItem = function (term, item) {
        var _this = this;
        return rxjs_1.zip(this.serverObs, this.defaultHttpHeaders()).pipe(operators_1.switchMap(function (_a) {
            var server = _a[0], headers = _a[1];
            return _this.http.post(server + "api/1.0/pmp/" + term + "/create", item, {
                headers: headers,
            });
        }), this.checkForError(), operators_1.map(function (json) { return JSON.parse(JSON.stringify(json.data)); }), operators_1.catchError(function (error) { return _this.handleError(error); }));
    };
    PmpRestApiService.prototype.updateItem = function (term, item) {
        var _this = this;
        return rxjs_1.zip(this.serverObs, this.defaultHttpHeaders()).pipe(operators_1.switchMap(function (_a) {
            var server = _a[0], headers = _a[1];
            return _this.http.post(server + "api/1.0/pmp/" + term, item, {
                headers: headers,
            });
        }), this.checkForError(), operators_1.map(function (json) { return json.data; }), operators_1.catchError(function (error) { return _this.handleError(error); }));
    };
    PmpRestApiService.prototype.updateItemById = function (term, item, id) {
        var _this = this;
        return rxjs_1.zip(this.serverObs, this.defaultHttpHeaders()).pipe(operators_1.switchMap(function (_a) {
            var server = _a[0], headers = _a[1];
            return _this.http.post(server + "api/1.0/pmp/" + term + "/id/" + (id != null ? id : item['id']), item, {
                headers: headers,
            });
        }), this.checkForError(), operators_1.map(function (json) { return json.data; }), operators_1.catchError(function (error) { return _this.handleError(error); }));
    };
    PmpRestApiService.prototype.removeItem = function (term, item) {
        return this.removeTerm(term + "/id/" + item);
    };
    PmpRestApiService.prototype.removeTerm = function (term, body) {
        var _this = this;
        return rxjs_1.zip(this.serverObs, this.defaultHttpHeaders()).pipe(operators_1.switchMap(function (_a) {
            var server = _a[0], headers = _a[1];
            if (body) {
                return _this.http.request('delete', server + "api/1.0/pmp/" + term, {
                    headers: headers,
                    body: body,
                });
            }
            else {
                return _this.http.delete(server + "api/1.0/pmp/" + term, {
                    headers: headers,
                });
            }
        }), this.checkForError(), operators_1.map(function (json) { return json; }), operators_1.catchError(function (error) { return _this.handleError(error); }));
    };
    PmpRestApiService.prototype.defaultHttpParameters = function () {
        return this.store.pipe(store_1.select(src_3.userQuery.getUser), operators_1.map(function (user) { return ({
            Authorization: "Bearer " + user.apiToken,
            MembershipId: user.memberships[0].id,
            CustomerId: user.memberships[0].customerId,
        }); }), operators_1.take(1));
    };
    PmpRestApiService.prototype.buildQueryParams = function (params, cacheBust) {
        if (cacheBust === void 0) { cacheBust = false; }
        var httpParams = new http_1.HttpParams();
        if (params) {
            Object.entries(params).forEach(function (_a) {
                var key = _a[0], value = _a[1];
                httpParams = httpParams.set(key, value);
            });
        }
        if (cacheBust) {
            httpParams = httpParams.set(Math.round(Date.now() / 30000).toString(), '');
        }
        return httpParams;
    };
    PmpRestApiService.ngInjectableDef = i0.defineInjectable({ factory: function PmpRestApiService_Factory() { return new PmpRestApiService(i0.inject(i1.Store), i0.inject(i2.ModalService), i0.inject(i3.HttpClient)); }, token: PmpRestApiService, providedIn: "root" });
    return PmpRestApiService;
}(rest_api_base_1.RestApiBase));
exports.PmpRestApiService = PmpRestApiService;
