"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
/// <reference path="../../../../../models/src/lib/api/paginated-server-response.ts" />
/// <reference path="../../../../../models/src/lib/api/person.ts" />
/// <reference path="../../../../../models/src/lib/api/widget.ts" />
/// <reference path="../../../../../models/src/lib/api/user.ts" />
var http_1 = require("@angular/common/http");
var rxjs_1 = require("rxjs");
var store_1 = require("@ngrx/store");
var operators_1 = require("rxjs/operators");
var src_1 = require("@fixiti/elements/modal/src");
var rest_api_base_1 = require("@fixiti/api/rest/rest-api.base");
var src_2 = require("@fixiti/actions/src");
var src_3 = require("@fixiti/state/settings/src");
var src_4 = require("@fixiti/state/user/src");
var i0 = require("@angular/core");
var i1 = require("@ngrx/store");
var i2 = require("../../../../../elements/modal/src/lib/services/modal.service");
var i3 = require("@angular/common/http");
var ImagesRestApiService = /** @class */ (function (_super) {
    __extends(ImagesRestApiService, _super);
    function ImagesRestApiService(store, modalService, http) {
        var _this = _super.call(this, store, modalService) || this;
        _this.store = store;
        _this.modalService = modalService;
        _this.http = http;
        return _this;
    }
    ImagesRestApiService.prototype.uploadImage = function (_a) {
        var _this = this;
        var file = _a.file, data = _a.data, type = _a.type, index = _a.index;
        return rxjs_1.zip(this.serverObs, this.defaultHttpHeaders().pipe(operators_1.map(function (headers) {
            return headers
                .append('dataType', 'json')
                .append('processData', 'false')
                .append('contentType', (file && file.type) || type)
                .append('Content-Type', (file && file.type) || type);
        }))).pipe(operators_1.switchMap(function (_a) {
            var server = _a[0], headers = _a[1];
            var req = new http_1.HttpRequest('put', server + "api/1.0/images/single_upload", data, {
                headers: headers,
                reportProgress: true,
            });
            return _this.http.request(req);
        }), this.retryStrategy(), this.checkForError(), operators_1.map(function (event) {
            if (event.type === http_1.HttpEventType.UploadProgress) {
                var progress = Math.round((100 * event.loaded) / event.total);
                _this.store.dispatch(new src_2.SubmitImagesProgress({ progress: progress, index: index }));
                return null;
            }
            else if (event instanceof http_1.HttpResponse) {
                _this.store.dispatch(new src_2.SubmitImagesProgress({ progress: 100, index: index }));
                return +event.body.data.id;
            }
            else {
                return null;
            }
        }), operators_1.filter(function (item) { return !!item; }), operators_1.catchError(function (error) { return _this.handleError(error); }));
    };
    ImagesRestApiService.prototype.rawUpload = function (file, callback) {
        var _this = this;
        return rxjs_1.zip(this.serverObs, this.defaultHttpHeaders()).pipe(operators_1.switchMap(function (_a) {
            var server = _a[0], headers = _a[1];
            var req = new http_1.HttpRequest('put', server + "api/1.0/images/raw_single_upload", file, {
                headers: headers,
                reportProgress: true,
            });
            return _this.http.request(req);
        }), this.retryStrategy(), operators_1.map(function (event) {
            if (event.type === http_1.HttpEventType.UploadProgress) {
                var progress = Math.round((100 * event.loaded) / event.total);
                callback(progress);
                return null;
            }
            if (event instanceof http_1.HttpResponse) {
                var json = event.body;
                if (json.status === 'failure') {
                    throw new Error('Whoops, we made a mistake somewhere.  Try again!');
                }
                if (json.status === 'error') {
                    throw new Error(json.errorMessage);
                }
                return +json.data.id;
            }
            return null;
        }), operators_1.filter(function (item) { return !!item; }), operators_1.catchError(function (error) { return _this.handleError(error); }));
    };
    ImagesRestApiService.prototype.defaultHttpParameters = function () {
        return rxjs_1.race(this.store.pipe(store_1.select(src_4.userQuery.getUser), operators_1.filter(function (state) { return !!state; }), operators_1.map(function (user) { return ({
            Authorization: "Bearer " + user.apiToken,
            MembershipId: user.membershipId || user.memberships[0].id,
            CustomerId: user.memberships[0].customerId,
        }); }), operators_1.timeout(1000)), this.store.pipe(store_1.select(src_3.selectConfiguration), operators_1.filter(function (state) { return !!state; }), operators_1.map(function (user) { return ({
            Authorization: "Bearer " + user.apiKey,
            MembershipId: user.membershipId,
        }); }), operators_1.timeout(1000))).pipe(operators_1.take(1));
    };
    ImagesRestApiService.ngInjectableDef = i0.defineInjectable({ factory: function ImagesRestApiService_Factory() { return new ImagesRestApiService(i0.inject(i1.Store), i0.inject(i2.ModalService), i0.inject(i3.HttpClient)); }, token: ImagesRestApiService, providedIn: "root" });
    return ImagesRestApiService;
}(rest_api_base_1.RestApiBase));
exports.ImagesRestApiService = ImagesRestApiService;
