import { Component, Input, OnInit } from '@angular/core';
import { DeleteFile, ReplaceFile, UpdateFile } from '@fixiti/actions/src';

import { PicaService } from '@fixiti/external/pica/src';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

@Component({
    selector: 'fixiti-photo-dialog-upload-images-gallery',
    templateUrl: './upload-images-gallery.component.html',
    styleUrls: ['./upload-images-gallery.component.css'],
})
export class UploadImagesGalleryComponent implements OnInit {
    window = window;
    @Input()
    gallery: any[] = [];
    @Input()
    images: ({ src?: File; url: string })[] = [];
    private _canDelete = true;
    get canDelete() {
        return this._canDelete;
    }
    @Input()
    set canDelete(value: boolean) {
        this._canDelete = value;
        this.conf = {
            ...this.conf,
            showDeleteControl: value,
        };
    }
    @Input()
    conf: any = {
        imageOffset: '0px',
        showDeleteControl: this.canDelete,
        showCloseControl: false,
        showImageTitle: true,
        inline: true,
    };
    @Input()
    text = 'before';
    @Input()
    minimum = 2;
    minimumArray: number[] = [1];

    /* istanbul ignore next */
    constructor(private store: Store<any>, private imageService: PicaService) {}

    ngOnInit() {
        if (this.minimum < 0) {
            throw new Error('minimum must be 0 or greater');
        }
        if (this.minimum !== 2) {
            const array = [];
            for (let i = 1; i < this.minimum; ++i) {
                array.push(i);
            }
            this.minimumArray = array;
        }
    }

    deleteImage(index) {
        const file = this.images[index]['src'];
        this.store.dispatch(
            new DeleteFile({
                file,
            })
        );
    }

    getIndex(length: number) {
        if (length <= this.minimum) {
            return this.minimum - 1;
        } else {
            return length - 1;
        }
    }

    getLastString(length: number) {
        if (length <= this.minimum) {
            return `#${this.minimum}`;
        } else {
            return `#${length}`;
        }
    }

    onChange($event, index = 0): void {
        this.replaceFile($event.target.files[0], index);
    }

    replaceFile(file: File, index: number) {
        return new Promise<string>(resolve => {
            const fr = new FileReader();
            fr.onloadend = ($event: any) => {
                const url = `data:image/jpeg;base64,${btoa(
                    $event.target.result
                )}`;
                this.store.dispatch(
                    new ReplaceFile({
                        file,
                        index,
                        url,
                    })
                );
                resolve(url);
                this.loadSmallerThumbnail(file);
            };
            fr.readAsBinaryString(file);
        });
    }

    loadSmallerThumbnail(file: File) {
        return new Promise<{
            url: string;
            src: File;
        }>(resolve => {
            const fr = new FileReader();
            fr.onloadend = (item: any) => {
                const url = `data:image/jpeg;base64,${btoa(
                    item.target.result
                )}`;
                this.store.dispatch(
                    new UpdateFile({
                        file,
                        url: url,
                    })
                );
                resolve({
                    src: file,
                    url: url,
                });
            };
            this.imageService
                .resizeImage(file, 512, 512, {
                    aspectRatio: {
                        keepAspectRatio: true,
                    },
                })
                .pipe(take(1))
                .subscribe(resizedFile => {
                    fr.readAsBinaryString(resizedFile);
                });
        });
    }

    isMobile() {
        if (!this.window || !this.window.navigator) {
            return false;
        }
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            this.window.navigator.userAgent
        );
    }

    selectFile(input: HTMLInputElement) {
        // for some reason safari only works when it bubbles up
        // tests for safari and iOS compatability before removing
        if (
            !/^((?!chrome|android).)*safari/i.test(
                this.window.navigator.userAgent
            )
        ) {
        }
        input.click();
    }
}
