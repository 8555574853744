<fixiti-photo-dialog-upload-images-gallery
    *ngIf="!(isUploadingObs | async)"
    class="preview"
    [images]="images | async"
    [minimum]="minimumObs | async"
    [text]="placeholderObs | async"
>
</fixiti-photo-dialog-upload-images-gallery>

<fixiti-photo-dialog-upload-images-selection class="input">
</fixiti-photo-dialog-upload-images-selection>

<fixiti-photo-dialog-upload-images-progress
    [isVisible]="isUploadingObs | async"
    [progress]="progressObs | async"
>
</fixiti-photo-dialog-upload-images-progress>

<fixiti-photo-dialog-upload-images-actions
    class="actions"
    [files]="images | async"
    [canSubmit]="!(isUploadingObs | async)"
    [canCancel]="!(isUploadingObs | async)"
    [minimum]="minimumObs | async"
    (submit)="onSubmit()"
    (cancel)="onCancel()"
></fixiti-photo-dialog-upload-images-actions>
