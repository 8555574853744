import { ModuleWithProviders, NgModule } from '@angular/core';
import {
    settingsInitialState,
    settingsReducer,
} from './+state/settings.reducer';

import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { SettingsEffects } from './+state/settings.effects';
import { StoreModule } from '@ngrx/store';

@NgModule({
    imports: [CommonModule],
})
export class SettingsModule {
    /* istanbul ignore next */
    static forRoot(): ModuleWithProviders {
        return {
            /* ts-lint ignore next */
            ngModule: RootSettingsModule,
        };
    }
}

@NgModule({
    imports: [
        StoreModule.forFeature('settings', settingsReducer, {
            initialState: settingsInitialState,
        }),
        EffectsModule.forFeature([SettingsEffects]),
        SettingsModule,
    ],
    exports: [SettingsModule],
})
export class RootSettingsModule {}
