"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var PageSettingsActionTypes;
(function (PageSettingsActionTypes) {
    PageSettingsActionTypes["ChangePage"] = "[PageSettings] Change Page";
    PageSettingsActionTypes["ChangePageDisplay"] = "[PageSettings] Change Page Display";
    PageSettingsActionTypes["ChangePageFilter"] = "[PageSettings] Change Page Filter";
    PageSettingsActionTypes["ChangePageIndex"] = "[PageSettings] Change Page Index";
    PageSettingsActionTypes["ChangePageSize"] = "[PageSettings] Change Page Size";
    PageSettingsActionTypes["ChangePageLength"] = "[PageSettings] Change Page Length";
    PageSettingsActionTypes["UpdatePageLength"] = "[PageSettings] Update Page Length";
    PageSettingsActionTypes["ChangePageSort"] = "[PageSettings] Change Page Sort";
    PageSettingsActionTypes["ChangePageSortDirection"] = "[PageSettings] Change Page Sort Direction";
    PageSettingsActionTypes["ChangePageSortField"] = "[PageSettings] Change Page Sort Field";
    PageSettingsActionTypes["ChangePageReportId"] = "[PageSettings] Change Page Report Id";
    PageSettingsActionTypes["HydratePage"] = "[PageSettings] Hydrate Page";
    PageSettingsActionTypes["UpdatePageUrl"] = "[PageSettings] Update Url";
    PageSettingsActionTypes["PageUrlUpdated"] = "[PageSettings] Page Url Updated";
})(PageSettingsActionTypes = exports.PageSettingsActionTypes || (exports.PageSettingsActionTypes = {}));
var ChangePage = /** @class */ (function () {
    function ChangePage(payload) {
        this.payload = payload;
        this.type = PageSettingsActionTypes.ChangePage;
    }
    return ChangePage;
}());
exports.ChangePage = ChangePage;
var ChangePageDisplay = /** @class */ (function () {
    function ChangePageDisplay(payload) {
        this.payload = payload;
        this.type = PageSettingsActionTypes.ChangePageDisplay;
    }
    return ChangePageDisplay;
}());
exports.ChangePageDisplay = ChangePageDisplay;
var ChangePageFilter = /** @class */ (function () {
    function ChangePageFilter(payload) {
        this.payload = payload;
        this.type = PageSettingsActionTypes.ChangePageFilter;
    }
    return ChangePageFilter;
}());
exports.ChangePageFilter = ChangePageFilter;
var ChangePageIndex = /** @class */ (function () {
    function ChangePageIndex(payload) {
        this.payload = payload;
        this.type = PageSettingsActionTypes.ChangePageIndex;
    }
    return ChangePageIndex;
}());
exports.ChangePageIndex = ChangePageIndex;
var ChangePageSize = /** @class */ (function () {
    function ChangePageSize(payload) {
        this.payload = payload;
        this.type = PageSettingsActionTypes.ChangePageSize;
    }
    return ChangePageSize;
}());
exports.ChangePageSize = ChangePageSize;
var UpdatePageLength = /** @class */ (function () {
    function UpdatePageLength(payload) {
        this.payload = payload;
        this.type = PageSettingsActionTypes.UpdatePageLength;
    }
    return UpdatePageLength;
}());
exports.UpdatePageLength = UpdatePageLength;
var ChangePageLength = /** @class */ (function () {
    function ChangePageLength(payload) {
        this.payload = payload;
        this.type = PageSettingsActionTypes.ChangePageLength;
    }
    return ChangePageLength;
}());
exports.ChangePageLength = ChangePageLength;
var ChangePageSort = /** @class */ (function () {
    function ChangePageSort(payload) {
        this.payload = payload;
        this.type = PageSettingsActionTypes.ChangePageSort;
    }
    return ChangePageSort;
}());
exports.ChangePageSort = ChangePageSort;
var ChangePageSortDirection = /** @class */ (function () {
    function ChangePageSortDirection(payload) {
        this.payload = payload;
        this.type = PageSettingsActionTypes.ChangePageSortDirection;
    }
    return ChangePageSortDirection;
}());
exports.ChangePageSortDirection = ChangePageSortDirection;
var ChangePageSortField = /** @class */ (function () {
    function ChangePageSortField(payload) {
        this.payload = payload;
        this.type = PageSettingsActionTypes.ChangePageSortField;
    }
    return ChangePageSortField;
}());
exports.ChangePageSortField = ChangePageSortField;
var ChangePageReportId = /** @class */ (function () {
    function ChangePageReportId(payload) {
        this.payload = payload;
        this.type = PageSettingsActionTypes.ChangePageReportId;
    }
    return ChangePageReportId;
}());
exports.ChangePageReportId = ChangePageReportId;
var HydratePage = /** @class */ (function () {
    function HydratePage(payload) {
        this.payload = payload;
        this.type = PageSettingsActionTypes.HydratePage;
    }
    return HydratePage;
}());
exports.HydratePage = HydratePage;
var UpdatePageUrl = /** @class */ (function () {
    function UpdatePageUrl() {
        this.type = PageSettingsActionTypes.UpdatePageUrl;
    }
    return UpdatePageUrl;
}());
exports.UpdatePageUrl = UpdatePageUrl;
var PageUrlUpdated = /** @class */ (function () {
    function PageUrlUpdated() {
        this.type = PageSettingsActionTypes.PageUrlUpdated;
    }
    return PageUrlUpdated;
}());
exports.PageUrlUpdated = PageUrlUpdated;
exports.fromPageSettingsActions = {
    ChangePage: ChangePage,
    ChangePageDisplay: ChangePageDisplay,
    ChangePageFilter: ChangePageFilter,
    ChangePageIndex: ChangePageIndex,
    ChangePageSize: ChangePageSize,
    ChangePageLength: ChangePageLength,
    UpdatePageLength: UpdatePageLength,
    ChangePageSort: ChangePageSort,
    ChangePageSortDirection: ChangePageSortDirection,
    ChangePageSortField: ChangePageSortField,
    ChangePageReportId: ChangePageReportId,
    HydratePage: HydratePage,
    UpdatePageUrl: UpdatePageUrl,
    PageUrlUpdated: PageUrlUpdated,
};
