"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".flex-container[_ngcontent-%COMP%]{display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center}.vertical[_ngcontent-%COMP%]{-webkit-flex-direction:column;flex-direction:column}.fill[_ngcontent-%COMP%]{width:100%}.center[_ngcontent-%COMP%]{text-align:center}span[_ngcontent-%COMP%]{font-weight:700;font-size:1.2rem}.hint[_ngcontent-%COMP%]{color:rgba(0,0,0,.5)}input[type=file][_ngcontent-%COMP%]{display:none}button[_ngcontent-%COMP%]{z-index:20;width:100%;max-width:calc(100vw - 20px)}.mat-file-input[_ngcontent-%COMP%]{display:contents;position:relative}.picture[_ngcontent-%COMP%]     button, .picture[_ngcontent-%COMP%]     mat-card{height:100px}.picture[_ngcontent-%COMP%]     fa-icon, .picture[_ngcontent-%COMP%]     img, .picture[_ngcontent-%COMP%]     svg{height:80px}.halo[_ngcontent-%COMP%]     button{box-shadow:0 0 40px 20px #94ba3f}\n/*# sourceMappingURL=upload-images-gallery.component.css.map*/"];
exports.styles = styles;
