export class PhotoDialogConfig {
    api?: PhotoDialogApi;
    job?: PhotoDialogJob;
    photoEvents?: PhotoDialogPhotoEvents;
    ApiUrl?: string;
    ApiKey?: string;
    MembershipId?: number;
    JobId?: number;
    JobStatus?: string;
    gallery?: any[];
    placeHolder: string;
    minimum: number;
}

export class PhotoDialogJob {
    id?: number;
    status: string;
}

export class PhotoDialogApi {
    url?: string;
    key?: string;
}

export class PhotoDialogPhotoEvents {
    shouldLoad: boolean;
    shouldSave: boolean;
}
