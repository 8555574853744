import {
    permissionsInitialState,
    permissionsReducer,
} from './+state/permissions.reducer';

import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';
import { PermissionsEffects } from './+state/permissions.effects';
import { StoreModule } from '@ngrx/store';
@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('permissions', permissionsReducer, {
            initialState: permissionsInitialState,
        }),
        EffectsModule.forFeature([PermissionsEffects]),
    ],
})
export class StatePermissionsModule {}
