"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./upload-images-progress.component.css.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../../../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory");
var i3 = require("@angular/material/progress-bar");
var i4 = require("@angular/platform-browser/animations");
var i5 = require("@angular/common");
var i6 = require("./upload-images-progress.component");
var styles_UploadImagesProgressComponent = [i0.styles];
var RenderType_UploadImagesProgressComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UploadImagesProgressComponent, data: {} });
exports.RenderType_UploadImagesProgressComponent = RenderType_UploadImagesProgressComponent;
function View_UploadImagesProgressComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "mat-progress-bar"], ["role", "progressbar"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i2.View_MatProgressBar_0, i2.RenderType_MatProgressBar)), i1.ɵdid(1, 4374528, null, 0, i3.MatProgressBar, [i1.ElementRef, i1.NgZone, [2, i4.ANIMATION_MODULE_TYPE], [2, i3.MAT_PROGRESS_BAR_LOCATION]], { value: [0, "value"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = i1.ɵinlineInterpolate(1, "", _co.progress, ""); _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).value; var currVal_1 = i1.ɵnov(_v, 1).mode; var currVal_2 = i1.ɵnov(_v, 1)._isNoopAnimation; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_UploadImagesProgressComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "timeout"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" Timeout: ", ":", " "])), i1.ɵppd(2, 2), i1.ɵppd(3, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 0), _co.minutes, "2.0")); var currVal_1 = i1.ɵunv(_v, 1, 1, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent, 0), _co.seconds, "2.0")); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_UploadImagesProgressComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, [[null, "load"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("load" === en)) {
        var pd_0 = (_co.setCountdownTimer() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_UploadImagesProgressComponent_3)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.countDownObs)); _ck(_v, 2, 0, currVal_0); }, null); }
function View_UploadImagesProgressComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i5.DecimalPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadImagesProgressComponent_1)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadImagesProgressComponent_2)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isVisible; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.isVisible; _ck(_v, 4, 0, currVal_1); }, null); }
exports.View_UploadImagesProgressComponent_0 = View_UploadImagesProgressComponent_0;
function View_UploadImagesProgressComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fixiti-photo-dialog-upload-images-progress", [], null, null, null, View_UploadImagesProgressComponent_0, RenderType_UploadImagesProgressComponent)), i1.ɵdid(1, 114688, null, 0, i6.UploadImagesProgressComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_UploadImagesProgressComponent_Host_0 = View_UploadImagesProgressComponent_Host_0;
var UploadImagesProgressComponentNgFactory = i1.ɵccf("fixiti-photo-dialog-upload-images-progress", i6.UploadImagesProgressComponent, View_UploadImagesProgressComponent_Host_0, { progress: "progress", isVisible: "isVisible", startDate: "startDate" }, {}, []);
exports.UploadImagesProgressComponentNgFactory = UploadImagesProgressComponentNgFactory;
