import { FieldFormDataModel } from './field-form-data.model';

export class FieldModel {
    name: string;
    label: string;
    formComponent?: any;
    form?: FieldFormDataModel;
    contentsAsHtml?: boolean;
    contentsFormatter?(model: any): string;
}
