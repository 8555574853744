import { createFeatureSelector, createSelector } from '@ngrx/store';

import { RolesState } from './roles.reducer';

// Lookup the 'Roles' feature state managed by NgRx
const getRolesState = createFeatureSelector<RolesState>('roles');

const getLoaded = createSelector(
    getRolesState,
    (state: RolesState) => state.loaded
);
const getError = createSelector(
    getRolesState,
    (state: RolesState) => state.error
);

const getAllRoles = createSelector(
    getRolesState,
    getLoaded,
    (state: RolesState, isLoaded) => {
        return isLoaded ? state.list : [];
    }
);
const getSelectedId = createSelector(
    getRolesState,
    (state: RolesState) => state.selectedId
);
const getSelectedRole = createSelector(
    getAllRoles,
    getSelectedId,
    (roles, id) => {
        const result = roles.find(it => it['id'] === id);
        return result ? Object.assign({}, result) : undefined;
    }
);
const getEditingId = createSelector(
    getRolesState,
    (state: RolesState) => state.editingId
);
const getEditingRole = createSelector(
    getAllRoles,
    getEditingId,
    (roles, id) => {
        const result = roles.find(it => it['id'] === id);
        return result ? Object.assign({}, result) : undefined;
    }
);
const getGroups = createSelector(
    getRolesState,
    (state: RolesState) => state.groups
);
const getSelectedGroup = createSelector(
    getGroups,
    getSelectedId,
    (groups, id) => {
        return groups[id];
    }
);
const getEditingGroup = createSelector(
    getGroups,
    getEditingId,
    (groups, id) => {
        return groups[id];
    }
);
const getUsersToAdd = createSelector(getRolesState, state =>
    Object.entries(state.addQueue).map(([roleId, users]) => ({
        roleId: +roleId,
        users,
    }))
);
const getUsersToRemove = createSelector(getRolesState, state =>
    Object.entries(state.removalQueue).map(([roleId, users]) => ({
        roleId: +roleId,
        users,
    }))
);
const getRoleChanges = createSelector(
    getUsersToAdd,
    getUsersToRemove,
    (add, removal) => ({
        add,
        removal,
    })
);

const getPermissionForRole = createSelector(
    getRolesState,
    state => state.permission
);

const getUsersForRole = createSelector(getRolesState, state => state.users);
const getRoleIsDirty = createSelector(getRolesState, state => state.isDirty);

export const rolesQuery = {
    getLoaded,
    getError,
    getAllRoles,
    getSelectedRole,
    getEditingRole,
    getSelectedGroup,
    getEditingGroup,
    getRoleChanges,
    getPermissionForRole,
    getUsersForRole,
    getRoleIsDirty,
};
