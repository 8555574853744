"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var store_1 = require("@ngrx/store");
// Lookup the 'Importer' feature state managed by NgRx
var getImporterState = store_1.createFeatureSelector('importer');
var ɵ0 = function (state) {
    return state.loaded;
};
exports.ɵ0 = ɵ0;
var getLoaded = store_1.createSelector(getImporterState, ɵ0);
var ɵ1 = function (state) {
    return state.showFilterMenu;
};
exports.ɵ1 = ɵ1;
var showFilterMenu = store_1.createSelector(getImporterState, ɵ1);
var ɵ2 = function (state) { return state.error; };
exports.ɵ2 = ɵ2;
var getError = store_1.createSelector(getImporterState, ɵ2);
var ɵ3 = function (state) { return state.showCreateImportFileForm; };
exports.ɵ3 = ɵ3;
var showCreateImportFileForm = store_1.createSelector(getImporterState, ɵ3);
var ɵ4 = function (state, isLoaded) {
    return isLoaded ? state.files : [];
};
exports.ɵ4 = ɵ4;
var getAllImportFiles = store_1.createSelector(getImporterState, getLoaded, ɵ4);
var ɵ5 = function (state) { return state.selectedId; };
exports.ɵ5 = ɵ5;
var getSelectedId = store_1.createSelector(getImporterState, ɵ5);
var ɵ6 = function (importFiles, id) {
    var result = importFiles.find(function (it) { return it['id'] === id; });
    return result ? Object.assign({}, result) : undefined;
};
exports.ɵ6 = ɵ6;
var getSelectedImportFile = store_1.createSelector(getAllImportFiles, getSelectedId, ɵ6);
var ɵ7 = function (state) {
    return state.selectedImportFileMapping;
};
exports.ɵ7 = ɵ7;
var getSelectedImportFileMapping = store_1.createSelector(getImporterState, ɵ7);
var ɵ8 = function (state) {
    return state.selectedImportFileMappingTos;
};
exports.ɵ8 = ɵ8;
var getSelectedImportFileMappingTos = store_1.createSelector(getImporterState, ɵ8);
var ɵ9 = function (state) {
    return state.importMappingInputErrors;
};
exports.ɵ9 = ɵ9;
var getInputErrors = store_1.createSelector(getImporterState, ɵ9);
var ɵ10 = function (state) {
    for (var _i = 0, _a = [
        'loadingImportMap',
        'savingImportMapping',
        'deletingImportFile',
        'creatingImportFile',
    ]; _i < _a.length; _i++) {
        var key = _a[_i];
        if (key in state && state[key]) {
            return true;
        }
    }
    return false;
};
exports.ɵ10 = ɵ10;
var isWorking = store_1.createSelector(getImporterState, ɵ10);
var ɵ11 = function (state) {
    return state.pageDisplay;
};
exports.ɵ11 = ɵ11;
var getPageDisplay = store_1.createSelector(getImporterState, ɵ11);
var ɵ12 = function (state) {
    return state.paginationData;
};
exports.ɵ12 = ɵ12;
var getPaginationData = store_1.createSelector(getImporterState, ɵ12);
var ɵ13 = function (state) { return state.showDelete; };
exports.ɵ13 = ɵ13;
var showDelete = store_1.createSelector(getImporterState, ɵ13);
var ɵ14 = function (importFiles, id) {
    var result = importFiles.find(function (it) { return it.id === id; });
    return result ? Object.assign({}, result) : undefined;
};
exports.ɵ14 = ɵ14;
var showDeleteRecord = store_1.createSelector(getAllImportFiles, showDelete, ɵ14);
var ɵ15 = function (state) {
    return state.querySettings;
};
exports.ɵ15 = ɵ15;
var getQuerySettings = store_1.createSelector(getImporterState, ɵ15);
exports.importerQuery = {
    getLoaded: getLoaded,
    showFilterMenu: showFilterMenu,
    getError: getError,
    showCreateImportFileForm: showCreateImportFileForm,
    getAllImportFiles: getAllImportFiles,
    getSelectedImportFile: getSelectedImportFile,
    getSelectedImportFileMapping: getSelectedImportFileMapping,
    getSelectedImportFileMappingTos: getSelectedImportFileMappingTos,
    isWorking: isWorking,
    getInputErrors: getInputErrors,
    getPageDisplay: getPageDisplay,
    getPaginationData: getPaginationData,
    getQuerySettings: getQuerySettings,
    showDelete: showDelete,
    showDeleteRecord: showDeleteRecord,
};
