"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./main-menu-toggle.component.css.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("./main-menu-toggle.component");
var i3 = require("@ngrx/store");
var styles_MainMenuToggleComponent = [i0.styles];
var RenderType_MainMenuToggleComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MainMenuToggleComponent, data: {} });
exports.RenderType_MainMenuToggleComponent = RenderType_MainMenuToggleComponent;
function View_MainMenuToggleComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["class", "burger"], ["href", "#"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Menu"]))], null, null); }
exports.View_MainMenuToggleComponent_0 = View_MainMenuToggleComponent_0;
function View_MainMenuToggleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fixiti-main-menu-toggle", [], null, null, null, View_MainMenuToggleComponent_0, RenderType_MainMenuToggleComponent)), i1.ɵdid(1, 49152, null, 0, i2.MainMenuToggleComponent, [i3.Store], null, null)], null, null); }
exports.View_MainMenuToggleComponent_Host_0 = View_MainMenuToggleComponent_Host_0;
var MainMenuToggleComponentNgFactory = i1.ɵccf("fixiti-main-menu-toggle", i2.MainMenuToggleComponent, View_MainMenuToggleComponent_Host_0, {}, {}, []);
exports.MainMenuToggleComponentNgFactory = MainMenuToggleComponentNgFactory;
