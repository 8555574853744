import { Component } from '@angular/core';

@Component({
    selector: 'fixiti-photo-dialog-dialog-body',
    templateUrl: './dialog-body.component.html',
    styleUrls: ['./dialog-body.component.css'],
})
export class DialogBodyComponent {
    /* istanbul ignore next */

    /* istanbul ignore next */
    constructor() {}
}
