import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/nx';
import { ImporterPartialState } from './importer.reducer';
import {
    ImportLoadFiles,
    ImportFilesLoadError,
    ImportFilesLoaded,
    ImportSelectFile,
    ImporterActionTypes,
    ImportMappingLoaded,
    ImportMappingLoadError,
    ImportSaveMapping,
    ImportMappingSaved,
    ImportMappingSaveError,
    ImportMappingInputErrors,
    ImportDeleteFile,
    ImportFileDeleted,
    ImportFileDeleteError,
    ImportCreateFile,
    ImportFileCreated,
    ImportFileCreateError,
    ImportSetQuerySetting,
} from '@fixiti/actions';
import { map } from 'rxjs/operators';

import { ApiRestImportService } from '@fixiti/api/rest/import/src/lib/api-rest-import.service';
import { ImportFileModel, ImportFileMapModel, ImportFileToModel } from '@fixiti/models';

@Injectable()
export class ImporterEffects {
    @Effect() loadImportFiles$ = this.dataPersistence.fetch(ImporterActionTypes.ImportLoadFiles, {
        run: (action: ImportLoadFiles, state: ImporterPartialState) => {
            return this.apiRestImportService
                .getImportFiles(action.apiSection, state.importer.querySettings)
                .pipe(
                    map((importFiles: ImportFileModel[]) => {
                        return new ImportFilesLoaded(importFiles);
                    })
                );
        },
        onError: (action: ImportLoadFiles, error) => {
            return new ImportFilesLoadError(error);
        },
    });

    @Effect() selectImportFile$ = this.dataPersistence.fetch(ImporterActionTypes.ImportSelectFile, {
        run: (action: ImportSelectFile, state: ImporterPartialState) => {
            return this.apiRestImportService
                .getMapping(state.importer.apiSection, action.payload)
                .pipe(map(mapping => new ImportMappingLoaded(mapping)));
        },
        onError: (action: ImportSelectFile, error) => {
            return new ImportMappingLoadError(error);
        },
    });

    @Effect() saveImportMapping$ = this.dataPersistence.fetch(ImporterActionTypes.ImportSaveMapping, {
        run: (action: ImportSaveMapping, state: ImporterPartialState) => {
            return this.apiRestImportService
                .setMapping(state.importer.apiSection, state.importer.selectedId, action.payload.columns)
                .pipe(
                    map(response => {
                        if ('errors' in response && Object.keys(response.errors).length) {
                            return new ImportMappingInputErrors(response.errors);
                        } else {
                            return new ImportMappingSaved({
                                columns: response.data.columns,
                                tos: response.data.tos,
                            });
                        }
                    })
                );
        },
        onError: (action: ImportSaveMapping, error) => {
            return new ImportMappingSaveError(error);
        },
    });

    @Effect() deleteImportFile$ = this.dataPersistence.fetch(ImporterActionTypes.ImportDeleteFile, {
        run: (action: ImportDeleteFile, state: ImporterPartialState) => {
            return this.apiRestImportService
                .deleteFile(state.importer.apiSection, action.payload)
                .pipe(map(serverResponse => new ImportFileDeleted()));
        },
        onError: (action: ImportDeleteFile, error) => {
            return new ImportFileDeleteError(error);
        },
    });

    @Effect() importFileDeleted$ = this.dataPersistence.fetch(ImporterActionTypes.ImportFileDeleted, {
        run: (action: ImportDeleteFile, state: ImporterPartialState) => {
            return new ImportLoadFiles(state.importer.apiSection);
        },
    });

    @Effect() importFileCreate$ = this.dataPersistence.fetch(ImporterActionTypes.ImportCreateFile, {
        run: (action: ImportCreateFile, state: ImporterPartialState) => {
            return this.apiRestImportService
                .createFile(state.importer.apiSection, action.payload.file)
                .pipe(map(serverResponse => new ImportFileCreated()));
        },
        onError: (action: ImportCreateFile, error) => {
            return new ImportFileCreateError(error);
        },
    });

    @Effect() importFileCreated$ = this.dataPersistence.fetch(ImporterActionTypes.ImportFileCreated, {
        run: (action: ImportCreateFile, state: ImporterPartialState) => {
            return new ImportLoadFiles(state.importer.apiSection);
        },
    });

    @Effect() importSetQuerySetting$ = this.dataPersistence.fetch(ImporterActionTypes.ImportSetQuerySetting, {
        run: (action: ImportSetQuerySetting, state: ImporterPartialState) => {
            return new ImportLoadFiles(state.importer.apiSection);
        },
    });

    constructor(
        private actions$: Actions,
        private apiRestImportService: ApiRestImportService,
        private dataPersistence: DataPersistence<ImporterPartialState>
    ) {}
}
