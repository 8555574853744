"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var src_1 = require("@fixiti/actions/src");
var store_1 = require("@ngrx/store");
var PhotoDialogJobSettings = /** @class */ (function () {
    function PhotoDialogJobSettings() {
    }
    return PhotoDialogJobSettings;
}());
exports.PhotoDialogJobSettings = PhotoDialogJobSettings;
var PhotoDialogApiSettings = /** @class */ (function () {
    function PhotoDialogApiSettings() {
    }
    return PhotoDialogApiSettings;
}());
exports.PhotoDialogApiSettings = PhotoDialogApiSettings;
var PhotoDialogMembershipSettings = /** @class */ (function () {
    function PhotoDialogMembershipSettings() {
    }
    return PhotoDialogMembershipSettings;
}());
exports.PhotoDialogMembershipSettings = PhotoDialogMembershipSettings;
var PhotoDialogPhotoEventsSettings = /** @class */ (function () {
    function PhotoDialogPhotoEventsSettings() {
    }
    return PhotoDialogPhotoEventsSettings;
}());
exports.PhotoDialogPhotoEventsSettings = PhotoDialogPhotoEventsSettings;
exports.photoDialogInitialState = {
    api: {
        url: null,
        key: null,
    },
    ids: [],
    job: {
        id: null,
        status: null,
    },
    membership: {
        id: null,
    },
    photoEvents: {
        shouldLoad: false,
        shouldSave: false,
    },
    placeHolder: '',
    gallery: [],
    minimum: 2,
};
function photoDialogReducer(state, action) {
    if (state === void 0) { state = exports.photoDialogInitialState; }
    switch (action.type) {
        case src_1.PhotoDialogTypes.LOAD_SETTINGS:
            if (!action.payload.config) {
                return state;
            }
            var config = action.payload.config;
            return __assign({}, state, { api: __assign({}, state.api, { url: config.ApiUrl, key: config.ApiKey }, config.api), job: __assign({}, state.job, { id: config.JobId }, config.job), membership: __assign({}, state.membership, { id: config.MembershipId }), photoEvents: __assign({}, state.photoEvents, config.photoEvents), placeHolder: config.placeHolder, gallery: config.gallery, minimum: config.minimum });
        case src_1.PhotoDialogTypes.ADD_IDS:
            return __assign({}, state, { ids: action.payload.ids });
        case src_1.PhotoDialogTypes.PHOTO_DIALOG_CLOSE:
            return exports.photoDialogInitialState;
        default:
            return state;
    }
}
exports.photoDialogReducer = photoDialogReducer;
exports.selectSettingsState = store_1.createFeatureSelector('photo-dialog');
exports.selectPhotoDialogConfig = store_1.createSelector(exports.selectSettingsState, function (state) {
    var ids = state.ids, config = __rest(state, ["ids"]);
    return Object.freeze(config);
});
exports.selectPhotoDialogIds = store_1.createSelector(exports.selectSettingsState, function (state) { return state.ids; });
exports.selectPhotoDialogPlaceHolder = store_1.createSelector(exports.selectSettingsState, function (state) { return state.placeHolder; });
