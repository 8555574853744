"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GO = '[Router] Go';
exports.BACK = '[Router] Back';
exports.FORWARD = '[Router] Forward';
var Go = /** @class */ (function () {
    function Go(payload) {
        this.payload = payload;
        this.type = exports.GO;
    }
    return Go;
}());
exports.Go = Go;
var Back = /** @class */ (function () {
    function Back() {
        this.type = exports.BACK;
    }
    return Back;
}());
exports.Back = Back;
var Forward = /** @class */ (function () {
    function Forward() {
        this.type = exports.FORWARD;
    }
    return Forward;
}());
exports.Forward = Forward;
